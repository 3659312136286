<div class="scope-snapshot-details-popup" *ngIf="scopeSnapshot">
	<div class="scope-variables-and-options-and-version">
		<div class="scope-variable-and-options" *ngFor="let scopeVariableToScopeDefiners of scopeVariableToScopeDefinersMap() | keyvalue">
			<div class="scope-variable">
				<div class="scope-variable-name">{{ scopeVariableToScopeDefiners.key }}</div>
			</div>
			<div class="scope-variable-options">
				<ng-container *ngFor="let scopeDefiner of scopeVariableToScopeDefiners.value">
					<span class="scope-variable-option" *ngIf="scopeDefiner.isScopeOptionIdExplicitlySelected">
						{{ !scopeDefiner.scopeOption ? 'All' : (scopeDefiner.scopeOption.value) }}
					</span>
				</ng-container>
			</div>
		</div>

		<div class="scope-version-label-and-version">
			<div class="scope-version-label-wrapper">
				<div class="scope-version-label">
					snapshot
				</div>
			</div>
			<div class="scope-version">
				{{ versionLabel(scopeSnapshot.scopeVersion) }}
			</div>
		</div>
	</div>
	<a [routerLink]="['/scope-snapshot/' + scopeSnapshot.id]" pRipple class="button go-to-scope-snapshot">Go to Inherited Scope Snapshot<div class="ficon icon-on-right ficon-ArrowUpRight8"></div></a>
</div>

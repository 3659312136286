import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DescendantScopeDifference, SavedSsInheritance, ScopeSnapshotFieldsForScopeSnapshotSelectorFragment, ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment, ScopeVariableFieldsForScopeSnapshotSelectorFragment, ValidatedSsInheritanceForDescendantSs } from 'src/generated/graphql';
import _ from 'lodash';
import { ModificationState, ScopeSnapshotSelectorComponent, SsSelectorTooltipDetails, State, Staticity } from '../scope-snapshot-selector/scope-snapshot-selector.component';
import { CommonService } from 'src/app/app-common/common.service';
import { SsInheritanceActionForTarget, ViewOrModifyOrDiscardSsInheritancesAction } from 'src/app/scope/scopes-page/scopes-page.component';
import { CompletedScopeDefinitionMap, ScopeSnapshotService } from 'src/app/scope-snapshot.service';
import { omitDeep } from '@apollo/client/utilities';
import { requireVar } from 'src/app/utilities';

export type InheritanceSetterCode = 'target-scope-snapshot' | 'delete-direct-inheritance' | 'revert-to-original'; // 'revert-to-original' means revert to the original snapshot before any modifications were made (or no inheritance if no inheritance was present)
type InheritanceSetterOption = {iconClass: string, code: InheritanceSetterCode, label: string};

export type InheritanceType = 'direct' | 'indirect';

type DescendantSnapshotsFilter = {
	inheritanceTypes: Set<InheritanceType>;
	inheritedSnapshotIdsOfTargetScope: Set<number> | 'any';
}

type SelectorStateForScopeFilter = {
	staticity: Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT;
	initialScope: ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment['scope'];
	actionsWhitelist: [];
	scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[];
	modifications?: {
		state: ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED,
		scopeDefinitionMap: CompletedScopeDefinitionMap, // Even though this is a *completed* scope definition map, we have no control over how the scope snapshot selector component modifies it, so we shouldn't rely on this continuing to be a completed scope definition map when the user plays around with the selector. Instead, we'll be relying on the (state-update) event on the scope snapshot selector component to update our state in this component, which will only be emitted if it's a completed scope definition map.
	}
}

export type DescendantManagerInnerState = {
	scopeFilter: CompletedScopeDefinitionMap;
	descendantSnapshotsFilter: DescendantSnapshotsFilter;
	includeNonDescendantsFilter: boolean;
	inheritanceSetter: InheritanceSetterCode;
}

type FilterIndexData = {
	indexWithinScope: number | undefined; // index of the descendant scope snapshot within its scope, after applying filters
	totalWithinScope: number; // total number of descendant scope snapshots in the scope, after applying filters
	descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number];
}

@Component({
  selector: 'app-scope-snapshot-modifiable-descendant-inheritances[target-scope-snapshot][descendant-scope-differences][scope-variables][grid-column-start-of-scope-snapshot-selector]',
  templateUrl: './scope-snapshot-modifiable-descendant-inheritances.component.html',
  styleUrl: './scope-snapshot-modifiable-descendant-inheritances.component.scss'
})
export class ScopeSnapshotModifiableDescendantInheritancesComponent implements OnInit {
	_ = _;
	Array = Array;
	versionLabel = CommonService.friendlyVersionLabel;
	requireVar = requireVar;
	inheritanceTypes: readonly InheritanceType[] = ['direct', 'indirect'] as const;
	ssStaticity = Staticity;
	component = ScopeSnapshotModifiableDescendantInheritancesComponent;
	@Input('target-scope-snapshot') targetScopeSnapshot?: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment;
	@Input('descendant-scope-differences') descendantScopeDifferences?: DescendantScopeDifference[];
	@Input('state') descendantManagerInnerState?: DescendantManagerInnerState;

	@Input('scope-variables') scopeVariables!: ScopeVariableFieldsForScopeSnapshotSelectorFragment[];
	@Input('grid-column-start-of-scope-snapshot-selector') gridColumnStartOfScopeSnapshotSelector!: string;
	@Output('view-or-modify-or-discard-ss-inheritances') viewOrModifyOrDiscardSsInheritances = new EventEmitter<ViewOrModifyOrDiscardSsInheritancesAction>();
	@Output('state-update') stateUpdate = new EventEmitter<DescendantManagerInnerState>();

	// Cached map (for performance reasons) indicating which descendant scope snapshots are visible in the descendant inheritances section, and which ones are hidden. This is different from functions like isDescendantScopeSnapshotWithinFilter() because those are not cached.
	// The map key is the descendant scope snapshot id. And the FilterIndexData contains the index and total number of descendant scope snapshots in the scope, after applying filters.
	// This cached map is updated whenever updateFilterIndexData() is called as a result of any of the filters being changed. It will use functions like isDescendantScopeSnapshotWithinFilter() to determine which descendant scope snapshots are visible.
	filterIndexDataOfDescendantScopeSnapshots: Map<number, FilterIndexData> = new Map();

	scopeSelectorState?: SelectorStateForScopeFilter;

	inheritanceSetterOptions: InheritanceSetterOption[] = []; // Initialized in ngOnInit

	constructor() {}

	ngOnInit(): void {
		const targetScopeSnapshot = requireVar(this.targetScopeSnapshot);

		let scopeSelectorState: SelectorStateForScopeFilter = {
			staticity: Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT,
			initialScope: targetScopeSnapshot.scope,
			actionsWhitelist: [],
			scopeVariables: this.scopeVariables,
		};
		if (this.descendantManagerInnerState?.scopeFilter !== undefined) {
			scopeSelectorState.modifications = {
				state: ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED,
				scopeDefinitionMap: this.descendantManagerInnerState.scopeFilter
			};
		}
		this.scopeSelectorState = scopeSelectorState;

		// If we haven't been initialized by the parent component for a descendant manager's state, then we'll use the default values.
		if (this.descendantManagerInnerState === undefined) {
			this.descendantManagerInnerState = {
				scopeFilter: ScopeSnapshotSelectorComponent.completedScopeDefinitionMap(scopeSelectorState) ?? this.completedScopeDefinitionMapOfTargetScope(),
				descendantSnapshotsFilter: {
					inheritanceTypes: new Set(['direct']),
					inheritedSnapshotIdsOfTargetScope: new Set([targetScopeSnapshot.id])
				},
				includeNonDescendantsFilter: true,
				inheritanceSetter: 'target-scope-snapshot'
			};
		}

		this.inheritanceSetterOptions = [
			this.inheritanceSetterCodeToOption('target-scope-snapshot'),
			this.inheritanceSetterCodeToOption('revert-to-original'),
			this.inheritanceSetterCodeToOption('delete-direct-inheritance')
		];

		this.handleNewDescendantManagerInnerState(); // Also emits the state update event which is necessary to update the url.
	}

	completedScopeDefinitionMapOfTargetScope(): CompletedScopeDefinitionMap {
		return ScopeSnapshotService.completedScopeDefinitionMapFromScope(requireVar(this.targetScopeSnapshot).scope);
	}

	/**
	 * Updates the cached map of which descendant scope snapshots are visible in the descendant inheritances section, and which ones are hidden.
	 * This cached map is updated whenever this function is called as a result of any of the filters being changed. It will use functions like isDescendantScopeSnapshotWithinFilter() to determine which descendant scope snapshots are visible.
	 * */
	updateFilterIndexData(): void {
		const descendantScopeDifferences = requireVar(this.descendantScopeDifferences);

		const filterIndexOfDescendantSnapshotInScope: Map<number, number | undefined> = new Map();
		const filterTotalSnapshotsInDescendantScopes: Map<number, number> = new Map();

		// We go through 2 passes in order to first determine the total number of descendant scope snapshots in each descendant scope after applying the filters, as well as the index of each descendant scope snapshot within its scope.
		for (const dsd of descendantScopeDifferences) {
			let filteredIndexWithinScope: number | undefined = undefined;
			let filteredTotalWithinScope = 0;
			for (const dss of dsd.descendantScopeSnapshots) {
				if (this.isDescendantScopeSnapshotWithinFilter(dss)) {
					if (filteredIndexWithinScope === undefined) {
						filteredIndexWithinScope = 0;
					}
					else {
						filteredIndexWithinScope++;
					}
					filterIndexOfDescendantSnapshotInScope.set(dss.id, filteredIndexWithinScope);

					filteredTotalWithinScope++;
					filterTotalSnapshotsInDescendantScopes.set(dsd.descendantScope.id, filteredTotalWithinScope);
				}
				else {
					filterIndexOfDescendantSnapshotInScope.set(dss.id, undefined);
					filterTotalSnapshotsInDescendantScopes.set(dsd.descendantScope.id, filteredTotalWithinScope);
				}
			}
		}

		// This is the second pass where we actually store the index data in the cached map, now that we have the right values in filterIndexOfDescendantSnapshotInScope and filterTotalSnapshotsInDescendantScopes.
		for (const dsd of descendantScopeDifferences) {
			for (const dss of dsd.descendantScopeSnapshots) {
				const filterIndex = filterIndexOfDescendantSnapshotInScope.get(dss.id);
				const filteredTotalWithinScope = filterTotalSnapshotsInDescendantScopes.get(dsd.descendantScope.id);

				if (filteredTotalWithinScope !== undefined) {
					const existingData = this.filterIndexDataOfDescendantScopeSnapshots.get(dss.id);
					const filterIndexData = {
						indexWithinScope: filterIndex,
						totalWithinScope: filteredTotalWithinScope,
						descendantScopeSnapshot: dss
					};

					if (existingData) {
						existingData.indexWithinScope = filterIndexData.indexWithinScope;
						existingData.totalWithinScope = filterIndexData.totalWithinScope;
					}
					else {
						this.filterIndexDataOfDescendantScopeSnapshots.set(dss.id, filterIndexData);
					}
				}
			}
		}
	}

	handleNewDescendantManagerInnerState(emitEvent: boolean = true): void {
		this.updateFilterIndexData();

		if (emitEvent) {
			this.stateUpdate.emit(this.descendantManagerInnerState);
		}
	}

	cachedIsDescendantScopeSnapshotWithinFilter(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const filterIndexData = this.filterIndexDataOfDescendantScopeSnapshots.get(descendantScopeSnapshot.id);
		if (filterIndexData === undefined) {
			return false;
		}
		return filterIndexData.indexWithinScope !== undefined;
	}

	cachedIsFirstDescendantSnapshotInScopeWithinFilter(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const filterIndexData = this.filterIndexDataOfDescendantScopeSnapshots.get(descendantScopeSnapshot.id);
		if (filterIndexData === undefined) {
			return false;
		}
		return filterIndexData.indexWithinScope === 0;
	}

	cachedIsLastDescendantSnapshotInScopeWithinFilter(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const filterIndexData = this.filterIndexDataOfDescendantScopeSnapshots.get(descendantScopeSnapshot.id);
		if (filterIndexData === undefined) {
			return false;
		}
		return filterIndexData.indexWithinScope === filterIndexData.totalWithinScope - 1;
	}

	isDescendantScopeWithinFilter(descendantScope: DescendantScopeDifference['descendantScope']): boolean {
		const descendantManagerInnerState = this.requireDescendantManagerInnerState();
		let parentScopeDefinitionMap = descendantManagerInnerState.scopeFilter;
		const childScopeDefinitionMap = ScopeSnapshotService.completedScopeDefinitionMapFromScope(omitDeep(descendantScope, '__typename'));

		return ScopeSnapshotSelectorComponent.isChildScopeDefinitionContainedInParentScopeDefinition(childScopeDefinitionMap, parentScopeDefinitionMap);
	}

	isDescendantScopeSnapshotWithinFilter(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const descendantScopeDifferences = requireVar(this.descendantScopeDifferences);
		const descendantScope = descendantScopeDifferences.find(dsd => dsd.descendantScope.id === descendantScopeSnapshot.scopeId)?.descendantScope;
		if (!descendantScope) {
			throw new Error('Cannot find descendant scope with id ' + descendantScopeSnapshot.scopeId + ' for descendant scope snapshot with id ' + descendantScopeSnapshot.id);
		}
		const isDescendantScopeWithinFilter = this.isDescendantScopeWithinFilter(descendantScope);
		if (!isDescendantScopeWithinFilter) {
			return false;
		}
		else {
			const descendantManagerInnerState = this.requireDescendantManagerInnerState();
			if (descendantScopeSnapshot.validatedInheritances.length === 0) {
				return descendantManagerInnerState.includeNonDescendantsFilter;
			}

			return descendantScopeSnapshot.validatedInheritances.some(inheritance => this.isValidatedInheritanceWithinFilter(inheritance));
		}
	}

	private isValidatedInheritanceWithinFilter(validatedInheritance: ValidatedSsInheritanceForDescendantSs): boolean {
		const descendantSnapshotsFilter = this.requireDescendantManagerInnerState().descendantSnapshotsFilter;

		// This shouldn't really be necessary since the server should only send us inheritances that are in the same scope as the target scope. But it's here just in case of bad data.
		const isInSameScopeAsTargetScope = validatedInheritance.inheritedScopeSnapshot.scopeId === requireVar(this.targetScopeSnapshot).scope.id;
		if (!isInSameScopeAsTargetScope) {
			return false;
		}
		// Same goes for self inheritances.
		if (validatedInheritance.isSelf) {
			return false;
		}

		const satisfiesInheritanceType =
			(descendantSnapshotsFilter.inheritanceTypes.has('direct') && validatedInheritance.isDirectInheritance) ||
			(descendantSnapshotsFilter.inheritanceTypes.has('indirect') && !validatedInheritance.isDirectInheritance);

		if (!satisfiesInheritanceType) {
			return false;
		}

		const satisfiesInheritedSnapshotIdsFilter =
			descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope === 'any' ||
			descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope.has(validatedInheritance.inheritedScopeSnapshotId);

		if (!satisfiesInheritedSnapshotIdsFilter) {
			return false;
		}

		return true;
	}

	inheritanceSetterCodeToOption(code: InheritanceSetterCode): InheritanceSetterOption {
		if (code === 'delete-direct-inheritance') {
			return {
				iconClass: 'ficon ficon-Remove',
				code: code,
				label: 'Remove direct inheritance'
			};
		}
		else if (code === 'revert-to-original') {
			return {
				iconClass: 'ficon ficon-UpdateRestore',
				code: code,
				label: 'Revert to original state'
			};
		}
		else {// if (code === 'target-scope-snapshot') {
			const targetScopeSnapshot = requireVar(this.targetScopeSnapshot);
			return {
				iconClass: 'ficon ficon-Childof',
				code: code,
				label: `Directly inherit ${this.versionLabel(targetScopeSnapshot.scopeVersion)}`
			};
		}

	}

	requireDescendantManagerInnerState(): DescendantManagerInnerState {
		if (this.descendantManagerInnerState === undefined) {
			throw new Error('Bulk descendant state is undefined');
		}
		return this.descendantManagerInnerState;
	}

	static primitiveToStaticScopeSnapshot = ScopeSnapshotSelectorComponent.primitiveToStaticScopeSnapshot;

	handleBulkDescendantScopeSelectorUpdate(state: State): void {
		const descendantManagerInnerState = this.requireDescendantManagerInnerState();
		const completedScopeDefinitionMap = ScopeSnapshotSelectorComponent.completedScopeDefinitionMap(state);
		if (completedScopeDefinitionMap === undefined) {
			return;
		}

		descendantManagerInnerState.scopeFilter = completedScopeDefinitionMap;
		this.handleNewDescendantManagerInnerState();
	}

	clickIncludeNonDescendantsFilter(): void {
		const descendantManagerInnerState = this.requireDescendantManagerInnerState();
		descendantManagerInnerState.includeNonDescendantsFilter = !descendantManagerInnerState.includeNonDescendantsFilter;
		this.handleNewDescendantManagerInnerState();
	}

	isIncludeNonDescendantsFilterSelected(): boolean {
		return this.requireDescendantManagerInnerState().includeNonDescendantsFilter;
	}

	labelForIncludeNonDescendantsFilter(): string {
		return 'Eligible descendants';
	}

	clickInheritanceTypeFilter(clickedInheritanceType: InheritanceType): void {
		const descendantSnapshotsFilter = this.requireDescendantManagerInnerState().descendantSnapshotsFilter;

		if (descendantSnapshotsFilter.inheritanceTypes.has(clickedInheritanceType)) {
			descendantSnapshotsFilter.inheritanceTypes.delete(clickedInheritanceType);
		}
		else {
			descendantSnapshotsFilter.inheritanceTypes.add(clickedInheritanceType);
		}
		this.handleNewDescendantManagerInnerState();
	}

	isInheritanceTypeFilterSelected(inheritanceType: InheritanceType): boolean {
		return this.requireDescendantManagerInnerState().descendantSnapshotsFilter.inheritanceTypes.has(inheritanceType);
	}

	labelForInheritanceTypeFilterOption(inheritanceType: InheritanceType): string {
		if (inheritanceType === 'direct') {
			return 'Direct descendants';
		}
		else {
			return 'Indirect descendants';
		}
	}

	clickEnableFilteringByInheritedTargetSnapshots(): void {
		const descendantSnapshotsFilter = this.requireDescendantManagerInnerState().descendantSnapshotsFilter;
		descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope = new Set();
		this.handleNewDescendantManagerInnerState();
	}

	clickDisableFilteringByInheritedTargetSnapshots(): void {
		const descendantSnapshotsFilter = this.requireDescendantManagerInnerState().descendantSnapshotsFilter;
		descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope = 'any';
		this.handleNewDescendantManagerInnerState();
	}

	clickToggleFilteringByInheritedTargetSnapshots(): void {
		if (this.isFilterByAllTargetSnapshotsSelected()) {
			this.clickEnableFilteringByInheritedTargetSnapshots();
		}
		else {
			this.clickDisableFilteringByInheritedTargetSnapshots();
		}
	}

	labelForFilterByAllInheritedSnapshots(): string {
		return 'Every snapshot in target scope';
	}

	clickFilterByInheritedSnapshotId(inheritedSnapshot: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment["scope"]["scopeSnapshots"][number]): void {
		const inheritedSnapshotId = inheritedSnapshot.id;
		const descendantSnapshotsFilter = this.requireDescendantManagerInnerState().descendantSnapshotsFilter;
		if (descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope === 'any') {
			descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope = new Set();
		}

		if (descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope.has(inheritedSnapshotId)) {
			descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope.delete(inheritedSnapshotId);
		}
		else {
			descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope.add(inheritedSnapshotId);
		}
		this.handleNewDescendantManagerInnerState();
	}

	isFilterByInheritedSnapshotExplicitlySelected(inheritedSnapshot: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment["scope"]["scopeSnapshots"][number]): boolean {
		const explicitlySelectedIds = this.requireDescendantManagerInnerState().descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope;
		return explicitlySelectedIds !== 'any' && explicitlySelectedIds.has(inheritedSnapshot.id);
	}

	isFilterByAllTargetSnapshotsSelected(): boolean {
		return this.requireDescendantManagerInnerState().descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope === 'any';
	}

	isFilterByInheritedSnapshotSelected(inheritedSnapshot: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment["scope"]["scopeSnapshots"][number]): boolean {
		return this.isFilterByInheritedSnapshotExplicitlySelected(inheritedSnapshot) || this.isFilterByAllTargetSnapshotsSelected();
	}

	labelForFilterByInheritedSnapshotOption(inheritedSnapshot: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment["scope"]["scopeSnapshots"][number]): string {
		return this.versionLabel(inheritedSnapshot.scopeVersion);
	}

	clickInheritanceSetterOption(inheritanceSetterOption: InheritanceSetterOption): void {
		const descendantManagerInnerState = this.requireDescendantManagerInnerState();
		descendantManagerInnerState.inheritanceSetter = inheritanceSetterOption.code;
		this.handleNewDescendantManagerInnerState();
	}

	pTooltipForInheritanceSetterOption(inheritanceSetterOption: InheritanceSetterOption): string | undefined {
		if (this.isInheritanceTypeFilterSelected('indirect')) {
			const allFilteredDescendantScopeSnapshots =
				[...this.filterIndexDataOfDescendantScopeSnapshots.values()]
					.filter(filterIndexData => filterIndexData.indexWithinScope !== undefined)
					.map(filterIndexData => filterIndexData.descendantScopeSnapshot);

			const areThereConflictingInheritances = allFilteredDescendantScopeSnapshots.some(dss => dss.validatedInheritances.length > 1);
			const areThereIndirectButNonConflictingDescendants = allFilteredDescendantScopeSnapshots.some(dss => dss.validatedInheritances.some(inheritance => !inheritance.isDirectInheritance) && dss.validatedInheritances.length <= 1);

			if (areThereIndirectButNonConflictingDescendants || areThereConflictingInheritances) {
				let tooltip = `Only modifies the direct parents of direct descendants${this.isIncludeNonDescendantsFilterSelected() ? ' and eligible descendants' : ''}.\n\nWill not modify the direct parents of `;
				if (areThereIndirectButNonConflictingDescendants && areThereConflictingInheritances) {
					tooltip += `indirect descendants or descendants that inherit from conflicting snapshots of the target scope.`;
				}
				else if (areThereIndirectButNonConflictingDescendants) {
					tooltip += `indirect descendants.`;
				}
				else { // At this point we know areThereConflictingInheritances is true and areThereIndirectButNonConflictingDescendants is false. i.e. in the UI we see conflicting inheritances in red, but no indirect descendants.
					tooltip += `descendants that inherit from conflicting snapshots of the target scope.`;
				}
				return tooltip;
			}
		}
	}

	labelForCurrentInheritanceFilters(): string {
		const descendantManagerInnerState = this.requireDescendantManagerInnerState();
		let label = '';

		const includesNonDescendants = descendantManagerInnerState.includeNonDescendantsFilter;
		const inheritanceTypes = descendantManagerInnerState.descendantSnapshotsFilter.inheritanceTypes;

		if (inheritanceTypes.size > 0) {
			const inheritedSnapshotsFilter = descendantManagerInnerState.descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope;

			const directAndIndirectInheritancesLabel = `${inheritedSnapshotsFilter === 'any' ? 'all ' : ''}${[...inheritanceTypes].sort().join('/')} descendants`;

			label += directAndIndirectInheritancesLabel.charAt(0).toUpperCase() + directAndIndirectInheritancesLabel.slice(1); // Capitalize the first letter

			if (inheritedSnapshotsFilter !== 'any') {
				if (inheritedSnapshotsFilter.size === 1) {
					const inheritedSnapshotVersions =
						requireVar(this.targetScopeSnapshot).scope.scopeSnapshots
							.filter(ss => inheritedSnapshotsFilter.has(ss.id))
							.map(ss => this.versionLabel(ss.scopeVersion));

					label += ` of ${inheritedSnapshotVersions.join(', ')}`;
				}
				else {
					label += ` of ${inheritedSnapshotsFilter.size} snapshots`;
				}
			}
		}

		if (inheritanceTypes.size > 0 && includesNonDescendants) {
			label += ', and eligibles';
		}
		else if (includesNonDescendants) {
			label += 'Eligible descendants';
		}

		if (inheritanceTypes.size === 0 && !includesNonDescendants) {
			label += 'No descendants selected';
		}

		return label;
	}

	isFirstInheritanceRow(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const descendantScopeDifferences = requireVar(this.descendantScopeDifferences);
		const firstNonEmptyScope = descendantScopeDifferences.find(dsd => dsd.descendantScopeSnapshots.length > 0);
		return firstNonEmptyScope?.descendantScopeSnapshots[0]?.id === descendantScopeSnapshot.id;

	}

	isLastInheritanceRow(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const descendantScopeDifferences = requireVar(this.descendantScopeDifferences);
		const reversed = [...descendantScopeDifferences].reverse();
		const lastNonEmptyScope = reversed.find(dsd => dsd.descendantScopeSnapshots.length > 0);

		if (lastNonEmptyScope !== undefined) {
			return lastNonEmptyScope.descendantScopeSnapshots[lastNonEmptyScope.descendantScopeSnapshots.length - 1]?.id === descendantScopeSnapshot.id;
		}
		return false;
	}

	static isInheritanceRowChanged(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
		const secondValidatedInheritance = descendantScopeSnapshot.validatedInheritances[1];
		// This means there's conflicting inheritances
		if (secondValidatedInheritance !== undefined) {
			return true;
		}

		if (firstValidatedInheritance === undefined) {
			return descendantScopeSnapshot.savedInheritance !== null;
		}
		else {
			return	descendantScopeSnapshot.savedInheritance === null ||
							firstValidatedInheritance.inheritedScopeSnapshotId !== descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshotId ||
							firstValidatedInheritance.isDirectInheritance !== descendantScopeSnapshot.savedInheritance.isDirectInheritance ||
							firstValidatedInheritance?.ordinalWithinDirectInheritances !== descendantScopeSnapshot.savedInheritance?.ordinalWithinDirectInheritances;
		}
	}

	isInheritanceRowNonDescendant(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): boolean {
		if (initialOrCurrent === 'initial') {
			return descendantScopeSnapshot.savedInheritance === null;
		}
		else {
			return descendantScopeSnapshot.validatedInheritances.length === 0;
		}
	}

	inheritanceIfInheritingFromJustAlternateSnapshotInScope(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): SavedSsInheritance | ValidatedSsInheritanceForDescendantSs | undefined {
		const targetScopeSnapshot = requireVar(this.targetScopeSnapshot);
		const inheritance = this.inheritanceIfInheritingFromTargetScopeWithoutConflicts(descendantScopeSnapshot, initialOrCurrent);

		if (inheritance !== undefined && inheritance.inheritedScopeSnapshot.id !== targetScopeSnapshot.id) {
			return inheritance;
		}
	}

	inheritanceIfInheritingFromJustTargetScopeSnapshot(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): SavedSsInheritance | ValidatedSsInheritanceForDescendantSs | undefined {
		const targetScopeSnapshot = requireVar(this.targetScopeSnapshot);
		const inheritance = this.inheritanceIfInheritingFromTargetScopeWithoutConflicts(descendantScopeSnapshot, initialOrCurrent);
		if (inheritance !== undefined && inheritance.inheritedScopeSnapshot.id === targetScopeSnapshot.id) {
			return inheritance;
		}
	}

	inheritanceIfInheritingFromTargetScopeWithoutConflicts(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): SavedSsInheritance | ValidatedSsInheritanceForDescendantSs | undefined {
		const targetScopeSnapshot = requireVar(this.targetScopeSnapshot);

		if (initialOrCurrent === 'initial') {
			if (descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshot.scopeId === targetScopeSnapshot.scopeId) {
				return descendantScopeSnapshot.savedInheritance;
			}
		}
		else {
			const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
			if (descendantScopeSnapshot.validatedInheritances.length === 1 && firstValidatedInheritance?.inheritedScopeSnapshot.scopeId === targetScopeSnapshot.scopeId) {
				return firstValidatedInheritance;
			}
		}
	}

	isInheritanceRowDirectDescendant(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): boolean {
		if (initialOrCurrent === 'initial') {
			return descendantScopeSnapshot.savedInheritance?.isDirectInheritance === true;
		}
		else {
			const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
			return firstValidatedInheritance?.isDirectInheritance === true && descendantScopeSnapshot.validatedInheritances.length === 1;
		}
	}

	isInheritanceRowIndirectDescendant(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): boolean {
		if (initialOrCurrent === 'initial') {
			return descendantScopeSnapshot.savedInheritance?.isDirectInheritance === false;
		}
		else {
			const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
			return firstValidatedInheritance?.isDirectInheritance === false && descendantScopeSnapshot.validatedInheritances.length === 1;
		}
	}

	isInheritanceRowUsingConflictingInheritances(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		return descendantScopeSnapshot.validatedInheritances.length > 1;
	}

	pTooltipForCurrentRowState(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): string {
		const targetScopeSnapshot = requireVar(this.targetScopeSnapshot);

		const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
		const secondValidatedInheritance = descendantScopeSnapshot.validatedInheritances[1];
		if (firstValidatedInheritance && secondValidatedInheritance) {
			return `This scope snapshot has conflicting inheritances because it inherits ${descendantScopeSnapshot.validatedInheritances.length} snapshot${descendantScopeSnapshot.validatedInheritances.length !== 1 ? 's' : ''} of the target scope: ${descendantScopeSnapshot.validatedInheritances.map(inheritance => this.versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)).join(', ')}.`;
		}
		else if (firstValidatedInheritance) {
			const indicatorForWhichSnapshotOfTargetScope = firstValidatedInheritance.inheritedScopeSnapshot.id === targetScopeSnapshot.id ? 'the target scope snapshot' : (this.versionLabel(firstValidatedInheritance.inheritedScopeSnapshot.scopeVersion) + ' of the target scope');

			const isFutureTense = ScopeSnapshotModifiableDescendantInheritancesComponent.isInheritanceRowChanged(descendantScopeSnapshot);

			if (firstValidatedInheritance.isDirectInheritance) {
				return `This scope snapshot ${isFutureTense ? 'will directly inherit' : 'directly inherits'} ${indicatorForWhichSnapshotOfTargetScope}.

If desired, use the snapshot dropdown to either inherit a different snapshot of the target scope, or choose not to inherit from the target scope at all.`;
			}
			else {
				return `This scope snapshot ${isFutureTense ? 'will indirectly inherit' : 'indirectly inherits'} ${indicatorForWhichSnapshotOfTargetScope}.`;
			}
		}
		else {
			const isFutureTense = ScopeSnapshotModifiableDescendantInheritancesComponent.isInheritanceRowChanged(descendantScopeSnapshot);
			return `This scope snapshot ${isFutureTense ? 'will no longer inherit' : 'does not inherit'} from the target scope.

If desired, select a snapshot of the target scope to inherit from.`;
		}
	}
	viewInheritancesOf(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): void {
		this.viewOrModifyOrDiscardSsInheritances.emit({
			ssInheritanceActionsForEachTarget: new Map([[descendantScopeSnapshot.id, {
				actionType: 'view',
				clearExistingModificationsIfPresent: false
			}]]),
			overallMergeStrategy: 'only-perform-actions-on-provided-targets',
			scrollToTargetScopeSnapshot: descendantScopeSnapshot.id
		});
	}

	inheritanceDropdownOptionsForRow(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): {id: number, scopeVersion: string}[] {
		const targetScopeSnapshot = requireVar(this.targetScopeSnapshot);
		const idToDetailsMap = new Map<number, {scopeVersion: string}>();

		if (descendantScopeSnapshot.savedInheritance !== null) {
			idToDetailsMap.set(descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshotId, {
				scopeVersion: descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshot.scopeVersion
			});
		}

		// descendantScopeSnapshot.validatedInheritances.forEach(inheritance => {
		// 	idToDetailsMap.set(inheritance.inheritedScopeSnapshotId, {
		// 		scopeVersion: inheritance.inheritedScopeSnapshot.scopeVersion
		// 	});
		// });

		// result.push({
		// 	id: descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshotId,
		// 		scopeVersion: descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshot.scopeVersion
		// 	});
		// }

		descendantScopeSnapshot.validatedInheritances.forEach(inheritance => {
			idToDetailsMap.set(inheritance.inheritedScopeSnapshotId, {
				scopeVersion: inheritance.inheritedScopeSnapshot.scopeVersion
			});
		});

		idToDetailsMap.set(targetScopeSnapshot.id, {
			scopeVersion: targetScopeSnapshot.scopeVersion
		});


		return Array.from(idToDetailsMap.entries()).map(([id, details]) => ({
			id,
			scopeVersion: details.scopeVersion
		}));
	}

	setInheritanceOfDescendantToSnapshotOfTargetScope(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], snapshotIdWithinTargetScope: number | null): void {
		const areThereConflictingInheritances = descendantScopeSnapshot.validatedInheritances[1] !== undefined;

		if (areThereConflictingInheritances) {
			throw new Error('Cannot set inheritance for a row that represents conflicting inheritances');
		}

		let newValidatedDirectParentIds = descendantScopeSnapshot.validatedDirectParentIds.slice();

		const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
		if (!firstValidatedInheritance) {
			if (snapshotIdWithinTargetScope === null) {
				return; // Nothing to do because we weren't inheriting from the target scope in the first place and so there's nothing to remove
			}
			else {
				newValidatedDirectParentIds.push(snapshotIdWithinTargetScope);
			}
		}
		else if (firstValidatedInheritance && firstValidatedInheritance.isDirectInheritance) {
			const indexOfDirectParentIdToReplace = descendantScopeSnapshot.validatedDirectParentIds.indexOf(firstValidatedInheritance.inheritedScopeSnapshotId);
			if (indexOfDirectParentIdToReplace === -1) {
				throw new Error('Cannot find direct parent id to replace');
			}
			else {
				if (snapshotIdWithinTargetScope === null) {
					newValidatedDirectParentIds.splice(indexOfDirectParentIdToReplace, 1);
				}
				else {
					newValidatedDirectParentIds[indexOfDirectParentIdToReplace] = snapshotIdWithinTargetScope;
				}
			}
		}
		else {
			throw new Error('Cannot set inheritance for a row that represents an indirect inheritance');
		}

		this.viewOrModifyOrDiscardSsInheritances.emit({
			ssInheritanceActionsForEachTarget: new Map([[descendantScopeSnapshot.id, {
				actionType: 'modify',
				directParentScopeSnapshotIds: newValidatedDirectParentIds,
				mergeStrategy: 'replace-entire-parent-list'
			}]]),
			overallMergeStrategy: 'only-perform-actions-on-provided-targets',
			scrollToTargetScopeSnapshot: null
		});
	}

	clickStageActionsOnDescendants(): void {
		const descendantScopeDifferences = requireVar(this.descendantScopeDifferences);
		const inheritanceSetterOption = this.inheritanceSetterCodeToOption(requireVar(this.descendantManagerInnerState).inheritanceSetter);
		const actionsMap = new Map<number, SsInheritanceActionForTarget>();
		const targetScopeSnapshot = requireVar(this.targetScopeSnapshot);

		for (const dsd of descendantScopeDifferences) {
			for (const dss of dsd.descendantScopeSnapshots) {
				if (this.cachedIsDescendantScopeSnapshotWithinFilter(dss)) {
					if (inheritanceSetterOption.code === 'revert-to-original') {
						actionsMap.set(dss.id, {
							actionType: 'discard'
						});
					}
					else if (inheritanceSetterOption.code === 'delete-direct-inheritance') {
						const directParentIdsToRemove = dss.validatedInheritances.filter(inheritance => inheritance.isDirectInheritance && inheritance.inheritedScopeSnapshot.scopeId === targetScopeSnapshot.scopeId).map(inheritance => inheritance.inheritedScopeSnapshotId);
						const newDirectParentIds = dss.validatedDirectParentIds.filter(id => !directParentIdsToRemove.includes(id));

						if (!_.isEqual(newDirectParentIds, dss.validatedDirectParentIds)) {
							actionsMap.set(dss.id, {
								actionType: 'modify',
								directParentScopeSnapshotIds: newDirectParentIds,
								mergeStrategy: 'replace-entire-parent-list'
							});
						}
					}
					else if (inheritanceSetterOption.code === 'target-scope-snapshot') {
						const otherSnapshotsInTargetScope = targetScopeSnapshot.scope.scopeSnapshots;
						const validatedDirectParentIdsWithTargetSsIdReplacements = dss.validatedDirectParentIds.map(
							id =>
								otherSnapshotsInTargetScope.find(ss => ss.id === id) ?
									targetScopeSnapshot.id :
									id
						);
						if (!validatedDirectParentIdsWithTargetSsIdReplacements.includes(targetScopeSnapshot.id)) {
							validatedDirectParentIdsWithTargetSsIdReplacements.push(targetScopeSnapshot.id);
						}

						if (!_.isEqual(validatedDirectParentIdsWithTargetSsIdReplacements, dss.validatedDirectParentIds)) {
							actionsMap.set(dss.id, {
								actionType: 'modify',
								directParentScopeSnapshotIds: validatedDirectParentIdsWithTargetSsIdReplacements,
								mergeStrategy: 'replace-entire-parent-list'
							});
						}
					}
				}
			}
		}

		this.viewOrModifyOrDiscardSsInheritances.emit({
			ssInheritanceActionsForEachTarget: actionsMap,
			overallMergeStrategy: 'only-perform-actions-on-provided-targets',
			scrollToTargetScopeSnapshot: null
		});
	}

	tooltipsForScopeFilter(): SsSelectorTooltipDetails {
		const toolbarState = this.requireVar(this.descendantManagerInnerState);
		return {
			scopeVariableDropdowns: this.scopeVariables.map(sv => {
				const scopeFilter = toolbarState.scopeFilter;
				const selectedScopeOptionIds = scopeFilter.get(sv.id);
				const isUnselected = selectedScopeOptionIds === undefined;
				if (isUnselected) {
					return {
						id: sv.id,
						message: undefined
					};
				}
				const isAllSelected = selectedScopeOptionIds.size === 0;

				const targetScopeOptionCsds = this.requireVar(this.targetScopeSnapshot).scope.comparableScopeDefiners;
				const targetScopeOptionIds: Set<number> = new Set();
				const selectedScopeOptions: NonNullable<ScopeSnapshotFieldsForScopeSnapshotSelectorFragment['scope']['comparableScopeDefiners'][number]['scopeOption']>[] = [];

				for (const csd of targetScopeOptionCsds) {
					if (csd.scopeOption !== null && selectedScopeOptionIds.has(csd.scopeOption.id)) {
						selectedScopeOptions.push(csd.scopeOption);
					}

					if (csd.isScopeOptionIdExplicitlySelected && csd.scopeVariable.id === sv.id && csd.scopeOption !== null) {
						targetScopeOptionIds.add(csd.scopeOption.id);
					}
				}

				const isScopeFilterStrictSubsetOfTarget =	(targetScopeOptionIds.size === 0 && selectedScopeOptionIds.size > 0) || _.some([...targetScopeOptionIds], (tsid) => !selectedScopeOptionIds.has(tsid));

				if (isAllSelected || !isScopeFilterStrictSubsetOfTarget) {
					return {
						id: sv.id,
						message: `No additional filtering applied on ${sv.name}.`
					}
				}
				else {
					return {
						id: sv.id,
						message: `Filtering down to only those descendants where their ${sv.name} is a subset of:\n\n${selectedScopeOptions.map(sso => sso.value).join(', ')}`
					}
				}
			}),
			snapshotDropdown: undefined
		}
	}
}

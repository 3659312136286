import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScopeSnapshotPageComponent } from './scope-snapshot-page/scope-snapshot-page.component';
import { DeploymentsPageComponent } from './deployments-page/deployments-page.component';
// import { FlagsComponent } from './flags/flags.component';


const routes: Routes = [
	{ path: 'scope-snapshot', component: ScopeSnapshotPageComponent },
	{ path: 'scope-snapshot/:scopeSnapshotId', component: ScopeSnapshotPageComponent },
	{ path: 'deployments', component: DeploymentsPageComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DevelopmentRoutingModule { }

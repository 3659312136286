import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { CrudStateService, GqlRequestInfo, gqlRequestInfo } from 'src/app/crud-state.service';
import { DeleteScopeMutation, DeleteScopeMutationVariables, OneSsInheritanceModifications, ScopeFieldsForScopeSnapshotSelectorFragmentDoc, ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment, ScopeVariableFieldsForScopeSnapshotSelectorFragmentDoc, ScopesPageQuery, ScopesPageQueryVariables, ValidateOrSaveInheritanceModificationsQuery, ValidateOrSaveInheritanceModificationsQueryVariables, ValidatedSsInheritance, ValidatedSsInheritanceModificationsFragment, ValidatedSsInheritanceModificationsFragmentDoc } from 'src/generated/graphql';
import { ErrorPreventingActionCode, ModificationState, Action as ScopeSnapshotSelectorAction, ActionType as ScopeSnapshotSelectorActionType, ScopeSnapshotSelectorComponent, State as ScopeSnapshotSelectorState, SelectableScopeWithNoVersionSelectorState, StaticScopeWithModifiableVersionSelectorState, Staticity } from '../../development/scope-snapshot-selector/scope-snapshot-selector.component';
import _ from 'lodash';
import { Subject, Subscription, map } from 'rxjs';
import { CompletedScopeDefinitionMap, EssentialValidatableScopeContainment, ScopeSnapshotService } from 'src/app/scope-snapshot.service';
import { CommonService } from 'src/app/app-common/common.service';
import type { XOR } from 'ts-xor'
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DescendantManagerState, ScopeSnapshotInheritancesComponent } from 'src/app/development/scope-snapshot-inheritances/scope-snapshot-inheritances.component';
import { InheritanceSetterCode, InheritanceType } from 'src/app/development/scope-snapshot-modifiable-descendant-inheritances/scope-snapshot-modifiable-descendant-inheritances.component';

// type CustomSelectorState = (XOR<SelectableScopeWithSelectableVersionSelectorState, StaticScopeWithStaticSnapshotSelectorState>) & {
// 	initialScope: NonNullable<Extract<ScopeSnapshotSelectorState, {staticity: Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION}>["initialScope"]>
// };

// type SelectorStateCommon = {
// 	initialScope: NonNullable<Extract<ScopeSnapshotSelectorState, {staticity: Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION}>["initialScope"]>,
// 	scopeVariables: ScopesPageQuery["scopeVariables"],
// 	actionsWhitelist: ScopeSnapshotSelectorActionType[],
// }

type ComponentGqlRequestInfos = {
	queries: {
		scopesPage: GqlRequestInfo<ScopesPageQuery, ScopesPageQueryVariables>,
		validateOrSaveInheritanceModifications: GqlRequestInfo<ValidateOrSaveInheritanceModificationsQuery, ValidateOrSaveInheritanceModificationsQueryVariables>,
	},
	mutations: {
		deleteScope: GqlRequestInfo<DeleteScopeMutation, DeleteScopeMutationVariables>,
	},
	fragments: {}
};

type InputsForValidatingInheritanceModifications = {[targetScopeSnapshotId: OneSsInheritanceModifications["targetScopeSnapshotId"]]: Omit<OneSsInheritanceModifications, "targetScopeSnapshotId">};
type RequiredDescendantManagerState = Required<DescendantManagerState>;
type DescendantSectionsMap = Map<number, RequiredDescendantManagerState>;

export type ValidatedInheritanceModifications = ValidatedSsInheritanceModificationsFragment;

// type CommonScopeDatum = {
// 	scope: ScopesPageQuery["scopes"][number],
// 	selectorState: CustomSelectorState,
// 	actioner: Subject<ScopeSnapshotSelectorAction>,
// 	isErrorExpanded?: boolean,
// }

enum PageState {
	MODIFIABLE_SCOPE_DEFINITIONS,
	MODIFIABLE_SS_INHERITANCES,
}

type CommonScopesPageData = {
	scopeVariables: ScopesPageQuery["scopeVariables"],
	scopeData: {
		scope: ScopesPageQuery["scopes"][number],
		// selectorState: CustomSelectorState,
		actioner: Subject<ScopeSnapshotSelectorAction>,
		// isErrorExpanded?: boolean,
	}[],
}

type ModifiableScopeDefinitionsPageData = Omit<CommonScopesPageData, "scopeData"> & {
	state: PageState.MODIFIABLE_SCOPE_DEFINITIONS,
	scopeData: (
		CommonScopesPageData["scopeData"][number] & {
			selectorState: SelectableScopeWithNoVersionSelectorState & {
				initialScope: NonNullable<Extract<ScopeSnapshotSelectorState, {staticity: Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT}>["initialScope"]>,
				actionsWhitelist: [
					ScopeSnapshotSelectorActionType.UPDATE_SCOPE_DEFINITION,
					ScopeSnapshotSelectorActionType.RESET_TO_INITIAL_STATE
				]
			},
			isErrorExpanded?: boolean,
		}
	)[],
	latestValidatableScopeContainments?: EssentialValidatableScopeContainment[];
};

type ModifiableSsInheritancesPageData = Omit<CommonScopesPageData, "scopeData"> & {
	state: PageState.MODIFIABLE_SS_INHERITANCES,
	scopeData: (
		CommonScopesPageData["scopeData"][number] & {
			selectorState: Extract<StaticScopeWithModifiableVersionSelectorState, {staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION}> & {
				initialScope: NonNullable<Extract<ScopeSnapshotSelectorState, {staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION}>["initialScope"]>,
				actionsWhitelist: [
					ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE,
					ScopeSnapshotSelectorActionType.RESET_TO_INITIAL_STATE,
					ScopeSnapshotSelectorActionType.VIEW_INHERITANCES_OF_NEWLY_SELECTED_SCOPE_SNAPSHOT
				]
			}
		}
	)[],
	inputsForValidatingInheritanceModifications: InputsForValidatingInheritanceModifications,
	descendantSectionsMap: DescendantSectionsMap,
	validatedInheritanceModifications?: ValidatedInheritanceModifications
}

export type SsInheritanceActionForTarget = {
	actionType: "view",
	clearExistingModificationsIfPresent: boolean,
}
|
{
	actionType: "modify",
	directParentScopeSnapshotIds: number[],
	mergeStrategy: "replace-entire-parent-list";// | "prepend-to-parent-list" | "append-to-parent-list" | "remove-from-parent-list"
}
|
{
	actionType: "discard",
}

export type SsInheritanceActionsMapForAllTargets = Map<number, // targetScopeSnapshotId
	SsInheritanceActionForTarget
>

export type ViewOrModifyOrDiscardSsInheritancesAction = {
	ssInheritanceActionsForEachTarget: SsInheritanceActionsMapForAllTargets,
	overallMergeStrategy: "only-perform-actions-on-provided-targets" | "replace-existing-inheritance-input-map-entirely"
	scrollToTargetScopeSnapshot: number | 'first-visible' | null
};

type ActionsWhitelistForModifyingScopeDefinitions = ModifiableScopeDefinitionsPageData["scopeData"][number]["selectorState"]["actionsWhitelist"];
type ActionsWhitelistForModifyingSsInheritances = ModifiableSsInheritancesPageData["scopeData"][number]["selectorState"]["actionsWhitelist"];

type ScopesPageData = XOR<ModifiableScopeDefinitionsPageData, ModifiableSsInheritancesPageData>;

@Component({
  selector: 'app-scopes-page',
  templateUrl: './scopes-page.component.html',
  styleUrls: ['./scopes-page.component.scss']
})
export class ScopesPageComponent implements OnInit, OnDestroy {
	@ViewChildren("inheritancesForSs") inheritanceSections: QueryList<ScopeSnapshotInheritancesComponent> = new QueryList();

	_ = _;
	self = ScopesPageComponent;
	Math = Math;
	PageState = PageState;
	typedIncompatibleInheritances = (incompatibleInheritances: EssentialValidatableScopeContainment[]) => incompatibleInheritances;

	versionLabel = CommonService.friendlyVersionLabel;

	private scopesPageQuery?: QueryRef<ScopesPageQuery, ScopesPageQueryVariables>;

	private actionsWhitelistForModifyingSsInheritances: ActionsWhitelistForModifyingSsInheritances = [
		ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE,
		ScopeSnapshotSelectorActionType.RESET_TO_INITIAL_STATE,
		ScopeSnapshotSelectorActionType.VIEW_INHERITANCES_OF_NEWLY_SELECTED_SCOPE_SNAPSHOT
	];

	private actionsWhitelistForModifyingScopeDefinitions: ActionsWhitelistForModifyingScopeDefinitions = [
		ScopeSnapshotSelectorActionType.UPDATE_SCOPE_DEFINITION,
		ScopeSnapshotSelectorActionType.RESET_TO_INITIAL_STATE,
	];

	ErrorPreventingActionCode = ErrorPreventingActionCode;
	isLoading = false;

	scopesPageData?: ScopesPageData;

	gqlRequestInfos: ComponentGqlRequestInfos = {
		queries: {
			scopesPage: gqlRequestInfo(gql`
				${ScopeFieldsForScopeSnapshotSelectorFragmentDoc}
				${ScopeVariableFieldsForScopeSnapshotSelectorFragmentDoc}
				query scopesPage {
					scopes(orderBy: {comparableScopeDefinition: {sortableScopeDefinition: DESC}}) {
						...scopeFieldsForScopeSnapshotSelector

						deployments(orderBy: {key: ASC, scopeVersion: ASC}) {
							id
							key
							scopeVersion
						}
					}
					scopeVariables(orderBy: {ordinal: ASC}) {
						...scopeVariableFieldsForScopeSnapshotSelector
					}
				}
			`),
			// When we load the modifiable inheritances for the first time, we will not pass in any modifications, but when the user modifies the inheritances, we will pass in the those modifications to the validateOrSaveInheritanceModifications query again.
			validateOrSaveInheritanceModifications: gqlRequestInfo(gql`
				${ValidatedSsInheritanceModificationsFragmentDoc}

				query validateOrSaveInheritanceModifications($modifications: [OneSsInheritanceModifications!]!, $validateOnly: Boolean!) {
					validateOrSaveSsInheritanceModifications(modifications: $modifications, validateOnly: $validateOnly) {
						...validatedSsInheritanceModifications
					}
				}
			`),
		},
		mutations: {
			deleteScope: gqlRequestInfo(gql`
				mutation deleteScope($id: Int!) {
					deleteScopesByPk(id: $id) {
						id
					}
				}
			`),
		},
		fragments: {}
	}

	private static scopeVersionGridColumnName = 'scope-version';

	ScopeSnapshotSelectorComponent = ScopeSnapshotSelectorComponent;
	actionTypes = ScopeSnapshotSelectorActionType;
	Staticity = Staticity;
	gridColumnStartOfScopeSnapshotSelector = 'start-of-scope-variable';
	modifiedInheritancesUrlSubscription?: Subscription;

	constructor(private apollo: Apollo, private cd: ChangeDetectorRef, private commonService: CommonService, private router: Router, private route: ActivatedRoute) { }

	requireScopesPageData(): NonNullable<ScopesPageComponent['scopesPageData']> {
		if (!this.scopesPageData) {
			throw new Error('Scopes page data is required to be loaded before it can be used');
		}
		return this.scopesPageData;
	}

	requireScopes(): NonNullable<ScopesPageComponent['scopesPageData']>['scopeData'][number]['scope'][] {
		return this.requireScopesPageData().scopeData.map((scopeDatum) => scopeDatum.scope);
	}

	requireModifiableScopeDefinitionsPageData(): Extract<NonNullable<ScopesPageComponent['scopesPageData']>, {state: PageState.MODIFIABLE_SCOPE_DEFINITIONS}> {
		const scopesPageData = this.requireScopesPageData();
		if (scopesPageData.state !== PageState.MODIFIABLE_SCOPE_DEFINITIONS) {
			throw new Error('Scopes page is not in a state where the scope definitions can be modified');
		}
		return scopesPageData;
	}

	requireModifiableSsInheritancesPageData(): Extract<NonNullable<ScopesPageComponent['scopesPageData']>, {state: PageState.MODIFIABLE_SS_INHERITANCES}> {
		const scopesPageData = this.requireScopesPageData();
		if (scopesPageData.state !== PageState.MODIFIABLE_SS_INHERITANCES) {
			throw new Error('Scopes page is not in a state where the scope snapshot inheritances can be modified');
		}
		return scopesPageData;
	}

	requireValidatedInheritanceModifications(): NonNullable<NonNullable<ScopesPageComponent['scopesPageData']>['validatedInheritanceModifications']> {
		const validatedInheritanceModifications = this.requireScopesPageData().validatedInheritanceModifications;
		if (!validatedInheritanceModifications) {
			throw new Error('Validated inheritance modifications are required to be loaded before they can be used');
		}
		return validatedInheritanceModifications;
	}

	static queryParamsForOpeningInheritancesOnScopesAndSnapshotsPage(scopeSnapshotId: number): Record<string, string | undefined> {
		return {
			[ScopesPageComponent.modifiedInheritancesUrlConfig.mainGetParam]: scopeSnapshotId.toString()
		}
	}

	allQueryParamsForValidatingSsInheritances(): Record<string, string | undefined> {
		const queryParams: Record<string, string | undefined> = {};

		const inputsForValidatingAncestorModifications = this.requireModifiableSsInheritancesPageData().inputsForValidatingInheritanceModifications;

		const encodedInheritances = ScopesPageComponent.encodeSsInheritancesForURL(inputsForValidatingAncestorModifications);
		queryParams[ScopesPageComponent.modifiedInheritancesUrlConfig.mainGetParam] = (!encodedInheritances) ? undefined : encodedInheritances;

		const descendantSectionsMap = this.requireModifiableSsInheritancesPageData().descendantSectionsMap;
		const encodedDescendantSections = this.encodeDescendantSectionsForURL(descendantSectionsMap);
		queryParams[ScopesPageComponent.descendantSectionsUrlConfig.mainGetParam] = (!encodedDescendantSections) ? undefined : encodedDescendantSections;

		return queryParams;
	}

	totalNumberOfScopeSnapshots(): number {
		const scopesPageData = this.requireScopesPageData();
		return _.sum(scopesPageData.scopeData.map((scopeDatum) => scopeDatum.scope.scopeSnapshots.length));
	}

	initPageForModifyingScopeDefinitions(scopes: ScopesPageQuery["scopes"], scopeVariables: ScopesPageQuery["scopeVariables"]) {
		// const scopesPageData = this.requireScopesPageData();
		const existingScopesPageDataByScopeId: {[scopeId: number]: ModifiableScopeDefinitionsPageData["scopeData"][number]} = {};
		let latestValidatableScopeContainments: ModifiableScopeDefinitionsPageData["latestValidatableScopeContainments"] = undefined;

		const scopesPageData = this.scopesPageData;

		if (scopesPageData !== undefined && scopesPageData.state === PageState.MODIFIABLE_SCOPE_DEFINITIONS) {
			latestValidatableScopeContainments = scopesPageData.latestValidatableScopeContainments;

			for (const scopeDatum of scopesPageData.scopeData) {
				scopeDatum.actioner.unsubscribe();

				existingScopesPageDataByScopeId[scopeDatum.scope.id] = scopeDatum;
			}
		}

		const newScopesPageData: ModifiableScopeDefinitionsPageData = {
			// ...scopesPageData,
			scopeVariables: scopeVariables,
			state: PageState.MODIFIABLE_SCOPE_DEFINITIONS,
			scopeData: scopes.map((scope) =>
				{
					const existingScopeDatum = existingScopesPageDataByScopeId[scope.id];

					let selectorState: ModifiableScopeDefinitionsPageData['scopeData'][number]['selectorState'];
					if (existingScopeDatum) {
						selectorState = ScopeSnapshotSelectorComponent.updateStateWithNewScopes(existingScopeDatum.selectorState, scopes);

						// Update the existing validatable containments so that they can be used with the new scopes.
						if (latestValidatableScopeContainments !== undefined) {
							const newScopeDefinitionMap = ScopeSnapshotService.completedScopeDefinitionMapFromScope(selectorState.initialScope);
							latestValidatableScopeContainments = ScopeSnapshotService.validatableScopeContainmentsWithModifiedScope(latestValidatableScopeContainments, selectorState.initialScope.id, newScopeDefinitionMap, scopeVariables);
						}

					}
					else {
						selectorState = {
							initialScope: scope,
							actionsWhitelist: this.actionsWhitelistForModifyingScopeDefinitions,
							scopeVariables: scopeVariables,
							staticity: Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT,
						};
					}

					return {
						scope,
						selectorState: selectorState,
						actioner: new Subject<ScopeSnapshotSelectorAction>(),
						latestValidatableScopeContainments: latestValidatableScopeContainments,
					};
				}
			)
		};

		// Update the existing validation entries so that other scope definition modifications can now be validated with our new data.
		if (latestValidatableScopeContainments !== undefined) {
			for (const scopeDatum of newScopesPageData.scopeData) {
				ScopeSnapshotSelectorComponent.updateStateWithNewValidatableScopeContainments(scopeDatum.selectorState, latestValidatableScopeContainments);
			}
		}

		this.scopesPageData = newScopesPageData;

		this.cd.detectChanges();
	}

	initPageForModifyingSsInheritances(scopes: ScopesPageQuery["scopes"], scopeVariables: ScopesPageQuery["scopeVariables"]) {
		const scopesPageData = this.scopesPageData;
		if (scopesPageData !== undefined) {
			for (const scopeDatum of scopesPageData.scopeData) {
				scopeDatum.actioner.unsubscribe();
			}
		}

		const newScopesPageData: ModifiableSsInheritancesPageData = {
			scopeVariables: scopeVariables,
			state: PageState.MODIFIABLE_SS_INHERITANCES,
			scopeData: scopes.map((scope) => ({
				scope: scope,
				actioner: new Subject(),
				selectorState: {
					initialScope: scope,
					scopeVariables: scopeVariables,
					actionsWhitelist: this.actionsWhitelistForModifyingSsInheritances,
					staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION,
				}
			})),
			inputsForValidatingInheritanceModifications: {},
			descendantSectionsMap: new Map(),
		};

		this.scopesPageData = newScopesPageData;

		this.cd.detectChanges();
	}

	isModifyingScopeDefinitions(): boolean {
		return this.requireScopesPageData().state === PageState.MODIFIABLE_SCOPE_DEFINITIONS;
	}

	ngOnInit(): void {
		const scopesQueryInfo = this.gqlRequestInfos.queries.scopesPage;
		scopesQueryInfo.subscription?.unsubscribe();

		this.scopesPageQuery = this.apollo.watchQuery({
			query: this.gqlRequestInfos.queries.scopesPage.gql,
			variables: {},
		});

		this.isLoading = true;

		this.gqlRequestInfos.queries.scopesPage.subscription = this.scopesPageQuery.valueChanges.subscribe({
			next: ({ data, loading }) => {
				this.isLoading = false;

				if (data) {
					if (data.scopeVariables.length === 0) {
						this.router.navigate(['/']); // Go home if there are no scope variables.
					}
					if (this.scopesPageData !== undefined && this.scopesPageData.state === PageState.MODIFIABLE_SCOPE_DEFINITIONS) {
						this.initPageForModifyingScopeDefinitions(data.scopes, data.scopeVariables);
					}
					else {
						this.initPageForModifyingSsInheritances(data.scopes, data.scopeVariables);

						const mods = this.route.snapshot.queryParamMap.get(ScopesPageComponent.modifiedInheritancesUrlConfig.mainGetParam);
						if (mods !== null && mods !== "" && this.scopesPageData) {
							this.validateOrSaveSsInheritanceModifications(ScopesPageComponent.decodeSsInheritancesFromURL(mods), true, 'first-visible');
						}
					}
				}
			},
			error: (error) => {
				this.isLoading = false;
				this.commonService.queryErrorHandler(error);
			}
		});

		const modifiedInheritancesUrl$ = this.route.queryParamMap.pipe(
      map((params: ParamMap) => params.get(ScopesPageComponent.modifiedInheritancesUrlConfig.mainGetParam)),
    );

		this.modifiedInheritancesUrlSubscription = modifiedInheritancesUrl$.subscribe((modifiedInheritancesParam) => {
			if (this.scopesPageData && this.scopesPageData.state === PageState.MODIFIABLE_SS_INHERITANCES) {
				const currentInputs = this.scopesPageData.inputsForValidatingInheritanceModifications;

				if (modifiedInheritancesParam !== null) {
					const newUrlInputs = ScopesPageComponent.decodeSsInheritancesFromURL(modifiedInheritancesParam);

					const isModified = !_.isEqual(newUrlInputs, currentInputs);

					// The provided inputs for inheritance modifications are different from the current inputs, so reload the inheritances based on the new inputs.
					if (isModified) {
						this.validateOrSaveSsInheritanceModifications(newUrlInputs, true, null);
					}
					// Otherwise, the provided inputs for inheritance modifications are the exact same as the current inputs. We likely got here by actually modifying the inheritances on this page which updated the url. Do not reload the inheritances. This is important to avoid an infinite loop of reloading inheritances.
					else {
						// console.log('Modified inheritances URL param matches the encoded inputs for validating inheritance modifications. Not reloading inheritances.', newUrlInputs, currentInputs);
					}
				}
				else {
					// If the URL param is null, then discard all inheritance modifications to reflect that no inputs are present.
					if (!_.isEqual(currentInputs, {})) {
						this.discardAllSsInheritanceModifications();
					}
				}
			}
		});
	}

	toggleScopeDefinitionErrorExpansion(scopeDatum: ModifiableScopeDefinitionsPageData['scopeData'][number]): void {
		scopeDatum.isErrorExpanded = !scopeDatum.isErrorExpanded;
	}

	handleScopeSnapshotSelectorStateChange(newState: ScopeSnapshotSelectorState): void {
		const scopesPageData = this.requireScopesPageData();
		// If a scope snapshot selector has just fetched a fresh copy of the validatable containments, then update our latest copy of it here in this component and also update all of the scopes with it.
		if ((newState.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION || newState.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION) && newState.modifications?.state === ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED && newState.modifications.validatableScopeContainments !== undefined) {
			scopesPageData.latestValidatableScopeContainments = newState.modifications.validatableScopeContainments;

			for (const scopeDatum of this.requireScopesPageData().scopeData) {
				ScopeSnapshotSelectorComponent.updateStateWithNewValidatableScopeContainments(scopeDatum.selectorState, newState.modifications.validatableScopeContainments);
			}
		}
	}

	areSsInheritancesSaveable(): boolean {
		const scopesPageData = this.requireScopesPageData();
		if (scopesPageData.state !== PageState.MODIFIABLE_SS_INHERITANCES) {
			return false;
		}

		const validatedInheritanceModifications = scopesPageData.validatedInheritanceModifications;
		if (!validatedInheritanceModifications || validatedInheritanceModifications.validationsByTargetScopeSnapshots.length === 0) {
			return false;
		}

		const aggMods = validatedInheritanceModifications.aggregateModifications;

		if (
			aggMods.deleteSsDirectInheritancesWhere.length === 0 &&
			aggMods.updateSsDirectInheritances.length === 0 &&
			aggMods.insertSsDirectInheritances.length === 0 &&
			aggMods.deleteConflictingSsDirectInheritancesWhere.length === 0
		) {
			return false;
		}

		if (this.isLoading) {
			return false;
		}

		if (this.allInheritancesContainingConflictingInheritances().length > 0 || this.allIncompatibleFlagNodes().length > 0 || this.allIncompatibleFlagValues().length > 0) {
			return false;
		}

		return true;
	}

	allInheritancesContainingConflictingInheritances(): ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number][] {
		const scopesPageData = this.requireScopesPageData();
		if (scopesPageData.state !== PageState.MODIFIABLE_SS_INHERITANCES || !scopesPageData.validatedInheritanceModifications) {
			return [];
		}

		const conflictCausingInheritances: ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number][] = [];

		for (const validationsForTargetScopeSnapshot of scopesPageData.validatedInheritanceModifications.validationsByTargetScopeSnapshots) {
			for (const ancestorScopeDifference of validationsForTargetScopeSnapshot.ancestorScopeDifferences) {
				if (ancestorScopeDifference.validatedInheritances.length >= 2) {
					conflictCausingInheritances.push(ancestorScopeDifference);
				}
			}
		}

		return conflictCausingInheritances;
	}

	allIncompatibleFlagNodes(): ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"][number]["incompatibleFlagNodes"][number][] {
		const scopesPageData = this.requireScopesPageData();
		if (scopesPageData.state !== PageState.MODIFIABLE_SS_INHERITANCES || !scopesPageData.validatedInheritanceModifications) {
			return [];
		}

		let incompatibleFlagNodes: ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"][number]["incompatibleFlagNodes"][number][] = [];

		for (const validationsForTargetScopeSnapshot of scopesPageData.validatedInheritanceModifications.validationsByTargetScopeSnapshots) {
			incompatibleFlagNodes = incompatibleFlagNodes.concat(validationsForTargetScopeSnapshot.incompatibleFlagNodes);
		}

		return incompatibleFlagNodes;
	}

	allIncompatibleFlagValues(): ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"][number]["incompatibleFlagValues"][number][] {
		const scopesPageData = this.requireScopesPageData();
		if (scopesPageData.state !== PageState.MODIFIABLE_SS_INHERITANCES || !scopesPageData.validatedInheritanceModifications) {
			return [];
		}

		let incompatibleFlagValues: ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"][number]["incompatibleFlagValues"][number][] = [];

		for (const validationsForTargetScopeSnapshot of scopesPageData.validatedInheritanceModifications.validationsByTargetScopeSnapshots) {
			incompatibleFlagValues = incompatibleFlagValues.concat(validationsForTargetScopeSnapshot.incompatibleFlagValues);
		}

		return incompatibleFlagValues;
	}

	errorNoticesThatPreventSavingSsInheritances(): string[] {
		const scopesPageData = this.requireScopesPageData();
		if (scopesPageData.state !== PageState.MODIFIABLE_SS_INHERITANCES || !scopesPageData.validatedInheritanceModifications) {
			return [];
		}

		const conflictCausingInheritances = this.allInheritancesContainingConflictingInheritances();
		const incompatibleFlagNodes = this.allIncompatibleFlagNodes();
		const incompatibleFlagValues = this.allIncompatibleFlagValues();

		const errors: string[] = [];

		if (conflictCausingInheritances.length > 0) {
			errors.push(conflictCausingInheritances.length + ' Conflicting Inheritance' + (conflictCausingInheritances.length !== 1 ? 's' : ''));
		}

		if (incompatibleFlagNodes.length > 0) {
			errors.push(incompatibleFlagNodes.length + ' Orphan Flag Node' + (incompatibleFlagNodes.length !== 1 ? 's' : ''));
		}

		if (incompatibleFlagValues.length > 0) {
			errors.push(incompatibleFlagValues.length + ' Orphan Flag Value' + (incompatibleFlagValues.length !== 1 ? 's' : ''));
		}

		return errors;
	}

	areAnySsInheritancesBeingEdited(): boolean {
		const scopesPageData = this.requireScopesPageData();
		return scopesPageData.state === PageState.MODIFIABLE_SS_INHERITANCES && (Object.keys(scopesPageData.inputsForValidatingInheritanceModifications).length > 0 || !!(scopesPageData.validatedInheritanceModifications?.validationsByTargetScopeSnapshots?.length));
	}

	discardAllSsInheritanceModifications() {
		const scopesPageData = this.requireScopesPageData();

		if (scopesPageData.state === PageState.MODIFIABLE_SS_INHERITANCES) {
			scopesPageData.inputsForValidatingInheritanceModifications = {};
			scopesPageData.validatedInheritanceModifications = undefined;
		}
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: {
				[ScopesPageComponent.modifiedInheritancesUrlConfig.mainGetParam]: undefined,
				[ScopesPageComponent.descendantSectionsUrlConfig.mainGetParam]: undefined
			},
			queryParamsHandling: 'merge'
		});

	}

	private validateOrSaveSsInheritanceModifications(inputs: InputsForValidatingInheritanceModifications, validateOnly: boolean, scrollToTargetScopeSnapshotId: ViewOrModifyOrDiscardSsInheritancesAction['scrollToTargetScopeSnapshot'] = null, newDescendantSectionsMap: DescendantSectionsMap | undefined = undefined): void {
		const scopesPageData = this.requireModifiableSsInheritancesPageData();

		const modifications: OneSsInheritanceModifications[] = [];

		const priorInputs = scopesPageData.inputsForValidatingInheritanceModifications;
		const priorDescendantSectionsMap = scopesPageData.descendantSectionsMap;

		Object.entries(inputs).forEach(([targetScopeSnapshotId, inputForTargetScopeSnapshot]) => {
			modifications.push({
				targetScopeSnapshotId: parseInt(targetScopeSnapshotId),
				replacementScopeSnapshotIdsToDirectlyInherit: inputForTargetScopeSnapshot.replacementScopeSnapshotIdsToDirectlyInherit
			});
		});

		scopesPageData.inputsForValidatingInheritanceModifications = inputs;
		if (newDescendantSectionsMap !== undefined) {
			scopesPageData.descendantSectionsMap = newDescendantSectionsMap;
		}
		this.isLoading = true;

		// Telling angular to detect changes here avoids the error "ExpressionChangedAfterItHasBeenCheckedError" that would normally occur if we set isLoading to true and then immediately make a request, like we do below.
		this.cd.detectChanges();

		this.gqlRequestInfos.queries.validateOrSaveInheritanceModifications.subscription = this.apollo.query({
			query: this.gqlRequestInfos.queries.validateOrSaveInheritanceModifications.gql,
			variables: {modifications, validateOnly},
			fetchPolicy: 'no-cache'
		}).subscribe({
			next: ({ data, loading }) => {
				this.isLoading = false;

				const isError = !data || data.validateOrSaveSsInheritanceModifications === undefined;
				if (!isError) {
					scopesPageData.inputsForValidatingInheritanceModifications = {};

					const newValidatedInheritanceModifications: ValidatedInheritanceModifications = {
						..._.omit(data.validateOrSaveSsInheritanceModifications, "validationsByTargetScopeSnapshots"),
						validationsByTargetScopeSnapshots: []
					};

					data.validateOrSaveSsInheritanceModifications.validationsByTargetScopeSnapshots.forEach((validation) => {
						const newValidatedInheritances: (Pick<ValidatedSsInheritance, 'inheritedScopeSnapshotId' | 'ordinalWithinDirectInheritances'> & {isDirectInheritance: true, isSelf: false})[] = [];

						for (const ancestorScopeDifference of validation.ancestorScopeDifferences) {
							for (const vi of ancestorScopeDifference.validatedInheritances) {
								if (vi.isDirectInheritance === true && vi.isSelf === false) {
									newValidatedInheritances.push({
										inheritedScopeSnapshotId: vi.inheritedScopeSnapshotId,
										ordinalWithinDirectInheritances: vi.ordinalWithinDirectInheritances,
										isDirectInheritance: vi.isDirectInheritance,
										isSelf: vi.isSelf
									});
								}
							}
						}

						const replacementScopeSnapshotIdsToDirectlyInherit = newValidatedInheritances.sort((a, b) => a.ordinalWithinDirectInheritances - b.ordinalWithinDirectInheritances).map((inheritance) => inheritance.inheritedScopeSnapshotId);

						scopesPageData.inputsForValidatingInheritanceModifications[validation.targetScopeSnapshot.id] = {
							replacementScopeSnapshotIdsToDirectlyInherit
						};

						newValidatedInheritanceModifications.validationsByTargetScopeSnapshots.push({
							...validation,
						});
					});

					if (!validateOnly) {
						this.commonService.messageService.add({
							severity: 'success',
							summary: 'Inheritances Saved',
						});
					}
					scopesPageData.validatedInheritanceModifications = newValidatedInheritanceModifications;

					const queryParams = this.allQueryParamsForValidatingSsInheritances();

					this.router.navigate([], {
						relativeTo: this.route,
						queryParams: queryParams,
						queryParamsHandling: 'merge'
					});

					this.cd.detectChanges();
					this.scrollToInheritancesSection(scrollToTargetScopeSnapshotId);
				}
				else {
					scopesPageData.inputsForValidatingInheritanceModifications = priorInputs;
					scopesPageData.descendantSectionsMap = priorDescendantSectionsMap;
					this.locallyReloadInheritanceSections();
					this.commonService.queryErrorHandler(`Error ${validateOnly ? "validating" : "saving"} inheritance modifications.`);
				}
			},
			error: (error) => {
				this.isLoading = false;
				scopesPageData.inputsForValidatingInheritanceModifications = priorInputs;
				scopesPageData.descendantSectionsMap = priorDescendantSectionsMap;
				this.locallyReloadInheritanceSections();
				this.commonService.queryErrorHandler(error);
			}
		});
	}

	private scrollToInheritancesSection(targetScopeSnapshot: ViewOrModifyOrDiscardSsInheritancesAction['scrollToTargetScopeSnapshot']) {
		if (targetScopeSnapshot !== null) {
			let targetScopeSnapshotId: number | undefined;
			if (targetScopeSnapshot === 'first-visible') {
				const firstInheritanceSectionInDOM = this.inheritanceSections.get(0);
				targetScopeSnapshotId = firstInheritanceSectionInDOM?.targetScopeSnapshot?.id;
			}
			else {
				targetScopeSnapshotId = targetScopeSnapshot;
			}

			if (targetScopeSnapshotId === undefined) {
				return;
			}
			let componentToScrollTo: ScopeSnapshotInheritancesComponent | undefined = undefined;
			for (const inheritancesComponent of this.inheritanceSections.toArray()) {
				if (inheritancesComponent.targetScopeSnapshot?.id === targetScopeSnapshotId) {
					componentToScrollTo = inheritancesComponent;
					break;
				}
			}
			if (componentToScrollTo !== undefined) {
				const element = componentToScrollTo.elementRef.nativeElement;
				element.scrollIntoView({
					behavior: "smooth",
					// block: "start",
					// inline: "nearest"
				});
			}
		}
	}

	/**
	 * Use this when you want Angular to refresh each scope snapshot's inheritance sections. This is useful when the user made a bunch of selections (like new direct parent selections) that resulted in a failed request (maybe because there's no internet connection, or any other reason). In which case, you would want to retract the user's selections and go back to the most recently validated state.
	 */
	private locallyReloadInheritanceSections() {
		const scopesPageData = this.requireModifiableSsInheritancesPageData();
		const tempMods = scopesPageData.validatedInheritanceModifications;

		scopesPageData.validatedInheritanceModifications = undefined;
		this.cd.detectChanges(); // Removes all <app-scope-snapshot-inheritances> components from the view

		scopesPageData.validatedInheritanceModifications = tempMods;
		this.cd.detectChanges(); // Adds them back in with a valid state
	}

	static modifiedInheritancesUrlConfig = {
		mainGetParam: "inheritance-modifications",
		joinParentsUsing: ",",
		joinInheritanceSectionsUsing: ";",
		startParentsListUsing: "(",
		endParentsListUsing: ")",
	};

	private static encodeSsInheritancesForURL(inheritancesOfMultipleSs: InputsForValidatingInheritanceModifications): string {
		let encodedParts: string[] = [];

		const urlConfig = ScopesPageComponent.modifiedInheritancesUrlConfig;

		for (const [targetScopeSnapshotId, inheritancesOfSs] of Object.entries(inheritancesOfMultipleSs)) {
			if (inheritancesOfSs.replacementScopeSnapshotIdsToDirectlyInherit !== undefined && inheritancesOfSs.replacementScopeSnapshotIdsToDirectlyInherit !== null){
				encodedParts.push(+targetScopeSnapshotId + urlConfig.startParentsListUsing + inheritancesOfSs.replacementScopeSnapshotIdsToDirectlyInherit.join(urlConfig.joinParentsUsing) + urlConfig.endParentsListUsing);
			}
			else {
				encodedParts.push("" + targetScopeSnapshotId);
			}
		}

		return encodedParts.join(urlConfig.joinInheritanceSectionsUsing);
	}

	private static decodeSsInheritancesFromURL(encodedInheritances: string): InputsForValidatingInheritanceModifications {
		const inputs: InputsForValidatingInheritanceModifications = {};
		const urlConfig = ScopesPageComponent.modifiedInheritancesUrlConfig;

		const basesWithParents = encodedInheritances.split(urlConfig.joinInheritanceSectionsUsing);
		for (const baseWithParents of basesWithParents) {
			// Go from "1(2,3)" to ["1", "2,3"]. Or from "1" to ["1"]. Where the first element is the target scope snapshot id, and the second element is the list of parent ids (if provided).
			const baseWithParentsSplit = baseWithParents.replace(urlConfig.endParentsListUsing, "").split(urlConfig.startParentsListUsing);
			const targetScopeSnapshotId = baseWithParentsSplit[0];
			if (targetScopeSnapshotId === undefined) {
				throw Error("Couldn't decode url into inheritances.");
			}
			const parentScopeSnapshotIds: number[] | undefined = baseWithParentsSplit[1]?.split(urlConfig.joinParentsUsing).filter((p) => p !== "").map((p) => +p);
			// If the parent ids were provided, open up the inheritances section and select the parents
			if (parentScopeSnapshotIds !== undefined) {

				inputs[+targetScopeSnapshotId] = {
					replacementScopeSnapshotIdsToDirectlyInherit: parentScopeSnapshotIds
				}
			}
			// Otherwise, simply open up the inheritances section
			else {
				inputs[+targetScopeSnapshotId] = {};
			}
		}

		return inputs;
	}

	static descendantSectionsUrlConfig = {
		mainGetParam: "descendant-sections",
		joinDescendantSectionsUsing: ";",
		joinParamsUsing: "-",
		openSectionValue: "open",
		descendantScopeFilterParam: "dsf",
		descendantInheritanceFilterParam: "dif",
		descendantActionParam: "da",
		joinParamValuesUsing: ",",
		startParamValuesUsing: "(",
		endParamValuesUsing: ")",
		inheritanceSetterCodeToAbbreviation: {
			'target-scope-snapshot': 't',
			'revert-to-original': 'r',
			'delete-direct-inheritance': 'd'
		},
		inheritanceTypeToAbbreviation: {
			'direct': 'd',
			'indirect': 'i'
		},
		includeEligibleDescendantsFilterAbbreviation: 'e',
		anyInheritedSnapshotIdsAbbreviation: 'any'
	} as const;

	/**
	 *
	 * @param descendantSectionsMap
	 * @returns Convert a descendant sections map to a url query string like: 493(open)-dsf(1,2,3)-dif(d,i,e,any)-da(r)
	 */
	encodeDescendantSectionsForURL(descendantSectionsMap: DescendantSectionsMap): string {
		const urlConfig = ScopesPageComponent.descendantSectionsUrlConfig;
		const encodedDescendantSections: string[] = [];

		for (const [targetScopeSnapshotId, descendantSection] of descendantSectionsMap.entries()) {
			let encodedParams: string[] = [];

			const {isOpen, innerState} = descendantSection;
			const ssInheritances = this.requireModifiableSsInheritancesPageData();
			const targetScopeData = ssInheritances.scopeData.find((scopeData) => scopeData.scope.scopeSnapshots.some((scopeSnapshot) => scopeSnapshot.id === targetScopeSnapshotId));
			if (targetScopeData === undefined) {
				throw Error("Couldn't find target scope data for target scope snapshot id " + targetScopeSnapshotId + ".");
			}

			// "473(open)" where 473 is the target scope snapshot id, and "(open)" indicates that the section is open
			encodedParams.push(`${targetScopeSnapshotId}${isOpen ? urlConfig.startParamValuesUsing + urlConfig.openSectionValue + urlConfig.endParamValuesUsing : ""}`);

			// "dsf(1,2,3)" where "(1,2,3)" is the list of scope option ids selected for the descendant scope filter
			const allSelectedScopeOptionIds: number[] = [];
			for (const [scopeVariableId, currScopeOptionIds] of innerState.scopeFilter.entries()) {
				allSelectedScopeOptionIds.push(...[...currScopeOptionIds].sort());
			}
			encodedParams.push(urlConfig.descendantScopeFilterParam + urlConfig.startParamValuesUsing + allSelectedScopeOptionIds.join(urlConfig.joinParamValuesUsing) + urlConfig.endParamValuesUsing);

			// "dif(d,i,any)" where "(d,i,any)" indicates that direct and indirect inheritances are selected, and "any" indicates that all inherited snapshots are selected.
			const listItemsForDescendantInheritanceFilter: string[] = [];
			if (innerState.descendantSnapshotsFilter.inheritanceTypes.has('direct')) {
				listItemsForDescendantInheritanceFilter.push(urlConfig.inheritanceTypeToAbbreviation['direct']);
			}
			if (innerState.descendantSnapshotsFilter.inheritanceTypes.has('indirect')) {
				listItemsForDescendantInheritanceFilter.push(urlConfig.inheritanceTypeToAbbreviation['indirect']);
			}
			if (innerState.includeNonDescendantsFilter) {
				listItemsForDescendantInheritanceFilter.push(urlConfig.includeEligibleDescendantsFilterAbbreviation);
			}
			if (innerState.descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope === 'any') {
				listItemsForDescendantInheritanceFilter.push(urlConfig.anyInheritedSnapshotIdsAbbreviation);
			}
			else {
				// If instead of "any" for the inherited snapshot ids, a list of inherited snapshot ids was provided, then add them to the list of filter values, so that it looks like "dif(d,i,1,2,3)"
				for (const inheritedSnapshotId of innerState.descendantSnapshotsFilter.inheritedSnapshotIdsOfTargetScope) {
					listItemsForDescendantInheritanceFilter.push(inheritedSnapshotId.toString());
				}
			}
			encodedParams.push(urlConfig.descendantInheritanceFilterParam + urlConfig.startParamValuesUsing + listItemsForDescendantInheritanceFilter.join(urlConfig.joinParamValuesUsing) + urlConfig.endParamValuesUsing);

			// "da(t)" where "(t)" indicates that the target scope snapshot as the action selected for the bulk descendants updater
			encodedParams.push(urlConfig.descendantActionParam + urlConfig.startParamValuesUsing + urlConfig.inheritanceSetterCodeToAbbreviation[innerState.inheritanceSetter] + urlConfig.endParamValuesUsing);

			encodedDescendantSections.push(encodedParams.join(urlConfig.joinParamsUsing));
		}

		return encodedDescendantSections.join(urlConfig.joinDescendantSectionsUsing);
	}

	private safeSplitParamValues(paramValue: string): string[] {
		return paramValue.split(ScopesPageComponent.descendantSectionsUrlConfig.joinParamValuesUsing).filter((filter) => filter !== '');
	}

	/**
	 * Use this to decode a url query string like: 493(open)-dsf(1,5,7)-dif(d,i,e,any)-da(r) into a descendant sections map.
	 * @returns A descendant sections map like:
	 *	{
	 *		493 => {
	 *			isOpen: true,
	 *			innerState: {
	 *				scopeFilter: {1 => {5, 7}}, // Scope variable id of 1 has scope option ids 5 and 7
	 *				includeNonDescendantsFilter: true,
	 *				inheritanceSetter: 'revert-to-original',
	 *				descendantSnapshotsFilter: {
	 *					inheritanceTypes: {"direct", "indirect"},
	 *					inheritedSnapshotIdsOfTargetScope: "any"
	 * 				}
	 * 			}
	 * 		}
	 * 	}
	 */
	decodeDescendantSectionsFromURL(): DescendantSectionsMap {
		const urlConfig = ScopesPageComponent.descendantSectionsUrlConfig;
		const descendantSectionsUrlValue = this.route.snapshot.queryParamMap.get(urlConfig.mainGetParam);
		if (descendantSectionsUrlValue === null) {
			return new Map();
		}
		const encodedDescendantSections = descendantSectionsUrlValue.split(urlConfig.joinDescendantSectionsUsing);
		const descendantSectionsMap: DescendantSectionsMap = new Map();
		const ssInheritances = this.requireModifiableSsInheritancesPageData();

		for (const encodedDescendantSection of encodedDescendantSections) {
			const encodedParams = encodedDescendantSection.split(urlConfig.joinParamsUsing);

			let targetScopeSnapshotSectionString = encodedParams[0];
			if (targetScopeSnapshotSectionString === undefined) {
				throw Error("Couldn't decode url to find target scope snapshot id.");
			}
			const targetScopeSnapshotSection = targetScopeSnapshotSectionString.replace(urlConfig.endParamValuesUsing, "").split(urlConfig.startParamValuesUsing);
			const targetScopeSnapshotIdString = targetScopeSnapshotSection[0];
			if (targetScopeSnapshotIdString === undefined || targetScopeSnapshotIdString.length === 0 || +targetScopeSnapshotIdString === 0) {
				throw Error("Couldn't decode url to find target scope snapshot id.");
			}
			const targetScopeSnapshotId: number = +targetScopeSnapshotIdString;
			const isOpen = targetScopeSnapshotSection[1] === urlConfig.openSectionValue;

			// Convert "dsf(1,5,7)" to a CompletedScopeDefinitionMap, such as {4 => {1}, 8 => {5, 7}} if the scope variable ids are 4 and 8 and the provided scope option ids are 1, 5, and 7.
			const descendantScopeFilterSectionString = encodedParams.find((encodedParam) => encodedParam.startsWith(urlConfig.descendantScopeFilterParam));
			if (descendantScopeFilterSectionString === undefined) {
				throw Error("Couldn't decode url to find descendant scope filter.");
			}
			const descendantScopeFilterSection = descendantScopeFilterSectionString.replace(urlConfig.endParamValuesUsing, "").split(urlConfig.startParamValuesUsing);

			let providedScopeOptionIds: number[] | undefined = undefined;
			if (descendantScopeFilterSection[1] !== undefined) {
				providedScopeOptionIds = this.safeSplitParamValues(descendantScopeFilterSection[1]).map((id) => +id);
			}
			if (providedScopeOptionIds === undefined) {
				throw Error("Couldn't decode values of descendant scope filter.");
			}

			const targetScopeData = ssInheritances.scopeData.find((scopeData) => scopeData.scope.scopeSnapshots.some((scopeSnapshot) => scopeSnapshot.id === targetScopeSnapshotId));
			if (targetScopeData === undefined) {
				throw Error("Couldn't find target scope data for target scope snapshot id " + targetScopeSnapshotId + ".");
			}
			const newScopeDefinitionMap: CompletedScopeDefinitionMap = new Map();
			for (const scopeVariable of this.requireScopesPageData().scopeVariables) {
				newScopeDefinitionMap.set(scopeVariable.id, new Set());
			}
			for (const providedScopeOptionId of providedScopeOptionIds) {
				const csd = targetScopeData.scope.comparableScopeDefiners.find((csd) => csd.scopeOption?.id === providedScopeOptionId);
				if (csd === undefined) {
					throw Error("Couldn't decode url to find scope option id " + providedScopeOptionId + ".");
				}
				const scopeOptionIdsForScopeVariable = newScopeDefinitionMap.get(csd.scopeVariable.id);
				if (scopeOptionIdsForScopeVariable === undefined) {
					throw Error("Couldn't find scope option ids for scope variable id " + csd.scopeVariable.id + ".");
				}

				scopeOptionIdsForScopeVariable.add(providedScopeOptionId);
			}

			// Convert "dif(d,i,e,any)" to a set of inheritance types being filtered for (such as {"direct", "indirect"}), a set of inherited snapshot ids (such as {"any"} or {1, 2, 3}). And, if "e" is present inside of "dif(...)" then we indicate that we are including eligible descendants.
			const descendantInheritanceFilterSectionString = encodedParams.find((encodedParam) => encodedParam.startsWith(urlConfig.descendantInheritanceFilterParam));
			if (descendantInheritanceFilterSectionString === undefined) {
				throw Error("Couldn't decode url to find descendant inheritance filter.");
			}
			const descendantInheritanceFilterSection = descendantInheritanceFilterSectionString.replace(urlConfig.endParamValuesUsing, "").split(urlConfig.startParamValuesUsing);

			if (descendantInheritanceFilterSection[1] === undefined) {
				throw Error("Couldn't decode values of descendant inheritance filter.");
			}
			const inheritanceTypes = this.safeSplitParamValues(descendantInheritanceFilterSection[1]);

			const inheritanceTypesSet: Set<InheritanceType> = new Set();
			if (inheritanceTypes.includes(urlConfig.inheritanceTypeToAbbreviation['direct'])) {
				inheritanceTypesSet.add('direct'); // This is a result of "dif(d,...)" in the url.
			}
			if (inheritanceTypes.includes(urlConfig.inheritanceTypeToAbbreviation['indirect'])) {
				inheritanceTypesSet.add('indirect'); // This is a result of "dif(i,...)" in the url.
			}
			const includeEligibleDescendantsFilter = inheritanceTypes.includes(urlConfig.includeEligibleDescendantsFilterAbbreviation); // This is a result of "dif(e,...)" in the url.
			const includeAllInheritedSnapshotIds = inheritanceTypes.includes(urlConfig.anyInheritedSnapshotIdsAbbreviation); // This is a result of "dif(any,...)" in the url.
			const inheritedSnapshotIdsOfTargetScope: Set<number> = new Set(inheritanceTypes.filter((v) => !isNaN(+v)).map((v) => +v)); // This is a result of "dif(#,#,#,...)" in the url.

			// Convert "da(r)" to "revert-to-original", or "da(d)" to "delete-direct-inheritance", or "da(t)" to "target-scope-snapshot".
			const descendantActionSectionString = encodedParams.find((encodedParam) => encodedParam.startsWith(urlConfig.descendantActionParam));
			if (descendantActionSectionString === undefined) {
				throw Error("Couldn't decode url to find descendant action.");
			}
			const descendantActionSection = descendantActionSectionString.replace(urlConfig.endParamValuesUsing, "").split(urlConfig.startParamValuesUsing).filter((filter) => filter !== '');
			if (descendantActionSection[1] === undefined) {
				throw Error("Couldn't decode url to find descendant action.");
			}
			const inheritanceSetter = this.safeSplitParamValues(descendantActionSection[1]);

			let inheritanceSetterCode: InheritanceSetterCode | undefined;
			for (const code of ['target-scope-snapshot', 'revert-to-original', 'delete-direct-inheritance'] as const) {
				if (inheritanceSetter.includes(urlConfig.inheritanceSetterCodeToAbbreviation[code])) {
					inheritanceSetterCode = code;
					break;
				}
			}
			if (inheritanceSetterCode === undefined) {
				throw Error("Couldn't decode url to find descendant action.");
			}

			descendantSectionsMap.set(targetScopeSnapshotId, {
				isOpen: isOpen,
				innerState: {
					scopeFilter: newScopeDefinitionMap,
					includeNonDescendantsFilter: includeEligibleDescendantsFilter,
					inheritanceSetter: inheritanceSetterCode,
					descendantSnapshotsFilter: {
						inheritanceTypes: inheritanceTypesSet,
						// If dif(any,...) is present in the url, then we set inheritedSnapshotIdsOfTargetScope to "any". Otherwise, if dif(#,#,...) is present in the url, then we set inheritedSnapshotIdsOfTargetScope to that list of numbers (of inherited snapshot ids).
						inheritedSnapshotIdsOfTargetScope: includeAllInheritedSnapshotIds ? urlConfig.anyInheritedSnapshotIdsAbbreviation : inheritedSnapshotIdsOfTargetScope
					}
				}
			});
		}

		return descendantSectionsMap;
	}

	safeDecodeDescendantSectionsFromURL(): DescendantSectionsMap | undefined {
		try {
			return this.decodeDescendantSectionsFromURL();
		}
		catch (e) {
			return undefined;
		}
	}

	descendantManagerStateForScopeSnapshotId(targetScopeSnapshotId: number): DescendantManagerState | undefined {
		try {
			const decodedDescendantSections = this.safeDecodeDescendantSectionsFromURL();
			if (decodedDescendantSections?.has(targetScopeSnapshotId)) {
				return decodedDescendantSections.get(targetScopeSnapshotId);
			}
		}
		catch (e) {
			return undefined;
		}
		return undefined;
	}

	/**
	 *
	 * @param targetScopeSnapshotId
	 * @param newState
	 * @param updateUrl
	 * @returns Use this to update the descendant sections map in ssInheritancesPageData based on a new descendant section state, as well as the url (if updateUrl is true).
	 * Note, this mutates ssInheritancesPageData.descendantSectionsMap. Use newDescendantSectionsMapFromNewState() if you need to keep the existing ssInheritancesPageData.descendantSectionsMap unchanged.
	 */
	handleNewDescendantManagerState(targetScopeSnapshotId: number, newState: DescendantManagerState, updateUrl: boolean = true): DescendantSectionsMap {
		const ssInheritancesPageData = this.requireModifiableSsInheritancesPageData();
		ssInheritancesPageData.descendantSectionsMap = this.newDescendantSectionsMapFromNewState(targetScopeSnapshotId, newState);

		if (updateUrl) {
			const queryParams = this.allQueryParamsForValidatingSsInheritances();
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: queryParams,
				queryParamsHandling: 'merge'
			});
		}

		return ssInheritancesPageData.descendantSectionsMap;
	}

	/**
	 *
	 * @param targetScopeSnapshotId
	 * @param newState
	 * @returns Returns a new descendant sections map based on the new descendant section state, without mutating the existing descendant sections map in ssInheritancesPageData.
	 */
	newDescendantSectionsMapFromNewState(targetScopeSnapshotId: number, newState: DescendantManagerState): DescendantSectionsMap {
		const descendantSectionsMap = _.cloneDeep(this.requireModifiableSsInheritancesPageData().descendantSectionsMap);
		if (newState.innerState !== undefined) {
			descendantSectionsMap.set(targetScopeSnapshotId, {
				isOpen: newState.isOpen,
				innerState: newState.innerState
			});
		}
		else {
			descendantSectionsMap.delete(targetScopeSnapshotId);
		}

		return descendantSectionsMap;
	}

	viewInheritancesOfScopeSnapshotId(targetScopeSnapshotId: number) {
		this.viewOrModifyOrDiscardSsInheritances({
			overallMergeStrategy: 'only-perform-actions-on-provided-targets',
			ssInheritanceActionsForEachTarget: new Map([[
				targetScopeSnapshotId,
				{
					actionType: 'view',
					clearExistingModificationsIfPresent: false
				}
			]]),
			scrollToTargetScopeSnapshot: null
		});
	}

	viewOrModifyOrDiscardSsInheritances(actions: ViewOrModifyOrDiscardSsInheritancesAction) {
		const scopesPageData = this.requireModifiableSsInheritancesPageData();
		const newInputs: InputsForValidatingInheritanceModifications = actions.overallMergeStrategy === 'only-perform-actions-on-provided-targets' ? _.cloneDeep(scopesPageData.inputsForValidatingInheritanceModifications) : {};

		let wereAnyInputsModified = false;

		let newDescendantSectionsMap: DescendantSectionsMap | undefined = undefined;

		for (const [targetScopeSnapshotId, actionForTarget] of actions.ssInheritanceActionsForEachTarget) {
			if (actionForTarget.actionType === 'discard') {
				delete newInputs[targetScopeSnapshotId];
				// Also, reset the descendant section state so that the filters are reset to their defaults.
				newDescendantSectionsMap = this.newDescendantSectionsMapFromNewState(
					targetScopeSnapshotId,
					{
						isOpen: false,
						innerState: undefined
					}
				);

				wereAnyInputsModified = true;
			}
			else if (actionForTarget.actionType === 'view') {
				if (actionForTarget.clearExistingModificationsIfPresent || newInputs[targetScopeSnapshotId] === undefined) {
					newInputs[targetScopeSnapshotId] = {};
					wereAnyInputsModified = true;
				}
			}
			else if (actionForTarget.actionType === 'modify') {
				if (actionForTarget.mergeStrategy === 'replace-entire-parent-list') {
					newInputs[targetScopeSnapshotId] = {
						replacementScopeSnapshotIdsToDirectlyInherit: actionForTarget.directParentScopeSnapshotIds
					};
					wereAnyInputsModified = true;
				}
			}
		}

		if (wereAnyInputsModified) {
				this.validateOrSaveSsInheritanceModifications(newInputs, true, actions.scrollToTargetScopeSnapshot, newDescendantSectionsMap);
				// No need to call scrollToInheritancesSection here since validateOrSaveSsInheritanceModifications will do that for us.
		}
		else {
			this.scrollToInheritancesSection(actions.scrollToTargetScopeSnapshot);
		}
	}

	deleteScope(scope: ScopesPageQuery["scopes"][number]) {
		const deleteScopeInfo = this.gqlRequestInfos.mutations.deleteScope;
		deleteScopeInfo.subscription?.unsubscribe();

		deleteScopeInfo.subscription = this.apollo.mutate({
			mutation: deleteScopeInfo.gql,
			variables: {id: scope.id},
		}).subscribe({
			next: ({ data }) => {
				this.commonService.messageService.add({ severity: 'success', summary: 'Empty scope deleted', life: 10000 });

				const scopesPageData = this.requireScopesPageData();
				scopesPageData.scopeData.splice(scopesPageData.scopeData.findIndex((scopeDatum) => scopeDatum.scope.id === scope.id), 1);
			},
			error: (error) => this.commonService.mutationErrorHandler(error)
		});
	}

	cancelViewOrModifySsInheritances(targetScopeSnapshotId: number) {
		this.viewOrModifyOrDiscardSsInheritances({
			overallMergeStrategy: 'only-perform-actions-on-provided-targets',
			ssInheritanceActionsForEachTarget: new Map([[
				targetScopeSnapshotId,
				{
					actionType: 'discard'
				}
			]]),
			scrollToTargetScopeSnapshot: null
		});
	}

	handleNewDirectInheritanceSelections(targetScopeSnapshotId: number, newDirectlyInheritedScopeSnapshots: ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment[]) {
		// const inheritanceModifications: OneSsInheritanceModifications[] = [];
		// const validatedInheritanceModifications = this.requireValidatedInheritanceModifications();

		this.viewOrModifyOrDiscardSsInheritances({
			overallMergeStrategy: 'only-perform-actions-on-provided-targets',
			ssInheritanceActionsForEachTarget: new Map([[
				targetScopeSnapshotId,
				{
					actionType: 'modify',
					directParentScopeSnapshotIds: newDirectlyInheritedScopeSnapshots.map((scopeSnapshot) => scopeSnapshot.id),
					mergeStrategy: 'replace-entire-parent-list'
				}
			]]),
			scrollToTargetScopeSnapshot: null
		});

		// else {
		// const directInheritances: NonNullable<NonNullable<ScopesPageComponent['scopesPageData']>['validatedInheritanceModifications']>["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number]["validatedInheritance"] = [];
		// const validations = scopesPageData.validatedInheritanceModifications?.validationsByTargetScopeSnapshots;
		// if (validations === undefined) {
		// 	return;
		// }
		// for (const validationsForTargetScopeSnapshot of validations) {
		// 	for (const ancestorScopeDifference of validationsForTargetScopeSnapshot.ancestorScopeDifferences) {
		// 		const validatedInheritance = ancestorScopeDifference.validatedInheritance;
		// 		if (validatedInheritance[0] !== undefined && validatedInheritance[0].isDirectInheritance === true) {
		// 			directInheritances.push(validatedInheritance[0]);
		// 		}
		// 		// If there are conflicting inheritances, don't bother trying to reload the inheritances since the user needs to resolve the conflicts first.
		// 		if (validatedInheritance.length > 1) {
		// 			return;
		// 		}
		// 	}

		// 	inheritanceModifications.push({
		// 		targetScopeSnapshotId: validationsForTargetScopeSnapshot.targetScopeSnapshot.id,
		// 		replacementScopeSnapshotIdsToDirectlyInherit: directInheritances.sort((a, b) => a.ordinalWithinDirectInheritances - b.ordinalWithinDirectInheritances).map((validatedInheritance) => validatedInheritance.inheritedScopeSnapshotId)
		// 	});
		// }
		// });

		// TODO Set all inheritance modifications to "loading" state and load the new inheritances for each one by calling validateOrSaveInheritanceModifications using the inheritanceModifications.
	}

	saveSsInheritanceModifications() {
		this.validateOrSaveSsInheritanceModifications(this.requireModifiableSsInheritancesPageData().inputsForValidatingInheritanceModifications, false);
	}

	snapshotInheritanceValidationsForScope(scopeId: number): ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"] | undefined {
		const scopesPageData = this.requireModifiableSsInheritancesPageData();
		const scopeDatum = scopesPageData.scopeData.find((scopeDatum) => scopeDatum.scope.id === scopeId);

		if (!scopeDatum) {
			throw new Error(`Could not find scope data for scope id ${scopeId}`);
		}

		const desiredScopeSnapshotsOrder = scopeDatum.selectorState.initialScope.scopeSnapshots.map((scopeSnapshot) => scopeSnapshot.id);
		return scopesPageData.validatedInheritanceModifications?.validationsByTargetScopeSnapshots
				.filter((validations) => validations.targetScopeSnapshot.scopeId === scopeId)
				.sort((a, b) => desiredScopeSnapshotsOrder.findIndex((ssId) => ssId === a.targetScopeSnapshot.id) - desiredScopeSnapshotsOrder.findIndex((ssId) => ssId === b.targetScopeSnapshot.id));
	}

	extractStaticScopeSnapshotFromValidatableInheritance(validatableInheritances: EssentialValidatableScopeContainment[], index: number, extractParentSnapshot: boolean): ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment | undefined {
		const validatableInheritance = validatableInheritances[index];
		if (validatableInheritance !== undefined) {
			return ScopeSnapshotSelectorComponent.extractStaticScopeSnapshotFromValidatableInheritance(validatableInheritance, this.requireScopesPageData().scopeVariables, extractParentSnapshot);
		}
	}

	static primitiveToStaticScopeSnapshot = ScopeSnapshotSelectorComponent.primitiveToStaticScopeSnapshot;
	static primitiveToSelectableScopeSnapshot = ScopeSnapshotSelectorComponent.primitiveToSelectableScopeSnapshot;

	zIndexForHeadingsRow(): number {
		return 1;
	}

	// zIndexForScopeSnapshotInheritances(scopeSnapshot: ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"][number]["targetScopeSnapshot"]): number {
	// 	return this.zIndexForHeadingsRow() + 1;
	// }

	stylesForSnapshotDeploymentsNotice(scopeDatum: ModifiableSsInheritancesPageData["scopeData"][number]): { 'grid-row-start': number } {
		const scopesPageData = this.requireModifiableSsInheritancesPageData();
		const scopeRowIndex = scopesPageData.scopeData.indexOf(scopeDatum);
		const scopesBeforeIndex = scopesPageData.scopeData.slice(0, scopeRowIndex);
		const numInheritanceSectionsOpenBeforeDeploymentNotice = _.sum(
			scopesBeforeIndex.map((scopeDatum) => {
				const inheritanceSectionsOpenForCurrScope = this.snapshotInheritanceValidationsForScope(scopeDatum.scope.id);
				if (inheritanceSectionsOpenForCurrScope === undefined) {
					return 0;
				}
				return inheritanceSectionsOpenForCurrScope.length;
			}));

		const resultingStyles = {
			'grid-row-start': numInheritanceSectionsOpenBeforeDeploymentNotice + ((scopeRowIndex + 1) * 2)
		};

		return resultingStyles;
	}

	numUniqueScopeVersionsInDeployments(deployments: CommonScopesPageData["scopeData"][number]["scope"]["deployments"]): number {
		return _.uniqBy(deployments, 'scopeVersion').length;
	}

	ngOnDestroy(){
		CrudStateService.unsubscribeFromGqlSubscriptions(this.gqlRequestInfos);
		this.modifiedInheritancesUrlSubscription?.unsubscribe();
	}

}

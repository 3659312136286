import { ChangeDetectorRef, Component, EventEmitter, Host, Inject, Input, OnDestroy, Output } from '@angular/core';
import { FlagNodeFromScopeSnapshotResponse, NonSelfInheritance, SelfInheritance } from '../scope-snapshot-page/scope-snapshot-page.component';
import { Apollo, gql } from 'apollo-angular';
import { FormControl, FormGroup } from '@angular/forms';
import { CrudStateService, EditableStateModel, GqlRequestInfo, gqlRequestInfo } from 'src/app/crud-state.service';
import { DeleteFlagValueMutation, DeleteFlagValueMutationVariables, FlagValueFieldsWithAppliedInScopeSnapshotDetailsFragment, InsertFlagValueMutation, InsertFlagValueMutationVariables, ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment, ScopeSnapshots } from 'src/generated/graphql';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/app-common/common.service';

type FlagValueInputControl = string | number | boolean | null | Symbol; // Symbol is used to represent undefined. This is because undefined is not a valid value for a FormControl (it simply gets replaced with null).

const undefinedSymbol = Symbol("undefined");

type ScopeSnapshotInheritances = [...NonSelfInheritance[], SelfInheritance];

// TODO If we ever want to make this component portable enough to not rely on a scope snapshot response, but rather just a flag component alone, then change all references to FlagNodeFromScopeSnapshotResponse to a new custom type: Experiment by removing fields from FlagNodeFromScopeSnapshotResponse until the component stops working.

// The key to identifying a flag value in the crud state service is going to be the leaf flag node + the (scopeId, scopeVersion) pair that you would like your flag value to be applied in. A leaf flag node is a flag node that has no children flag nodes but can have flag values assigned to them.
type LeafFlagNodeAndInheritances = {
	_crudId: FlagNodeFromScopeSnapshotResponse["id"],
	leafFlagNode: FlagNodeFromScopeSnapshotResponse,
	inheritances: ScopeSnapshotInheritances
};

type FlagValue =  FlagValueFieldsWithAppliedInScopeSnapshotDetailsFragment;

// enum FlagValueType {
// 	Boolean,
// 	Number,
// 	String,
// 	Null,
// }

enum FlagValueTypeKey {
	True = "True",
	False = "False",
	Number = "Number",
	String = "String",
	Null = "Null",
	NoValue = "NoValue",
};

export type FlagValueEditableModel = FormGroup<{
	readonly id: FormControl<FlagValue["id"] | null>, // null for new. Need to make this field readonly
	value: FormControl<FlagValueInputControl>,
	tempNumericValue: FormControl<string | null>,
}>;

const leafFlagNodeToEditableFlagValueTransformer = (leafFlagNodeAndInheritances: LeafFlagNodeAndInheritances): FlagValueEditableModel => {
	const allFlagValues = leafFlagNodeAndInheritances.leafFlagNode.flagValues;

	const currentScopeSnapshotId = FlagValueComponent.currentScopeSnapshotFromInheritances(leafFlagNodeAndInheritances.inheritances).id;
	const initializeWithFlagValueObject = FlagValueComponent.flagValueObjectAppliedIn(leafFlagNodeAndInheritances, currentScopeSnapshotId);

	const existingFlagValueId = allFlagValues.find(flagValue => flagValue.appliedInScopeSnapshotId === currentScopeSnapshotId)?.id ?? null;

	let value: FlagValueInputControl;
	let tempNumericValue: string | null = null;

	// Use the flag value object (according to the inheritances) to determine the default value and value type.
	if (initializeWithFlagValueObject) {
		if (typeof initializeWithFlagValueObject.value === "boolean" || typeof initializeWithFlagValueObject.value === "number" || typeof initializeWithFlagValueObject.value === "string") {
			value = initializeWithFlagValueObject.value;
			if (typeof value === "number") {
				tempNumericValue = value.toString();
			}
		}
		else {
			value = null;
		}
	}
	else {
		value = undefinedSymbol;
	}

	return new FormGroup({
		id: new FormControl(existingFlagValueId),
		value: new FormControl(value, { nonNullable: true }),
		tempNumericValue: new FormControl<string | null>(tempNumericValue),
	});
};

const flagValueCrudOptions = {ignoreFieldsForDirtyCheck: ["id", "tempNumericValue"]};

type ComponentGqlRequestInfos = {
	mutations: {
		insertFlagValue: GqlRequestInfo<InsertFlagValueMutation, InsertFlagValueMutationVariables>,
		deleteFlagValue: GqlRequestInfo<DeleteFlagValueMutation, DeleteFlagValueMutationVariables>,
	}
};

type FlagValueCrud = CrudStateService<LeafFlagNodeAndInheritances, FlagValueEditableModel>;

@Component({
  selector: 'app-flag-value[leaf-flag-node][scope-snapshot-inheritances]',
  templateUrl: './flag-value.component.html',
  styleUrls: ['./flag-value.component.scss'],
	providers: [
		{
			provide: leafFlagNodeToEditableFlagValueTransformer,
			useValue: leafFlagNodeToEditableFlagValueTransformer,
		},
		{
			provide: flagValueCrudOptions,
			useValue: flagValueCrudOptions,
		},
		{
			provide: "flagValueCrud",
			useFactory: (
				toEditableModelTransformer: (leafFlagNode: LeafFlagNodeAndInheritances) => FlagValueEditableModel,
				options: FlagValueCrud["options"]
			) => new CrudStateService<LeafFlagNodeAndInheritances, FlagValueEditableModel>(toEditableModelTransformer, undefined, options),
			deps: [
				leafFlagNodeToEditableFlagValueTransformer,
				flagValueCrudOptions,
			]
		}
	]
})
export class FlagValueComponent implements OnDestroy {
	@Input('leaf-flag-node') leafFlagNode?: FlagNodeFromScopeSnapshotResponse;
	@Input('scope-snapshot-inheritances') scopeSnapshotInheritances?: ScopeSnapshotInheritances;
	@Output('state-model') stateModelEvent: EventEmitter<EditableStateModel<FlagValueEditableModel> | null> = new EventEmitter();
	@Output('save') saveEvent = new EventEmitter<true>();

	flagValueKey?: LeafFlagNodeAndInheritances;
	FlagValueTypeKey = FlagValueTypeKey;
	self = FlagValueComponent;
	friendlyVersionLabel = CommonService.friendlyVersionLabel;

	gqlRequestInfos: ComponentGqlRequestInfos = {
		mutations: {
			insertFlagValue: gqlRequestInfo(gql`
				mutation insertFlagValue($scopeFlagNodeId: Int!, $flagNodeScopeSnapshotId: Int!, $appliedInScopeSnapshotId: Int!, $value: jsonb) {
					insertFlagValuesOne(object: {
						scopeFlagNodeId: $scopeFlagNodeId,
						flagNodeScopeSnapshotId: $flagNodeScopeSnapshotId,
						appliedInScopeSnapshotId: $appliedInScopeSnapshotId,
						value: $value
					},
					onConflict: {constraint: flag_values_scope_flag_node_id_applied_in_scope_snapshot_id_key, updateColumns: value}) {
						id
					}
				}
			`),
			deleteFlagValue: gqlRequestInfo(gql`
				mutation deleteFlagValue($scopeFlagNodeId: Int!, $flagNodeScopeSnapshotId: Int!, $appliedInScopeSnapshotId: Int!) {
					deleteFlagValues(where: {
						scopeFlagNodeId: {_eq: $scopeFlagNodeId},
						appliedInScopeSnapshotId: {_eq: $appliedInScopeSnapshotId},
						flagNodeScopeSnapshotId: {_eq: $flagNodeScopeSnapshotId}
						}
					) {
						returning {
							id
						}
						affectedRows
					}
				}
				`)
		}
	}

	constructor(
		private apollo: Apollo,
		@Host() @Inject("flagValueCrud") private flagValueCrud: FlagValueCrud,
		private commonService: CommonService,
	) { }

	ngOnInit(): void {
		const leafFlagNode = this.requireLeafFlagNode();
		this.flagValueKey = {
			_crudId: leafFlagNode.id,
			leafFlagNode: this.requireLeafFlagNode(),
			inheritances: this.requireScopeSnapshotInheritances(),
		};
	}

	private requireLeafFlagNode(): FlagNodeFromScopeSnapshotResponse {
		if (!this.leafFlagNode) {
			throw new Error("leafFlagNode is undefined");
		}
		return this.leafFlagNode;
	}

	private requireScopeSnapshotInheritances(): ScopeSnapshotInheritances {
		if (!this.scopeSnapshotInheritances) {
			throw new Error("inheritances is undefined");
		}
		return this.scopeSnapshotInheritances;
	}

	requireCurrentScopeSnapshot(): ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment {
		const inheritances = this.requireScopeSnapshotInheritances();
		return FlagValueComponent.currentScopeSnapshotFromInheritances(inheritances);
	}

	requireCurrentScopeSnapshotId(): number {
		return this.requireCurrentScopeSnapshot().id;
	}

	static currentScopeSnapshotFromInheritances(inheritances: ScopeSnapshotInheritances): ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment {
		const selfInheritance = inheritances[inheritances.length - 1];
		if (!selfInheritance || !selfInheritance.isSelf) {
			throw new Error("Self inheritance must be provided within the inheritances array.");
		}
		return selfInheritance.inheritedScopeSnapshot;
	}

	requireFlagValueKey(): LeafFlagNodeAndInheritances {
		if (!this.flagValueKey) {
			throw new Error("flagValueKey is undefined");
		}
		return this.flagValueKey;
	}

	static flagValueObjectAppliedIn(flagValueKey: LeafFlagNodeAndInheritances, scopeSnapshotId: number): FlagValue | undefined {
		return flagValueKey.leafFlagNode.flagValues.find(flagValue => flagValue.appliedInScopeSnapshotId === scopeSnapshotId);
	}

	flagValueObjectAppliedInCurrentScopeSnapshot(): FlagValue | undefined {
		return FlagValueComponent.flagValueObjectAppliedIn(this.requireFlagValueKey(), this.requireCurrentScopeSnapshotId());
	}

	static mostApplicableFlagValueObject(flagValueKey: LeafFlagNodeAndInheritances): FlagValue | undefined {
		let mostApplicableFlagValueObject: FlagValue | undefined;

		for (const inheritance of flagValueKey.inheritances) {
			const flagValueObject = FlagValueComponent.flagValueObjectAppliedIn(flagValueKey, inheritance.inheritedScopeSnapshotId);
			if (flagValueObject) {
				mostApplicableFlagValueObject = flagValueObject;
			}
		}

		return mostApplicableFlagValueObject;
	}

	// TODO Memoize this function for performance.
	static flagValuesWithinNonSelfInheritances(flagValueKey: LeafFlagNodeAndInheritances): FlagValue[] {
		const flagValues: FlagValue[] = [];

		for (const inheritance of flagValueKey.inheritances) {
			if (!inheritance.isSelf) {
				const flagValueObject = FlagValueComponent.flagValueObjectAppliedIn(flagValueKey, inheritance.inheritedScopeSnapshotId);
				if (flagValueObject) {
					flagValues.push(flagValueObject);
				}
			}
		}

		return flagValues;
	}

	isInheritedFlagValueObject(flagValueObject: FlagValue): boolean {
		return flagValueObject.appliedInScopeSnapshotId !== this.requireCurrentScopeSnapshotId();
	}

	inheritanceFromScopeSnapshotId(inheritedScopeSnapshotId: number): ScopeSnapshotInheritances[number] | undefined {
		const inheritances = this.requireScopeSnapshotInheritances();
		return inheritances.find(inheritance => inheritance.inheritedScopeSnapshotId === inheritedScopeSnapshotId);
	}

	requireInheritanceFromScopeSnapshotId(inheritedScopeSnapshotId: number): ScopeSnapshotInheritances[number] {
		const inheritance = this.inheritanceFromScopeSnapshotId(inheritedScopeSnapshotId);
		if (!inheritance) {
			throw new Error(`Inheritance with scope snapshot id ${inheritedScopeSnapshotId} not found.`);
		}
		return inheritance;
	}

	// static castedValue(value: FlagValueInputControl | undefined, toType: FlagValueType | undefined): FlagValueInputControl {
	// 	if (value === undefined || toType === undefined) {
	// 		return null;
	// 	}
	// 	switch (toType) {
	// 		case FlagValueType.Boolean:
	// 			return value === "true";
	// 		case FlagValueType.Number:
	// 			return Number(value);
	// 		case FlagValueType.String:
	// 			return value;
	// 		case FlagValueType.Null:
	// 			return null;
	// 	}
	// }

	// castedValue = FlagValueComponent.castedValue;
	tempNumericValueSubscription?: Subscription;

	edit(): EditableStateModel<FlagValueEditableModel> {
		const editableStateModel = this.flagValueCrud.edit(this.requireFlagValueKey());

		this.tempNumericValueSubscription?.unsubscribe();
		this.tempNumericValueSubscription = editableStateModel.model.controls.tempNumericValue.valueChanges.subscribe((value) => {
			if (this.flagValueTypeKeyOfEditable() === FlagValueTypeKey.Number) {
				const castedValue = Number(value);

				if (value === null || value === "") {
					editableStateModel.model.controls.tempNumericValue.setErrors({empty: true});
					editableStateModel.model.controls.value.setErrors({empty: true});
				}
				else if (!Number.isNaN(castedValue)) {
					// Copy the value from the tempNumericValue control to the main value control.
					editableStateModel.model.controls.value.setValue(castedValue);
				}
				else {
					// Indicate that the numeric value is invalid - this will cause the numeric input to be underlined red.
					editableStateModel.model.controls.tempNumericValue.setErrors({invalidNumber: true});
					// Also indicate that the value is invalid in the main value input - this will cause the save button to be disabled.
					editableStateModel.model.controls.value.setErrors({invalidNumber: true});
				}
			}
		});
		this.stateModelEvent.emit(editableStateModel);
		return editableStateModel;
	}

	ngOnDestroy(): void {
		this.tempNumericValueSubscription?.unsubscribe();
	}

	editable(): EditableStateModel<FlagValueEditableModel> | undefined {
		return this.flagValueCrud.editable(this.requireFlagValueKey());
	}

	requireEditable(): EditableStateModel<FlagValueEditableModel> {
		const editable = this.editable();
		if (!editable) {
			throw new Error("Editable is undefined");
		}
		return editable;
	}

	discard() {
		const isDiscarded = this.flagValueCrud.discardEditable(this.requireFlagValueKey());
		if (isDiscarded) {
			this.stateModelEvent.emit(null);
		}
	}

	isDirty() {
		return this.flagValueCrud.isDirty(this.requireFlagValueKey());
	}

	save() {
		const editable = this.requireEditable();
		const flagValueKey = this.requireFlagValueKey();

		const flagValueInput = editable.model.controls.value.getRawValue();

		const scopeFlagNodeId = flagValueKey.leafFlagNode.scopeFlagNodeId;
		const flagNodeScopeSnapshotId = flagValueKey.leafFlagNode.definedInScopeSnapshotId;
		const appliedInScopeSnapshotId = this.requireCurrentScopeSnapshotId();

		if (typeof flagValueInput === 'string' || typeof flagValueInput === 'number' || typeof flagValueInput === 'boolean' || flagValueInput === null) {
			const insertFlagValueInfo = this.gqlRequestInfos.mutations.insertFlagValue;
			insertFlagValueInfo.subscription?.unsubscribe();
			insertFlagValueInfo.subscription = this.apollo.mutate({
				mutation: insertFlagValueInfo.gql,
				variables: {
					scopeFlagNodeId: scopeFlagNodeId,
					flagNodeScopeSnapshotId: flagNodeScopeSnapshotId,
					appliedInScopeSnapshotId: appliedInScopeSnapshotId,
					value: flagValueInput,
				}
			}).subscribe({
				next: ({ data }) => {
					this.saveEvent.emit(true);
				},
				error: (error) => this.commonService.mutationErrorHandler(error)
			});
		}
		else if (flagValueInput === undefinedSymbol) {
			const deleteFlagValueInfo = this.gqlRequestInfos.mutations.deleteFlagValue;
			deleteFlagValueInfo.subscription?.unsubscribe();
			deleteFlagValueInfo.subscription = this.apollo.mutate({
				mutation: deleteFlagValueInfo.gql,
				variables: {
					scopeFlagNodeId: scopeFlagNodeId,
					flagNodeScopeSnapshotId: flagNodeScopeSnapshotId,
					appliedInScopeSnapshotId: appliedInScopeSnapshotId,
				}
			}).subscribe({
				next: ({ data }) => {
					this.saveEvent.emit(true);
				},
				error: (error) => this.commonService.mutationErrorHandler(error)
			});
		}
	}

	flagValueTypeKeys: FlagValueTypeKey[] = [
		FlagValueTypeKey.True,
		FlagValueTypeKey.False,
		FlagValueTypeKey.Number,
		FlagValueTypeKey.String,
		FlagValueTypeKey.Null,
		FlagValueTypeKey.NoValue,
	];

	flagValueTypeKeyOfEditable(): FlagValueTypeKey {
		const editable = this.requireEditable();
		const value = editable.model.controls.value.value;

		return FlagValueComponent.valueToFlagValueTypeKey(value);
	}

	static valueToFlagValueTypeKey(value: string | number | boolean | null | undefined | Symbol): FlagValueTypeKey {
		if (value === undefinedSymbol || value === undefined) {
			return FlagValueTypeKey.NoValue;
		}
		else if (value === null) {
			return FlagValueTypeKey.Null;
		}
		else if (typeof value === "boolean") {
			return value ? FlagValueTypeKey.True : FlagValueTypeKey.False;
		}
		else if (typeof value === "number") {
			return FlagValueTypeKey.Number;
		}
		else if (typeof value === "string") {
			return FlagValueTypeKey.String;
		}
		else {
			throw new Error("Unexpected value type");
		}
	}

	flagValueTypeKeyOfExisting(): FlagValueTypeKey {
		const existingFlagValueObject = FlagValueComponent.flagValueObjectAppliedIn(this.requireFlagValueKey(), this.requireCurrentScopeSnapshotId());
		if (!existingFlagValueObject) {
			return FlagValueTypeKey.NoValue;
		}
		else {
			return FlagValueComponent.valueToFlagValueTypeKey(existingFlagValueObject.value);
		}
	}

	flagValueTypeInfoForView(key: FlagValueTypeKey): {name: string, class: string}  {
		if (key === FlagValueTypeKey.True) {
			return {
				name: "True",
				class: "ficon ficon-ToggleRight",
			};
		}
		else if (key === FlagValueTypeKey.False) {
			return {
				name: "False",
				class: "ficon ficon-ToggleLeft",
			}
		}
		else if (key === FlagValueTypeKey.Number) {
			return {
				name: "Number",
				class: "ficon ficon-NumberField",
			}
		}
		else if (key === FlagValueTypeKey.String) {
			return {
				name: "String",
				class: "ficon ficon-TextField",
			}
		}
		else if (key === FlagValueTypeKey.Null) {
			return {
				name: "Null",
				class: "ficon ficon-FieldFilled",
			}
		}
		else {
			if (FlagValueComponent.flagValuesWithinNonSelfInheritances(this.requireFlagValueKey()).length > 0) {
				return {
					name: "Inherit",
					class: "ficon ficon-DependencyAdd",
				}
			}
			else {
				return {
					name: "No value",
					class: "ficon ficon-UnSetColor",
				}
			}
		}
	}

	changeFlagValueTypeOfEditable(newFlagValueTypeKey: FlagValueTypeKey, dropdownElement: HTMLElement) {
		const editable = this.requireEditable();
		const currentFlagValueTypeKey = this.flagValueTypeKeyOfEditable();
		if (currentFlagValueTypeKey === newFlagValueTypeKey) {
			return;
		}

		let newValue: FlagValueInputControl = undefinedSymbol;

		const existingFlagValue = FlagValueComponent.mostApplicableFlagValueObject(this.requireFlagValueKey());
		let existingFlagValueTypeKey: FlagValueTypeKey;

		if (!existingFlagValue) {
			existingFlagValueTypeKey = FlagValueTypeKey.NoValue;
		}
		else {
			existingFlagValueTypeKey = FlagValueComponent.valueToFlagValueTypeKey(existingFlagValue.value);
			if (existingFlagValueTypeKey === newFlagValueTypeKey) {
				newValue = existingFlagValue.value;
			}
		}

		if (newFlagValueTypeKey === FlagValueTypeKey.NoValue) {
			newValue = undefinedSymbol;
		}
		else if (existingFlagValueTypeKey !== newFlagValueTypeKey) {
			if (newFlagValueTypeKey === FlagValueTypeKey.String) {
				newValue = "";
			}
			else if (newFlagValueTypeKey === FlagValueTypeKey.Null) {
				newValue = null;
			}
			else if (newFlagValueTypeKey === FlagValueTypeKey.True) {
				newValue = true;
			}
			else if (newFlagValueTypeKey === FlagValueTypeKey.False) {
				newValue = false;
			}
			else if (newFlagValueTypeKey === FlagValueTypeKey.Number) {
				newValue = 0;
			}
		}
		// If the new flag value type is the same as the existing flag value type, it is handled above in a type safe way.

		// Do not update the input component if the value is undefinedSymbol. This is because the input component will not be able to handle this value and the FormControl will throw an error.
		const emitModelToViewChange = newValue !== undefinedSymbol;

		editable.model.controls.value.setValue(newValue, {emitModelToViewChange});
		if (newFlagValueTypeKey === FlagValueTypeKey.Number && typeof newValue === "number") {
			editable.model.controls.tempNumericValue.setValue(newValue.toString());
		}
	}
	// TODO Memoize this function for performance.
	flagValuesAppliedInSameScope(): FlagValue[] {
		const flagValueKey = this.requireFlagValueKey();
		const currentScopeId = this.requireCurrentScopeSnapshot().scopeId;
		const flagValuesWithinSameScope: FlagValue[] = [];

		for (const flagValue of flagValueKey.leafFlagNode.scopeFlagNode.flagValues) {
			if (flagValue.appliedInScopeSnapshot.scopeId === currentScopeId) {
				flagValuesWithinSameScope.push(flagValue);
			}
		}

		return flagValuesWithinSameScope;
	}
}

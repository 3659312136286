<div class="flags-and-values-grid" [ngClass]="{'use-parent-grid': useParentGrid}">
	<ng-container *ngFor="let flagRow of memoizedFlagRows(false); index as flagRowIndex">
		<div
			class="flag-and-value-container"
			[class]="{
				'new-flag': flagRow.flagKey.type === 'CREATABLE',
			}"
		>
			<div
				class="flag-container"
				[class]="{
					'in-view-state': !flagCrud.genericInCreateOrEditState(flagRow.flagKey),
					'in-edit-state': flagCrud.genericInCreateOrEditState(flagRow.flagKey),
					'actions-disabled': areFlagRowFlagActionsDisabled(flagRow.flagKey),
					'fully-disabled': isFlagRowFlagDisabled(flagRow.flagKey)
				}"
			>

				<div class="disabler-overlay" *ngIf="isFlagRowFlagDisabled(flagRow.flagKey)"></div>
				<div class="bulk-flag-selector-wrapper" *ngIf="areBulkFlagSelectorsEnabled() && flagRow.flagKey.type === 'EDITABLE'" [pTooltip]="bulkFlagSelector(flagRow.flagKey)?.tooltip ?? ''" tooltipPosition="top">
					<ng-container *ngIf="bulkFlagSelector(flagRow.flagKey) as selector">
						<div class="ficon ficon-SplitObject" *ngIf="selector.selectorType === 'STATIC_INHERITED'"></div>
						<div class="ficon ficon-Important" *ngIf="selector.selectorType === 'STATIC_CONFLICT'"></div>
						<div class="ficon ficon-AcceptMedium" *ngIf="selector.selectorType === 'STATIC_CHECKMARK'"></div>
						<div class="ficon ficon-Remove" *ngIf="selector.selectorType === 'STATIC_NOT_APPLICABLE'"></div>
						<p-checkbox [binary]="true" class="select-flag" [ngModel]="selector.checked" (onChange)="handleBulkFlagSelectorCheckboxChange($event, flagRow.flagKey.editableModelKey.id, selector)" *ngIf="selector.selectorType === 'CHECKBOX'" />
					</ng-container>
				</div>
				<div class="flag-expander-container" *ngFor="let nodeKey of flagRow.flagNodeKeys.slice(0, -1);">
				</div>
				<div class="flag-expander-container">
					<ng-container *ngIf="flagRow.flagKey.type === 'EDITABLE'">
						<div class="action-link" *ngIf="isFlagRowExpandable(flagRow)" (click)="expandFlagRow(flagRow.flagKey.editableModelKey)">
							<div class="ficon ficon-ExpandAll"></div>
						</div>
						<div class="action-link" *ngIf="isFlagRowCollapsible(flagRow)" (click)="collapseFlagRow(flagRow.flagKey.editableModelKey)">
							<div class="ficon ficon-CollapseAll"></div>
						</div>
					</ng-container>
				</div>

				<div class="flag-node-containers" [class]="{'not-empty': flagRow.flagNodeKeys.length > 0}">

					<ng-container *ngFor="let nodeKey of flagRow.flagNodeKeys; index as nodeIndex">

						<div class="flag-node-container-wrapper" *ngIf="!flagNodeCrud.genericIsDeleted(nodeKey)" [class]="{'showing-edit-state': flagCrud.genericInCreateOrEditState(flagRow.flagKey) && flagNodeCrud.genericInCreateOrEditState(nodeKey), 'non-inherited-node': !isInheritedNode(nodeKey), 'last-inherited-node': isLastInheritedNodeInFlagRow(flagRow.flagKey, nodeKey), 'dirty': flagNodeCrud.genericIsDirty(nodeKey)}">

							<div class="flag-node-container">
								<div class="label-and-input-and-type">
									<div class="label-and-input">
										<div class="label" (click)="flagCrud.genericInCreateOrEditState(flagRow.flagKey) && !isInheritedNode(nodeKey) && createOrEditNodeInFlagRow(flagRow.flagKey, nodeIndex)">
											<div class="code">{{ flagNodeLabel(nodeKey) }}</div>

											<div class="type-indicator-wrapper" *ngIf="flagCrud.genericInCreateOrEditState(flagRow.flagKey) && !flagNodeCrud.genericInCreateOrEditState(nodeKey)">
												<div class="type-indicator">{{ flagNodeTypeLabel(flagNodeTypeForView(nodeKey)) }}</div>
											</div>
										</div>
										<div class="input-containers" *ngIf="flagCrud.genericInCreateOrEditState(flagRow.flagKey) && flagNodeCrud.genericInCreateOrEditState(nodeKey); let genericFlagNode;">
											<input
												class="value-input code"
												[formControl]="genericFlagNode.model.controls.key"
												(keydown.Tab)="createOrEditNodeInFlagRowEvent($event, flagRow.flagKey, nodeIndex + 1)"
												(keydown.shift.Tab)="createOrEditNodeInFlagRowEvent($event, flagRow.flagKey, nodeIndex - 1)"
												(keydown.Enter)="saveFlag(flagRow.flagKey)"
												(keydown)="handleInsertCharInFlagNodeKeyInput($event, flagRow.flagKey, nodeKey)"
												pInputText
												pAutoFocus
												[autofocus]="true"
												/>
										</div>
									</div>

									<div class="custom-select enabled" *ngIf="flagCrud.genericInCreateOrEditState(flagRow.flagKey) && flagNodeCrud.genericInCreateOrEditState(nodeKey); let genericFlagNode;">
										<div class="primary-label">
											<div>{{ flagNodeTypeLabel(genericFlagNode.model.controls.type.value) }}</div>
											<div class="ficon ficon-ChevronDownMed"></div>
										</div>

										<div class="dropdown-wrapper">
											<div class="dropdown">
												<ng-container *ngFor="let flagNodeType of flagNodeTypes(nodeKey)">
													<div
														pRipple class="dropdown-option"
														[ngClass]="{
															'initially-selected': nodeKey.type === 'EDITABLE' && flagNodeType === genericFlagNode.model.controls.type.defaultValue,
															'newly-selected': flagNodeType === genericFlagNode.model.controls.type.value
														}"
														(click)="genericFlagNode.model.controls.type.setValue(flagNodeType)"
													>
														{{ flagNodeTypeLabel(flagNodeType) }}
													</div>
												</ng-container>
											</div>
										</div>
									</div>
								</div>

								<div pRipple class="button red delete-flag-node" *ngIf="isDeleteNodeInFlagRowAllowed(flagRow.flagKey, nodeKey)" (click)="this.flagNodeCrud.genericDelete(nodeKey); emptyFlagRowsCache()"><div class="ficon ficon-Delete icon-only"></div></div>
							</div>
							<app-scope-snapshot-details-popup [scope-snapshot]="scopeSnapshotFromNode(nodeKey)" *ngIf="!isNodeFromCurrentScopeSnapshot(nodeKey)"></app-scope-snapshot-details-popup>

						</div>
					</ng-container>

				</div>

				<div class="actions" [class]="{
					'in-edit-state': flagCrud.genericInCreateOrEditState(flagRow.flagKey),
					'is-after-inherited-node': isLastInheritedNodeInFlagRow(flagRow.flagKey, flagRow.flagNodeKeys[flagRow.flagNodeKeys.length - 1])
				}">

					<ng-container *ngIf="flagCrud.genericInCreateOrEditState(flagRow.flagKey)">
						<div class="flag-node-actions">
							<div (click)="createOrEditNodeInFlagRow(flagRow.flagKey, flagRow.flagNodeKeys.length)" *ngIf="isCreateOrEditNodeInFlagRowAllowed(flagRow.flagKey, flagRow.flagNodeKeys.length)" pRipple class="button gray add-flag-node"><div class="ficon ficon-Add icon-only"></div></div>
						</div>
						<div class="flag-actions">
							<div (click)="saveFlag(flagRow.flagKey)" pRipple class="button action"><div class="ficon icon-only ficon-AcceptMedium"></div></div>
							<div (click)="cancelFlag(flagRow.flagKey)" pRipple class="button gray action"><div class="ficon icon-only ficon-Cancel"></div></div>
							<div (click)="deleteFlag(flagRow.flagKey)" pRipple class="button red delete-flag action" *ngIf="flagRow.flagKey.type === 'CREATABLE' && isDeleteFlagAllowed(flagRow.flagKey)"><div class="ficon icon-only ficon-Delete"></div></div>
						</div>
					</ng-container>

					<div class="flag-actions" *ngIf="!flagCrud.genericInCreateOrEditState(flagRow.flagKey)">
						<div *ngIf="flagRow.flagKey.type === 'EDITABLE'" (click)="createOrEditFlag(flagRow.flagKey)" pRipple class="button edit-flag action">
							<div
								class="icon-only ficon"
								[ngClass]="{
									'ficon-EditSolid12': !isLastInheritedNodeInFlagRow(flagRow.flagKey, flagRow.flagNodeKeys[flagRow.flagNodeKeys.length - 1]),
									'ficon-Add': isLastInheritedNodeInFlagRow(flagRow.flagKey, flagRow.flagNodeKeys[flagRow.flagNodeKeys.length - 1])
							}"></div>
						</div>
						<div *ngIf="flagRow.flagKey.type === 'CREATABLE'" (click)="createOrEditFlag(flagRow.flagKey)" class="action-link gray new-flag action"><div class="ficon ficon-Add"></div> New flag</div>
					</div>

				</div>

				<div class="notices" *ngIf="flagCrud.genericIsInViewState(flagRow.flagKey) && (childFlagNodesCount(flagRow.flagKey, false) || flagValuesCount(flagRow.flagKey, false) || visibleReplicatedFlagNodesCount(flagRow.flagKey))">
					<div class="related-objects-notice has-tooltip-indicator" *ngIf="visibleReplicatedFlagNodesCount(flagRow.flagKey)">
						<div class="ficon ficon-FullHistory"></div><div class="notice-text">{{visibleReplicatedFlagNodesCount(flagRow.flagKey)}}</div>
						<div class="related-objects-popup">
							<div class="popup-title">Replicated across {{visibleReplicatedFlagNodesCount(flagRow.flagKey)}} version{{visibleReplicatedFlagNodesCount(flagRow.flagKey) !== 1 ? 's' : ''}}</div>
							<div class="notice-for-objects" *ngIf="flagRow.flagKey.type === 'EDITABLE'">
								Sharing scope flag ID: {{flagRow.flagKey.editableModelKey.scopeFlagNodeId}}
							</div>
							<div class="object-rows">
								<div class="object-row" [ngClass]="{'current-value': replicatedFlagNode.scopeSnapshot.scopeVersion === requireCurrentScopeSnapshot().scopeVersion}" *ngFor="let replicatedFlagNode of replicatedFlagNodes(flagRow.flagKey)">
									<div class="object-row-key">{{friendlyVersionLabel(replicatedFlagNode.scopeSnapshot.scopeVersion)}}</div>
									<div class="object-row-value">{{replicatedFlagNode.key}}</div>
								</div>
							</div>
						</div>
					</div>

					<div
						class="notice related-objects-notice has-tooltip-indicator"
						*ngIf="childFlagNodesCount(flagRow.flagKey, false)"
						pTooltip="{{childFlagNodesNotice(flagRow.flagKey)}}"
						[autoHide]="false"
						[tooltipOptions]="{
							tooltipStyleClass: 'width-max-content'
						}"
						tooltipPosition="top">
						<div class="ficon ficon-DOM"></div><div class="notice-text">{{childFlagNodesCount(flagRow.flagKey, false)}}</div>
					</div>

					<div
						class="notice related-objects-notice has-tooltip-indicator"
						*ngIf="flagValuesCount(flagRow.flagKey, false)"
						pTooltip="{{flagValuesNotice(flagRow.flagKey)}}"
						[autoHide]="false"
						[tooltipOptions]="{
							tooltipStyleClass: 'width-max-content'
						}"
						tooltipPosition="top">
						<div class="ficon ficon-Variable2"></div><div class="notice-text">{{flagValuesCount(flagRow.flagKey, false)}}</div>
					</div>
				</div>

			</div>

			<div
				class="flag-value-container"
				[class]="{
					'in-edit-state': flagRow.flagKey.type === 'EDITABLE' && flagValueStateModels[flagRow.flagKey.editableModelKey.id]?.state === 'CREATE_OR_EDIT',
					'fully-disabled': isFlagRowValueDisabled(flagRow.flagKey),
					'actions-disabled': isFlagRowValueActionsDisabled(flagRow.flagKey)
				}"
			>
				<div class="disabler-overlay" *ngIf="isFlagRowValueDisabled(flagRow.flagKey)"></div>

				<div class="bulk-value-selector-wrapper" *ngIf="areBulkValueSelectorsEnabled() && flagRow.flagKey.type === 'EDITABLE'">
					<ng-container *ngIf="bulkValueSelector(flagRow.flagKey) as selector">
						<div class="ficon ficon-SplitObject" *ngIf="selector.selectorType === 'STATIC_INHERITED'" [pTooltip]="selector.tooltip ?? ''" tooltipPosition="top"></div>
						<div class="ficon ficon-AcceptMedium" *ngIf="selector.selectorType === 'STATIC_CHECKMARK'" [pTooltip]="selector.tooltip ?? ''" tooltipPosition="top"></div>
						<div class="ficon ficon-Remove" *ngIf="selector.selectorType === 'STATIC_NOT_APPLICABLE'" [pTooltip]="selector.tooltip ?? ''" tooltipPosition="top"></div>
						<p-checkbox [binary]="true" class="select-flag-value" [ngModel]="selector.checked" (onChange)="handleBulkValueSelectorCheckboxChange($event, flagRow.flagKey.editableModelKey.id, selector)" *ngIf="selector.selectorType === 'CHECKBOX'" />
					</ng-container>
				</div>

				<ng-container *ngIf="flagRow.flagKey.type === 'EDITABLE' && flagRow.flagKey.editableModelKey.type === 'VALUE'">
					<app-flag-value
						[scope-snapshot-inheritances]="requireCurrentScopeSnapshot().inheritances"
						[leaf-flag-node]="flagRow.flagKey.editableModelKey"
						(state-model)="handleFlagValueStateChange(flagRow.flagKey.editableModelKey.id, $event)"
						(save)="saveEvent.emit(true)"
					></app-flag-value>
				</ng-container>
			</div>

		</div>
	</ng-container>
</div>

<!-- <div style="cursor:pointer" (click)="goToPage(0)">Page 0</div><br /><br />
<div style="cursor:pointer" (click)="goToPage(1)">Page 1</div><br /><br />
<div style="cursor:pointer" (click)="goToPage(2)">Page 2</div><br /><br />
<div style="cursor:pointer" (click)="goToPage(3)">Page 3</div><br /><br />
<div style="cursor:pointer" (click)="goToPage(4)">Page 4</div><br /><br /> -->

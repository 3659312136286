import { Component, OnInit, Input } from '@angular/core';
import _ from 'lodash';
import { requireVar } from 'src/app/utilities';
import { FlagNodeFromScopeSnapshotResponse } from '../scope-snapshot-page/scope-snapshot-page.component';
import { CommonService } from 'src/app/app-common/common.service';
import { ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment } from 'src/generated/graphql';

type ScopeSnapshot = ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment;
type ScopeVariableToScopeDefinersMap = {[key: number]: ScopeSnapshot["scope"]["comparableScopeDefiners"]};

@Component({
	selector: 'app-scope-snapshot-details-popup[scope-snapshot]',
	templateUrl: './scope-snapshot-details-popup.component.html',
	styleUrls: ['./scope-snapshot-details-popup.component.scss']
})
export class ScopeSnapshotDetailsPopupComponent implements OnInit {

	@Input('scope-snapshot') scopeSnapshot?: ScopeSnapshot;

	scopeVariableToScopeDefinersMapCache?: ScopeVariableToScopeDefinersMap;
	versionLabel = CommonService.friendlyVersionLabel;

	constructor() { }

	ngOnInit(): void {}

	scopeVariableToScopeDefinersMap(): ScopeVariableToScopeDefinersMap {
		const scopeSnapshot = requireVar(this.scopeSnapshot);

		if (!this.scopeVariableToScopeDefinersMapCache) {
			this.scopeVariableToScopeDefinersMapCache = _.groupBy(scopeSnapshot.scope.comparableScopeDefiners, comparableScopeDefiner => comparableScopeDefiner.scopeVariable.name);
		}
		return this.scopeVariableToScopeDefinersMapCache;
	}
}

import { Component, Host, Inject } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { CrudStateService, GqlRequestInfo, State as CrudState, gqlRequestInfo } from 'src/app/crud-state.service';
import { DeploymentsPageQuery, DeploymentsPageQueryVariables, ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc, ScopeVariableFieldsForScopeSnapshotSelectorFragmentDoc, DeleteDeploymentMutation, DeleteDeploymentMutationVariables, UpdateDeploymentMutation, UpdateDeploymentMutationVariables, InsertDeploymentMutation, InsertDeploymentMutationVariables, ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment } from 'src/generated/graphql';
import { ScopeSnapshotSelectorComponent, State as ScopeSnapshotSelectorState, ActionType as ScopeSnapshotSelectorActionType, Staticity } from '../scope-snapshot-selector/scope-snapshot-selector.component';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/app-common/common.service';

type ComponentGqlRequestInfos = {
	queries: {
		deploymentsPage: GqlRequestInfo<DeploymentsPageQuery, DeploymentsPageQueryVariables>,
	},
	mutations: {
		deleteDeployment: GqlRequestInfo<DeleteDeploymentMutation, DeleteDeploymentMutationVariables>,
		updateDeployment: GqlRequestInfo<UpdateDeploymentMutation, UpdateDeploymentMutationVariables>,
		insertDeployment: GqlRequestInfo<InsertDeploymentMutation, InsertDeploymentMutationVariables>,
	},
	fragments: {}
};

type UpdateDeploymentActionInfo = {
	newDeploymentKey: string | null,
	newScopeSnapshot: ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment | null,
	buttonLabel: string
};

type DeploymentsPageData = {
	scopeVariables: DeploymentsPageQuery['scopeVariables'],
	deploymentRows: {
		deployment: DeploymentsPageQuery['deployments'][number],
		selectorState: ScopeSnapshotSelectorState
	}[],
};

type EditableDeploymentKey = {_crudId: DeploymentsPageQuery['deployments'][number]['id'], deployment: DeploymentsPageData['deploymentRows'][number]['deployment'], scopeVariables: DeploymentsPageData['scopeVariables']};

type EditableDeploymentModel = FormGroup<{
	key: FormControl<DeploymentsPageData['deploymentRows'][number]['deployment']['key']>,
	selectorState: FormControl<
		Extract<ScopeSnapshotSelectorState, { staticity: Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION }> & {
			initialScopeSnapshot: DeploymentsPageData['deploymentRows'][number]['deployment']['scopeSnapshot'],
		}
	>,
}>;


type CreatableDeploymentKey = {scopeVariables: DeploymentsPageData['scopeVariables']};
type CreatableDeploymentModel = FormGroup<{
	key: FormControl<DeploymentsPageData['deploymentRows'][number]['deployment']['key']>,
	selectorState: FormControl<Extract<ScopeSnapshotSelectorState, { staticity: Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION }>>,
}>;

@Component({
  selector: 'app-deployments-page',
  templateUrl: './deployments-page.component.html',
  styleUrls: ['./deployments-page.component.scss'],
	providers: [

		{
			provide: "deploymentCrud",

			useFactory: () => new CrudStateService<EditableDeploymentKey, EditableDeploymentModel, CreatableDeploymentKey, CreatableDeploymentModel>(
				(editableDeploymentKey: EditableDeploymentKey) => {
					const scopeSnapshot = editableDeploymentKey.deployment.scopeSnapshot;
					const deploymentKeyFormControl = new FormControl(editableDeploymentKey.deployment.key, {nonNullable: true, validators: [Validators.required]});

					return new FormGroup({
						key: deploymentKeyFormControl,
						selectorState: new FormControl({
							staticity: Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION,
							scopeVariables: editableDeploymentKey.scopeVariables,
							actionsWhitelist: [] as ScopeSnapshotSelectorActionType[],
							initialScopeSnapshot: scopeSnapshot,
						}, {nonNullable: true}),
					});
				},
				(creatableModelKey: CreatableDeploymentKey) => new FormGroup({
					key: new FormControl("", {nonNullable: true, validators: [Validators.required]}),
					selectorState: new FormControl({
						staticity: Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION,
						scopeVariables: creatableModelKey.scopeVariables,
						actionsWhitelist: [] as ScopeSnapshotSelectorActionType[]
					}, {nonNullable: true}),
				})
			),
		},
	]
})
export class DeploymentsPageComponent {
	ScopeSnapshotSelectorComponent = ScopeSnapshotSelectorComponent;
	CommonService = CommonService;
	crudState = CrudState;
	actionTypes = ScopeSnapshotSelectorActionType;
	gqlRequestInfos: ComponentGqlRequestInfos = {
		queries: {
			deploymentsPage: gqlRequestInfo(gql`
				${ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc}
				${ScopeVariableFieldsForScopeSnapshotSelectorFragmentDoc}
				query deploymentsPage {
					deployments(orderBy: {key: ASC}) {
						id
						key
						scopeSnapshot {
							...scopeSnapshotFieldsForScopeSnapshotSelector
						}
					}
					scopeVariables(orderBy: {ordinal: ASC}) {
						...scopeVariableFieldsForScopeSnapshotSelector
					}
				}
			`),
		},
		mutations: {
			deleteDeployment: gqlRequestInfo(gql`
				mutation deleteDeployment($deploymentId: Int!) {
					deleteDeploymentsByPk(id: $deploymentId) {
						id
						key
					}
				}
			`),
			updateDeployment: gqlRequestInfo(gql`
				${ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc}

				mutation updateDeployment($id: Int!, $setFields: DeploymentsSetInput) {
					updateDeploymentsByPk(pkColumns: {id: $id}, _set: $setFields) {
						id
						key
						scopeSnapshot {
							...scopeSnapshotFieldsForScopeSnapshotSelector
						}
					}
				}
			`),
			insertDeployment: gqlRequestInfo(gql`
				${ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc}

				mutation insertDeployment($key: String!, $scopeId: Int!, $scopeVersion: String!) {
					insertDeploymentsOne(object: {key: $key, scopeId: $scopeId, scopeVersion: $scopeVersion}) {
						id
						key
						scopeSnapshot {
							...scopeSnapshotFieldsForScopeSnapshotSelector
						}
					}
				}
			`),
		},
		fragments: {}
	}

	private deploymentsPageQuery?: QueryRef<DeploymentsPageQuery, DeploymentsPageQueryVariables>;

	deploymentsPageData?: DeploymentsPageData;

	isLoading = false;

	constructor(
		private apollo: Apollo,
		@Host() @Inject("deploymentCrud") public deploymentCrud: CrudStateService<EditableDeploymentKey, EditableDeploymentModel, CreatableDeploymentKey, CreatableDeploymentModel>,
		private commonService: CommonService,
	) { }

	ngOnInit(): void {
		const scopesQueryInfo = this.gqlRequestInfos.queries.deploymentsPage;
		scopesQueryInfo.subscription?.unsubscribe();

		this.deploymentsPageQuery = this.apollo.watchQuery({
			query: this.gqlRequestInfos.queries.deploymentsPage.gql,
			variables: {},
		});

		this.isLoading = true;

		this.gqlRequestInfos.queries.deploymentsPage.subscription = this.deploymentsPageQuery.valueChanges.subscribe({
			next: ({ data, loading }) => {
				this.isLoading = false;

				if (data) {
					this.deploymentsPageData = {
						scopeVariables: data.scopeVariables,
						deploymentRows: data.deployments.map(deployment => ({
							deployment: deployment,
							selectorState: {
								staticity: Staticity.STATIC_SCOPE_WITH_STATIC_VERSION,
								scopeVariables: data.scopeVariables,
								actionsWhitelist: [ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE, ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE, ScopeSnapshotSelectorActionType.RESET_TO_INITIAL_STATE],
								initialScopeSnapshot: deployment.scopeSnapshot,
							},
						})),
					}
				}
			},
			error: (error) => {
				this.isLoading = false;
				this.commonService.queryErrorHandler(error);
			}
		});

	}

	editableKey(deploymentRow: NonNullable<DeploymentsPageComponent['deploymentsPageData']>['deploymentRows'][number]): EditableDeploymentKey {
		const deploymentsPageData = this.requireDeploymentsPageData();
		return {_crudId: deploymentRow.deployment.id, deployment: deploymentRow.deployment, scopeVariables: deploymentsPageData.scopeVariables};
	}

	creatableKey(): CreatableDeploymentKey {
		const deploymentsPageData = this.requireDeploymentsPageData();
		return {scopeVariables: deploymentsPageData.scopeVariables};
	}

	requireDeploymentsPageData(): NonNullable<DeploymentsPageComponent['deploymentsPageData']> {
		if (this.deploymentsPageData === undefined) {
			throw new Error(`this.deploymentsPageData === undefined`);
		}
		return this.deploymentsPageData;
	}

	selectorState(deploymentRow: NonNullable<DeploymentsPageComponent['deploymentsPageData']>['deploymentRows'][number]): ScopeSnapshotSelectorState {
		const editableModel = this.deploymentCrud.editableInEditState(this.editableKey(deploymentRow));
		if (editableModel) {
			return editableModel.model.controls.selectorState.value;
		}
		return deploymentRow.selectorState;
	}

	deploymentActionInfoForUpdate(original: EditableDeploymentKey): UpdateDeploymentActionInfo | null {
		const editable = this.deploymentCrud.editable(original);
		if (editable) {
			let actionInfo: UpdateDeploymentActionInfo = {
				newDeploymentKey: null,
				newScopeSnapshot: null,
				buttonLabel: "",
			}

			if (editable.model.controls.key.value === '') {
				return null;
			}

			const isDifferentKey = original.deployment.key !== editable.model.controls.key.value;
			if (isDifferentKey){
				actionInfo.newDeploymentKey = editable.model.controls.key.value;
				actionInfo.buttonLabel += "Rename Key";
			}

			const selectedScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(editable.model.controls.selectorState.value);

			if (!selectedScopeSnapshot) {
				return null;
			}
			const isDifferentScopeSnapshot = original.deployment.scopeSnapshot.id !== selectedScopeSnapshot.id;

			if (isDifferentScopeSnapshot) {
				const isDifferentScope = original.deployment.scopeSnapshot.scope.id !== selectedScopeSnapshot.scope.id;

				if (isDifferentKey) {
					actionInfo.buttonLabel += " and ";
				}

				if (isDifferentScope) {
					actionInfo.buttonLabel += `Deploy ${CommonService.friendlyVersionLabel(selectedScopeSnapshot.scopeVersion)} of Selected Scope`;
				}
				else {
					actionInfo.buttonLabel += `Deploy ${CommonService.friendlyVersionLabel(selectedScopeSnapshot.scopeVersion)}`;
				}
				actionInfo.newScopeSnapshot = selectedScopeSnapshot;
			}

			if (!isDifferentKey && !isDifferentScopeSnapshot) {
				return null;
			}

			return actionInfo;
		}
		return null;
	}

	updateDeployment(original: EditableDeploymentKey): void {
		const actionInfo = this.deploymentActionInfoForUpdate(original);
		if (actionInfo) {
			const updateDeploymentInfo = this.gqlRequestInfos.mutations.updateDeployment;

			updateDeploymentInfo.subscription?.unsubscribe();

			updateDeploymentInfo.subscription = this.apollo.mutate({
				mutation: updateDeploymentInfo.gql,
				variables: {
					id:	original.deployment.id,
					setFields: {
						key: actionInfo.newDeploymentKey ? actionInfo.newDeploymentKey : undefined,
						scopeId: actionInfo.newScopeSnapshot ? actionInfo.newScopeSnapshot.scopeId : undefined,
						scopeVersion: actionInfo.newScopeSnapshot ? actionInfo.newScopeSnapshot.scopeVersion : undefined,
					},
				},
			}).subscribe({
				next: ({ data }) => {
					if (data?.updateDeploymentsByPk) {
						this.commonService.messageService.add({severity:'success', summary: `Deployed '${data.updateDeploymentsByPk.key}'`, detail: `'${data.updateDeploymentsByPk.key}' ${original.deployment.scopeSnapshot.id === data.updateDeploymentsByPk.scopeSnapshot.id ? 'continues to use' : 'now uses' } ${CommonService.friendlyVersionLabel(data.updateDeploymentsByPk.scopeSnapshot.scopeVersion)}${original.deployment.scopeSnapshot.id !== data.updateDeploymentsByPk.scopeSnapshot.id ? ' of your selected scope' : ''}.`});
						this.deploymentCrud.discardEditable(original);
					}
				},
				error: (error) => this.commonService.mutationErrorHandler(error)
			});
		}

	}

	deleteDeployment(original: EditableDeploymentKey): void {
		const deploymentId = original.deployment.id;
		const deleteDeploymentInfo = this.gqlRequestInfos.mutations.deleteDeployment;

		deleteDeploymentInfo.subscription?.unsubscribe();

		deleteDeploymentInfo.subscription = this.apollo.mutate({
			mutation: deleteDeploymentInfo.gql,
			variables: {
				deploymentId:	original.deployment.id,
			},
		}).subscribe({
			next: ({ data }) => {
				if (data?.deleteDeploymentsByPk) {
					this.commonService.messageService.add({severity:'success', summary: 'Deployment Deleted', detail: `'${data.deleteDeploymentsByPk.key}' is no longer deployed to any scope snapshot.`});
					this.deploymentsPageQuery?.refetch();
				}
			},
			error: (error) => this.commonService.mutationErrorHandler(error)
		});
	}

	insertDeployment(): void {
		const creatableKey = this.creatableKey();
		const creatableModel = this.deploymentCrud.creatableInCreateState(creatableKey);

		if (creatableModel) {
			const selectedScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(creatableModel.model.controls.selectorState.value);
			if (selectedScopeSnapshot) {
				const insertDeploymentInfo = this.gqlRequestInfos.mutations.insertDeployment;

				insertDeploymentInfo.subscription?.unsubscribe();

				insertDeploymentInfo.subscription = this.apollo.mutate({
					mutation: insertDeploymentInfo.gql,
					variables: {
						key: creatableModel.model.controls.key.value,
						scopeId: selectedScopeSnapshot.scopeId,
						scopeVersion: selectedScopeSnapshot.scopeVersion,
					},
				}).subscribe({
					next: ({ data }) => {
						if (data?.insertDeploymentsOne) {
							this.commonService.messageService.add({severity:'success', summary: 'New Deployment Created', detail: `'${data.insertDeploymentsOne.key}' now uses ${CommonService.friendlyVersionLabel(data.insertDeploymentsOne.scopeSnapshot.scopeVersion)} of your selected scope.`});
							this.deploymentsPageQuery?.refetch();
							this.deploymentCrud.discardCreatable(creatableKey);
						}
					},
					error: (error) => this.commonService.mutationErrorHandler(error)
				});
			}
		}
	}

	handleScopeSnapshotSelectorStateChange(newState: ScopeSnapshotSelectorState): void {
	}

	copyBearerTokenToClipboard() {
		this.commonService.copyBearerTokenToClipboard();
	}

	viewConfigInGraphqlUrl() {
		return `https://cloud.hasura.io/public/graphiql?endpoint=https%3A%2F%2Fapi.scoperoot.com%2Fv1%2Fgraphql&variables=%7B%22deploymentKey%22%3Anull%7D&query=query+ConfigByDeploymentQuery%28%24deploymentKey%3A+String%21%29+%7B%0A++deployments%28where%3A+%7Bkey%3A+%7B_eq%3A+%24deploymentKey%7D%7D%29+%7B%0A++++id%0A++++key%0A++++scopeSnapshot+%7B%0A++++++id%0A++++++scopeId%0A++++++scopeVersion%0A++++++configuration+%7B%0A++++++++jsonConfiguration%0A++++++%7D%0A++++%7D%0A++%7D%0A%7D%0A`;
	}

}


<div class="descendant-inheritances-toolbar" *ngIf="targetScopeSnapshot && descendantManagerInnerState && scopeSelectorState">
	<div
		class="row-state-indicators"
		[autoHide]="true"
		[tooltipOptions]="{
			tooltipStyleClass: 'width-max-content',
			positionTop: 25
		}"
		[pTooltip]="'Use this toolbar to filter and modify the descendants of ' + versionLabel(requireVar(targetScopeSnapshot).scopeVersion) + ' in bulk.'"
		tooltipPosition="bottom"
	>
		<div class="row-state-indicator current-state indicator-for-toolbar">
			<div class="label-for-row-state-indicator">
				Filters and Actions
			</div>
			<div class="icon-wrapper">
				<div class="ficon ficon-DOM hidden-spacer"></div>
				<div class="ficon ficon-DOM removed-from-document-flow"></div>
			</div>
		</div>
	</div>
	<app-scope-snapshot-selector
		[state]="scopeSelectorState"
		[styles-for-spanning-across-parent-grid]="{
			'first-dropdown': {
				'grid-column-start': gridColumnStartOfScopeSnapshotSelector
			}
		}"
		[z-index-for-dropdowns]="200"
		[disable-scope-search]="true"
		[scope-options-filter]="completedScopeDefinitionMapOfTargetScope()"
		(state-update)="handleBulkDescendantScopeSelectorUpdate($event)"

		[tooltip]="tooltipsForScopeFilter()"
	>
	</app-scope-snapshot-selector>
	<div class="where-snapshot-selector-wrapper">
		<!-- <div class="label-for-dropdown">Those inheriting from:</div> -->
		<div class="custom-select enabled">
			<div class="primary-label">
				<div
					class="ficon"
					[ngClass]="{
						'ficon-FilterSettings': true,
					}">
				</div>
			</div>
			<div class="secondary-label">
				{{labelForCurrentInheritanceFilters()}}
			</div>
			<div class="dropdown-wrapper">
				<div class="dropdown">
					<label
						pRipple
						class="dropdown-option"
						[class]="{
							'initially-selected': ['direct'].includes(inheritanceType),
							'newly-selected': isInheritanceTypeFilterSelected(inheritanceType)
						}"
						*ngFor="let inheritanceType of inheritanceTypes"
					>
						<p-checkbox [binary]="true" [ngModel]="isInheritanceTypeFilterSelected(inheritanceType)" (onChange)="clickInheritanceTypeFilter(inheritanceType)"></p-checkbox>
						<div class="ficon ficon-Childof" *ngIf="inheritanceType === 'direct'"></div>
						<div class="ficon ficon-SplitObject" *ngIf="inheritanceType === 'indirect'"></div>
						<span class="text-label">{{labelForInheritanceTypeFilterOption(inheritanceType)}}</span>
					</label>

					<label
						pRipple
						class="dropdown-option initially-selected"
						[class]="{
							'newly-selected': isIncludeNonDescendantsFilterSelected()
						}"
					>
						<p-checkbox [binary]="true" [ngModel]="isIncludeNonDescendantsFilterSelected()" (onChange)="clickIncludeNonDescendantsFilter()"></p-checkbox>
						<div class="ficon ficon-Remove"></div>
						<span class="text-label">{{labelForIncludeNonDescendantsFilter()}}</span>
					</label>

					<ng-container *ngIf="this.requireDescendantManagerInnerState().descendantSnapshotsFilter.inheritanceTypes.size > 0">
						<div class="group-title">Include {{Array.from(this.requireDescendantManagerInnerState().descendantSnapshotsFilter.inheritanceTypes).sort().join('/')}} descendants of</div>

						<label
							pRipple
							class="dropdown-option"
							[class]="{
								'newly-selected': isFilterByAllTargetSnapshotsSelected()
							}"
						>
							<p-checkbox [binary]="true" [ngModel]="isFilterByAllTargetSnapshotsSelected()" (onChange)="clickToggleFilteringByInheritedTargetSnapshots()"></p-checkbox>
							<span class="text-label">{{labelForFilterByAllInheritedSnapshots()}}</span>
						</label>

						<!-- <div class="divider-line-and-margin"></div> -->

						<label
							pRipple
							class="dropdown-option"
							[class]="{
								'initially-selected': alternateSnapshotInTargetScope.id === targetScopeSnapshot.id,
								'newly-selected': isFilterByInheritedSnapshotExplicitlySelected(alternateSnapshotInTargetScope),
								'implicitly-selected': isFilterByAllTargetSnapshotsSelected()
							}"
							*ngFor="let alternateSnapshotInTargetScope of targetScopeSnapshot.scope.scopeSnapshots">
							<p-checkbox [binary]="true" [ngModel]="isFilterByInheritedSnapshotExplicitlySelected(alternateSnapshotInTargetScope)" (onChange)="clickFilterByInheritedSnapshotId(alternateSnapshotInTargetScope)"></p-checkbox>
							<div class="implicitly-selected-icon ficon ficon-AcceptMedium" *ngIf="isFilterByAllTargetSnapshotsSelected()"></div>
							<div class="ficon ficon-BranchFork flip-vertically" *ngIf="alternateSnapshotInTargetScope.id !== targetScopeSnapshot.id"></div>
							<div class="ficon ficon-Childof" *ngIf="alternateSnapshotInTargetScope.id === targetScopeSnapshot.id"></div>
							{{labelForFilterByInheritedSnapshotOption(alternateSnapshotInTargetScope)}}
						</label>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div class="to-new-snapshot-selector-wrapper">
		<div class="custom-select enabled">
			<div class="primary-label"><div class="ficon ficon-SetAction"></div></div>
			<div class="secondary-label" *ngIf="inheritanceSetterCodeToOption(descendantManagerInnerState.inheritanceSetter) as inheritanceSetterOption">
				<div [ngClass]="inheritanceSetterOption.iconClass" *ngIf="inheritanceSetterOption.code === 'target-scope-snapshot'"></div>
				<div [ngClass]="inheritanceSetterOption.iconClass" *ngIf="inheritanceSetterOption.code === 'delete-direct-inheritance'"></div>
				<div [ngClass]="inheritanceSetterOption.iconClass" *ngIf="inheritanceSetterOption.code === 'revert-to-original'"></div>
				{{inheritanceSetterOption.label}}
			</div>
			<div class="dropdown-wrapper">
				<div class="dropdown">
					<div
						pRipple
						class="dropdown-option"
						[class]="{
							'newly-selected': descendantManagerInnerState.inheritanceSetter === option.code
						}"
						[autoHide]="true"
						[pTooltip]="pTooltipForInheritanceSetterOption(option)"
						tooltipPosition="right"
						[tooltipOptions]="{
							tooltipStyleClass: 'width-wider'
						}"
						(click)="clickInheritanceSetterOption(option)"
						*ngFor="let option of inheritanceSetterOptions">

						<div [ngClass]="option.iconClass" *ngIf="option.code === 'target-scope-snapshot'"></div>
						<div [ngClass]="option.iconClass" *ngIf="option.code === 'delete-direct-inheritance'"></div>
						<div [ngClass]="option.iconClass" *ngIf="option.code === 'revert-to-original'"></div>
						{{option.label}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="stage-changes-wrapper">
		<div
		class="button"
		(click)="clickStageActionsOnDescendants()"
		[pTooltip]="'Stage your action on the descendants below to see what they would look like before actually saving.'"
		[tooltipOptions]="{
			tooltipPosition: 'bottom',
			positionTop: 25
		}">
			<div class="ficon ficon-LightningBolt"></div>Stage Action
		</div>
	</div>
</div>
<div class="inheritances-and-base-divider"></div>

<div class="all-rows-for-all-scopes">
	<ng-container *ngFor="let scopeDifference of descendantScopeDifferences">
		<div class="scope-snapshot-selector-rows-for-scope" *ngIf="scopeDifference.descendantScopeSnapshots.length > 0">
			<ng-container	*ngFor="let descendantScopeSnapshot of scopeDifference.descendantScopeSnapshots; let snapshotIndexWithinScope = index">
				<div
					class="scope-snapshot-selector-row descendant-row"
					[ngClass]="{
						'unfocused-scope': !isDescendantScopeWithinFilter(scopeDifference.descendantScope)
					}"
					#row
					*ngIf="cachedIsDescendantScopeSnapshotWithinFilter(descendantScopeSnapshot)"
				>
					<ng-container *ngIf="row as rowHTMLDivElement">
						<div
							class="row-state-indicators"
							[ngClass]="{
								'initial-state-hidden': !component.isInheritanceRowChanged(descendantScopeSnapshot),
								'disabled': !cachedIsDescendantScopeSnapshotWithinFilter(descendantScopeSnapshot)
							}"
							[autoHide]="true"
							[pTooltip]="pTooltipForCurrentRowState(descendantScopeSnapshot)"
							tooltipPosition="bottom"
							[tooltipOptions]="{
								tooltipStyleClass: 'width-wider'
							}"
							>
							<ng-container>
								<div class="row-state-indicator initial-state">
									<div class="text-label-wrapper" *ngIf="inheritanceIfInheritingFromTargetScopeWithoutConflicts(descendantScopeSnapshot, 'initial') as inheritance">
										<div class="text-label version-label hidden-spacer">{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}</div>
										<div class="text-label version-label removed-from-document-flow">{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}</div>
									</div>
									<!-- <div class="ficon ficon-AcceptMedium" *ngIf="inheritanceIfInheritingFromJustTargetScopeSnapshot(descendantScopeSnapshot, 'initial')"></div> -->
									<div
										class="ficon ficon-Remove"
										*ngIf="isInheritanceRowNonDescendant(descendantScopeSnapshot, 'initial')">
									</div>
									<div
										class="ficon ficon-BranchFork flip-vertically"
										[ngClass]="{
											'ficon-Childof': descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshotId === requireVar(targetScopeSnapshot).id,
											'ficon-BranchFork flip-vertically': descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshotId !== requireVar(targetScopeSnapshot).id
										}"
										*ngIf="isInheritanceRowDirectDescendant(descendantScopeSnapshot, 'initial')">
									</div>
									<div
										class="ficon ficon-SplitObject"
										*ngIf="isInheritanceRowIndirectDescendant(descendantScopeSnapshot, 'initial')">
									</div>
								</div>

								<div class="transformation-icon-wrapper">
									<div class="ficon ficon-SortDown transformation-icon"></div>
								</div>
							</ng-container>

							<div class="row-state-indicator current-state" [ngClass]="{
								'conflicting-inheritances-state': isInheritanceRowUsingConflictingInheritances(descendantScopeSnapshot)
							}">
								<div class="text-label-wrapper" *ngFor="let inheritance of descendantScopeSnapshot.validatedInheritances">
									<div class="text-label version-label hidden-spacer">{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}</div>
									<div class="text-label version-label removed-from-document-flow">{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}</div>
								</div>
								<!-- <ng-container class="ficon ficon-AcceptMedium" *ngIf="inheritanceIfInheritingFromJustTargetScopeSnapshot(descendantScopeSnapshot, 'current')"></ng-container> -->
								<div class="icon-wrapper" *ngIf="isInheritanceRowNonDescendant(descendantScopeSnapshot, 'current')">
									<div class="ficon ficon-Remove hidden-spacer"></div>
									<div class="ficon ficon-Remove removed-from-document-flow"></div>
								</div>
								<div class="icon-wrapper" *ngIf="isInheritanceRowDirectDescendant(descendantScopeSnapshot, 'current')">
									<div
										class="ficon hidden-spacer"
										[ngClass]="{
											'ficon-Childof': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId === requireVar(targetScopeSnapshot).id,
											'ficon-BranchFork flip-vertically': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId !== requireVar(targetScopeSnapshot).id
										}"></div>
									<div
										class="ficon removed-from-document-flow"
										[ngClass]="{
											'ficon-Childof': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId === requireVar(targetScopeSnapshot).id,
											'ficon-BranchFork flip-vertically': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId !== requireVar(targetScopeSnapshot).id
										}"></div>
								</div>
								<div class="icon-wrapper" *ngIf="isInheritanceRowIndirectDescendant(descendantScopeSnapshot, 'current')">
									<div class="ficon ficon-SplitObject hidden-spacer"></div>
									<div class="ficon ficon-SplitObject removed-from-document-flow"></div>
								</div>
								<div class="icon-wrapper" *ngIf="isInheritanceRowUsingConflictingInheritances(descendantScopeSnapshot)">
									<div class="ficon ficon-Important hidden-spacer"></div>
									<div class="ficon ficon-Important removed-from-document-flow"></div>
								</div>
							</div>
						</div>
						<!-- TODO Calling primitiveToStaticScopeSnapshot is probably highly inefficient. Store it statically in the component instead of having angular recompute it every time. -->
						<app-scope-snapshot-selector
							[state]="{
								initialScopeSnapshot: component.primitiveToStaticScopeSnapshot(descendantScopeSnapshot, scopeDifference.descendantScope),
								actionsWhitelist: [],
								staticity: ssStaticity.STATIC_SCOPE_WITH_STATIC_VERSION,
								scopeVariables: scopeVariables
							}"
							[styles-for-spanning-across-parent-grid]="{
								'first-dropdown': {
									'grid-column-start': gridColumnStartOfScopeSnapshotSelector
								}
							}"
							[draw-snapshot-to-scope-connectors]="{
								areMoreSnapshotsAbove: !cachedIsFirstDescendantSnapshotInScopeWithinFilter(descendantScopeSnapshot),
								areMoreSnapshotsBelow: !cachedIsLastDescendantSnapshotInScopeWithinFilter(descendantScopeSnapshot),
								distanceFromSnapshotToMidlinePx: 40
							}"></app-scope-snapshot-selector>
						<div class="inheritance-row-actions first" [ngClass]="{
							'always-visible': component.isInheritanceRowChanged(descendantScopeSnapshot),
							'disabled': !cachedIsDescendantScopeSnapshotWithinFilter(descendantScopeSnapshot)
						}">
							<!-- <div
								class="action-link"
								(click)="viewInheritancesOf(descendantScopeSnapshot)">

							</div> -->
							<!-- TODO Also check to ensure its not conflicting inheritances-->
							<div
								class="custom-select enabled"
								[ngClass]="{
									'modified': component.isInheritanceRowChanged(descendantScopeSnapshot)
								}"
								*ngIf="!isInheritanceRowIndirectDescendant(descendantScopeSnapshot, 'current') && !isInheritanceRowUsingConflictingInheritances(descendantScopeSnapshot)">
								<div class="primary-label no-dirty-indicator">
									Inherit from
								</div>
								<div class="secondary-label" *ngIf="descendantScopeSnapshot.validatedInheritances.length <= 1">
									<ng-container *ngIf="component.isInheritanceRowChanged(descendantScopeSnapshot)">
										<span class="show-as-older-state">
											<div class="ficon"
												[ngClass]="{
													'ficon-Remove': descendantScopeSnapshot.savedInheritance === null,
													'ficon-SplitObject': descendantScopeSnapshot.savedInheritance && descendantScopeSnapshot.savedInheritance.isDirectInheritance === false,
													'ficon-Childof': descendantScopeSnapshot.savedInheritance?.isDirectInheritance && descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshotId === requireVar(targetScopeSnapshot).id,
													'ficon-BranchFork flip-vertically': descendantScopeSnapshot.savedInheritance?.isDirectInheritance && descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshotId !== requireVar(targetScopeSnapshot).id
												}"
											></div>
											<ng-container *ngIf="!descendantScopeSnapshot.savedInheritance">None</ng-container>
											<ng-container *ngIf="descendantScopeSnapshot.savedInheritance">{{versionLabel(descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshot.scopeVersion)}}</ng-container>
										</span>
										<div class="ficon ficon-SortDown transformation-icon"></div>
									</ng-container>
									<ng-container *ngIf="descendantScopeSnapshot.validatedInheritances.length === 0"><div class="ficon ficon-Remove"></div> None</ng-container>
									<ng-container *ngIf="descendantScopeSnapshot.validatedInheritances[0] as inheritance">
										<div class="ficon"
											[ngClass]="{
												'ficon-Childof': inheritance.inheritedScopeSnapshot.id === requireVar(targetScopeSnapshot).id,
												'ficon-BranchFork flip-vertically': inheritance.inheritedScopeSnapshot.id !== requireVar(targetScopeSnapshot).id
											}"
										></div> {{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}
									</ng-container>
								</div>
								<div class="dropdown-wrapper">
									<div class="dropdown">
										<div
											class="dropdown-option none-option"
											[ngClass]="{
												'initially-selected': descendantScopeSnapshot.savedInheritance === null,
												'newly-selected': descendantScopeSnapshot.validatedInheritances.length === 0
											}"
											(click)="setInheritanceOfDescendantToSnapshotOfTargetScope(descendantScopeSnapshot, null)">
											<div class="ficon ficon-Remove"></div>
											None
										</div>
										<div class="divider-line-and-margin"></div>
										<div
											pRipple
											class="dropdown-option"
											[ngClass]="{
												'initially-selected': descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshotId === inheritFromSnapshot.id,
												'newly-selected': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId === inheritFromSnapshot.id
											}"
											(click)="setInheritanceOfDescendantToSnapshotOfTargetScope(descendantScopeSnapshot, inheritFromSnapshot.id)"
											*ngFor="let inheritFromSnapshot of inheritanceDropdownOptionsForRow(descendantScopeSnapshot)">
											<div
												class="ficon"
												[ngClass]="{
													'ficon-Childof': inheritFromSnapshot.id === requireVar(targetScopeSnapshot).id,
													'ficon-BranchFork flip-vertically': inheritFromSnapshot.id !== requireVar(targetScopeSnapshot).id
												}"></div>
											<div class="version-label">{{versionLabel(inheritFromSnapshot.scopeVersion)}}</div>
										</div>
									</div>
								</div>
							</div>

							<div class="custom-select modified error spaced-secondary-labels" *ngIf="descendantScopeSnapshot.validatedInheritances.length > 1">
								<div class="primary-label no-dirty-indicator">
									Conflicting Inheritances:
								</div>
								<div class="secondary-label" *ngFor="let inheritance of descendantScopeSnapshot.validatedInheritances">
									{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}
								</div>
							</div>
						</div>
						<div class="inheritance-row-actions second" [ngClass]="{
							'disabled': !cachedIsDescendantScopeSnapshotWithinFilter(descendantScopeSnapshot)
						}">
							<div
								class="action-link"
								(click)="viewInheritancesOf(descendantScopeSnapshot)">
								<div class="ficon ficon-SplitObject"></div> Modify All Inheritances of {{versionLabel(descendantScopeSnapshot.scopeVersion)}}
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</ng-container>
</div>

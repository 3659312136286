"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * A type-safe representation of JSON data.
 *
 * This class helps avoid having to repeated type-check every step of the way
 * when accessing a value nested in JSON.
 *
 * For example,
 * ```ts
 *  const key = TypedJSON.parse(json).get("secrets", 3, "key").string;
 *  if (key) {
 *      // go
 *  }
 * ```
 *
 * This can also be used to access other values of type `any` in a typesafe way.
 */
var TypedJSON = /** @class */function () {
  /**
   * Constructs a new `TypedJSON` object with the specified `value`.
   *
   * If `value` is a `string`, it is **not** parsed.
   * Use `TypedJSON.parse` for that.
   */
  function TypedJSON(value) {
    this.value = value;
  }
  /**
   * Parses the passed `json` to produce a `TypedJSON` object.
   *
   * If `json` is not valid JSON,
   * returns a `TypedJSON` object containing `undefined`.
   *
   * For example
   * ``TypedJSON.parse(`{ "a": [{ "b": "c" }] }`).get("a", 0, "b").string === "c";``
   *
   * @param json The JSON `string` to parse.
   */
  TypedJSON.parse = function (json) {
    try {
      return new TypedJSON(JSON.parse(json));
    } catch (_a) {
      return new TypedJSON(undefined);
    }
  };
  /**
   * `true` if this `TypedJSON` object represents a `string`.
   */
  TypedJSON.prototype.isString = function () {
    return typeof this.value === "string";
  };
  /**
   * The value of this `TypedJSON` object as a `string`,
   * or `undefined` if it is not a `string`.
   */
  TypedJSON.prototype.string = function () {
    if (this.isString()) {
      return this.value;
    }
  };
  /**
   * `true` if this `TypedJSON` object represents a `number`.
   */
  TypedJSON.prototype.isNumber = function () {
    return typeof this.value === "number";
  };
  /**
   * The value of this `TypedJSON` object as a `number`,
   * or `undefined` if it is not a `number`.
   */
  TypedJSON.prototype.number = function () {
    if (this.isNumber()) {
      return this.value;
    }
  };
  /**
   * `true` if this `TypedJSON` object represents a `boolean`.
   */
  TypedJSON.prototype.isBoolean = function () {
    return typeof this.value === "boolean";
  };
  /**
   * The value of this `TypedJSON` object as a `boolean`,
   * or `undefined` if it is not a `boolean`.
   */
  TypedJSON.prototype.boolean = function () {
    if (this.isBoolean()) {
      return this.value;
    }
  };
  /**
   * `true` if this `TypedJSON` object represents a `null`.
   *
   * There is no `.null`
   * since there is only one possible `null` value.
   */
  TypedJSON.prototype.isNull = function () {
    return this.value === null;
  };
  /**
   * `true` if this `TypedJSON` object is `undefined`.
   *
   * There is no `.undefined`
   * since there is only one possible `undefined` value.
   */
  TypedJSON.prototype.isUndefined = function () {
    return this.value === undefined;
  };
  /**
   * `true` if this `TypedJSON` object is an `array`.
   */
  TypedJSON.prototype.isArray = function () {
    return Array.isArray(this.value);
  };
  /**
   * The value of this `TypedJSON` object as an `array`,
   * or `undefined` if it is not an `array`.
   *
   * Prefer using `.get` for access,
   * instead of using the `array` directly.
   */
  TypedJSON.prototype.array = function () {
    if (this.isArray()) {
      return this.value;
    }
  };
  /**
   * `true` if this `TypedJSON` object is an `object`.
   */
  TypedJSON.prototype.isObject = function () {
    return !this.isArray() && !this.isNull() && typeof this.value === "object";
  };
  /**
   * The value of this `TypedJSON` object as an `object`,
   * or `undefined` if it is not an object.
   *
   * Prefer using `.get` for access,
   * instead of using the `object` directly.
   */
  TypedJSON.prototype.object = function () {
    if (this.isObject()) {
      return this.value;
    }
  };
  /**
   * Gets the `TypedJSON` object under the specified chain of keys,
   * or a `TypedJSON` object containing `undefined` if it is not available.
   *
   * For example,
   * ```ts
   * new TypedJSON({ a: [{ b: "c" }] }).get("a", 0, "b").string === "c";
   * ```
   *
   * Only `string` keys can be used on `TypedJSON` objects
   * that contain an `object`.
   * and only `number` keys can be used on `TypedJSON` objects
   * that contain an `array`.
   *
   * Note that `({ 1: 1 })["1"] === 1`,
   * so `number`-keyed objects can still be accessed via `strings`.
   *
   * @param keys The chain of keys to the value to return.
   */
  TypedJSON.prototype.get = function () {
    var keys = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      keys[_i] = arguments[_i];
    }
    var current = this;
    for (var _a = 0, keys_1 = keys; _a < keys_1.length; _a++) {
      var key = keys_1[_a];
      current = current._getSingle(key);
      if (current.isUndefined()) {
        break;
      }
    }
    return current;
  };
  /**
   * Prefer `.get`.
   *
   * Gets the `TypedJSON` object under the specified `key`,
   * or a `TypedJSON` object containing `undefined` if it is not available.
   *
   * For example,
   * ```ts
   * TypedJSON.parse("[0, 1, 2]")._getSingle(1) === 1;
   * TypedJSON.parse(`{ a: "a", b: "b" }`)._getSingle("a").string === "a";
   * ```
   *
   * Only `string` keys can be used on `TypedJSON` objects
   * that contain an `object`.
   * and only `number` keys can be used on `TypedJSON` objects
   * that contain an `array`.
   *
   * Note that `({ 1: 1 })["1"] === 1`,
   * so `number`-keyed objects can still be accessed via `strings`.
   *
   * @param key The key of the value to return.
   */
  TypedJSON.prototype._getSingle = function (key) {
    if (typeof key === "string") {
      var object = this.object();
      if (object) {
        return new TypedJSON(object[key]);
      }
    } else {
      var array = this.array();
      if (array) {
        return new TypedJSON(array[key]);
      }
    }
    return new TypedJSON(undefined);
  };
  /**
   * Returns an `array` of the keys of this `TypedJSON` object.
   *
   * This array is a `string[]` if this `TypedJSON` is an `object`,
   * a `number[]` if this TypedJSON is an `array`,
   * or an empty array if this TypedJSON is anything else.
   *
   * If this `TypedJSON` object's value is an `array`,
   * only non-negative integers
   * will be included in the resulting `array`,
   * in ascending order.
   *
   * If this TypedJSON's value is an object
   * with some `Symbol` keys,
   * these keys are ignored.
   *
   * The type is `(string | number)[]`
   * instead of `string[] | number[]`
   * because functions such as `sort` and `map`
   * have difficulties with `string[] | number[]`.
   *
   * Useful for iterating through `TypedJSON` objects.
   * For example,
   * ```ts
   *  for (const key of typedJSON.keys()) {
   *      const value = typedJSON.get(value);
   *      // Do something with `key` and `value`
   *  }
   * ```
   *
   * Use `.values()` to get an array of only values
   * if only values are needed instead.
   */
  TypedJSON.prototype.keys = function () {
    var asObject = this.object();
    if (asObject) {
      return Object.keys(asObject);
    }
    var asArray = this.array();
    if (asArray) {
      return Object.keys(asArray).map(function (key) {
        return parseInt(key, 10);
      }).filter(function (key) {
        return !isNaN(key) && key >= 0;
      }).sort(function (a, b) {
        return a - b;
      });
    }
    return [];
  };
  /**
   * Returns an array of `TypedJSON` objects
   * containing the values of this `TypedJSON`.
   *
   * If this `TypedJSON` object contains an `object`,
   * it returns the values of the `object` wrapped in `TypedJSON` objects.
   * If this `TypedJSON` contains an `array`,
   * it returns the values of the `array` wrapped in `TypedJSON` objects.
   * Otherwise, it returns an empty `array`.
   *
   * If this `TypedJSON` object's value is an `array`,
   * only values with non-negative integer keys
   * will be included in the resulting `array`,
   * in ascending order.
   *
   * Useful for iterating through `TypedJSON` objects.
   * For example,
   * ```ts
   *  for (const value of typedJSON.value()) {
   *      // Do something with the `value`
   *  }
   * ```
   *
   * Use `.keys()` if the keys are needed instead of the values.
   */
  TypedJSON.prototype.values = function () {
    var _this = this;
    return this.keys().map(function (key) {
      return _this.get(key);
    });
  };
  /**
   * Returns a JSON string representing this `TypedJSON` object,
   * or `undefined`, if it is not a valid JSON object.
   */
  TypedJSON.prototype.stringify = function () {
    try {
      return JSON.stringify(this.value);
    } catch (_a) {
      return undefined;
    }
  };
  /**
   * Returns a `string` representation of this `TypedJSON` object.
   *
   * This will **not** return JSON.
   * It is for debug purposes only,
   * and should not be parsed.
   *
   * To get JSON, use `.stringify()`.
   */
  TypedJSON.prototype.toString = function () {
    return "TypedJSON <" + this.stringify() + ">";
  };
  return TypedJSON;
}();
exports.TypedJSON = TypedJSON;
import { Component, OnInit, OnDestroy, ChangeDetectorRef, HostBinding, ElementRef } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import _ from 'lodash';
import { CrudStateService, GqlFragmentInfo, GqlRequestInfo, gqlRequestInfo } from 'src/app/crud-state.service';
import {
	FlagValueFieldsFragmentDoc,
	FlagValueFieldsWithAppliedInScopeSnapshotDetailsFragmentDoc,
	ScopeSnapshotDataForScopeSnapshotPageQuery,
	ScopeSnapshotDataForScopeSnapshotPageQueryVariables,
	ScopeVariableFieldsForScopeSnapshotSelectorFragment,
	InitialDataForScopeSnapshotSelectorQuery,
	InitialDataForScopeSnapshotSelectorQueryVariables,
	ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc,
	ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragmentDoc,
	CreateScopeAndSnapshotMutation,
	CreateScopeAndSnapshotMutationVariables,
	CreateScopeSnapshotMutation,
	CreateScopeSnapshotMutationVariables,
	RenameScopeVersionMutation,
	RenameScopeVersionMutationVariables,
	ScopeSnapshotDataForCherryPickReplicationQuery,
	ScopeSnapshotDataForCherryPickReplicationQueryVariables,
	ConfigurationForScopeSnapshotPageQuery,
	ConfigurationForScopeSnapshotPageQueryVariables,
	DeleteScopeSnapshotMutation,
	DeleteScopeSnapshotMutationVariables,
	FlagsValuesDataQuery,
	FlagsValuesDataQueryVariables,
} from 'src/generated/graphql';
import { requireVar } from 'src/app/utilities';
import { MessageService } from 'primeng/api';
import { ActionBase, ActionType, CherryPickReplicateActionBase, ReplicateScopeSnapshotAsNewVersionActionBase, Action as ScopeSnapshotSelectorAction, ActionBase as ScopeSnapshotSelectorActionBase, ActionType as ScopeSnapshotSelectorActionType, ScopeSnapshotSelectorComponent, State as ScopeSnapshotSelectorState, SelectorStateChangerOption, SelectorStateChangerOptionType, Staticity } from '../scope-snapshot-selector/scope-snapshot-selector.component';
import { CommonService } from 'src/app/app-common/common.service';
import { InheritancesSectionExternalAction, InheritancesSectionStateType, StaticScopeSnapshotInheritance } from '../scope-snapshot-inheritances/scope-snapshot-inheritances.component';
import { ScopeSnapshotService } from 'src/app/scope-snapshot.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { XOR } from 'ts-xor';
import { Clipboard } from '@angular/cdk/clipboard';
import { ScopesPageComponent } from 'src/app/scope/scopes-page/scopes-page.component';

type InheritanceWithoutIsSelfField = Omit<NonNullable<ScopeSnapshotDataForScopeSnapshotPageQuery["scopeSnapshotsByPk"]>["inheritances"][number], "isSelf">;

export type NonSelfInheritance = InheritanceWithoutIsSelfField & {
	isSelf: false;
};

export type SelfInheritance = InheritanceWithoutIsSelfField & {
	isSelf: true;
};

export type ExpectedScopeSnapshotData = ScopeSnapshotDataForScopeSnapshotPageQuery & {
	scopeSnapshotsByPk: NonNullable<Omit<ScopeSnapshotDataForScopeSnapshotPageQuery["scopeSnapshotsByPk"], "inheritances">> & {
		inheritances: [...NonSelfInheritance[], SelfInheritance];
	}
};

export type FlagNodeFromScopeSnapshotResponse = FlagsValuesDataQuery["inheritedValuedFlagNodes"][number]["flagNode"];
export type FlagValueFromScopeSnapshotResponse = FlagsValuesDataQuery["inheritedValuedFlagNodes"][number]["flagValue"];

type ComponentGqlRequestInfos = {
	queries: {
		scopeSnapshot: GqlRequestInfo<ScopeSnapshotDataForScopeSnapshotPageQuery, ScopeSnapshotDataForScopeSnapshotPageQueryVariables>,
		flagsValues: GqlRequestInfo<FlagsValuesDataQuery, FlagsValuesDataQueryVariables>,
		configuration: GqlRequestInfo<ConfigurationForScopeSnapshotPageQuery, ConfigurationForScopeSnapshotPageQueryVariables>,
		scopeSnapshotForCherryPickReplication: GqlRequestInfo<ScopeSnapshotDataForCherryPickReplicationQuery, ScopeSnapshotDataForCherryPickReplicationQueryVariables>,
		initialDataForScopeSnapshotSelector: GqlRequestInfo<InitialDataForScopeSnapshotSelectorQuery, InitialDataForScopeSnapshotSelectorQueryVariables>,
	},
	mutations: {
		deleteScopeSnapshot: GqlRequestInfo<DeleteScopeSnapshotMutation, DeleteScopeSnapshotMutationVariables>,
		createScopeAndSnapshot: GqlRequestInfo<CreateScopeAndSnapshotMutation, CreateScopeAndSnapshotMutationVariables>,
		createScopeSnapshot: GqlRequestInfo<CreateScopeSnapshotMutation, CreateScopeSnapshotMutationVariables>,
		renameScopeVersion: GqlRequestInfo<RenameScopeVersionMutation, RenameScopeVersionMutationVariables>,
	},
	fragments: {
		scopeVariableFields: GqlFragmentInfo<ScopeVariableFieldsForScopeSnapshotSelectorFragment>,
		// scopeSnapshotFields: GqlFragmentInfo<ScopeSnapshotFieldsForScopeSnapshotSelectorFragment>,
		// scopeFields: GqlFragmentInfo<ScopeFieldsForScopeSnapshotSelectorFragment>,
	},
};

enum ExpansionsMenuState {
	COLLAPSED = 'COLLAPSED',
	INHERITANCES_EXPANDED = 'INHERITANCES_EXPANDED',
	INHERITANCES_BEING_MODIFIED = 'INHERITANCES_BEING_MODIFIED',
	CHILD_SCOPE_SNAPSHOTS_EXPANDED = 'CHILD_SCOPE_SNAPSHOTS_EXPANDED',
	IMPORT_JSON_EXPANDED = 'IMPORT_JSON_EXPANDED',
	RENAME_VERSION_EXPANDED = 'RENAME_VERSION_EXPANDED',
	DELETE_SCOPE_SNAPSHOT_EXPANDED = 'DELETE_SCOPE_SNAPSHOT_EXPANDED',
}

enum ExpansionsMenuOption {
	TOGGLE_INHERITANCES = 'TOGGLE_INHERITANCES',
	TOGGLE_CHILD_SCOPE_SNAPSHOTS = 'TOGGLE_CHILD_SCOPE_SNAPSHOTS',
	MODIFY_INHERITANCES = 'MODIFY_INHERITANCES',
	TOGGLE_IMPORT_JSON = 'TOGGLE_IMPORT_JSON',
	TOGGLE_RENAME_VERSION = 'TOGGLE_RENAME_VERSION',
	TOGGLE_DELETE_SCOPE_SNAPSHOT = 'TOGGLE_DELETE_SCOPE_SNAPSHOT',
}

enum ExpansionSection {
	INHERITANCES = 'INHERITANCES',
	CHILD_SCOPE_SNAPSHOTS = 'CHILD_SCOPE_SNAPSHOTS',
	IMPORT_JSON = 'IMPORT_JSON',
	RENAME_VERSION = 'RENAME_VERSION',
	DELETE_SCOPE_SNAPSHOT = 'DELETE_SCOPE_SNAPSHOT',
}

type ExpansionsMenuTree = {
	[expansionsMenuState in ExpansionsMenuState]: ExpansionsMenuOption[]
};

type BulkSelector =
	XOR<
		{
			selectorType: 'STATIC_CONFLICT',
			tooltip?: string,
		},
	XOR<
		{
			selectorType: 'STATIC_INHERITED',
			tooltip?: string,
		},
	XOR<
		{
			selectorType: 'STATIC_CHECKMARK',
			tooltip?: string,
		},
	XOR<
		{
			selectorType: 'STATIC_NOT_APPLICABLE',
			tooltip?: string,
		},
		{
			selectorType: 'CHECKBOX',
			tooltip?: string,
			checked: boolean,
		}
	>>>>;

export type BulkFlagSelector = BulkSelector & {selectionType: 'FLAG'};
export type BulkValueSelector = BulkSelector & {selectionType: 'VALUE'};

export type BulkFlagSelectorChange = {
	flagNodeId: FlagNodeFromScopeSnapshotResponse["id"],
	selector: BulkFlagSelector,
};

export type BulkValueSelectorChange = {
	flagNodeId: FlagNodeFromScopeSnapshotResponse["id"],
	selector: BulkValueSelector,
};

type ScopeSnapshotDataForCherryPickReplication = {
	inheritedValuedFlagNodes: ScopeSnapshotDataForCherryPickReplicationQuery["inheritedValuedFlagNodes"],
	bulkFlagSelectors: {
		[key: FlagNodeFromScopeSnapshotResponse["id"]]: BulkFlagSelector
	},
	bulkValueSelectors: {
		[key: FlagNodeFromScopeSnapshotResponse["id"]]: BulkValueSelector
	}
};

@Component({
	selector: 'app-scope-snapshot-page',
	templateUrl: './scope-snapshot-page.component.html',
	styleUrls: ['./scope-snapshot-page.component.scss']
})
export class ScopeSnapshotPageComponent implements OnInit, OnDestroy {
	_ = _;

  @HostBinding('class.scope-snapshot-not-loaded') addScopeSnapshotNotLoadedClassToHost = !this.scopeSnapshotData;

	private fragments: ComponentGqlRequestInfos["fragments"] = {
		scopeVariableFields: gql`
			fragment scopeVariableFieldsForScopeSnapshotSelector on ScopeVariables {
				id
				ordinal
				name
				scopeOptions(orderBy: {ordinal: ASC}) {
					id
					scopeVariableId
					value
					ordinal
				}
			}
		`,
	}

	private gqlRequestInfos: ComponentGqlRequestInfos = {
		queries: {
			scopeSnapshot: gqlRequestInfo(gql`
				${this.fragments.scopeVariableFields}
				${ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragmentDoc}
				${ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc}

				query scopeSnapshotDataForScopeSnapshotPage($scopeSnapshotId: Int!) {
					scopeSnapshotsByPk(id: $scopeSnapshotId) {
						...scopeSnapshotFieldsForScopeSnapshotSelector
						inheritances(orderBy: {ordinal: ASC}) {
							ordinal
							isDirectInheritance
							isSelf
							inheritedScopeSnapshotId
							ordinalWithinDirectInheritances
							inheritedScopeSnapshot {
								...scopeSnapshotFieldsForStaticScopeSnapshotSelector
							}
						}
						childInheritances(orderBy: {ordinal: ASC}) {
							ordinal
							scopeSnapshotId
							scopeSnapshot {
								...scopeSnapshotFieldsForStaticScopeSnapshotSelector
							}
						}
					}
					scopeVariables(orderBy: {ordinal: ASC}) {
						...scopeVariableFieldsForScopeSnapshotSelector
					}
				}
			`),
			flagsValues: gqlRequestInfo(gql`
				${FlagValueFieldsFragmentDoc}
				${FlagValueFieldsWithAppliedInScopeSnapshotDetailsFragmentDoc}

				query flagsValuesData($scopeSnapshotId: Int!) {
					inheritedValuedFlagNodes(args: {inherited_in_scope_snapshot_id: $scopeSnapshotId}) {
						id
						flagNode {
							id
							key
							ordinal
							scopeFlagNodeId
							definedInScopeSnapshotId
							parentScopeFlagNodeId
							type

							parent {
								id
							}

							scopeFlagNode {
								id
								flagNodes(orderBy: {scopeSnapshot: {scopeVersion: ASC}}) {
									id
									key
									scopeSnapshot {
										id
										scopeVersion
									}
								}
								flagValues {
									...flagValueFields
								}
							}

							childrenAggregate {
								aggregate {
									count
								}
							}

							flagValues { # Contains all flag values for this flag node, even those that are not inherited in this scope snapshot.
								...flagValueFields
							}

							flagValuesAggregate {
								aggregate {
									count
								}
							}
						}

						flagValue { # Contains the final resulting flag value for this flag node in this scope snapshot, after considering all inherited flag values.
							...flagValueFields
						}
					}
				}
			`),
			configuration: gqlRequestInfo(gql`
				query configurationForScopeSnapshotPage($scopeSnapshotId: Int!) {
					configuration(args: {scope_snapshot_id: $scopeSnapshotId}) {
						scopeSnapshotId
						jsonConfiguration
					}
				}
			`),
			scopeSnapshotForCherryPickReplication: gqlRequestInfo(gql`
				query scopeSnapshotDataForCherryPickReplication($replicateToScopeSnapshotId: Int!) {
					inheritedValuedFlagNodes(args: {inherited_in_scope_snapshot_id: $replicateToScopeSnapshotId}) {
						id
						flagNode {
							id
							key
							scopeFlagNodeId
							parentScopeFlagNodeId
							type
						}
						flagValue {
							id
							value
							flagNodeScopeSnapshotId
							appliedInScopeSnapshotId
							scopeFlagNodeId
						}
					}
				}
			`),
			initialDataForScopeSnapshotSelector: gqlRequestInfo(gql`
				${this.fragments.scopeVariableFields}

				query initialDataForScopeSnapshotSelector {
					scopeVariables(orderBy: {ordinal: ASC}) {
						...scopeVariableFieldsForScopeSnapshotSelector
					}
					scopeSnapshotsAggregate {
						aggregate {
							count
						}
					}
				}
			`)
		},
		mutations: {
			deleteScopeSnapshot: gqlRequestInfo(gql`
				mutation deleteScopeSnapshot($id: Int!) {
					deleteFlagValues(where: {appliedInScopeSnapshotId: {_eq: $id}}) {
						returning {
							id
						}
					}
					deleteFlagNodes(where: {definedInScopeSnapshotId: {_eq: $id}}) {
						returning {
							id
						}
					}
					deleteScopeSnapshotsByPk(id: $id) {
						id
					}
				}
			`),
			createScopeAndSnapshot: gqlRequestInfo(gql`
				mutation createScopeAndSnapshot($scopeDefiners: [ScopeDefinersInsertInput!]!, $scopeVersion: String!) {
					insertScopeSnapshotsOne(object: {
						scopeVersion: $scopeVersion,
						scope: {data: {scopeDefiners: {data: $scopeDefiners}}}
					}) {
						id
						scopeId
						scopeVersion
					}
				}
			`),
			createScopeSnapshot: gqlRequestInfo(gql`
				mutation createScopeSnapshot($scopeId: Int!, $scopeVersion: String!) {
					insertScopeSnapshotsOne(object: {
						scopeId: $scopeId,
						scopeVersion: $scopeVersion
					}) {
						id
						scopeId
						scopeVersion
					}
				}
			`),
			renameScopeVersion: gqlRequestInfo(gql`
				mutation renameScopeVersion($scopeSnapshotId: Int!, $newScopeVersion: String!) {
					updateScopeSnapshotsByPk(pkColumns: {id: $scopeSnapshotId}, _set: {scopeVersion: $newScopeVersion}) {
						id
						scopeId
						scopeVersion
					}
				}
			`),
		},
		fragments: this.fragments
	}

	private scopeVariables?: ScopeVariableFieldsForScopeSnapshotSelectorFragment[];
	selectorTitleIndicatesScopeCreationOnly = false;

	scopeSnapshotData?: ExpectedScopeSnapshotData;
	private scopeSnapshotDataQuery?: QueryRef<ScopeSnapshotDataForScopeSnapshotPageQuery, ScopeSnapshotDataForScopeSnapshotPageQueryVariables>;
	flagsValuesData?: FlagsValuesDataQuery;
	private flagsValuesQuery?: QueryRef<FlagsValuesDataQuery, FlagsValuesDataQueryVariables>;
	isDisablingFlagsSectionInputs = false;
	configuration?: ConfigurationForScopeSnapshotPageQuery["configuration"][number] | null; // null means error. undefined means loading.
	private configurationQuery?: QueryRef<ConfigurationForScopeSnapshotPageQuery, ConfigurationForScopeSnapshotPageQueryVariables>;

	private routeSubscription?: Subscription;

	currentExpansionsMenuState: ExpansionsMenuState = ExpansionsMenuState.COLLAPSED;
	expansionsMenuStates = ExpansionsMenuState;
	expansionsMenuOptions = ExpansionsMenuOption;
	expansionSections = ExpansionSection;
	expansionsMenuStateToSectionVisibilities: {[expansionsMenuState in ExpansionsMenuState]: ExpansionSection[]} = {
		[ExpansionsMenuState.COLLAPSED]: [],
		[ExpansionsMenuState.INHERITANCES_EXPANDED]: [ExpansionSection.INHERITANCES],
		[ExpansionsMenuState.INHERITANCES_BEING_MODIFIED]: [ExpansionSection.INHERITANCES],
		[ExpansionsMenuState.CHILD_SCOPE_SNAPSHOTS_EXPANDED]: [ExpansionSection.CHILD_SCOPE_SNAPSHOTS],
		[ExpansionsMenuState.IMPORT_JSON_EXPANDED]: [ExpansionSection.IMPORT_JSON],
		[ExpansionsMenuState.RENAME_VERSION_EXPANDED]: [ExpansionSection.RENAME_VERSION],
		[ExpansionsMenuState.DELETE_SCOPE_SNAPSHOT_EXPANDED]: [ExpansionSection.DELETE_SCOPE_SNAPSHOT],
	};

	private params?: ParamMap;

	// readonly scopeSnapshotSelectorStaticity: Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION = Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION;
	readonly inheritedScopeSnapshotStaticity: Staticity.STATIC_SCOPE_WITH_STATIC_VERSION = Staticity.STATIC_SCOPE_WITH_STATIC_VERSION;

	scopeSnapshotSelectorState?: ScopeSnapshotSelectorState;
	Staticity = Staticity;

	// scopeSnapshotSelectorStateChanger: Subject<SelectorStateChangerOption> = new Subject<SelectorStateChangerOption>();

	scopeSnapshotSelectorActioner: Subject<ScopeSnapshotSelectorAction> = new Subject();
	actionTypes = ScopeSnapshotSelectorActionType;

	versionLabel = CommonService.friendlyVersionLabel;
	inheritancesSectionStateChanger: Subject<InheritancesSectionExternalAction> = new Subject<InheritancesSectionExternalAction>();
	latestInheritancesSectionState: InheritancesSectionStateType | null = null;

	formForRenamingCurrentVersion: FormGroup<{
		newVersionName: FormControl<string>,
	}> = new FormGroup({
		newVersionName: new FormControl<string>(
			"",
			{
				nonNullable: true,
				validators: [
					Validators.required,
				]
			},
		)
	});

	private scopeSnapshotDataForCherryPickReplicationQuery?: QueryRef<ScopeSnapshotDataForCherryPickReplicationQuery, ScopeSnapshotDataForCherryPickReplicationQueryVariables>;

	scopeSnapshotsDataForCherryPickReplication: {[replicateToScopeSnapshotId: number]: ScopeSnapshotDataForCherryPickReplication} = {};

	pageNumber = 0;
	// FYI - This page number input is 1-indexed (for UI purposes), but the pageNumber variable is 0-indexed.
	pageNumberInput: FormControl<number> = new FormControl<number>(
		this.pageNumber + 1,
		{
			validators: [
				Validators.min(1),
				Validators.required,
				(control) => {
					const numberOfPages = this.safeNumberOfPages();
					if (numberOfPages !== null && control.value > numberOfPages) {
						return {"outOfBounds" : `Page number must be between 1 and ${numberOfPages}.`}
					}
					return null;
				}
			],
			nonNullable: true
		});
	pageSize = 50;
	flagNodeIdsToPaginate: number[] | null = null;

	latestMainScrollTop: number | null = null;

	ScopeSnapshotSelectorComponent = ScopeSnapshotSelectorComponent;
	private scopeSnapshotSelectorActionsWhitelist: ScopeSnapshotSelectorActionType[] = [
		ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE,
		ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE,
		ScopeSnapshotSelectorActionType.REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION,
		ScopeSnapshotSelectorActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
		ScopeSnapshotSelectorActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
		ScopeSnapshotSelectorActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
		ScopeSnapshotSelectorActionType.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE,
		ScopeSnapshotSelectorActionType.CREATE_BLANK_SNAPSHOT_IN_DIFFERENT_SCOPE,
		ScopeSnapshotSelectorActionType.CREATE_BLANK_SNAPSHOT_WITH_NEW_SCOPE,
		ScopeSnapshotSelectorActionType.RESET_TO_INITIAL_STATE
	];
	scrollEventListener?: (event: Event) => void;

	constructor(private apollo: Apollo, public auth: AuthService, private scopeSnapshotService: ScopeSnapshotService, private route: ActivatedRoute, private messageService: MessageService, private commonService: CommonService, private cd: ChangeDetectorRef, private router: Router, private clipboard: Clipboard, private elRef: ElementRef) {	}

	private resetStatesAndCaches(resetStateForExpansionsMenu: boolean, resetStateForFlagPagination: boolean, resetCacheForFlags: boolean) {
		if (resetStateForExpansionsMenu) {
			this.currentExpansionsMenuState = ExpansionsMenuState.COLLAPSED;
		}

		if (resetStateForFlagPagination) {
			this.flagNodeIdsToPaginate = null;
			this.pageNumber = 0;
			this.pageNumberInput.setValue(this.pageNumber + 1);
		}

		if (resetCacheForFlags) {
			this.flagsThatHaveValuesCache = new Map();
		}
	}

	scopeSnapshotTitle() {
		if (this.scopeSnapshotData) {
			return "Scope Snapshot";
		}
		let title = "";
		if (this.selectorTitleIndicatesScopeCreationOnly) {
			title += "Create a New Scope";
		}
		else {
			title += "Select a Scope";
		}
		if (this.scopeSnapshotSelectorState) {
			const completedScopeOptions = ScopeSnapshotSelectorComponent.completedScopeDefinitionMap(this.scopeSnapshotSelectorState);
			if (completedScopeOptions !== undefined) {
				title += " Snapshot";
			}
		}
		return title;
	}

	queryParamsForOpeningInheritancesOnScopesAndSnapshotsPage(scopeSnapshotId: number) {
		return ScopesPageComponent.queryParamsForOpeningInheritancesOnScopesAndSnapshotsPage(scopeSnapshotId);
	}

	renameCurrentScopeSnapshotVersion(newVersionName: string) {
		if (this.scopeSnapshotDataQuery) {
			const currentScopeSnapshot = this.requireCurrentScopeSnapshot();
			const oldVersionName = currentScopeSnapshot.scopeVersion;
			const renameScopeVersionInfo = this.gqlRequestInfos.mutations.renameScopeVersion;
			renameScopeVersionInfo.subscription?.unsubscribe();

			// Set the next fetch policy to cache-only (previously it was 'standby'), so that it updates the scope snapshot selector with the new version name.
			// const previousFetchPolicy = this.scopeSnapshotDataQuery.options.nextFetchPolicy;
			// this.scopeSnapshotDataQuery.options.nextFetchPolicy = 'cache-only';

			renameScopeVersionInfo.subscription = this.apollo.mutate({
				mutation: renameScopeVersionInfo.gql,
				variables: {
					scopeSnapshotId: currentScopeSnapshot.id,
					newScopeVersion: newVersionName
				}
			})
			.subscribe({
				next: ({data, loading}) => {
					this.messageService.add({severity:'success', summary: 'Renamed Version', detail: `Renamed ${this.versionLabel(oldVersionName)} to ${this.versionLabel(newVersionName)}.`});
					this.cd.detectChanges();
				},
				error: (error) => {
					this.commonService.mutationErrorHandler(error);
				}
			});
		}
		else {
			throw new Error('scopeSnapshotDataQuery is undefined');
		}
	}

	handleImportJsonSectionImported() {
		if (this.scopeSnapshotDataQuery) {
			this.scopeSnapshotDataQuery.refetch().then(({data}) => {
				this.currentExpansionsMenuState = ExpansionsMenuState.COLLAPSED;
			});
		}
	}

	handleImportJsonSectionCancel() {
		this.currentExpansionsMenuState = ExpansionsMenuState.COLLAPSED;
	}

	ngOnInit() {
		this.addScrollTrackerIfNotAlreadyAdded();

		// Refresh the list of flags, values, and json output whenever the scope is changed.
		this.routeSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
			const requestedScopeSnapshotId = params.get('scopeSnapshotId');
			CrudStateService.unsubscribeFromGqlSubscriptions(this.gqlRequestInfos);

			this.selectorTitleIndicatesScopeCreationOnly = false;

			if (requestedScopeSnapshotId === null){
				this.addScopeSnapshotNotLoadedClassToHost = true;

				this.gqlRequestInfos.queries.initialDataForScopeSnapshotSelector.subscription?.unsubscribe();

				this.gqlRequestInfos.queries.initialDataForScopeSnapshotSelector.subscription = this.apollo.watchQuery({
					query: this.gqlRequestInfos.queries.initialDataForScopeSnapshotSelector.gql,
				})
				.valueChanges.subscribe({
					next: ({data, loading}) => {
						if (data.scopeVariables.length === 0) {
							this.router.navigate(['']); // Go home if there are no scope variables set up yet.
						}

						this.scopeSnapshotData = undefined;
						this.scopeVariables = data.scopeVariables;
						this.selectorTitleIndicatesScopeCreationOnly = data.scopeSnapshotsAggregate.aggregate?.count === 0;

						this.scopeSnapshotSelectorState = {
							staticity: Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION,
							actionsWhitelist: this.scopeSnapshotSelectorActionsWhitelist,
							scopeVariables: this.scopeVariables
						};
						this.resetStatesAndCaches(true, true, true);
						this.cd.detectChanges();
					},
					error: (error) => {
						this.commonService.queryErrorHandler(error);
					}
				});
			}
			else {
				this.configuration = undefined; // Set the configuration to undefined to indicate a loading state.
				this.flagsValuesData = undefined; // Reset the flags section so that it doesn't display while we load a new snapshot. This is actually necessary because it prevents errors where the flags component complains about incompatible data while loading new snapshot data.
				this.cd.detectChanges();

				this.gqlRequestInfos.queries.scopeSnapshot.subscription?.unsubscribe();

				this.scopeSnapshotDataQuery = this.apollo.watchQuery({
					query: this.gqlRequestInfos.queries.scopeSnapshot.gql,
					variables: {scopeSnapshotId: +requestedScopeSnapshotId},
					// We have a normal fetchPolicy here (unlike flagsValuesQuery) because we need the scope snapshot selector to be updated when you do actions such as rename the version.
				});

				this.gqlRequestInfos.queries.scopeSnapshot.subscription = this.scopeSnapshotDataQuery
				.valueChanges.subscribe({
					next: ({ data, loading }) => {
						if (data.scopeVariables.length === 0) {
							this.router.navigate(['']); // Go home if there are no scope variables set up yet.
						}
						this.scopeVariables = data.scopeVariables;
						this.addScopeSnapshotNotLoadedClassToHost = false;

						const previousScopeSnapshotId = this.scopeSnapshotData?.scopeSnapshotsByPk?.id;

						this.scopeSnapshotData = this.requireValidScopeSnapshotData(data, true);
						const currentScopeSnapshot = this.requireCurrentScopeSnapshot();

						// If we never had a scope snapshot selector state, or if the previous scope snapshot selector state was for a different scope snapshot, then create a new scope snapshot selector state.
						if (!this.scopeSnapshotSelectorState || this.scopeSnapshotSelectorState?.initialScopeSnapshot?.id !== currentScopeSnapshot.id) {
							this.scopeSnapshotSelectorState = {
								staticity: Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION,
								actionsWhitelist: this.scopeSnapshotSelectorActionsWhitelist,
								initialScopeSnapshot: currentScopeSnapshot,
								scopeVariables: this.scopeVariables
							};
						}
						// Otherwise, simply update the existing scope snapshot selector state with the new scope snapshot data. This is necessary because the scope snapshot selector state may have been modified by the user, and we don't want to lose those modifications.
						else {
							ScopeSnapshotSelectorComponent.updateStateWithNewScopeSnapshots(this.scopeSnapshotSelectorState, [this.scopeSnapshotData.scopeSnapshotsByPk]);
						}

						const isNewScopeSnapshot = previousScopeSnapshotId !== currentScopeSnapshot.id;
						this.resetStatesAndCaches(true, isNewScopeSnapshot, true);
						this.cd.detectChanges();
					},
					error: (error) => {
						this.commonService.queryErrorHandler(error);
					}
				});

				// Fetch the configuration for this scope snapshot *after* all the other scope snapshot data has been fetched. This improves initial load time.
				this.configurationQuery = this.apollo.watchQuery({
					query: this.gqlRequestInfos.queries.configuration.gql,
					variables: {scopeSnapshotId: +requestedScopeSnapshotId},
					fetchPolicy: 'no-cache' // Super important to reduce post-response loading time: from 15 secs down to 4 secs when loading 5000+ flags.
				});

				this.gqlRequestInfos.queries.configuration.subscription?.unsubscribe();
				this.gqlRequestInfos.queries.configuration.subscription = this.configurationQuery.valueChanges.subscribe({
					next: ({ data, loading }) => {
						this.configuration = data.configuration[0];
						if (!this.configuration) {
							this.configuration = null; // Set the configuration to null to indicate an error state.
							this.commonService.queryErrorHandler("Could not fetch json configuration for scope snapshot.");
						}
					},
					error: (error) => {
						this.commonService.queryErrorHandler(error);
					}
				});

				this.gqlRequestInfos.queries.flagsValues.subscription?.unsubscribe();

				this.flagsValuesQuery = this.apollo.watchQuery({
					query: this.gqlRequestInfos.queries.flagsValues.gql,
					variables: {scopeSnapshotId: +requestedScopeSnapshotId},
					fetchPolicy: 'no-cache' // Super important to reduce post-response loading time: from 15 secs down to 4 secs when loading 5000+ flags.
				});

				this.gqlRequestInfos.queries.flagsValues.subscription = this.flagsValuesQuery
					.valueChanges.subscribe({
						next: ({ data, loading }) => {
							this.flagsValuesData = data;
							this.isDisablingFlagsSectionInputs = false;
						},
						error: (error) => {
							this.commonService.queryErrorHandler(error);
						}
					}
				);

			}
		});
	}

	/**
	 *  We only show the flags component ONLY if both the scope snapshot data and flags are loaded, AND the flags that are loaded are for the scope snapshot id present in the scope snapshot data. This is to account for the scope snapshot data and flags being loaded at different times.
	 *  */
	isShowingFlagsSection(): boolean {
		if (this.flagsValuesData === undefined) {
			return false;
		}

		const currentScopeSnapshotId = this.scopeSnapshotData?.scopeSnapshotsByPk.id;
		if (currentScopeSnapshotId === undefined) {
			return false;
		}

		if (this.flagsValuesQuery?.variables.scopeSnapshotId !== currentScopeSnapshotId) {
			return false;
		}

		return true;
	}

	isLoadingFlagsSection(): boolean {
		return !this.isShowingFlagsSection() && !!this.scopeSnapshotData?.scopeSnapshotsByPk.id;
	}

	addScrollTrackerIfNotAlreadyAdded() {
		if (this.latestMainScrollTop === null) {
			// Helps to keep track of the latest scroll position of the main element, so that the scope snapshot section can be collapsed when the user scrolls down.
			const mainElement = this.elRef.nativeElement;

			if (this.scrollEventListener !== undefined) {
				mainElement.removeEventListener('scroll', this.scrollEventListener);
			}

			this.scrollEventListener = (event: Event) => {
				const previousScrollTop = this.latestMainScrollTop;
				this.latestMainScrollTop = (event.target as HTMLElement).scrollTop;
				// We only need to detect changes when the user scrolls from the top to just below the top, or from below the top to the top.
				// Limiting the detection to these cases helps to prevent unnecessary change detection calls and improves performance.
				// We can do this because the only div depending on this is the actions under the scope snapshot selector. If we ever add more divs that depend on this, then we may need to change this logic.
				if (previousScrollTop === null || (previousScrollTop === 0 && this.latestMainScrollTop > 0) || (previousScrollTop > 0 && this.latestMainScrollTop === 0)) {
					this.cd.detectChanges();
				}
			};
			mainElement.addEventListener('scroll', this.scrollEventListener);
		}
	}

	refreshFlagsValuesData() {
		this.configuration = undefined; // Indicate that the raw json config is now loading
		this.isDisablingFlagsSectionInputs = true;

		if (this.flagsValuesQuery) {
			this.flagsValuesQuery.refetch();
		}
		if (this.configurationQuery) {
			this.configurationQuery.refetch();
		}
	}

	requireScopeSnapshotSelectorState(): ScopeSnapshotSelectorState {
		const scopeSnapshotSelectorState = this.scopeSnapshotSelectorState;
		if (!scopeSnapshotSelectorState) {
			throw new Error("Scope snapshot selector state is undefined.");
		}
		return scopeSnapshotSelectorState;
	}

	currentExpansionsMenuTree(): ExpansionsMenuTree {
		let tree: ExpansionsMenuTree = {
			[ExpansionsMenuState.COLLAPSED]: [ExpansionsMenuOption.TOGGLE_INHERITANCES, ExpansionsMenuOption.TOGGLE_CHILD_SCOPE_SNAPSHOTS, ExpansionsMenuOption.TOGGLE_IMPORT_JSON, ExpansionsMenuOption.TOGGLE_RENAME_VERSION, ExpansionsMenuOption.TOGGLE_DELETE_SCOPE_SNAPSHOT],
			[ExpansionsMenuState.INHERITANCES_EXPANDED]: [ExpansionsMenuOption.TOGGLE_INHERITANCES, ExpansionsMenuOption.MODIFY_INHERITANCES],
			[ExpansionsMenuState.INHERITANCES_BEING_MODIFIED]: [],
			[ExpansionsMenuState.CHILD_SCOPE_SNAPSHOTS_EXPANDED]: [ExpansionsMenuOption.TOGGLE_CHILD_SCOPE_SNAPSHOTS],
			[ExpansionsMenuState.IMPORT_JSON_EXPANDED]: [],
			[ExpansionsMenuState.RENAME_VERSION_EXPANDED]: [],
			[ExpansionsMenuState.DELETE_SCOPE_SNAPSHOT_EXPANDED]: [],
		};

		// If there are no inheritances, then replace the "See Inheritances" option with the "Modify Inheritances" option.
		if (this.requireScopeSnapshotInheritancesWithoutSelf().length === 0) {
			tree[ExpansionsMenuState.COLLAPSED] = _.map(tree[ExpansionsMenuState.COLLAPSED], (option) => option === ExpansionsMenuOption.TOGGLE_INHERITANCES ? ExpansionsMenuOption.MODIFY_INHERITANCES : option);
		}

		// If there are no child scope snapshots, then remove the "See Child Scope Snapshots" option.
		if (this.requireScopeSnapshotData().scopeSnapshotsByPk.childInheritances.length === 0) {
			_.remove(tree[ExpansionsMenuState.COLLAPSED], (option) => option === ExpansionsMenuOption.TOGGLE_CHILD_SCOPE_SNAPSHOTS);
		}

		return tree;
	}

	currentExpansionsMenuOptions(): ExpansionsMenuOption[] {
		return this.currentExpansionsMenuTree()[this.currentExpansionsMenuState];
	}

	currentlyVisibleExpansionSections(): ExpansionSection[] {
		return this.expansionsMenuStateToSectionVisibilities[this.currentExpansionsMenuState];
	}

	toggleImportJsonSection() {
		if (this.currentExpansionsMenuState === ExpansionsMenuState.IMPORT_JSON_EXPANDED) {
			this.currentExpansionsMenuState = ExpansionsMenuState.COLLAPSED;
		}
		else {
			this.currentExpansionsMenuState = ExpansionsMenuState.IMPORT_JSON_EXPANDED;
		}
	}

	copyRawJsonToClipboard() {
		this.clipboard.copy(JSON.stringify(this.requireScopeSnapshotJson().jsonConfiguration));
		this.commonService.messageService.add({severity:'success', summary: 'Copied!', detail: `The entire raw json config has been copied to your clipboard.`});
	}

	copyBearerTokenToClipboard() {
		this.commonService.copyBearerTokenToClipboard();
	}

	viewConfigInGraphqlUrl(scopeSnapshotId: number) {
		return `https://cloud.hasura.io/public/graphiql?endpoint=https%3A%2F%2Fapi.scoperoot.com%2Fv1%2Fgraphql&variables=%7B%22scopeSnapshotId%22%3A${scopeSnapshotId}%7D&query=query+ConfigurationQuery%28%24scopeSnapshotId%3A+Int%21%29+%7B%0A++scopeSnapshotsByPk%28id%3A+%24scopeSnapshotId%29+%7B%0A++++id%0A++++scopeId%0A++++scopeVersion%0A++++configuration+%7B%0A++++++jsonConfiguration%0A++++%7D%0A++%7D%0A%7D%0A`;
	}

	toggleRenameVersionSection() {
		if (this.currentExpansionsMenuState === ExpansionsMenuState.RENAME_VERSION_EXPANDED) {
			this.closeRenameVersionSection();
		}
		else {
			this.openRenameVersionSection();
		}
	}

	closeRenameVersionSection() {
		this.currentExpansionsMenuState = ExpansionsMenuState.COLLAPSED;
	}

	openRenameVersionSection() {
		this.currentExpansionsMenuState = ExpansionsMenuState.RENAME_VERSION_EXPANDED;
		this.formForRenamingCurrentVersion.controls.newVersionName.setValue(this.requireCurrentScopeSnapshot().scopeVersion);
	}

	toggleDeleteScopeSnapshotSection() {
		if (this.currentExpansionsMenuState === ExpansionsMenuState.DELETE_SCOPE_SNAPSHOT_EXPANDED) {
			this.currentExpansionsMenuState = ExpansionsMenuState.COLLAPSED;
		}
		else {
			this.currentExpansionsMenuState = ExpansionsMenuState.DELETE_SCOPE_SNAPSHOT_EXPANDED;
		}
	}

	replicateCurrentScopeSnapshotAsNewVersion(actionBase: ReplicateScopeSnapshotAsNewVersionActionBase) {
		this.scopeSnapshotSelectorActioner.next({
			...actionBase,
			inputs: {
				newDirectlyInheritedScopeSnapshotIds: this.requireCurrentScopeSnapshot().inheritances.filter(inheritance => inheritance.isDirectInheritance).map(inheritance => inheritance.inheritedScopeSnapshot.id),
			}
		});
	}

	enterModeForCherryPickingFlagsAndValues(actionBase: Extract<ActionBase, {type: ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}>) {
		this.scopeSnapshotSelectorActioner.next(actionBase);

		const selectorState = this.requireScopeSnapshotSelectorState()

		const replicateToScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(selectorState);

		if (!replicateToScopeSnapshot) {
			throw new Error("No scope snapshot selected.");
		}

		delete this.scopeSnapshotsDataForCherryPickReplication[replicateToScopeSnapshot.id];

		this.scopeSnapshotDataForCherryPickReplicationQuery = this.apollo.watchQuery({
			query: this.gqlRequestInfos.queries.scopeSnapshotForCherryPickReplication.gql,
			variables: {
				replicateToScopeSnapshotId: replicateToScopeSnapshot.id
			},
			fetchPolicy: 'no-cache', // Improve post-response loading performance
		});

		this.gqlRequestInfos.queries.scopeSnapshotForCherryPickReplication.subscription?.unsubscribe();
		this.gqlRequestInfos.queries.scopeSnapshotForCherryPickReplication.subscription = this.scopeSnapshotDataForCherryPickReplicationQuery.valueChanges.subscribe({
			next: ({ data, loading }) => {

				const currentLeafFlagNodeIds = this.leafFlagNodeIds();
				const currentFlagNodesMap = this.flagNodesMap();

				const bulkFlagSelectors: {[key: FlagNodeFromScopeSnapshotResponse["id"]]: BulkFlagSelector} = {};
				const bulkValueSelectors: {[key: FlagNodeFromScopeSnapshotResponse["id"]]: BulkValueSelector} = {};

				const scopeFlagNodeIdsInOriginThatConflictWithDestination: Set<number> = new Set();

				const existingScopeFlagNodeIdsInDestination: Set<number> = new Set();
				let existingFlagValuesInDestination: {
					[scopeFlagNodeId: number]: ScopeSnapshotDataForCherryPickReplicationQuery["inheritedValuedFlagNodes"][number]["flagValue"]
				} = {};

				for (const inheritedValuedFlagNode of data.inheritedValuedFlagNodes) {
					existingScopeFlagNodeIdsInDestination.add(inheritedValuedFlagNode.flagNode.scopeFlagNodeId);
					existingFlagValuesInDestination[inheritedValuedFlagNode.flagNode.scopeFlagNodeId] = inheritedValuedFlagNode.flagValue;
				}

				for (const currentLeafFlagNodeId of currentLeafFlagNodeIds) {
					const currentFlagNode = currentFlagNodesMap[currentLeafFlagNodeId];
					if (!currentFlagNode) {
						throw new Error(`Could not find flag node with id ${currentLeafFlagNodeId}`);
					}

					const isCurrentFlagNodeInherited = currentFlagNode.definedInScopeSnapshotId !== this.requireCurrentScopeSnapshot().id;
					const lastInheritedFlagNodeWithinCurrFlagPath = _.findLast(this.flagNodePath(currentFlagNode), flagNode => flagNode.definedInScopeSnapshotId !== this.requireCurrentScopeSnapshot().id);
					const requiredInheritedScopeFlagNodeIdInDestination: number | null = lastInheritedFlagNodeWithinCurrFlagPath ? lastInheritedFlagNodeWithinCurrFlagPath.scopeFlagNodeId : null;
					const flagValueAppliedInCurrentScopeSnapshot = currentFlagNode.flagValues.find(flagValue => flagValue.appliedInScopeSnapshotId === this.requireCurrentScopeSnapshot().id);

					let isMatchingFlagValueAndNotInherited = false;
					if (!flagValueAppliedInCurrentScopeSnapshot) {
						isMatchingFlagValueAndNotInherited = false;
					}
					else {
						const existingFlagValueInDestination = existingFlagValuesInDestination[currentFlagNode.scopeFlagNodeId];
						if (existingFlagValueInDestination) {
							const isFlagValueInDestinationNonInherited = existingFlagValueInDestination.appliedInScopeSnapshotId === replicateToScopeSnapshot.id;
							if (isFlagValueInDestinationNonInherited) {
								isMatchingFlagValueAndNotInherited = flagValueAppliedInCurrentScopeSnapshot.value === existingFlagValueInDestination.value;
							}
						}
					}

					if (existingScopeFlagNodeIdsInDestination.has(currentFlagNode.scopeFlagNodeId)) {
						// If an inherited flag node is also inherited in the destination scope snapshot, then indicate that with a inheritance icon.
						if (isCurrentFlagNodeInherited) {
							bulkFlagSelectors[currentLeafFlagNodeId] = {
								selectorType: 'STATIC_INHERITED',
								selectionType: 'FLAG',
								tooltip: `This flag node is also inherited in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)}.`
							};
						}
						// If a non-inherited flag node is already replicated in the destination scope snapshot, then indicate that with a static checkmark.
						else {
							bulkFlagSelectors[currentLeafFlagNodeId] = {
								selectorType: 'STATIC_CHECKMARK',
								selectionType: 'FLAG',
								tooltip: `This flag node is already replicated in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)}.`
							};
						}

						// If the flag node has a value in the current scope snapshot, then display either a checkbox so that the user can replicate the value, or a static checkmark to indicate that the value is already replicated in the destination scope snapshot.
						if (flagValueAppliedInCurrentScopeSnapshot) {
							// If the destination flag node has the same value as the current flag node (and they are both non inherited), then indicate that with a static checkmark.
							if (isMatchingFlagValueAndNotInherited) {
								bulkValueSelectors[currentLeafFlagNodeId] = {
									selectorType: 'STATIC_CHECKMARK',
									selectionType: 'VALUE',
									tooltip: `This flag value is already replicated in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)}.`
								};
							}
							// Otherwise, display a checkbox so that the user can replicate the value.
							else {
								bulkValueSelectors[currentLeafFlagNodeId] = {
									selectorType: 'CHECKBOX',
									selectionType: 'VALUE',
									checked: false
								};
							}
						}
					}
					else {
						// If an inherited flag node is not inherited in the destination scope snapshot, then indicate that with a static "not applicable" icon and a tooltip that lets the user know how they can go about inheriting it in the destination scope snapshot.
						if (isCurrentFlagNodeInherited) {
							bulkFlagSelectors[currentLeafFlagNodeId] = {
								selectorType: 'STATIC_NOT_APPLICABLE',
								selectionType: 'FLAG',
								tooltip:
`This flag is not inherited in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)}.

Modify the inheritances of ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)} in order to inherit this flag there as well.`
							};
						}
						else {
							// If a non-inherited flag node is not replicated in the destination scope snapshot, and we cannot replicate it due to missing inherited flag nodes, then indicate that with a static "not applicable" icon and a tooltip that lets the user know how they can go about replicating it in the destination scope snapshot.
							if (requiredInheritedScopeFlagNodeIdInDestination !== null && !existingScopeFlagNodeIdsInDestination.has(requiredInheritedScopeFlagNodeIdInDestination)) {
								bulkFlagSelectors[currentLeafFlagNodeId] = {
									selectorType: 'STATIC_NOT_APPLICABLE',
									selectionType: 'FLAG',
									tooltip:
`The inherited flag nodes of this flag are not present in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)}.

Modify the inheritances of ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)} so that it too inherits the inherited flag nodes here. You'll then be able to replicate this flag.`
								};
							}
							else {
								const isCurrentFlagNodeConflictingWithDestination = data.inheritedValuedFlagNodes.some(ivfn => ivfn.flagNode.key === currentFlagNode.key && ivfn.flagNode.parentScopeFlagNodeId === currentFlagNode.parentScopeFlagNodeId);

								// Even though the current flag node is not replicated in the destination scope snapshot according to the scope flag node id, there may still a flag node in the destination scope snapshot that has the same key and parent scope flag node id as the current flag node. If so, then we need to indicate that the current flag node conflicts with the destination flag node.
								if (isCurrentFlagNodeConflictingWithDestination) {
									scopeFlagNodeIdsInOriginThatConflictWithDestination.add(currentFlagNode.scopeFlagNodeId);
								}

								const isParentFlagNodeConflictingWithDestination = currentFlagNode.parentScopeFlagNodeId && scopeFlagNodeIdsInOriginThatConflictWithDestination.has(currentFlagNode.parentScopeFlagNodeId);
								if (isParentFlagNodeConflictingWithDestination) {
									scopeFlagNodeIdsInOriginThatConflictWithDestination.add(currentFlagNode.scopeFlagNodeId);
								}

								if (isCurrentFlagNodeConflictingWithDestination || isParentFlagNodeConflictingWithDestination) {
									bulkFlagSelectors[currentLeafFlagNodeId] = {
										selectorType: 'STATIC_CONFLICT',
										selectionType: 'FLAG',
										tooltip:
											isCurrentFlagNodeConflictingWithDestination ?
`This flag has the same path as a flag in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)} but they have different scope flag ids.

You will need to either rename this flag here or in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)} in order to replicate this flag. Alternatively, you can delete the flag in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)} and then proceed with replicating this flag.`
												:
`This flag has an ancestor flag that cannot be replicated because it has the same path as a flag in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)} but with a different scope flag id.

Follow the instructions for the ancestor flag in order to proceed with replicating this flag.`
									};
								}
								else {
									// Otherwise, display a checkbox so that the user can replicate the flag node.
									bulkFlagSelectors[currentLeafFlagNodeId] = {
										selectorType: 'CHECKBOX',
										selectionType: 'FLAG',
										checked: false
									};

									// If the flag node has a value in the current scope snapshot, then display either a checkbox so that the user can replicate the value, or a static checkmark to indicate that the value is already replicated in the destination scope snapshot.
									if (flagValueAppliedInCurrentScopeSnapshot) {
										// If the destination flag node has the same value as the current flag node (and they are both non inherited), then indicate that with a static checkmark.
										if (isMatchingFlagValueAndNotInherited) {
											bulkValueSelectors[currentLeafFlagNodeId] = {
												selectorType: 'STATIC_CHECKMARK',
												selectionType: 'VALUE',
												tooltip: `This flag value is already replicated in ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)}.`
											};
										}
										// Otherwise, display a checkbox so that the user can replicate the value.
										else {
											bulkValueSelectors[currentLeafFlagNodeId] = {
												selectorType: 'CHECKBOX',
												selectionType: 'VALUE',
												checked: false
											};
										}
									}
								}
							}
						}
					}
				}

				this.scopeSnapshotsDataForCherryPickReplication[replicateToScopeSnapshot.id] = {
					inheritedValuedFlagNodes: data.inheritedValuedFlagNodes,
					bulkFlagSelectors: bulkFlagSelectors,
					bulkValueSelectors: bulkValueSelectors
				};
				this.cd.detectChanges();
			},
			error: (error) => {
				this.commonService.queryErrorHandler(error);
			}
		});
	}

	handleBulkFlagSelectorChange(bulkFlagSelectorChange: BulkFlagSelectorChange) {
		const scopeSnapshotDataForCherryPickReplication = this.scopeSnapshotDataForCherryPickReplicationIfEnabled();

		if (scopeSnapshotDataForCherryPickReplication) {
			const newBulkFlagSelector = bulkFlagSelectorChange.selector;
			if (newBulkFlagSelector.selectorType === 'CHECKBOX') {
				const flagNode = this.flagNodesMap()[bulkFlagSelectorChange.flagNodeId];
				if (!flagNode) {
					throw new Error(`Could not find flag node with id ${bulkFlagSelectorChange.flagNodeId}`);
				}

				// If the flag was checked, then check all flag nodes that are ancestors of the current flag node.
				if (newBulkFlagSelector.checked) {
					const flagNodeIdsThatMustBeChecked = this.flagNodePath(flagNode).map(flagNode => flagNode.id);

					for (const flagNodeIdThatMustBeChecked of flagNodeIdsThatMustBeChecked) {
						const bulkFlagSelector = scopeSnapshotDataForCherryPickReplication.bulkFlagSelectors[flagNodeIdThatMustBeChecked];
						if (!bulkFlagSelector) {
							throw new Error(`Could not find bulk flag selector for flag node with id ${flagNodeIdThatMustBeChecked}`);
						}
						if (bulkFlagSelector.selectorType === 'CHECKBOX') {
							bulkFlagSelector.checked = true;
						}
					}
				}

				// If the flag was unchecked, then uncheck all flag nodes that are descendants of the current flag node, and their values. Also, include the current flag node itself in this list so that it's value, too, will also be unchecked down below.
				else {
					const flagNodeIdsThatMustBeUnchecked = [flagNode.id, ...this.flagNodeDescendants(flagNode).map(fn => fn.id)];

					for (const flagNodeIdThatMustBeUnchecked of flagNodeIdsThatMustBeUnchecked) {
						const bulkFlagSelector = scopeSnapshotDataForCherryPickReplication.bulkFlagSelectors[flagNodeIdThatMustBeUnchecked];
						if (!bulkFlagSelector) {
							throw new Error(`Could not find bulk flag selector for flag node with id ${flagNodeIdThatMustBeUnchecked}`);
						}
						if (bulkFlagSelector.selectorType === 'CHECKBOX') {
							bulkFlagSelector.checked = false;
						}

						const bulkValueSelector = scopeSnapshotDataForCherryPickReplication.bulkValueSelectors[flagNodeIdThatMustBeUnchecked];
						if (bulkValueSelector && bulkValueSelector.selectorType === 'CHECKBOX') {
							bulkValueSelector.checked = false;
						}
					}
				}

			}
		}
	}

	handleBulkValueSelectorChange(bulkValueSelectorChange: BulkValueSelectorChange) {
		const scopeSnapshotDataForCherryPickReplication = this.scopeSnapshotDataForCherryPickReplicationIfEnabled();

		if (scopeSnapshotDataForCherryPickReplication) {
			const newBulkValueSelector = bulkValueSelectorChange.selector;

			// If the value was checked, then check the flag node, too.
			if (newBulkValueSelector.selectorType === 'CHECKBOX' && newBulkValueSelector.checked) {

				const bulkFlagSelector = scopeSnapshotDataForCherryPickReplication.bulkFlagSelectors[bulkValueSelectorChange.flagNodeId];
				if (bulkFlagSelector) {
					if (bulkFlagSelector.selectorType === 'CHECKBOX') {
						bulkFlagSelector.checked = true;
					}

					// Check all ancestors of the current flag node as well by simulating a flag selector change.
					this.handleBulkFlagSelectorChange({
						flagNodeId: bulkValueSelectorChange.flagNodeId,
						selector: {
							selectorType: 'CHECKBOX',
							selectionType: 'FLAG',
							checked: true
						}
					});
				}
			}
		}
	}

	selectOrDeselectAllFlags(selectOrDeselect: boolean){
		const scopeSnapshotDataForCherryPickReplication = this.scopeSnapshotDataForCherryPickReplicationIfEnabled();
		if (!scopeSnapshotDataForCherryPickReplication) {
			return undefined;
		}

		for (const [flagNodeId, bulkFlagSelector] of _.entries(scopeSnapshotDataForCherryPickReplication.bulkFlagSelectors)) {
			if (bulkFlagSelector.selectorType === 'CHECKBOX') {
				bulkFlagSelector.checked = selectOrDeselect;
				this.handleBulkFlagSelectorChange({flagNodeId: +flagNodeId, selector: bulkFlagSelector});
			}
		}
	}

	selectOrDeselectAllValues(selectOrDeselect: boolean){
		const scopeSnapshotDataForCherryPickReplication = this.scopeSnapshotDataForCherryPickReplicationIfEnabled();
		if (!scopeSnapshotDataForCherryPickReplication) {
			return undefined;
		}

		for (const [flagNodeId, bulkValueSelector] of _.entries(scopeSnapshotDataForCherryPickReplication.bulkValueSelectors)) {
			if (bulkValueSelector.selectorType === 'CHECKBOX') {
				bulkValueSelector.checked = selectOrDeselect;
				this.handleBulkValueSelectorChange({flagNodeId: +flagNodeId, selector: bulkValueSelector});
			}
		}
	}

	handleFlagNodeIdsToPaginate(flagNodeIdsToPaginate: number[]) {
		this.flagNodeIdsToPaginate = flagNodeIdsToPaginate;
		this.cd.detectChanges();
	}

	scopeSnapshotDataForCherryPickReplicationIfEnabled(): ScopeSnapshotDataForCherryPickReplication | undefined {
		const state = this.scopeSnapshotSelectorState;
		if (!state) {
			return undefined;
		}
		const replicateToScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(state);

		if (!replicateToScopeSnapshot) {
			return undefined;
		}

		const scopeSnapshotDataForCherryPickReplication = this.scopeSnapshotsDataForCherryPickReplication[replicateToScopeSnapshot.id];

		if (!scopeSnapshotDataForCherryPickReplication) {
			return undefined;
		}

		return ScopeSnapshotSelectorComponent.isSelectingFlagsAndValuesToReplicate(state) ? scopeSnapshotDataForCherryPickReplication : undefined;
	}

	buttonLabelForCherryPickReplicationIfEnabled(): string | undefined {
		const scopeSnapshotDataForCherryPickReplication = this.scopeSnapshotDataForCherryPickReplicationIfEnabled();
		if (!scopeSnapshotDataForCherryPickReplication) {
			return undefined;
		}

		const replicateToScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(this.requireScopeSnapshotSelectorState());

		if (!replicateToScopeSnapshot) {
			return undefined;
		}

		const selectedFlagNodeIdsCount = _.filter(scopeSnapshotDataForCherryPickReplication.bulkFlagSelectors, (bulkFlagSelector) => bulkFlagSelector.selectorType === 'CHECKBOX' && bulkFlagSelector.checked).length;

		const selectedValuesOfFlagNodeIdsCount = _.filter(scopeSnapshotDataForCherryPickReplication.bulkValueSelectors, (bulkValueSelector) => bulkValueSelector.selectorType === 'CHECKBOX' && bulkValueSelector.checked).length;

		if (selectedFlagNodeIdsCount === 0 && selectedValuesOfFlagNodeIdsCount === 0) {
			return undefined;
		}

		const items: string[] = [];
		if (selectedFlagNodeIdsCount > 0) {
			items.push(`${selectedFlagNodeIdsCount} Flag${selectedFlagNodeIdsCount === 1 ? "" : "s"}`);
		}
		if (selectedValuesOfFlagNodeIdsCount > 0) {
			items.push(`${selectedValuesOfFlagNodeIdsCount} Value${selectedValuesOfFlagNodeIdsCount === 1 ? "" : "s"}`);
		}

		return `Replicate ${items.join(" and ")} into ${this.versionLabel(replicateToScopeSnapshot.scopeVersion)}`;
	}

	bulkFlagSelectorsForCherryPicking(): {[key: FlagNodeFromScopeSnapshotResponse["id"]]: BulkFlagSelector} | undefined {
		const scopeSnapshotDataForCherryPickReplication = this.scopeSnapshotDataForCherryPickReplicationIfEnabled();

		return scopeSnapshotDataForCherryPickReplication ? scopeSnapshotDataForCherryPickReplication.bulkFlagSelectors : undefined;
	}

	bulkValueSelectorsForCherryPicking(): {[key: FlagNodeFromScopeSnapshotResponse["id"]]: BulkValueSelector} | undefined {
		const scopeSnapshotDataForCherryPickReplication = this.scopeSnapshotDataForCherryPickReplicationIfEnabled();

		return scopeSnapshotDataForCherryPickReplication ? scopeSnapshotDataForCherryPickReplication.bulkValueSelectors : undefined;
	}

	replicateFlagsAndValuesIntoSelectedVersion(actionBase: Extract<ActionBase, {type: ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}>) {
		const scopeSnapshotDataForCherryPickReplication = this.scopeSnapshotDataForCherryPickReplicationIfEnabled();

		if (!scopeSnapshotDataForCherryPickReplication) {
			throw new Error("Could not find scope snapshot data for cherry pick replication.");
		}
		const flagNodesMap = this.flagNodesMap();

		const flagsOfScopeFlagNodeIds = _.entries(scopeSnapshotDataForCherryPickReplication.bulkFlagSelectors).filter(([flagNodeId, bulkFlagSelector]) => bulkFlagSelector.selectorType === 'CHECKBOX' && bulkFlagSelector.checked).map(([flagNodeId, bulkFlagSelector]) => {
			const flagNode = flagNodesMap[+flagNodeId];
			if (!flagNode) {
				throw new Error(`Could not find flag node with id ${flagNodeId}`);
			}
			return flagNode.scopeFlagNodeId;
		});

		const valuesOfScopeFlagNodeIds = _.entries(scopeSnapshotDataForCherryPickReplication.bulkValueSelectors).filter(([flagNodeId, bulkValueSelector]) => bulkValueSelector.selectorType === 'CHECKBOX' && bulkValueSelector.checked).map(([flagNodeId, bulkValueSelector]) => {
			const flagNode = flagNodesMap[+flagNodeId];
			if (!flagNode) {
				throw new Error(`Could not find flag node with id ${flagNodeId}`);
			}
			return flagNode.scopeFlagNodeId;
		});

		this.scopeSnapshotSelectorActioner.next({
			...actionBase,
			inputs: {
				flagsOfScopeFlagNodeIds,
				valuesOfScopeFlagNodeIds
			}
		});
	}

	exitModeForCherryPickingFlagsAndValues(actionBase: Extract<ActionBase, {type: ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}>) {
		this.scopeSnapshotSelectorActioner.next(actionBase);
		this.gqlRequestInfos.queries.scopeSnapshotForCherryPickReplication.subscription?.unsubscribe();
		this.scopeSnapshotDataForCherryPickReplicationQuery = undefined;

		const selectorState = this.requireScopeSnapshotSelectorState()

		const replicateToScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(selectorState);

		if (!replicateToScopeSnapshot) {
			throw new Error("No scope snapshot selected.");
		}

		// delete this.scopeSnapshotsDataForCherryPickReplication[replicateToScopeSnapshot.id];
	}


	cherryPickReplicateFlagsAndValues(actionBase: CherryPickReplicateActionBase, flagsOfScopeFlagNodeIds: number[], valuesOfScopeFlagNodeIds: number[]) {
		this.scopeSnapshotSelectorActioner.next({
			...actionBase,
			inputs: {
				flagsOfScopeFlagNodeIds,
				valuesOfScopeFlagNodeIds
			}
		});
	}

	handleScopeSnapshotSelectorStateChange(newState: ScopeSnapshotSelectorState) {
		this.scopeSnapshotSelectorState = newState;
	}

	isConfigurationLoading(): boolean {
		return !!this.scopeSnapshotData && this.configuration === undefined;
	}

	isConfigurationLoaded(): boolean {
		return this.configuration !== undefined && !this.isConfigurationErrored();
	}

	isConfigurationErrored(): boolean {
		return this.configuration === null;
	}

	isScopeVariablesLoaded(): boolean {
		return !!(this.scopeVariables);
	}

	requireScopeVariables(): ScopeVariableFieldsForScopeSnapshotSelectorFragment[] {
		if (!this.scopeVariables) {
			throw new Error("Scope variables are not loaded.");
		}
		return this.scopeVariables;
	}

	requireScopeSnapshotData(): ExpectedScopeSnapshotData {
		if (!this.scopeSnapshotData) {
			throw new Error("Scope snapshot data is not loaded.");
		}
		return this.scopeSnapshotData;
	}

	requireFlagsValuesData(): FlagsValuesDataQuery {
		if (!this.flagsValuesData) {
			throw new Error("Flags and values are not loaded.");
		}
		return this.flagsValuesData;
	}

	requireCurrentScopeSnapshot(): ExpectedScopeSnapshotData["scopeSnapshotsByPk"] {
		return (requireVar(this.scopeSnapshotData))["scopeSnapshotsByPk"];
	}


	requireScopeSnapshotJson(): ConfigurationForScopeSnapshotPageQuery["configuration"][number] {
		const firstConfiguration = this.configuration;
		if (firstConfiguration === null || firstConfiguration === undefined) {
			throw new Error("Scope snapshot json configuration was not received.");
		}
		return firstConfiguration;
	}

	currentScopeSnapshot(): ExpectedScopeSnapshotData["scopeSnapshotsByPk"] | null {
		return this.scopeSnapshotData?.scopeSnapshotsByPk || null;
	}

	requireScopeSnapshotInheritancesWithoutSelf(): NonSelfInheritance[] {
		const scopeSnapshot = this.requireCurrentScopeSnapshot();
		const nonSelfInheritances: NonSelfInheritance[] = [];
		for (const inheritance of scopeSnapshot.inheritances) {
			if (!inheritance.isSelf) {
				nonSelfInheritances.push(inheritance);
			}
		}

		return nonSelfInheritances;
	}

	requireScopeSnapshotInheritancesWithSelf(): [...NonSelfInheritance[], SelfInheritance] {
		const scopeSnapshot = this.requireCurrentScopeSnapshot();
		return scopeSnapshot.inheritances;
	}

	deleteCurrentScopeSnapshot() {
		const currentScopeSnapshot = this.requireCurrentScopeSnapshot();
		const deleteScopeSnapshotInfo = this.gqlRequestInfos.mutations.deleteScopeSnapshot;
		deleteScopeSnapshotInfo.subscription?.unsubscribe();

		deleteScopeSnapshotInfo.subscription = this.apollo.mutate({
			mutation: deleteScopeSnapshotInfo.gql,
			variables: {id: currentScopeSnapshot.id},
		}).subscribe({
			next: ({ data }) => {
				this.commonService.messageService.add({ severity: 'success', summary: 'Scope snapshot deleted', detail: `Scope snapshot ${this.versionLabel(currentScopeSnapshot.scopeVersion)} was deleted.`, life: 10000 });
				this.router.navigate(['/scope-snapshot']);
			},
			error: (error) => this.commonService.mutationErrorHandler(error)
		});
	}

	inheritancesTogglerLabel(): string {
		const inheritedCount = this.requireScopeSnapshotInheritancesWithoutSelf().length;
		// const parentOrAncestor = inheritedCount === 1 ? "Parent" : "Ancestor";
		if (this.currentExpansionsMenuState === this.expansionsMenuStates.INHERITANCES_EXPANDED) {
			// return `Hide ${parentOrAncestor}${(inheritedCount === 1 ? "" : "s")}`;
			return `Hide Inheritances`;
		}
		else {
			// return `See ${(inheritedCount > 1 ? `${inheritedCount} ` : ``)}${parentOrAncestor}${(inheritedCount === 1 ? "" : "s")}`;
			return `See ${inheritedCount} Inheritance${(inheritedCount === 1 ? "" : "s")}`;
		}
	}

	childScopeSnapshotsTogglerLabel(): string {
		const childScopeSnapshotsCount = this.requireCurrentScopeSnapshot().childInheritances.length;
		if (this.currentExpansionsMenuState === this.expansionsMenuStates.CHILD_SCOPE_SNAPSHOTS_EXPANDED) {
			return `Hide Children`;
		}
		else {
			return `See ${childScopeSnapshotsCount} Child${(childScopeSnapshotsCount === 1 ? "" : "ren")}`;
		}
	}

	toggleInheritancesSection() {
		if (this.currentExpansionsMenuState === this.expansionsMenuStates.INHERITANCES_EXPANDED) {
			this.closeInheritancesSection();
		}
		else if (this.currentExpansionsMenuState === this.expansionsMenuStates.COLLAPSED) {
			this.openInheritancesSection();
		}
	}

	toggleChildScopeSnapshotsSection() {
		if (this.currentExpansionsMenuState === this.expansionsMenuStates.CHILD_SCOPE_SNAPSHOTS_EXPANDED) {
			this.closeChildScopeSnapshotsSection();
		}
		else if (this.currentExpansionsMenuState === this.expansionsMenuStates.COLLAPSED) {
			this.openChildScopeSnapshotsSection();
		}
	}

	openInheritancesSection() {
		this.currentExpansionsMenuState = this.expansionsMenuStates.INHERITANCES_EXPANDED;
	}

	closeInheritancesSection() {
		this.currentExpansionsMenuState = this.expansionsMenuStates.COLLAPSED;
	}

	openChildScopeSnapshotsSection() {
		this.currentExpansionsMenuState = this.expansionsMenuStates.CHILD_SCOPE_SNAPSHOTS_EXPANDED;
	}

	closeChildScopeSnapshotsSection() {
		this.currentExpansionsMenuState = this.expansionsMenuStates.COLLAPSED;
	}

	private requireValidScopeSnapshotData(scopeSnapshotData: ScopeSnapshotDataForScopeSnapshotPageQuery, redirectIfNotFound: boolean): ExpectedScopeSnapshotData {
		if (!scopeSnapshotData.scopeSnapshotsByPk) {
			this.messageService.add({ severity: 'error', summary: 'Scope snapshot not found', detail: `There is no scope snapshot with id ${this.route.snapshot.paramMap.get('scopeSnapshotId')}.`, life: 10000 });
			if (redirectIfNotFound) {
				this.router.navigate(['/scope-snapshot']);
			}
			throw new Error("Scope snapshot was not found.");
		}

		let selfInheritance: SelfInheritance | undefined = undefined;
		let nonSelfInheritances: NonSelfInheritance[] = [];

		for (const inheritance of scopeSnapshotData.scopeSnapshotsByPk.inheritances) {
			if (inheritance.isSelf) {
				selfInheritance = {
					...inheritance,
					isSelf: true
				};
			}
			else {
				nonSelfInheritances.push({
					...inheritance,
					isSelf: false
				});
			}
		}

		if (!selfInheritance) {
			throw new Error("Self inheritance must be provided within the inheritances array of the scope snapshot data.");
		}

		const expectedInheritances: [...NonSelfInheritance[], SelfInheritance] = [...nonSelfInheritances, selfInheritance];

		const expectedScopeSnapshotData: ExpectedScopeSnapshotData = {
			...scopeSnapshotData,
			scopeSnapshotsByPk: {
				..._.omit(scopeSnapshotData.scopeSnapshotsByPk, "inheritances"),
				inheritances: expectedInheritances,
			},
		};

		return expectedScopeSnapshotData;
	}

	// TODO Consider memoizing this function for performance. First check if flags component even refreshes when this function is called.
	leafFlagNodeIds(includeInherited = true, includeNonInherited = true): number[] {
		// const currentScopeSnapshot = this.requireCurrentScopeSnapshot();
		const flagsValuesData = this.requireFlagsValuesData();
		const scopeSnapshotData = this.requireScopeSnapshotData();
		const currentScopeSnapshot = scopeSnapshotData.scopeSnapshotsByPk;
		let flagNodes = flagsValuesData.inheritedValuedFlagNodes;

		if (!(includeInherited && includeNonInherited)) {
			flagNodes = flagNodes.filter(ivFlagNode => {
				if (includeInherited && !includeNonInherited) {
					return ivFlagNode.flagNode.definedInScopeSnapshotId !== currentScopeSnapshot.id;
				}
				else if (!includeInherited && includeNonInherited) {
					return ivFlagNode.flagNode.definedInScopeSnapshotId === currentScopeSnapshot.id;
				}
			});
		}

		return flagNodes.map(ivFlagNode => ivFlagNode.id);
	}

	// TODO Consider memoizing this function for performance. First check if flags component even refreshes when this function is called.
	flagNodesMap(): {[key: number]: FlagNodeFromScopeSnapshotResponse} {
		const flagsValuesData = this.requireFlagsValuesData();
		const flagNodesMap: {[key: number]: FlagNodeFromScopeSnapshotResponse} = {};
		for (const ivFlagNode of flagsValuesData.inheritedValuedFlagNodes) {
			flagNodesMap[ivFlagNode.id] = ivFlagNode.flagNode;
		}

		return flagNodesMap;
	}

	numberOfPages(): number {
		const numberOfFlagNodesToPaginate = this.flagNodeIdsToPaginate?.length;
		if (numberOfFlagNodesToPaginate === undefined) {
			throw new Error("Could not determine the number of flags to paginate.");
		}
		return Math.max(1, Math.ceil(numberOfFlagNodesToPaginate / this.pageSize));
	}

	private safeNumberOfPages(): number | null {
		try {
			return this.numberOfPages();
		}
		catch (e) {
			return null;
		}
	}

	visiblePageNumber(): number {
		return this.pageNumber + 1;
	}

	goToPage(newPageNumber: number) {
		// FYI page number is 0-indexed, and newPageNumber is also expected to be 0-indexed.

		if (newPageNumber < 0 || newPageNumber >= this.numberOfPages()) {
			throw new Error(`Invalid page number ${newPageNumber}`);
		}
		this.pageNumber = newPageNumber;
		this.pageNumberInput.setValue(this.visiblePageNumber());
	}

	flagsThatHaveValuesCache: Map<string, FlagNodeFromScopeSnapshotResponse[]> = new Map();

	memoizedFlagsThatHaveValues(requireNonNullValue: boolean, inheritedOrNonInherited: boolean | null): FlagNodeFromScopeSnapshotResponse[] {
		const cacheKey = `${requireNonNullValue}_${inheritedOrNonInherited}`;
		const cachedFlagsThatHaveValues = this.flagsThatHaveValuesCache.get(cacheKey);
		if (cachedFlagsThatHaveValues) {
			return cachedFlagsThatHaveValues;
		}
		else {
			const flagsUsingValues = this.flagsThatHaveValues(requireNonNullValue, inheritedOrNonInherited);
			this.flagsThatHaveValuesCache.set(cacheKey, flagsUsingValues);
			return flagsUsingValues;
		}
	}

	flagsThatHaveValues(requireNonNullValue: boolean, inheritedOrNonInherited: boolean | null): FlagNodeFromScopeSnapshotResponse[] {
		const flagsValuesData = this.requireFlagsValuesData();
		const scopeSnapshotData = this.requireScopeSnapshotData();
		const currentScopeSnapshotId = scopeSnapshotData.scopeSnapshotsByPk.id;
		const ivFlagNodes = _.values(flagsValuesData.inheritedValuedFlagNodes);

		const flagsUsingValues: FlagNodeFromScopeSnapshotResponse[] = [];

		for (const ivFlagNode of ivFlagNodes) {
			if (ivFlagNode && ivFlagNode.flagValue !== null && (!requireNonNullValue || ivFlagNode.flagValue.value !== null)) {
				if (inheritedOrNonInherited === true && ivFlagNode.flagValue.appliedInScopeSnapshotId !== currentScopeSnapshotId) {
					flagsUsingValues.push(ivFlagNode.flagNode);
				}
				else if (inheritedOrNonInherited === false && ivFlagNode.flagValue.appliedInScopeSnapshotId === currentScopeSnapshotId) {
					flagsUsingValues.push(ivFlagNode.flagNode);
				}
				else if (inheritedOrNonInherited === null){
					flagsUsingValues.push(ivFlagNode.flagNode);
				}
			}
		}

		return flagsUsingValues;
	}

	flagsSectionTitleTooltip(): string {
		const flagsValuesData = this.requireFlagsValuesData();
		const scopeSnapshotData = this.requireScopeSnapshotData();
		const currentScopeSnapshotId = scopeSnapshotData.scopeSnapshotsByPk.id;

		let inheritedFlagsCount = 0;
		let nonInheritedFlagsCount = 0;

		for (const ivfn of flagsValuesData.inheritedValuedFlagNodes) {
			if (ivfn.flagNode.definedInScopeSnapshotId !== currentScopeSnapshotId) {
				inheritedFlagsCount++;
			}
			else {
				nonInheritedFlagsCount++;
			}
		}

		return `▪ ${inheritedFlagsCount} inherited flag${inheritedFlagsCount === 1 ? "" : "s"}\n▪ ${nonInheritedFlagsCount} non-inherited flag${nonInheritedFlagsCount === 1 ? "" : "s"}`;
	}

	valuesSectionTitleTooltip(): string {
		const flagsUsingInheritedValues = this.flagsThatHaveValues(false, true);
		const nonNullFlagsUsingInheritedValues = this.flagsThatHaveValues(true, true);
		const nullFlagsUsingInheritedValuesCount = flagsUsingInheritedValues.length - nonNullFlagsUsingInheritedValues.length;

		const flagsUsingNonInheritedValues = this.flagsThatHaveValues(false, false);
		const nonNullFlagsUsingNonInheritedValues = this.flagsThatHaveValues(true, false);
		const nullFlagsUsingNonInheritedValuesCount = flagsUsingNonInheritedValues.length - nonNullFlagsUsingNonInheritedValues.length;


		const inheritedCount = flagsUsingInheritedValues.length;
		const nonInheritedCount = flagsUsingNonInheritedValues.length;

		return `▪ ${inheritedCount} flag${inheritedCount === 1 ? "" : "s"} using ${inheritedCount === 1 ? "a " : ""}inherited value${inheritedCount === 1 ? "" : "s"}\n      ▪ ${nullFlagsUsingInheritedValuesCount} of these values ${nullFlagsUsingInheritedValuesCount === 1 ? "is" : "are"} null\n\n▪ ${nonInheritedCount} flag${nonInheritedCount === 1 ? "" : "s"} using ${nonInheritedCount === 1 ? "a " : ""}non-inherited value${nonInheritedCount === 1 ? "" : "s"}\n      ▪ ${nullFlagsUsingNonInheritedValuesCount} of these values ${nullFlagsUsingNonInheritedValuesCount === 1 ? "is" : "are"} null`;
	}

	flagNodePath(flagNode: FlagNodeFromScopeSnapshotResponse): FlagNodeFromScopeSnapshotResponse[] {
		const allFlagNodes = _.values(this.flagNodesMap());
		const path: FlagNodeFromScopeSnapshotResponse[] = [];
		let currFlagNode: FlagNodeFromScopeSnapshotResponse | undefined = flagNode;
		while (currFlagNode) {
			path.push(currFlagNode);
			const parentScopeFlagNodeId: number | null = currFlagNode.parentScopeFlagNodeId;
			if (parentScopeFlagNodeId === null) {
				break;
			}
			else {
				currFlagNode = allFlagNodes.find(fn => fn.scopeFlagNodeId === parentScopeFlagNodeId);
			}
		}
		return path.reverse();
	}

	flagNodeDescendants(flagNode: FlagNodeFromScopeSnapshotResponse): FlagNodeFromScopeSnapshotResponse[] {
		const allFlagNodes = _.values(this.flagNodesMap());
		const descendants: FlagNodeFromScopeSnapshotResponse[] = [];

		let currFlagNode: FlagNodeFromScopeSnapshotResponse = flagNode;
		const currFlagNodeChildren = allFlagNodes.filter(fn => fn.parentScopeFlagNodeId === currFlagNode.scopeFlagNodeId);
		descendants.push(...currFlagNodeChildren);

		for (const currFlagNodeChild of currFlagNodeChildren) {
			descendants.push(...this.flagNodeDescendants(currFlagNodeChild));
		}
		return descendants;
	}

  isScopeSnapshotSelectorActionsVisible(): boolean {
		const state = this.scopeSnapshotSelectorState;
		if (!state) {
			return false;
		}

    return ScopeSnapshotSelectorComponent.isUIDirty(state) || this.latestMainScrollTop === 0 || this.latestMainScrollTop === null;
  }

	ngOnDestroy() {
		CrudStateService.unsubscribeFromGqlSubscriptions(this.gqlRequestInfos);
		if (this.scrollEventListener !== undefined) {
			this.elRef.nativeElement.removeEventListener('scroll', this.scrollEventListener);
		}
		this.routeSubscription?.unsubscribe();
	}
}

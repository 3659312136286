<div class="title-and-description">
	<div class="subsection-smallcaps-title">Import JSON</div>
	<div class="section-description">
		Provide a JSON object to create and update the flags and values of this scope snapshot.
	</div>
</div>
<form [formGroup]="importForm">
	<textarea
		rows="20"
		cols="100"
		pInputTextarea
		pAutoFocus
		[autofocus]="true"
		class="json-input"
		spellcheck="false"
		formControlName="json">
	</textarea>
	<!-- <div class="flag-value-import-option-label">Flag value options:</div> -->
	<div class="action-link advanced-options" (click)="showingAdvancedOptions = !showingAdvancedOptions">
		<div class="action-link-label">{{ !showingAdvancedOptions ? 'Show' : 'Hide' }} advanced import options</div>
		<div class="ficon" [class]="{
			'ficon-ChevronDownMed': !showingAdvancedOptions,
			'ficon-ChevronUpMed': showingAdvancedOptions
		}"></div>
	</div>
	<div class="flag-value-import-options" *ngIf="showingAdvancedOptions">
		<div *ngFor="let optionKey of flagValueImportOptions" class="field-checkbox">
			<p-radioButton [inputId]="optionKey" [value]="optionKey" formControlName="flagValueImportOption"></p-radioButton>
			<label [for]="optionKey" class="ml-2" [innerHTML]="flagValueImportOptionLabel(optionKey)"></label>
		</div>
	</div>
</form>
<div class="actions">
	<div pRipple class="button import" *ngIf="importForm.valid" (click)="importJson()"><div class="ficon ficon-Installation"></div> Import into {{versionLabel(this.requireScopeSnapshot().scopeVersion)}}</div>
	<div class="error-notice-and-icon" *ngIf="!importForm.valid"><div class="ficon ficon-Important"></div><div class="error-notice">Fix your JSON in order to proceed.</div></div>
	<div class="action-link" (click)="cancelImport()">
		<div class="ficon icon ficon-Cancel"></div>Cancel
	</div>
</div>

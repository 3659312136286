<div class="main-section" *ngIf="!!scopeSnapshotData || isScopeVariablesLoaded()" [class]="{'scope-snapshot-not-loaded': !scopeSnapshotData}">
	<div class="scope-snapshot-title-and-expansions-section section" >
		<div class="section-title light" *ngIf="scopeSnapshotTitle()">{{ scopeSnapshotTitle() }}</div>

		<ng-container *ngIf="!!scopeSnapshotData">

			<!-- Menu consisting of links, above the scope snapshot selector -->
			<div class="expansions-menu" *ngIf="currentExpansionsMenuOptions().length > 0">
				<ng-container *ngFor="let expansionMenuOption of currentExpansionsMenuOptions()">
					<div class="action-link" (click)="toggleInheritancesSection()" *ngIf="expansionMenuOption === expansionsMenuOptions.TOGGLE_INHERITANCES">
						<div class="ficon ficon-SplitObject"></div>
						<div class="action-link-label">{{inheritancesTogglerLabel()}}</div>
						<div class="ficon ficon-ChevronDownMed" [ngClass]="{
							'ficon-ChevronDownMed': currentExpansionsMenuState !== expansionsMenuStates.INHERITANCES_EXPANDED,
							'ficon-ChevronUpMed': currentExpansionsMenuState === expansionsMenuStates.INHERITANCES_EXPANDED
						}"></div>
					</div>

					<div class="action-link" (click)="toggleChildScopeSnapshotsSection()" *ngIf="expansionMenuOption === expansionsMenuOptions.TOGGLE_CHILD_SCOPE_SNAPSHOTS">
						<div class="ficon ficon-Childof"></div>
						<div class="action-link-label">{{childScopeSnapshotsTogglerLabel()}}</div>
						<div class="ficon ficon-ChevronDownMed" [ngClass]="{
							'ficon-ChevronDownMed': currentExpansionsMenuState !== expansionsMenuStates.CHILD_SCOPE_SNAPSHOTS_EXPANDED,
							'ficon-ChevronUpMed': currentExpansionsMenuState === expansionsMenuStates.CHILD_SCOPE_SNAPSHOTS_EXPANDED
						}"></div>
					</div>

					<a
						[routerLink]="['/scopes-and-snapshots/']"
						[queryParams]="queryParamsForOpeningInheritancesOnScopesAndSnapshotsPage(this.scopeSnapshotData.scopeSnapshotsByPk.id)"
						target="_blank"
						class="action-link"
						*ngIf="expansionMenuOption === expansionsMenuOptions.MODIFY_INHERITANCES">
						<div class="ficon" [ngClass]="{
							'ficon-SplitObject': this.requireScopeSnapshotInheritancesWithoutSelf().length === 0,
							'ficon-Edit': this.requireScopeSnapshotInheritancesWithoutSelf().length > 0
						}"></div>
						<div class="action-link-label">
							{{(this.requireScopeSnapshotInheritancesWithoutSelf().length === 0) ? 'Set' : 'Modify'}} Inheritances
						</div>
						<div class="ficon icon-on-right ficon-OpenInNewTab"></div>
					</a>

					<div class="action-link" (click)="toggleImportJsonSection()" *ngIf="expansionMenuOption === expansionsMenuOptions.TOGGLE_IMPORT_JSON">
						<div class="ficon ficon-Code"></div>
						<div class="action-link-label">Import JSON</div>
					</div>

					<div class="action-link" (click)="toggleRenameVersionSection()" *ngIf="expansionMenuOption === expansionsMenuOptions.TOGGLE_RENAME_VERSION">
						<div class="ficon ficon-Rename"></div>
						<div class="action-link-label">Rename Version</div>
					</div>

					<div class="action-link" (click)="toggleDeleteScopeSnapshotSection()" *ngIf="expansionMenuOption === expansionsMenuOptions.TOGGLE_DELETE_SCOPE_SNAPSHOT">
						<div class="ficon ficon-Delete"></div>
						<div class="action-link-label">Delete Scope Snapshot</div>
					</div>
				</ng-container>
			</div>

			<!-- Inheritances section -->
			<app-scope-snapshot-inheritances
				[scope-snapshot]="requireCurrentScopeSnapshot()"
				[static-or-validated-inheritances]="requireScopeSnapshotInheritancesWithoutSelf()"
				[scope-variables]="requireScopeVariables()"
				[inheritances-section-state-changer]="inheritancesSectionStateChanger"
				*ngIf="currentlyVisibleExpansionSections().includes(expansionSections.INHERITANCES)"
			>
			</app-scope-snapshot-inheritances>

			<div class="child-scope-snapshots-section" *ngIf="currentlyVisibleExpansionSections().includes(expansionSections.CHILD_SCOPE_SNAPSHOTS)">
				<div class="child-scope-snapshots" [style]="{
					'grid-template-columns': '[child-inheritances] repeat(' + (requireScopeVariables().length + 1) + ', auto) [go-to-scope-snapshot] auto'
				}">
					<ng-container *ngFor="let childInheritance of scopeSnapshotData.scopeSnapshotsByPk.childInheritances">
						<app-scope-snapshot-selector
							[state]="{initialScopeSnapshot: childInheritance.scopeSnapshot, actionsWhitelist: [], staticity: inheritedScopeSnapshotStaticity, scopeVariables: requireScopeVariables()}"
							[styles-for-spanning-across-parent-grid]="{
								'first-dropdown': {
									'grid-column-start': 'child-inheritances'
								}
							}"
						>
						</app-scope-snapshot-selector>
						<div class="actions">
							<a [routerLink]="['/scope-snapshot/'+childInheritance.scopeSnapshotId]" class="action-link">Go to Child<div class="ficon icon-on-right ficon-Forward"></div></a>
						</div>
					</ng-container>
					<!-- <ng-template #noChildScopeSnapshots>
						<div class="no-child-scope-snapshots">No child scope snapshots</div>
					</ng-template> -->
				</div>
			</div>

			<!-- Import json section -->
			<app-import-json
				[scope-snapshot]="requireCurrentScopeSnapshot()"
				(cancel)="handleImportJsonSectionCancel()"
				(imported)="handleImportJsonSectionImported()"
				*ngIf="currentlyVisibleExpansionSections().includes(expansionSections.IMPORT_JSON)"
			></app-import-json>

			<!-- Rename version section -->
			<div class="rename-version-section" *ngIf="currentlyVisibleExpansionSections().includes(expansionSections.RENAME_VERSION)">
				<div class="title-and-description">
					<div class="subsection-smallcaps-title">Rename Snapshot {{versionLabel(requireCurrentScopeSnapshot().scopeVersion)}}</div>
					<div class="section-description">
						Enter your new version name for {{versionLabel(requireCurrentScopeSnapshot().scopeVersion)}}. If your version name starts with a number, you do not need to prefix it with 'v' as this will be displayed automatically.
					</div>
				</div>
				<form [formGroup]="formForRenamingCurrentVersion" class="rename-current-scope-snapshot-version-form">
					<input
						[formControl]="formForRenamingCurrentVersion.controls.newVersionName"
						pInputText
						pInputFilled
						pAutoFocus
						[autofocus]="true"
						(keydown.Enter)="!!formForRenamingCurrentVersion.value.newVersionName && requireCurrentScopeSnapshot().scopeVersion !== formForRenamingCurrentVersion.value.newVersionName && renameCurrentScopeSnapshotVersion(formForRenamingCurrentVersion.value.newVersionName)"
						(keydown.Escape)="closeRenameVersionSection()"
						[placeholder]="'New version name (e.g. \'2\')'"
						type="text"
						class="rename-scope-version-input p-inputtext-sm"
					/>
					<div pRipple class="button" (click)="renameCurrentScopeSnapshotVersion(formForRenamingCurrentVersion.value.newVersionName)" *ngIf="formForRenamingCurrentVersion.valid && !!formForRenamingCurrentVersion.value.newVersionName && requireCurrentScopeSnapshot().scopeVersion !== formForRenamingCurrentVersion.value.newVersionName">
						<div class="ficon ficon-AcceptMedium"></div> Rename {{versionLabel(requireCurrentScopeSnapshot().scopeVersion)}} to {{versionLabel(formForRenamingCurrentVersion.value.newVersionName)}}</div>
						<div class="action-link" (click)="closeRenameVersionSection()"><div class="ficon ficon-Cancel"></div> Cancel</div>
				</form>
			</div>

			<!-- Delete scope snapshot section -->
			<div class="delete-scope-snapshot-section" *ngIf="currentlyVisibleExpansionSections().includes(expansionSections.DELETE_SCOPE_SNAPSHOT)" [class]="{'cannot-delete': scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length > 0}">
				<div class="title-and-description">
					<div class="subsection-smallcaps-title">{{scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length > 0 ? 'Cannot ' : '' }}Delete Scope Snapshot {{versionLabel(requireCurrentScopeSnapshot().scopeVersion)}}</div>
					<div class="section-description">
						<ng-container *ngIf="scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length > 0">
							You cannot delete this scope snapshot because it is currently directly inherited by {{scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length}} scope snapshot{{scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length !== 1 ? 's' : ''}}. Go to the child{{scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length !== 1 ? 'ren' : ''}} section to see {{scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length !== 1 ? 'these' : 'this'}} {{scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length}} scope snapshot{{scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length !== 1 ? 's' : ''}}.
						</ng-container>
						<ng-container *ngIf="scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length === 0">
							This will delete scope snapshot {{versionLabel(requireCurrentScopeSnapshot().scopeVersion)}} and all of its flags and values. Replicated flags and values in other snapshots of the same scope will remain unaffected. This action cannot be undone.
						</ng-container>
					</div>
				</div>
				<div class="actions">
					<div pRipple class="button red" (click)="deleteCurrentScopeSnapshot()" *ngIf="scopeSnapshotData.scopeSnapshotsByPk.childInheritances.length === 0">
						<div class="ficon ficon-Delete"></div> Delete Scope Snapshot {{versionLabel(this.requireCurrentScopeSnapshot().scopeVersion)}}
					</div>
					<div class="action-link" (click)="toggleDeleteScopeSnapshotSection()"><div class="ficon ficon-Cancel"></div> Cancel</div>
				</div>
			</div>
		</ng-container>
	</div>

	<div class="scope-snapshot-selector-section">
		<div class="scope-snapshot-selector-and-actions" *ngIf="scopeSnapshotSelectorState" [ngClass]="{'dirty': ScopeSnapshotSelectorComponent.isUIDirty(scopeSnapshotSelectorState)}">
			<app-scope-snapshot-selector
				[state]="scopeSnapshotSelectorState"
				[actioner]="scopeSnapshotSelectorActioner"
				[show-diffs]="ScopeSnapshotSelectorComponent.isSelectingFlagsAndValuesToReplicate(scopeSnapshotSelectorState)"
				(state-update)="handleScopeSnapshotSelectorStateChange($event)"
				>
			</app-scope-snapshot-selector>
			<div class="actions" [class]="{'collapsed': !isScopeSnapshotSelectorActionsVisible()}">
				<div class="disabler-overlay" *ngIf="ScopeSnapshotSelectorComponent.isActionsDisabled(scopeSnapshotSelectorState)"></div>
				<div class="actions-for-list">
					<div class="custom-select enabled unselected" *ngIf="ScopeSnapshotSelectorComponent.isSelectingFlagsAndValuesToReplicate(scopeSnapshotSelectorState)">
						<div class="primary-label"><div class="ficon ficon-LightningBolt"></div>Bulk select <div class="ficon ficon-ChevronDownMed"></div></div>
						<div class="dropdown-wrapper">
							<div class="dropdown">
								<div pRipple class="dropdown-option" (click)="selectOrDeselectAllFlags(true)">Select All Flags</div>
								<div pRipple class="dropdown-option" (click)="selectOrDeselectAllValues(true)">Select All Values</div>
								<div class="divider-line-and-margin"></div>
								<div pRipple class="dropdown-option" (click)="selectOrDeselectAllFlags(false)">Deselect All Flags</div>
								<div pRipple class="dropdown-option" (click)="selectOrDeselectAllValues(false)">Deselect All Values</div>
							</div>
						</div>
					</div>
				</div>

				<div class="actions-for-page" *ngIf="ScopeSnapshotSelectorComponent.whitelistedActionBases(scopeSnapshotSelectorState); let actionMap">
					<div
						class="action-link"
						(click)="scopeSnapshotSelectorActioner.next(actionBase)"
						*ngIf="actionMap[actionTypes.RESET_TO_INITIAL_STATE]; let actionBase">
						<div class="ficon icon ficon-Cancel"></div>
						{{actionBase.defaultButtonLabel}}
					</div>

					<div
						(click)="enterModeForCherryPickingFlagsAndValues(actionBase)"
						class="action-link replicate-flags-and-values"
						[ngClass]="{'gray': !actionBase.isPrimary}"
						*ngIf="actionMap[actionTypes.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]; let actionBase">
							<div class="ficon icon ficon-MultiSelect"></div>{{actionBase.defaultButtonLabel}}
					</div>

					<div
						(click)="exitModeForCherryPickingFlagsAndValues(actionBase)"
						class="action-link exit-replicate-flags-and-values-mode"
						[ngClass]="{'gray': !actionBase.isPrimary}"
						*ngIf="actionMap[actionTypes.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]; let actionBase">
							<div class="ficon icon ficon-Cancel"></div>{{actionBase.defaultButtonLabel}}
					</div>

					<div
						(click)="replicateFlagsAndValuesIntoSelectedVersion(actionBase)"
						pRipple class="button replicate-flags-and-values"
						[ngClass]="{'gray': !actionBase.isPrimary}"
						*ngIf="buttonLabelForCherryPickReplicationIfEnabled() && actionMap[actionTypes.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]; let actionBase">
							<div class="ficon icon ficon-BranchFork"></div>{{buttonLabelForCherryPickReplicationIfEnabled()}}
					</div>

					<a
						[routerLink]="['/scope-snapshot/' + actionBase.destinationScopeSnapshot.id]"
						pRipple class="button"
						[ngClass]="{'gray': !actionBase.isPrimary}"
						*ngIf="actionMap[actionTypes.GO_TO_SNAPSHOT_IN_SAME_SCOPE]; let actionBase">
						{{actionBase.defaultButtonLabel}}<div class="ficon icon-on-right ficon-Forward"></div>
					</a>

					<div
						(click)="scopeSnapshotSelectorActioner.next(actionBase)"
						pRipple class="button"
						[ngClass]="{'gray': !actionBase.isPrimary}"
						*ngIf="actionMap[actionTypes.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE]; let actionBase">
							<div class="ficon icon-on ficon-Add"></div>
							{{actionBase.defaultButtonLabel}}
					</div>

					<div
						(click)="scopeSnapshotSelectorActioner.next(actionBase)"
						pRipple class="button"
						[ngClass]="{'gray': !actionBase.isPrimary}"
						*ngIf="actionMap[actionTypes.CREATE_BLANK_SNAPSHOT_IN_DIFFERENT_SCOPE]; let actionBase">
							<div class="ficon icon-on ficon-Add"></div>
							{{actionBase.defaultButtonLabel}}
					</div>

					<div
						(click)="scopeSnapshotSelectorActioner.next(actionBase)"
						pRipple class="button"
						[ngClass]="{'gray': !actionBase.isPrimary}"
						*ngIf="actionMap[actionTypes.CREATE_BLANK_SNAPSHOT_WITH_NEW_SCOPE]; let actionBase">
							<div class="ficon icon-on ficon-Add"></div>
							{{actionBase.defaultButtonLabel}}
					</div>

					<a
						[routerLink]="['/scope-snapshot/' + actionBase.destinationScopeSnapshot.id]"
						pRipple class="button"
						[ngClass]="{'gray': !actionBase.isPrimary}"
						*ngIf="actionMap[actionTypes.GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE]; let actionBase">
							{{actionBase.defaultButtonLabel}}<div class="ficon icon-on-right ficon-Forward"></div>
					</a>

					<div
						(click)="replicateCurrentScopeSnapshotAsNewVersion(actionBase)"
						pRipple class="button replicate-scope-snapshot"
						[ngClass]="{'gray': !actionBase.isPrimary}"
						*ngIf="actionMap[actionTypes.REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION]; let actionBase">
							<div class="ficon icon ficon-BranchFork"></div>{{actionBase.defaultButtonLabel}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="empty-scope-snapshot-section" *ngIf="!scopeSnapshotData && scopeSnapshotSelectorState">
		<div class="section-title light">Need help getting started?</div>
		<div class="getting-started-info">Follow this <a [href]="'https://scoperoot.notion.site/Getting-Started-with-ScopeRoot-46cd25bc78e04500b2ea2029f7c75f34'" target="_blank">step-by-step guide</a>.</div>
	</div>
	<div class="flags-values-section">
		<ng-container *ngIf="isShowingFlagsSection()">
			<div class="disabler-overlay" *ngIf="isDisablingFlagsSectionInputs"></div>
			<div class="flags-and-values-section section">
				<ng-container *ngIf="leafFlagNodeIds().length > 0">
					<div class="section-title light flags-section-title">
						<div class="ficon ficon-IconSetsFlag"></div>
						<div
							class="section-title-text has-tooltip-indicator"
							[pTooltip]="flagsSectionTitleTooltip()"
							[autoHide]="false"
							tooltipPosition="top">
							{{ leafFlagNodeIds().length }} Flag{{ leafFlagNodeIds().length !== 1 ? 's': '' }}
						</div>
					</div>
					<div
						class="section-title light values-section-title"
						[class]="{'in-cherry-pick-mode': !!scopeSnapshotDataForCherryPickReplicationIfEnabled()}"
					>
						<div class="ficon ficon-Variable2"></div>
						<div
							class="section-title-text has-tooltip-indicator"
							[pTooltip]="valuesSectionTitleTooltip()"
							[autoHide]="false"
							[tooltipOptions]="{
								tooltipStyleClass: 'width-max-content'
							}"
							tooltipPosition="top">
							{{ memoizedFlagsThatHaveValues(false, null).length }} Value{{ memoizedFlagsThatHaveValues(false, null).length !== 1 ? 's' : '' }}
						</div>
					</div>
				</ng-container>

				<!-- TODO We should only refresh the flags section whenever a flag is saved, rather than all the sections, which is what it currently does now. -->
				<app-flags
					[flag-nodes-map]="flagNodesMap()"
					[leaf-flag-node-ids]="leafFlagNodeIds()"
					[current-scope-snapshot]="requireCurrentScopeSnapshot()"
					[use-parent-grid]="true"
					[inheritances]="requireScopeSnapshotInheritancesWithSelf()"
					[page-number]="pageNumber"
					[page-size]="pageSize"
					[bulk-flag-selectors]="bulkFlagSelectorsForCherryPicking()"
					(bulk-flag-selector-change)="handleBulkFlagSelectorChange($event)"
					[bulk-value-selectors]="bulkValueSelectorsForCherryPicking()"
					(bulk-value-selector-change)="handleBulkValueSelectorChange($event)"
					(save)="refreshFlagsValuesData()"
					(flag-node-ids-to-paginate)="handleFlagNodeIdsToPaginate($event)">
				</app-flags>
			</div>
		</ng-container>
		<ng-container *ngIf="isLoadingFlagsSection()">
			<div class="flags-and-values-section section">

				<div class="section-title light flags-section-title">
					<div class="ficon"></div> <!-- Empty icon to mimic the spacing of the actual icon that will appear once loaded. -->
					<div class="section-title-text">
						Loading Flags/Values...
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="actions-bar" *ngIf="isShowingFlagsSection() && flagNodeIdsToPaginate !== null">
		<div class="pagination-wrapper">
			<div class="pagination">
				<ng-container>
					<div class="previous-page-button" [class]="{'disabled': visiblePageNumber() <= 1}" (click)="visiblePageNumber() > 1 && goToPage(pageNumber - 1)">
						<div
							class="ficon ficon-ChevronLeftSmall">
						</div>
					</div>
					<div class="page-input-with-action">
						<p-inputNumber type="number" placeholder="Page" class="page-number-input p-inputtext-sm" (keydown.Enter)="goToPage(this.pageNumberInput.value - 1)" [formControl]="pageNumberInput" />
						<div class="go-to-page-button-container">
							<div class="disabler-overlay" *ngIf="!this.pageNumberInput.valid"></div>
							<div pRipple class="button go-to-page" (click)="goToPage(this.pageNumberInput.value - 1)">Go</div>
						</div>
					</div>
					<div class="next-page-button" [class]="{'disabled': visiblePageNumber() >= numberOfPages()}" (click)="visiblePageNumber() < numberOfPages() && goToPage(pageNumber + 1)">
						<div
							class="ficon ficon-ChevronRightSmall">
						</div>
					</div>
					<div class="pagination-info subsection-smallcaps-title">
						<div class="info-item">Page {{visiblePageNumber()}} of {{numberOfPages()}}</div>
						<div class="info-item">{{pageSize}} Flag{{pageSize !== 1 ? 's' : ''}} per page</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
<div class="side-section" *ngIf="!!scopeSnapshotData">
	<div class="generated-code-section section">
		<div class="section-title light">
			<ng-container *ngIf="isConfigurationLoading()">Loading Raw JSON Config From API...</ng-container>
			<ng-container *ngIf="!isConfigurationLoading()">Raw JSON Config From API</ng-container>
		</div>
		<div class="action-links" *ngIf="isConfigurationLoaded()">
			<div class="action-link" (click)="copyRawJsonToClipboard()">
				<div class="ficon ficon-Paste"></div>
				Copy Raw JSON
			</div>
			<div class="action-link" (click)="copyBearerTokenToClipboard()">
				<div class="ficon ficon-Unlock"></div>
				Copy Bearer Token
			</div>
			<a class="action-link" [href]="viewConfigInGraphqlUrl(scopeSnapshotData.scopeSnapshotsByPk.id)" target="_blank">
				<span class="ficon ficon-ExpressRouteCircuits"></span>
				View in API Explorer
			</a>
		</div>
		<ngx-json-viewer [json]="requireScopeSnapshotJson().jsonConfiguration" class="code generated-json no-left-margin" *ngIf="isConfigurationLoaded()" [depth]="0"></ngx-json-viewer>
	</div>
</div>

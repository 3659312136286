<div class="deployments-page-container">
	<ng-container *ngIf="deploymentsPageData">
		<div class="section-title">{{deploymentsPageData.deploymentRows.length}} Deployment{{deploymentsPageData.deploymentRows.length !== 1 ? 's' : ''}}</div>
		<div class="grid-based-table listing-for-scope-snapshots" [ngStyle]='{
			"grid-template-columns": "[start] max-content repeat(" + (deploymentsPageData.scopeVariables.length) + ", [start-of-scope-variable] max-content) [start-of-scope-version-dropdown] minmax(190px, max-content) [start-of-state-and-actions] max-content [end]"
		}'>
			<div class="disabler-overlay" *ngIf="isLoading"></div>
			<div class="headings-background"></div>
			<div class="deployment-key-heading heading">Deployment Key</div>
			<div
				class="scope-options-dropdown-heading heading"
				[ngStyle]="{
					'grid-column-start': 'start-of-scope-variable ' + (scopeVariableIndex + 1)
				}"
				*ngFor="let scopeVariable of deploymentsPageData.scopeVariables; let scopeVariableIndex=index"
			>
				{{scopeVariable.name}}
			</div>
			<div class="scope-version-heading heading"></div>
			<div class="state-and-actions-heading heading"></div>

			<div
				class="row"
				[class]="{
					'static-scope-snapshot': !deploymentCrud.editableInEditState(editableKey(deploymentRow)),
					'in-delete-confirmation-state': deploymentCrud.editableInDeleteConfirmationState(editableKey(deploymentRow)),
				}"
				*ngFor="let deploymentRow of deploymentsPageData.deploymentRows; let index = index">
				<div class="deployment-key">

					<ng-container *ngIf="!deploymentCrud.editableInEditState(editableKey(deploymentRow))">{{deploymentRow.deployment.key}}</ng-container>

					<ng-container *ngIf="deploymentCrud.editableInEditState(editableKey(deploymentRow)); let editable">
						<input class="deployment-key-input p-inputtext-sm" [placeholder]="'New key for ' + deploymentRow.deployment.key" pInputText pInputFilled [formControl]="editable.model.controls.key" type="text" (keyup.Enter)="updateDeployment(editableKey(deploymentRow))" />
					</ng-container>

				</div>
				<app-scope-snapshot-selector
					[state]="selectorState(deploymentRow)"
					[styles-for-spanning-across-parent-grid]="{
						'first-dropdown': {
							'grid-column-start': 'start-of-scope-variable'
						}
					}"
					[z-index-for-dropdowns]="deploymentsPageData.deploymentRows.length - index"
					[show-diffs]="true"
					(state-update)="handleScopeSnapshotSelectorStateChange($event)"
					>
				></app-scope-snapshot-selector>
				<div class="state-and-actions">
					<ng-container *ngIf="ScopeSnapshotSelectorComponent.whitelistedActionBases(selectorState(deploymentRow)); let actionMap">

						<ng-container *ngIf="!deploymentCrud.editableInDeleteConfirmationState(editableKey(deploymentRow))">
							<div
								class="action-link"
								(click)="deploymentCrud.edit(editableKey(deploymentRow))"
								*ngIf="!deploymentCrud.editableInEditState(editableKey(deploymentRow))">
								<div class="ficon ficon-Edit"></div>
								Modify Deployment
							</div>
							<div
								pRipple class="button"
								(click)="updateDeployment(editableKey(deploymentRow))"
								*ngIf="deploymentActionInfoForUpdate(editableKey(deploymentRow)) as info">
								<div class="ficon ficon-Rocket"></div>
								{{info.buttonLabel}}
							</div>
							<div
								class="action-link"
								(click)="deploymentCrud.enterDeletePrompt(editableKey(deploymentRow))"
								*ngIf="!deploymentCrud.editableInEditState(editableKey(deploymentRow)) && !deploymentCrud.editableInDeleteConfirmationState(editableKey(deploymentRow))">
								<div class="ficon ficon-Delete"></div>
								Danger Zone
							</div>
							<a
								[routerLink]="['/scope-snapshot/' + actionBase.destinationScopeSnapshot.id]"
								pRipple class="button"
								[ngClass]="{'gray': false}"
								*ngIf="actionMap[actionTypes.GO_TO_SNAPSHOT_IN_SAME_SCOPE]; let actionBase">
								{{actionBase.defaultButtonLabel}}<div class="ficon icon-on-right ficon-Forward"></div>
							</a>
							<a
								[routerLink]="['/scope-snapshot/' + actionBase.destinationScopeSnapshot.id]"
								pRipple class="button"
								[ngClass]="{'gray': false}"
								*ngIf="actionMap[actionTypes.GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE]; let actionBase">
								{{actionBase.defaultButtonLabel}}<div class="ficon icon-on-right ficon-Forward"></div>
							</a>
							<div
								class="action-link"
								(click)="deploymentCrud.discardEditable(editableKey(deploymentRow))"
								*ngIf="deploymentCrud.editableInEditState(editableKey(deploymentRow))">
								<div class="ficon ficon-Cancel"></div>
								Cancel
							</div>
						</ng-container>

						<ng-container *ngIf="deploymentCrud.editableInDeleteConfirmationState(editableKey(deploymentRow))">
							<div
								pRipple class="button red"
								(click)="deleteDeployment(editableKey(deploymentRow))">
								<div class="ficon ficon-Delete"></div>
								Delete Deployment '{{deploymentRow.deployment.key}}'
							</div>
							<div
								class="action-link"
								(click)="deploymentCrud.exitDeletePrompt(esm, crudState.VIEW)"
								*ngIf="deploymentCrud.editable(editableKey(deploymentRow)) as esm">
								<div class="ficon ficon-Cancel"></div>
								Back
							</div>
						</ng-container>

					</ng-container>

				</div>
				<div class="row-spacer-and-hoverable"><div class="hoverable"></div></div>
			</div>
		</div>

		<div class="actions" *ngIf="!deploymentCrud.creatableInCreateState(creatableKey())">
			<div class="action-link-wrapper">
				<a class="action-link" (click)="deploymentCrud.create(creatableKey())"><div class="ficon ficon-Add"></div>New Deployment</a>
			</div>
			<div class="action-link-wrapper">
				<div class="action-link" (click)="copyBearerTokenToClipboard()">
					<div class="ficon ficon-Unlock"></div>
					Copy Bearer Token
				</div>
			</div>
			<div class="action-link-wrapper">
				<a class="action-link" target="_blank" [href]="viewConfigInGraphqlUrl()"><div class="ficon ficon ficon-ExpressRouteCircuits"></div>View Deployment Query in API Explorer</a>
			</div>
		</div>

		<div class="new-deployment-section">
			<div class="new-deployment-input-section" *ngIf="deploymentCrud.creatableInCreateState(creatableKey()) as creatable">
				<div class="section-title">Create a New Deployment</div>
				<div class="new-deployment-key-input-and-selector">
					<input class="deployment-key-input p-inputtext-sm" pInputText pAutoFocus [autofocus]="true" pInputFilled [formControl]="creatable.model.controls.key" type="text" placeholder="New deployment key" />

					<app-scope-snapshot-selector
						[state]="creatable.model.controls.selectorState.value"
						[show-diffs]="true"
						(state-update)="handleScopeSnapshotSelectorStateChange($event)"
						>
					></app-scope-snapshot-selector>
				</div>

				<div class="new-deployment-actions">
					<div class="action-link cancel" (click)="deploymentCrud.discardCreatable(creatableKey())"><div class="ficon ficon-Cancel"></div>Cancel</div>
					<div
						pRipple class="button"
						(click)="insertDeployment()"
						*ngIf="creatable.model.controls.key.value.length > 0 && ScopeSnapshotSelectorComponent.selectedScopeSnapshot(creatable.model.controls.selectorState.value) as selectedScopeSnapshot">
						<div class="ficon ficon-Rocket"></div>Deploy {{CommonService.friendlyVersionLabel(selectedScopeSnapshot.scopeVersion)}} to '{{creatable.model.controls.key.value }}'
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>

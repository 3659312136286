<div class="inherited-values-container" *ngIf="editable() && self.flagValuesWithinNonSelfInheritances(requireFlagValueKey()).length > 0">
	<ng-container *ngIf="self.flagValuesWithinNonSelfInheritances(requireFlagValueKey()) as inheritedFlagValues">
		<div class="inherited-values-title"><div class="ficon ficon-SplitObject"></div><div>{{inheritedFlagValues.length}} Inherited Value{{inheritedFlagValues.length > 1 ? 's' : ''}}:</div></div>
		<div class="inherited-values">
			<div class="inherited-value-label" *ngFor="let inheritedFlagValue of inheritedFlagValues">
				<app-scope-snapshot-details-popup [scope-snapshot]="inheritedScopeSnapshot" *ngIf="requireInheritanceFromScopeSnapshotId(inheritedFlagValue.appliedInScopeSnapshotId).inheritedScopeSnapshot as inheritedScopeSnapshot"></app-scope-snapshot-details-popup>
				<span class="value">{{ inheritedFlagValue.value | json }}</span>
			</div>
		</div>
	</ng-container>
</div>
<div class="most-applicable-value-container" *ngIf="!editable()">
	<div class="most-applicable-value-label" [ngClass]="{'inherited': isInheritedFlagValueObject(flagValueObject)}" *ngIf="self.mostApplicableFlagValueObject(requireFlagValueKey()) as flagValueObject">
		<app-scope-snapshot-details-popup [scope-snapshot]="inheritedScopeSnapshot" *ngIf="flagValueObject.appliedInScopeSnapshotId !== requireCurrentScopeSnapshotId() && inheritanceFromScopeSnapshotId(flagValueObject.appliedInScopeSnapshotId)?.inheritedScopeSnapshot as inheritedScopeSnapshot"></app-scope-snapshot-details-popup>
		<span class="value">{{flagValueObject.value | json}}</span>
	</div>
	<div class="actions">
		<div pRipple class="button edit-flag-value" (click)="edit()" *ngIf="self.mostApplicableFlagValueObject(requireFlagValueKey()) as flagValueObject">
			<div
				class="ficon icon-only"
				[ngClass]="{
					'ficon-EditSolid12': !isInheritedFlagValueObject(flagValueObject),
					'ficon-Add': isInheritedFlagValueObject(flagValueObject)
				}">
			</div>
		</div>
		<div pRipple class="button set-flag-value" (click)="edit()" *ngIf="!self.mostApplicableFlagValueObject(requireFlagValueKey())"><div class="ficon ficon-Add"></div>Set Value</div>
	</div>
	<div class="notices" *ngIf="flagValuesAppliedInSameScope().length > 0">
		<div class="related-objects-notice has-tooltip-indicator">
			<div class="ficon ficon-FullHistory"></div><div class="notice-text">{{flagValuesAppliedInSameScope().length}}</div>
			<div class="related-objects-popup right-aligned">
				<div class="popup-title">Valued across {{flagValuesAppliedInSameScope().length}} version{{flagValuesAppliedInSameScope().length > 1 ? 's' : ''}}</div>
				<div class="object-rows">
					<div class="object-row" [ngClass]="{'current-value': replicatedFlagValue.appliedInScopeSnapshotId === requireCurrentScopeSnapshotId()}" *ngFor="let replicatedFlagValue of flagValuesAppliedInSameScope()">
						<div class="object-row-key">{{friendlyVersionLabel(replicatedFlagValue.appliedInScopeSnapshot.scopeVersion)}}</div>
						<div class="object-row-value">{{replicatedFlagValue.value | json}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="editable-value-container" *ngIf="editable() as editable">
	<div class="type-and-input-container">
		<div class="custom-select enabled">
			<div class="dirty-indicator" *ngIf="isDirty()"></div>
			<div class="primary-label">
				<div class="flag-value-type-and-name" *ngIf="flagValueTypeKeyOfEditable() as flagValueTypeKey">
					<div [class]="flagValueTypeInfoForView(flagValueTypeKey).class"></div>
					<div>{{flagValueTypeInfoForView(flagValueTypeKey).name}}</div>
				</div>
				<div class="ficon ficon-ChevronDownMed"></div>
			</div>
			<div class="dropdown-wrapper" #flagValueTypeDropdown>
				<div class="dropdown">
						<div
							pRipple class="dropdown-option"
							[class]="{
								'initially-selected': flagValueTypeKey === flagValueTypeKeyOfExisting(),
								'newly-selected': flagValueTypeKeyOfEditable() === flagValueTypeKey
							}"
							(click)="changeFlagValueTypeOfEditable(flagValueTypeKey, flagValueTypeDropdown)"
							*ngFor="let flagValueTypeKey of flagValueTypeKeys"
						>
							<div [class]="flagValueTypeInfoForView(flagValueTypeKey).class"></div><div pRipple class="dropdown-option-text">{{flagValueTypeInfoForView(flagValueTypeKey).name}}</div>
						</div>
				</div>
			</div>
		</div>

		<div class="value-input-container">
			<input pInputText pAutoFocus [autofocus]="true" type="text" placeholder="Enter value for '{{requireFlagValueKey().leafFlagNode.key}}'" class="value-input p-inputtext-sm" [formControl]="editable.model.controls.value" (keyup.Enter)="save()" *ngIf="flagValueTypeKeyOfEditable() === FlagValueTypeKey.String"/>
			<input pInputText pAutoFocus [autofocus]="true" type="text" placeholder="Enter value for '{{requireFlagValueKey().leafFlagNode.key}}'" class="value-input p-inputtext-sm" [formControl]="editable.model.controls.tempNumericValue" (keyup.Enter)="save()" *ngIf="flagValueTypeKeyOfEditable() === FlagValueTypeKey.Number"/>
			<div class="dirty-indicator" *ngIf="isDirty()"></div>
		</div>
	</div>

	<div class="actions">
		<div class="button-wrapper">
			<div class="disabler-overlay" *ngIf="!isDirty() || editable.model.controls.value.errors"></div>
			<div pRipple class="button save-flag-value" (click)="save()"><div class="ficon icon-only ficon-AcceptMedium"></div></div>
		</div>
		<div pRipple class="button gray cancel-flag-value" (click)="discard()"><div class="ficon icon-only ficon-Cancel"></div></div>
	</div>
</div>

import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appScopeSnapshotId]',
  standalone: true,
	exportAs: 'appScopeSnapshotId'
})
export class ScopeSnapshotIdDirective {
	@Input() appScopeSnapshotId!: number;
	elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
		this.elementRef = elementRef;
	}

}

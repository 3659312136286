import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagsComponent } from './flags/flags.component';
import { DevelopmentRoutingModule } from './development-routing.module';
import { ScopeSnapshotPageComponent } from './scope-snapshot-page/scope-snapshot-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from '../app-common/app-common.module';
import { ScopeSnapshotSelectorComponent } from './scope-snapshot-selector/scope-snapshot-selector.component';
// import { ValueComponent } from './value/value.component';
import { ScopeSnapshotDetailsPopupComponent } from './scope-snapshot-details-popup/scope-snapshot-details-popup.component';
// import { ScopesPageComponent } from './scopes-page/scopes-page.component';
// import { MatTableModule } from '@angular/material/table';
import { AutoFocusModule } from 'primeng/autofocus';
import { CheckboxModule } from 'primeng/checkbox';
// import { FlagValueComponent } from './flag-value/flag-value.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScopeSnapshotInheritancesComponent } from './scope-snapshot-inheritances/scope-snapshot-inheritances.component';
import { ScopeSnapshotModifiableDescendantInheritancesComponent } from './scope-snapshot-modifiable-descendant-inheritances/scope-snapshot-modifiable-descendant-inheritances.component';
import { ImportJsonComponent } from './import-json/import-json.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SkeletonModule } from 'primeng/skeleton';
import { FlagValueComponent } from './flag-value/flag-value.component';
import { DeploymentsPageComponent } from './deployments-page/deployments-page.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { RippleModule } from 'primeng/ripple';
import { ScopeSnapshotIdDirective } from '../scope-snapshot-id.directive';

@NgModule({
	declarations: [
		/*InputComponent, */
		FlagsComponent,
		FlagValueComponent,
		ScopeSnapshotPageComponent,
		// ScopeSelectorComponent,
		ScopeSnapshotDetailsPopupComponent,
		// ScopesPageComponent,
		ScopeSnapshotSelectorComponent,
		ScopeSnapshotInheritancesComponent,
  	ImportJsonComponent,
		DeploymentsPageComponent,
		ScopeSnapshotModifiableDescendantInheritancesComponent
  	// FlagValueComponent
	],
	imports: [
		CommonModule,
		AppCommonModule,
		DevelopmentRoutingModule,
		// MatTableModule,
		// ScopeModule,
		AutoFocusModule,
		CheckboxModule,
		SelectButtonModule,
		InputNumberModule,
		OverlayPanelModule,
		FormsModule,
		ReactiveFormsModule,
		TooltipModule,
		InputTextareaModule,
		RadioButtonModule,
		SkeletonModule,
		NgxJsonViewerModule,
		RippleModule,
		ScopeSnapshotIdDirective,
	],
	exports: [
		ScopeSnapshotSelectorComponent,
		ScopeSnapshotInheritancesComponent,
	]
})
export class DevelopmentModule { }

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import _ from 'lodash';
import { GqlRequestInfo, gqlRequestInfo, CrudStateService } from 'src/app/crud-state.service';
import { ScopeSnapshotFieldsForScopeSnapshotSelectorFragment, ScopeFieldsForScopeSnapshotSelectorFragment, ScopeVariableFieldsForScopeSnapshotSelectorFragment, SaveParentConnectionsMutation, SaveParentConnectionsMutationVariables, ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment, ValidatedSsInheritanceModificationsFragment } from 'src/generated/graphql';
import { Staticity, State as ScopeSnapshotSelectorState, ScopeSnapshotSelectorComponent, ActionType as ScopeSnapshotSelectorActionType, ModificationState } from '../scope-snapshot-selector/scope-snapshot-selector.component';
import { Apollo, gql } from 'apollo-angular';
import { CommonService } from 'src/app/app-common/common.service';
import ordinal from 'ordinal';
import { Subject, Subscription } from 'rxjs';
import type { XOR } from 'ts-xor'
import { ScopeSnapshotService } from 'src/app/scope-snapshot.service';
import { ScopeSnapshotIdDirective } from 'src/app/scope-snapshot-id.directive';
import { ValidatedInheritanceModifications, ViewOrModifyOrDiscardSsInheritancesAction } from 'src/app/scope/scopes-page/scopes-page.component';
import { DescendantManagerInnerState, ScopeSnapshotModifiableDescendantInheritancesComponent } from '../scope-snapshot-modifiable-descendant-inheritances/scope-snapshot-modifiable-descendant-inheritances.component';

export type StaticScopeSnapshotInheritance = Omit<
	NonNullable<ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number]["savedInheritance"]>,
	"__typename"
> & {
	inheritedScopeSnapshot: ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment
};

type ValidatedInheritance = ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number]["validatedInheritances"][number];
type DescendantScopeDifference = ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["descendantScopeDifferences"][number];

type ComponentGqlRequestInfos = {
	queries: {
	},
	mutations: {
		saveParentConnections: GqlRequestInfo<SaveParentConnectionsMutation, SaveParentConnectionsMutationVariables>
	},
	fragments: {
		// scopeSnapshotFields: GqlFragmentInfo<ScopeSnapshotFieldsForScopeSnapshotSelectorFragment>,
		// scopeFields: GqlFragmentInfo<ScopeFieldsForScopeSnapshotSelectorFragment>,
	},
};

export enum InheritancesSectionStateType {
	VIEW = 'VIEW',
	// LOADING_MODIFICATIONS = 'LOADING_MODIFICATIONS',
	MODIFY = 'MODIFY',
	// RELOADING_MODIFICATIONS = 'RELOADING_MODIFICATIONS',
	// SAVING = 'SAVING',
	// SAVED = 'SAVED'
};

enum InheritanceRowStateType {
	DIRECTLY_INHERITED = 'DIRECTLY_INHERITED',
	INDIRECTLY_INHERITED = 'INDIRECTLY_INHERITED',
	CONFLICTING_INHERITANCES = 'CONFLICTING_INHERITANCES',
	NOT_INHERITED = 'NOT_INHERITED'
}

export enum InheritancesSectionExternalAction {
	VIEW = 'VIEW',
	MODIFY = 'MODIFY',
};

type NonModifiableInheritancesSection = {
	stateType: InheritancesSectionStateType.VIEW,
	inheritanceRows: {
		selectorState: {
			staticity: Staticity.STATIC_SCOPE_WITH_STATIC_VERSION;
			actionsWhitelist: [ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE],
			initialScopeSnapshot: ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment
			scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[];
		}
	}[]
};

type NewlySelectedDirectParentModifications = {
	// This will contain a new scope snapshot if the user selects a new scope snapshot for this parent, otherwise it will contain the
	// same scope snapshot as the default scope snapshot for the selector (i.e. the one in selectorState.initialScopeSnapshot).
	newlySelectedDirectParent: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment;
	// This is controlled by the ordinal input field next to the scope snapshot selector.
	newlySelectedDirectParentOrdinalInput: number;
};

type EmptyNewlySelectedDirectParentModifications = {
	newlySelectedDirectParent: null;
	newlySelectedDirectParentOrdinalInput: null;
};

type InheritanceRowInitialDirectInheritance = {
	initialStateType: InheritanceRowStateType.DIRECTLY_INHERITED;
	initialScopeSnapshot: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment;
	initialParentOrdinal: number;
};

type InheritanceRowInitialIndirectInheritance = {
	initialStateType: InheritanceRowStateType.INDIRECTLY_INHERITED;
	initialScopeSnapshot: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment;
};

type InheritanceRowInitialNonInheritance = {
	initialStateType: InheritanceRowStateType.NOT_INHERITED;
};

type InheritanceRowLoadedConflictingInheritances = {
	loadedStateType: InheritanceRowStateType.CONFLICTING_INHERITANCES;
	conflictingInheritances: [
		ValidatedInheritance,
		ValidatedInheritance,
		...ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number]["validatedInheritances"]
	]; // Atleast 2 inheritances needed to represent a conflict.
	selectorState: {
		staticity: Staticity.STATIC_SCOPE_WITH_STATIC_VERSION;
		actionsWhitelist: [],
		initialScope: ScopeFieldsForScopeSnapshotSelectorFragment;
		scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[];
	};
};

// If this scope is not inherited, then we will allow the user to select a scope snapshot. The initializer of the selector can be either
// a scope snapshot or a scope, depending on the prior state of the selector.
type InheritanceRowLoadedNonInheritance = {
	loadedStateType: InheritanceRowStateType.NOT_INHERITED;

	selectorState: {
		staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION;
		actionsWhitelist: [ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE],
		scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[];
	} & XOR<
		{
			initialScope: ScopeFieldsForScopeSnapshotSelectorFragment
		},
		{
			initialScopeSnapshot: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment,
			modifications: {
				state: ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
				selectedScopeSnapshotId: null
			}
		}
	>
};

// If this scope is inherited but not selected as a direct parent, then we will use the inherited scope snapshot as the default
// scope snapshot for the selector, and we will not allow it to be selected as a direct parent.
type InheritanceRowLoadedIndirectInheritance = {
	loadedStateType: InheritanceRowStateType.INDIRECTLY_INHERITED;
	loadedInheritance: ValidatedInheritance

	// Since this scope is inherited, we will use the inherited scope snapshot as the default scope snapshot for the selector.
	selectorState: {
		staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION;
		actionsWhitelist: [ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE],
		modifications?: {
			state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
			selectedScopeSnapshotId: number
		},
		scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[]
	} & XOR<
		{initialScope: ScopeFieldsForScopeSnapshotSelectorFragment},
		{initialScopeSnapshot: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment}
	>
};

// If this scope is selected as a direct parent, then we will:
//	1. Have a dropdown to specify the ordinal of the parent using the model newlySelectedDirectParentOrdinalInput.
//	2. Have an enabled scope snapshot selector to allow specifying an alternative scope snapshot to use as the parent using the model newlySelectedDirectParent.
type InheritanceRowLoadedDirectInheritance = {
	loadedStateType: InheritanceRowStateType.DIRECTLY_INHERITED;
	loadedParentOrdinal: number;
	loadedInheritance: ValidatedInheritance

	selectorState: {
		staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION,
		actionsWhitelist: [ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE],
		modifications?: {
			state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
			selectedScopeSnapshotId: number
		},
		scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[];
	} & XOR<
		{initialScope: ScopeFieldsForScopeSnapshotSelectorFragment},
		{initialScopeSnapshot: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment}
	>
};

type ModifiableInheritanceRow = {
	inheritableScope: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number]["ancestorScope"],
	// We will sort the inherited scopes by this ordinal strictly for display purposes. This has no effect on the parent connections that
	// will be saved.
	templateOrdinal: number;
	// modifiable: NewlySelectedDirectParentModifications | EmptyNewlySelectedDirectParentModifications
	// TODO Consider how we can ensure newlySelectedDirectParent is always equal to the current scope snapshot of the selector.
	loaded: (
		(
			XOR<
				InheritanceRowLoadedConflictingInheritances
			,
			XOR<
				InheritanceRowLoadedNonInheritance & {
					modifiable: NewlySelectedDirectParentModifications | EmptyNewlySelectedDirectParentModifications
				}
			,
			XOR<
				InheritanceRowLoadedIndirectInheritance
			,
				InheritanceRowLoadedDirectInheritance & {
					modifiable: NewlySelectedDirectParentModifications | EmptyNewlySelectedDirectParentModifications
				}
			>>>
		)
		&
		(
			XOR<
				InheritanceRowInitialDirectInheritance
			,
			XOR<
				InheritanceRowInitialIndirectInheritance
			,
				InheritanceRowInitialNonInheritance
			>>
		)
	)
};

type ModifiableInheritancesSection = {
	stateType: InheritancesSectionStateType.MODIFY,

	incompatibleFlagNodes: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["incompatibleFlagNodes"],
	incompatibleFlagValues: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["incompatibleFlagValues"]

	// scopeInheritabilities: NonNullable<LoadModifiableInheritancesQuery["scopeSnapshotsByPk"]>["scope"]["scopeInheritabilities"],

	// Map of scope id representing the inherited scope, to the inheritance row for that scope id, plus the parent connection row.
	// initialInheritanceMap: Map<number, StaticScopeSnapshotInheritance[]>,

	// These are the current scope snapshot's parents.
	// currentDirectInheritances: DirectInheritances; // TODO Fetch the parent connections and get the type from there.

	// Map of scope id representing the inheritable scope (based on scope_inheritabilities in the database), to properties like:
	//
	//   1. The inherited scope snapshot, if any. Or even multiple inherited scope snapshots if there are conflicting inheritances.
	//			This is present twice per inheritable scope, to show two different cases:
	//	    - The initial inheritance, before any modifications (what lives on the server). Properties representing the initial state are typically prefixed with 'initial', such as initialStateType, initialInheritance, initialParentOrdinal, etc.
	//	    - Currently modified inheritance (what the user is currently seeing and validating before saving). Properties representing the current state are typically prefixed with 'loaded', such as loadedStateType, loadedInheritance, loadedParentOrdinal, etc.
	//
	//	 2. The scope snapshot selector state that can be used to initialize the scope snapshot selector component. Again, present for both initial and modified states.
	//
	//   3. The templateOrdinal, which is used to sort the rows.
	//
	//	 4. The directParentOrdinal, indicating the ordinal of the direct parent (i.e. ss_direct_inheritances.ordinal from the database). This is present only for direct parent inheritances, but again for both initial and modified states.
	inheritanceRowsMap: Map<number, ModifiableInheritanceRow>,

	descendantScopeDifferences: DescendantScopeDifference[]
};

type InheritancesSection = NonModifiableInheritancesSection | ModifiableInheritancesSection;

export type DescendantManagerState = {
	isOpen: boolean;
	// If innerState is set to undefined here and this DescendantManagerState is emitted to the parent scopes-page component, the scopes-page component will remove it from its descendantSectionsMap resulting in the state of the descendant inheritances component being discarded (meaning, the descendant toolbar's state is discarded and the descendant-sections url is updated to reflect that too). See the handleNewDescendantManagerInnerState() method in scopes-page to see this behavior.
	// However, the child component of modifiable-descendant-inheritances will treat undefined as a signal to initialize the innerState with default values. In other words, setting isOpen to true and innerState to undefined is a way to let modifiable-descendant-inheritances initialize the innerState with default values. See showDescendantScopeSnapshots() in this file for more info to see how we do this.
	innerState?: DescendantManagerInnerState;
}

@Component({
  selector: 'app-scope-snapshot-inheritances[scope-snapshot][scope-variables][static-or-validated-inheritances]',
  templateUrl: './scope-snapshot-inheritances.component.html',
  styleUrls: ['./scope-snapshot-inheritances.component.scss'],
})
export class ScopeSnapshotInheritancesComponent implements OnInit, OnDestroy, AfterViewInit {
	_ = _;
	component = ScopeSnapshotInheritancesComponent;
	scopeSnapshotSelectorComponent = ScopeSnapshotSelectorComponent;
	Math = Math;
	ssStaticity = Staticity;

	@Input('scope-snapshot') targetScopeSnapshot?: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment;
	@Input('scope-variables') baseScopeVariables?: ScopeVariableFieldsForScopeSnapshotSelectorFragment[];
	@Input('static-or-validated-inheritances') staticOrValidatedInheritances?: StaticScopeSnapshotInheritance[] | ValidatedInheritanceModifications['validationsByTargetScopeSnapshots'][number];
	@Input('descendant-manager-state') descendantManagerState?: DescendantManagerState;

	@Input('inheritances-section-state-changer') inheritancesSectionStateChanger?: Subject<InheritancesSectionExternalAction>;
	loadModifiableInheritancesSubscription?: Subscription;
	@Output('view-or-modify-or-discard-ss-inheritances') viewOrModifyOrDiscardSsInheritancesEvent: EventEmitter<ViewOrModifyOrDiscardSsInheritancesAction> = new EventEmitter();
	@Output('descendant-manager-state-update') descendantManagerStateUpdate = new EventEmitter<DescendantManagerState>();

	@ViewChildren('inheritanceRowElementRefs') inheritanceRowElementRefs: QueryList<ElementRef> = new QueryList();
	@ViewChildren('stateTransitionIndicators') stateTransitionIndicators: QueryList<ElementRef> = new QueryList();
	@ViewChildren('conflictingInheritanceDirectiveRefs') conflictingInheritanceDirectiveRefs: QueryList<ScopeSnapshotIdDirective> = new QueryList();
	@ViewChild('baseRowElementRef') baseRowElementRef!: ElementRef;

	stateTransitionIndicatorUnlisteners: (() => void)[] = [];

	// readonly initialInheritancesSection: InheritancesSection = {
	// 	state: InheritancesSectionStateType.VIEW,
	// 	// eligibleLatestVersionAncestorScopeSnapshots: null // TODO
	// }
	inheritancesSection?: InheritancesSection;

	static inheritancesSectionStateTypes = InheritancesSectionStateType;
	static inheritanceRowStateTypes = InheritanceRowStateType;

	static gridColumnStartOfScopeSnapshotSelector = 'start-of-first-dropdown';

	readonly inheritedScopeSnapshotStaticity: Staticity.STATIC_SCOPE_WITH_STATIC_VERSION = Staticity.STATIC_SCOPE_WITH_STATIC_VERSION;

	// private scopeFields: ComponentGqlRequestInfos["fragments"]["scopeFields"] = gql`
	// 	fragment scopeFieldsForScopeSnapshotSelector on Scopes {
	// 		id
	// 		name
	// 		comparableScopeDefiners {
	// 			isScopeOptionIdExplicitlySelected
	// 			scopeVariable {
	// 				id
	// 				ordinal
	// 				name
	// 			}
	// 			scopeOption {
	// 				id
	// 				ordinal
	// 				value
	// 			}
	// 		}
	// 		scopeSnapshots {
	// 			id
	// 			scopeId
	// 			scopeVersion
	// 			createdAt
	// 		}
	// 	}
	// `;

	private fragments: ComponentGqlRequestInfos["fragments"] = {}

	gqlRequestInfos: ComponentGqlRequestInfos = {
		queries: {
		},
		mutations: {
			saveParentConnections: gqlRequestInfo(gql`
				mutation saveParentConnections(
					$targetScopeSnapshotId: Int!,
					$deleteByParentScopeIds: [Int!]!,
					$updateParentConnections: [SsDirectInheritancesUpdates!]!,
					$insertParentConnections: [SsDirectInheritancesInsertInput!]!)
				{
					deleteSsDirectInheritances(where: {scopeSnapshotId: {_eq: $targetScopeSnapshotId}, directlyInheritedScopeId: {_in: $deleteByParentScopeIds}}) {
						returning {
							id
						}
					}
					updateSsDirectInheritancesMany(updates: $updateParentConnections) {
						returning {
							id
						}
					}
					insertSsDirectInheritances(objects: $insertParentConnections) {
						returning {
							id
						}
					}
				}
			`),
		},
		fragments: this.fragments
	}

	private sortedInheritanceRowsCache: ModifiableInheritanceRow[] | null = null;

	// These constants will be used in the svg template to draw the inheritance lines.
	// Make sure to keep these in sync with the css - specifically: rowHeight, rowGap, and baseRowBorderTopHeight.
	static inheritanceDiagramConstants = {
		headingHeight: 34, // px
		rowHeight: 34, // px
		rowGap: 25, // px
		baseRowBorderTopHeight: 2, // px
		leftMarginForChildLine: 6, // px
		leftMarginForParentTriangle: 3, // px
		triangleWidth: 10, // px
		mainVerticalLineX: '40', // percent. Indicates how far right the main vertical line is as a percentage of the width of the svg.

		// Make the ancestor width a bit smaller than the direct parent width, so that the partially transparent half-pixels of the ancestor line don't overlap with the direct parent line.
		ancestorStrokeWidth: 1.5, // px
		directParentStrokeWidth: 2, // px
		// strokeColorOuter: '#0e7791',
		// strokeColorOuter: '#fff',

		ancestorStrokeDashArray: '3,5',
		// ancestorStrokeDashArray: '1,0',
		strokeColorAncestor: '#8f8f8f',

		directParentStrokeDashArray: '1,0',
		strokeColorDirectParent: '#007fa8', //'#00b2dd',//'#70dbff',//'#00afdb',//'#0e7791',
	}

	friendlyOrdinal = ordinal;
	friendlyVersionLabel = CommonService.friendlyVersionLabel;

	constructor(private apollo: Apollo, private commonService: CommonService, private cd: ChangeDetectorRef, private ngZone: NgZone, public elementRef: ElementRef, private renderer: Renderer2) {}

	private generateInheritancesSection(): InheritancesSection {
		const staticOrValidatedInheritances = this.requireStaticOrValidatedInheritances();
		if ("ancestorScopeDifferences" in staticOrValidatedInheritances) {
			const validatedInheritances = staticOrValidatedInheritances;

			return ScopeSnapshotInheritancesComponent.validatedInheritancesToModifiableInheritancesSection(validatedInheritances, this.requireScopeVariables());
		}
		else {
			const staticInheritances = staticOrValidatedInheritances;

			return ScopeSnapshotInheritancesComponent.staticInheritancesToNonModifiableInheritancesSection(staticInheritances, this.requireScopeVariables());
		}
	}

	ngOnInit() {
		this.inheritancesSection = this.generateInheritancesSection();
		this.cd.detectChanges();
	}

	requireStaticOrValidatedInheritances(): StaticScopeSnapshotInheritance[] | ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"][number] {
		const staticOrValidatedInheritances = this.staticOrValidatedInheritances;
		if (!staticOrValidatedInheritances) {
			throw new Error("Static or modifiable inheritances are required.");
		}
		return staticOrValidatedInheritances;
	}

	requireValidatedInheritances(): ValidatedInheritanceModifications["validationsByTargetScopeSnapshots"][number] {
		const validatedInheritances = this.requireStaticOrValidatedInheritances();
		if ("ancestorScopeDifferences" in validatedInheritances) {
			return validatedInheritances;
		}
		throw new Error("Validated inheritances are required.");
	}

	ngAfterViewInit(): void {
		this.ngZone.runOutsideAngular(() => {

			const removeFocusFromInheritanceRows = () => {
				this.renderer.removeClass(this.baseRowElementRef.nativeElement, 'unhighlighted');

				this.inheritanceRowElementRefs.forEach((currRowElementRef, currRowIndex) => {
					this.renderer.removeClass(currRowElementRef.nativeElement, 'unhighlighted');
				});

				this.conflictingInheritanceDirectiveRefs.forEach((currConflictingInheritanceDirective) => {
					this.renderer.removeClass(currConflictingInheritanceDirective.elementRef.nativeElement, 'unhighlighted');
				});
			}


			// We add event listeners to the state transition indicators outside of angular, so that we don't trigger change detection when the user hovers over them, which would cause the entire component to re-render. This improves UI performance.
			// Note that in order to work outside of angular, we are constrained to dealing with indexes of the inheritance rows instead of the convenient inheritance row objects themselves. This is because by using ViewChildren, we only get back a list of native element rows as they are rendered in the DOM.
			this.inheritanceRowElementRefs.forEach((indicator, focusedOnRowIndex) => {

				// When the mouse enters an indicator, unhighlight all ancestor rows, and all conflicting inheritance labels, except for:
				// 1. The focus row.
				// 2. The focus row's ancestors.
				// 3. The individual conflicting inheritance labels that are inherited by the focus row.
				const mouseMoveEventListener = (event: MouseEvent) => {
					const isHoveringOverTargetRowActions = event.target !== null && event.target instanceof HTMLElement && event.target.closest(".inheritance-row-actions") !== null && event.target.closest(".target-scope-snapshot-row") !== null;
					if (isHoveringOverTargetRowActions) {
						removeFocusFromInheritanceRows();
						return;
					}
					const allInheritanceRows = this.memoizedSortedModifiableInheritanceRows();

					// If they're focusing on the base row, then simply highlight it and it's ancestors - that is, those rows that have a state type of DIRECTLY_INHERITED or INDIRECTLY_INHERITED or CONFLICTING_INHERITANCES. The conflicting inheritances cannot actually be saved into the database, but we simply show them to the user for informational purposes.
					if (focusedOnRowIndex === allInheritanceRows.length) {

						this.inheritanceRowElementRefs.forEach((currRowElementRef, currRowIndex) => {
							if (currRowIndex < allInheritanceRows.length) {
								// Keep the focus row's ancestors highlighted.
								const currRow = allInheritanceRows[currRowIndex];
								const currRowShouldBeHighlighted = currRow && (currRow.loaded.loadedStateType === InheritanceRowStateType.DIRECTLY_INHERITED || currRow.loaded.loadedStateType === InheritanceRowStateType.INDIRECTLY_INHERITED || currRow.loaded.loadedStateType === InheritanceRowStateType.CONFLICTING_INHERITANCES);
								if (currRowShouldBeHighlighted) {
									this.renderer.removeClass(currRowElementRef.nativeElement, 'unhighlighted');
								}
								else {
									this.renderer.addClass(currRowElementRef.nativeElement, 'unhighlighted');
								}
							}
						});
					}
					else {
						const focusedOnRow = allInheritanceRows[focusedOnRowIndex];
						if (focusedOnRow === undefined) {
							return;
						}

						const highlightRowsMap = this.ancestorRowsMapOfChildRow(focusedOnRow);
						const highlightRowIndexes: number[] = [focusedOnRowIndex];

						for (const [scopeId, highlightRow] of highlightRowsMap) {
							const indexWithinAllRows = _.findIndex(allInheritanceRows, row => row.inheritableScope.id === scopeId);
							if (indexWithinAllRows !== -1) {
								highlightRowIndexes.push(indexWithinAllRows);
							}
						}
						highlightRowIndexes.sort();

						this.inheritanceRowElementRefs.forEach((currRowElementRef, currRowIndex) => {
							// Keep the focus row's ancestors highlighted, as well as the focus row itself. Keep the base row highlighted as well.
							const currRowShouldBeHighlighted = highlightRowIndexes.indexOf(currRowIndex) !== -1 || currRowIndex === allInheritanceRows.length;
							if (currRowShouldBeHighlighted) {
								this.renderer.removeClass(currRowElementRef.nativeElement, 'unhighlighted');
							}
							else {
								this.renderer.addClass(currRowElementRef.nativeElement, 'unhighlighted');
							}
						});

						let highlightConflictingInheritances = this.conflictingInheritancesOfChildRow(focusedOnRow);
						for (const currRow of allInheritanceRows) {
							// If the focus row itself is one that is a conflicting inheritance row, then highlight all conflicting inheritances in this focus row.
							if (currRow.loaded.loadedStateType === InheritanceRowStateType.CONFLICTING_INHERITANCES && focusedOnRow.inheritableScope.id === currRow.inheritableScope.id) {
								highlightConflictingInheritances = highlightConflictingInheritances.concat(currRow.loaded.conflictingInheritances);
							}
						}

						this.conflictingInheritanceDirectiveRefs.forEach((currConflictingInheritanceDirective, currConflictingInheritanceIndex) => {
							this.renderer.addClass(currConflictingInheritanceDirective.elementRef.nativeElement, 'unhighlighted');
							for (const highlightConflictingInheritance of highlightConflictingInheritances) {
								// Keep those conflicting inheritances that are part of the focus row's ancestors highlighted.
								if (highlightConflictingInheritance.inheritedScopeSnapshotId === currConflictingInheritanceDirective.appScopeSnapshotId) {
									this.renderer.removeClass(currConflictingInheritanceDirective.elementRef.nativeElement, 'unhighlighted');
								}
							}
						});
					}
				};

				const mouseMoveUnlistener = this.renderer.listen(indicator.nativeElement, 'mousemove', mouseMoveEventListener);
				this.stateTransitionIndicatorUnlisteners.push(mouseMoveUnlistener);

				// Remove all 'unhighlighted' classes when the mouse leaves the indicator, so that everything is highlighted again like normal.
				const mouseLeaveEventListener = removeFocusFromInheritanceRows;

				const mouseLeaveUnlistener = this.renderer.listen(indicator.nativeElement, 'mouseleave', mouseLeaveEventListener);
				this.stateTransitionIndicatorUnlisteners.push(mouseLeaveUnlistener);
			});
		});
	}

	ngOnDestroy() {
		CrudStateService.unsubscribeFromGqlSubscriptions(this.gqlRequestInfos);
		if (this.loadModifiableInheritancesSubscription) {
			this.loadModifiableInheritancesSubscription.unsubscribe();
		}
		this.sortedInheritanceRowsCache = null;
		this.stateTransitionIndicatorUnlisteners.forEach(unlistener => {
			unlistener();
		});
	}

	requireTargetScopeSnapshot(): ScopeSnapshotFieldsForScopeSnapshotSelectorFragment {
		const scopeSnapshot = this.targetScopeSnapshot;
		if (!scopeSnapshot) {
			throw new Error("Target scope snapshot is required.");
		}
		return scopeSnapshot;
	}

	requireStaticInheritances(): StaticScopeSnapshotInheritance[] {
		const inheritances = this.staticOrValidatedInheritances;
		if (!inheritances) {
			throw new Error("Initial inheritances are required.");
		}
		if ("ancestorScopeDifferences" in inheritances) {
			return _.compact(inheritances.ancestorScopeDifferences.map(ancestorScopeDifference => {
				if (ancestorScopeDifference.savedInheritance?.isSelf === false) {
					return {
						...ancestorScopeDifference.savedInheritance,
						isSelf: ancestorScopeDifference.savedInheritance.isSelf,
						inheritedScopeSnapshot: ScopeSnapshotService.formatInheritablesToScopeSnapshotForSelector(ancestorScopeDifference.savedInheritance.inheritedScopeSnapshotId, ancestorScopeDifference.ancestorScope, ancestorScopeDifference.ancestorScopeSnapshots)
					}
				}
				else {
					return null;
				}
			}));
		}
		return inheritances;
	}

	existingDescendantsDirectlyInheritingTargetScopeSnapshotCount(): number {
		const inheritances = this.staticOrValidatedInheritances;
		if (inheritances === undefined || !("descendantScopeDifferences" in inheritances)) {
			throw new Error("Initial inheritances are required.");
		}

		const allEligibleDescendantInheritances = _.flatMap(inheritances.descendantScopeDifferences, descendantScopeDifference => descendantScopeDifference.descendantScopeSnapshots);
		return allEligibleDescendantInheritances.filter(
			possibleDescendantInheritance => possibleDescendantInheritance.savedInheritance?.inheritedScopeSnapshotId === this.requireTargetScopeSnapshot().id && possibleDescendantInheritance.savedInheritance.isDirectInheritance === true
		).length;
	}

	requireModifiableInheritancesSection(): ModifiableInheritancesSection {
		const inheritancesSection = this.inheritancesSection;
		if (!inheritancesSection || inheritancesSection.stateType !== ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY) {
			throw new Error("Modifiable inheritances section is required.");
		}
		return inheritancesSection;
	}

	requireScopeVariables(): ScopeVariableFieldsForScopeSnapshotSelectorFragment[] {
		const scopeVariables = this.baseScopeVariables;
		if (!scopeVariables) {
			throw new Error("Scope variables are required.");
		}
		return scopeVariables;
	}

	private static inheritanceRowsSortComparator(a: ModifiableInheritanceRow, b: ModifiableInheritanceRow): number {
		return a.templateOrdinal - b.templateOrdinal;
	}

	memoizedSortedModifiableInheritanceRows(): ModifiableInheritanceRow[] {
		if (this.sortedInheritanceRowsCache === null) {
			this.sortedInheritanceRowsCache = ScopeSnapshotInheritancesComponent.sortedInheritanceRowsByMap(this.requireModifiableInheritancesSection().inheritanceRowsMap);
		}
		return this.sortedInheritanceRowsCache;
	}

	static sortedInheritanceRowsByMap(inheritanceRows: Map<number, ModifiableInheritanceRow>): ModifiableInheritanceRow[] {
		return ScopeSnapshotInheritancesComponent.sortedInheritanceRows(Array.from(inheritanceRows.values()));
	}

	static sortedInheritanceRows(inheritanceRows: ModifiableInheritanceRow[]): ModifiableInheritanceRow[] {
		return inheritanceRows.sort(ScopeSnapshotInheritancesComponent.inheritanceRowsSortComparator);
	}

	// static inheritanceRowsSortComparator(akv: KeyValue<string, ModifiableInheritanceRow>, bkv: KeyValue<string, ModifiableInheritanceRow>): number {
	// 	const aRow = akv.value;
	// 	const bRow = bkv.value;

	// 	return ScopeSnapshotInheritancesComponent.inheritanceRowsSortComparator(aRow, bRow);
	// };

	parentOrdinalDropdownOptions(): number[] | undefined {
		// if (this.parentOrdinalDropdownOptionsCache !== null) {
		// 	return this.parentOrdinalDropdownOptionsCache;
		// }

		if (ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY === this.inheritancesSection?.stateType) {

			let maxOrdinalWithinNewParents = 0;
			for (const [scopeId, possibleNewParentInheritance] of this.inheritancesSection.inheritanceRowsMap) {
				if (!!possibleNewParentInheritance.loaded.modifiable?.newlySelectedDirectParent) {
					const initialParentOrdinal = ('initialParentOrdinal' in possibleNewParentInheritance.loaded) ? possibleNewParentInheritance.loaded.initialParentOrdinal : null;
					const maxOrdinal = Math.max(initialParentOrdinal || 0, possibleNewParentInheritance.loaded.modifiable.newlySelectedDirectParentOrdinalInput);
					if (maxOrdinal > maxOrdinalWithinNewParents) {
						maxOrdinalWithinNewParents = maxOrdinal;
					}
				}
			}

			return  _.range(1, maxOrdinalWithinNewParents + 1);

			// this.parentOrdinalDropdownOptionsCache = _.range(1, maxOrdinalWithinNewParents + 2);
			// return this.parentOrdinalDropdownOptionsCache;
		}
	}

	// We return the newly selected direct parents in the order of their ordinal input. If there are any duplicate ordinals, then we return null to indicate that the modifications are not valid, which can be used to prevent emitting/reloading the inheritances until the
	// user fixes the duplicate ordinals.
	newlySelectedDirectParents(): ScopeSnapshotFieldsForScopeSnapshotSelectorFragment[] | null {
		if (
			!this.inheritancesSection || this.inheritancesSection.stateType !== ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY
		) {
			throw new Error("Cannot get newly selected direct parents because the inheritances section is not in modify state.");
		}
		let eligibleModifications: NewlySelectedDirectParentModifications[] = [];

		const encounteredOrdinals: number[] = [];
		for (const [scopeId, inheritanceRow] of this.inheritancesSection.inheritanceRowsMap) {
			if (!!inheritanceRow.loaded.modifiable?.newlySelectedDirectParent) {
				const isDuplicateOrdinal = encounteredOrdinals.includes(inheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput);

				// If the user has indicated that this row should be a direct parent, and has selected a scope snapshot for it, and the ordinal is not a duplicate, then append it to the list of newlySelectedDirectParents.
				if (!isDuplicateOrdinal) {
					encounteredOrdinals.push(inheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput);
					eligibleModifications.push(inheritanceRow.loaded.modifiable);
				}
				// If the user has indicated that this row should be a direct parent, and has selected a scope snapshot for it, but we have already encountered this ordinal, then return null to indicate that the modifications are not valid.
				else {
					return null;
				}
			}
		}

		// Sort the eligible modifications by the ordinal input, and return the array of newly selected direct parents.
		return eligibleModifications.sort((a, b) => a.newlySelectedDirectParentOrdinalInput - b.newlySelectedDirectParentOrdinalInput).map(eligibleModification => eligibleModification.newlySelectedDirectParent);
	}

	loadedDirectParents(): ScopeSnapshotFieldsForScopeSnapshotSelectorFragment[] {
		if (!this.inheritancesSection || this.inheritancesSection.stateType !== ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY) {
			throw new Error("Cannot get the loaded direct parents because the inheritances section is not in modify state.");
		}

		let loadedDirectParentSections: InheritanceRowLoadedDirectInheritance[] = [];
		for (const [scopeId, inheritanceRow] of this.inheritancesSection.inheritanceRowsMap) {
			if (inheritanceRow.loaded.loadedStateType === InheritanceRowStateType.DIRECTLY_INHERITED) {
				loadedDirectParentSections.push(inheritanceRow.loaded);
			}
		}
		return loadedDirectParentSections.sort((a, b) => a.loadedParentOrdinal - b.loadedParentOrdinal).map(loadedDirectParentSection => {
			const initialScope = (loadedDirectParentSection.selectorState?.initialScopeSnapshot) ? loadedDirectParentSection.selectorState.initialScopeSnapshot.scope : loadedDirectParentSection.selectorState.initialScope;

			const selectedScopeSnapshot = initialScope.scopeSnapshots.find(scopeSnapshot => scopeSnapshot.id === loadedDirectParentSection.loadedInheritance.inheritedScopeSnapshotId);
			if (!selectedScopeSnapshot) {
				throw new Error("Could not find the selected scope snapshot with id " + loadedDirectParentSection.loadedInheritance.inheritedScopeSnapshotId + " in the scope snapshots of scope " + initialScope.id);
			}
			return {
				...selectedScopeSnapshot,
				scope: initialScope,
			}
		});

	}

	emittableModifications(): ScopeSnapshotFieldsForScopeSnapshotSelectorFragment[] | null {
		if (ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY === this.inheritancesSection?.stateType) {
			const newlySelectedDirectParents = this.newlySelectedDirectParents();
			// The newly selected direct parents are emittable/reloadable if the inputs are valid (i.e. there are no duplicate ordinals), and there are modifications that are different from the currently loaded state.
			if (newlySelectedDirectParents !== null && !_.isEqual(newlySelectedDirectParents.map(scopeSnapshot => scopeSnapshot.id), this.loadedDirectParents().map(scopeSnapshot => scopeSnapshot.id))) {
				return newlySelectedDirectParents;
			}
		}
		return null;
	}

	handleParentScopeSnapshotSelectorStateChange(state: ScopeSnapshotSelectorState, inheritanceRow: ModifiableInheritanceRow) {
		// if (state.staticity !== Staticity.STATIC_SCOPE_WITH_STATIC_VERSION) {
			// TODO If the parents selected are reloadable (valid), then reload. Regardless, store the newly selected scope snapshot (even if null), in modifiable.newlySelectedDirectParent.
			// if (this.isScopeSnapshotSelectorDirty(state)) {
			// this.parentOrdinalDropdownOptionsCache = null;
			if (ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY === this.inheritancesSection?.stateType) {
				this.sortedInheritanceRowsCache = null;

				const selectedDirectParentViaStateChange = (state.staticity !== Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT && state.staticity !== Staticity.STATIC_SCOPE_WITH_STATIC_VERSION) ? (ScopeSnapshotSelectorComponent.selectedScopeSnapshotWithOtherSnapshotsInSameScope(state) ?? null) : null;
				if (inheritanceRow.loaded.loadedStateType === InheritanceRowStateType.DIRECTLY_INHERITED || inheritanceRow.loaded.loadedStateType === InheritanceRowStateType.NOT_INHERITED) {
					// If we have deselected the direct parent, then we indicate that in the modifiable section of the row.
					if (selectedDirectParentViaStateChange === null) {
						inheritanceRow.loaded.modifiable = {
							newlySelectedDirectParent: null,
							newlySelectedDirectParentOrdinalInput: null
						}
					}
					// Otherwise, if we have selected a direct parent, then we indicate that in the modifiable section of the row.
					else {
						// If we didn't have a direct parent selected before, then we need to compute the new ordinal for this direct parent, and increment the ordinals of the other direct parents that appear after this row.
						if (!inheritanceRow.loaded.modifiable.newlySelectedDirectParent) {
							const inheritanceRowsArray = Array.from(this.inheritancesSection.inheritanceRowsMap.values());

							// Select a new ordinal that is equal to the max ordinal of the other direct parents that *appear* before this row, + 1. Note that we use the templateOrdinal to determine the appearance order.
							const otherInheritanceRowsAppearingBeforeThisRow =_.filter(inheritanceRowsArray, otherInheritanceRow => otherInheritanceRow.templateOrdinal < inheritanceRow.templateOrdinal);
							let currentMaxParentOrdinal = 0;
							for (const otherInheritanceRow of otherInheritanceRowsAppearingBeforeThisRow) {
								if (!!otherInheritanceRow.loaded.modifiable?.newlySelectedDirectParentOrdinalInput) {
									const ordinalForOtherInheritanceRow = otherInheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput;
									if (ordinalForOtherInheritanceRow > currentMaxParentOrdinal) {
										console.log(otherInheritanceRow.loaded.modifiable.newlySelectedDirectParent);
										currentMaxParentOrdinal = ordinalForOtherInheritanceRow;
									}
								}
							}

							const newOrdinal = currentMaxParentOrdinal + 1;
							inheritanceRow.loaded.modifiable = {
								newlySelectedDirectParent: selectedDirectParentViaStateChange,
								newlySelectedDirectParentOrdinalInput: newOrdinal
							}

							this.incrementModifiableOrdinalsIfConflictingWithRow(inheritanceRow);
						}
						// If we did have a direct parent selected before, then we need to simply reuse it's ordinal.
						else {
							inheritanceRow.loaded.modifiable = {
								newlySelectedDirectParent: selectedDirectParentViaStateChange,
								newlySelectedDirectParentOrdinalInput: inheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput
							}
						}
					}
				}

				// if (this.emittableModifications()){
				// 	this.loadOrReloadModifiableInheritances();
				// }
				const emittableModifications = this.emittableModifications();
				if (emittableModifications !== null){
					this.emitNewDirectInheritanceSelections(emittableModifications);
				}
			}
			// }
			// this.primaryScopeSnapshotSelectorStateChange = state;
		// }
	}

	conflictingInheritancesOfChildRow(childRow: ModifiableInheritanceRow): ValidatedInheritance[] {
		const conflictingInheritances: ValidatedInheritance[] = [];
		const ancestorInheritancesMapOfChildRow = this.ancestorInheritancesMapOfChildRow(childRow);
		const allInheritanceRowsMap = this.requireModifiableInheritancesSection().inheritanceRowsMap;

		for (const [scopeId, ancestorInheritances] of ancestorInheritancesMapOfChildRow) {
			if (allInheritanceRowsMap.get(scopeId)?.loaded.loadedStateType === InheritanceRowStateType.CONFLICTING_INHERITANCES) {
				for (const ancestorInheritance of ancestorInheritances) {
					conflictingInheritances.push(ancestorInheritance);
				}
			}
		}
		return conflictingInheritances;
	}

	static findConflictingInheritanceByInheritedScopeSnapshotId(conflictingInheritances: InheritanceRowLoadedConflictingInheritances['conflictingInheritances'], inheritedScopeSnapshotId: number) : InheritanceRowLoadedConflictingInheritances['conflictingInheritances'][number] | undefined {
		return conflictingInheritances.find(conflictingInheritance => conflictingInheritance.inheritedScopeSnapshotId === inheritedScopeSnapshotId);
	}

	pTooltipForCurrentRowState(inheritanceRow: ModifiableInheritanceRow): string {
		const inheritancesSection = this.inheritancesSection;
		if (inheritancesSection === undefined) {
			throw new Error("Inheritances section is required.");
		}

		const numAllAncestors = this.ancestorRowsMapOfChildRow(inheritanceRow).size;
		const numDirectAncestors = this.directParentRowsMapOfChildRow(inheritanceRow).size;
		const numIndirectAncestors = numAllAncestors - numDirectAncestors;

		const bulletPointPadded = "    ▪ ";
		const indicatedByDirectParentArrow = "indicated by the blue arrow";
		const indicatedByAncestorArrow = "indicated by the gray arrow";

		if (inheritanceRow.loaded.loadedStateType === InheritanceRowStateType.DIRECTLY_INHERITED) {
			let tooltip = `This scope snapshot will be inherited as a direct parent`;
			if (numDirectAncestors > 0) {
				tooltip += ` and will also bring along:\n${bulletPointPadded}${numDirectAncestors} of its own direct parents, ${indicatedByDirectParentArrow}${numDirectAncestors !== 1 ? 's' : ''}.`;
				if (numIndirectAncestors !== 0) {
					tooltip += `\n${bulletPointPadded}${numIndirectAncestors} of its indirect inheritances, ${indicatedByAncestorArrow}${numIndirectAncestors !== 1 ? 's' : ''}.`;
				}
			}
			else {
				tooltip += `.`;
			}

			// tooltip += `\n\nYou can change this selected snapshot as desired or prevent it from being inherited by deselecting the snapshot.`;
			tooltip += `\n\nIf desired, use the snapshot dropdown to either inherit a different snapshot from this scope, or choose not to inherit from this scope at all.`;
			return tooltip;
		}
		else if (inheritanceRow.loaded.loadedStateType === InheritanceRowStateType.INDIRECTLY_INHERITED) {
			let tooltip = `This scope snapshot is inherited by one or more of your selected direct parents and so it will be inherited here as well.`;

			if (numDirectAncestors > 0) {
				tooltip += `\n\nIt will also bring along:\n${bulletPointPadded}${numDirectAncestors} of its own direct parents, ${indicatedByDirectParentArrow}${numDirectAncestors !== 1 ? 's' : ''}.`;
				if (numIndirectAncestors !== 0) {
					tooltip += `\n${bulletPointPadded}${numIndirectAncestors} of its indirect inheritances, ${indicatedByAncestorArrow}${numIndirectAncestors !== 1 ? 's' : ''}.`;
				}
			}
			return tooltip;
		}
		else if (inheritanceRow.loaded.loadedStateType === InheritanceRowStateType.NOT_INHERITED) {
			return `This scope will neither be a direct parent nor will it be inherited.

Select a snapshot if you want it to be inherited as a direct parent.`;
		}
		else if (inheritanceRow.loaded.loadedStateType === InheritanceRowStateType.CONFLICTING_INHERITANCES) {
			const numConflictingInheritances = inheritanceRow.loaded.conflictingInheritances.length;
			return `The selected direct parents result in inheriting ${numConflictingInheritances} different snapshot${numConflictingInheritances !== 1 ? 's' : ''} of this scope.

In order to resolve this inheritance conflict, you need to either:
1. Select different direct parents or,
2. Modify the inheritances of the currently selected direct parents such that they all inherit the same snapshot of this scope.`;
		}
		return "";
	}

	/**
	 *
	 * @param childInheritances
	 * @returns Return a map of the provided children's direct parents. The map consists of those scope ids of parents as keys, and the array of the direct parents that share the same scope id as values.
	 * Visually that looks like this:
	 * {
	 * 	1: [parent1, parent2], // parent1 and parent2 have a scope id of 1.
	 * 	2: [parent3], // parent3 has a scope id of 2.
	 * 	3: [parent4, parent5, parent6] // parent4, parent5, and parent6 have a scope id of 3.
	 * }
	 */
	directParentsMapOfChildInheritances(childInheritances: ValidatedInheritance[]): Map<number, ValidatedInheritance[]> {
		const allInheritanceRowsMap = this.requireModifiableInheritancesSection().inheritanceRowsMap;
		const resultingInheritancesMap: Map<number, ValidatedInheritance[]> = new Map();

		// First, determine the scope snapshot ids that were passed in.
		let childScopeSnapshotIds = childInheritances.map(childInheritance => childInheritance.inheritedScopeSnapshotId);

		// Iterate through all of the rows to find which rows the provided children inherit from. This involves looking at each of scope snapshots of these rows. To help us do this, we use the directChildrenIdsLimitedToInheritancesOfTarget property of each inheritance to see if it contains any of the scope snapshot ids of the children.
		for (const [scopeId, row] of allInheritanceRowsMap) {
			let possibleParentInheritances = ScopeSnapshotInheritancesComponent.validatedInheritancesOfModifiableRow(row);

			// See if any of the children inherit from any of the scope snapshots in this row.
			for (const possibleParentInheritance of possibleParentInheritances) {
				const anyChildRowSnapshotsInheritFromThisParentSnapshot = _.intersection(possibleParentInheritance.directChildrenIdsLimitedToInheritancesOfTarget, childScopeSnapshotIds).length > 0;

				if (anyChildRowSnapshotsInheritFromThisParentSnapshot) {
					let currentInheritancesForScope = resultingInheritancesMap.get(row.inheritableScope.id);

					if (currentInheritancesForScope) {
						currentInheritancesForScope.push(possibleParentInheritance);
					}
					else {
						currentInheritancesForScope = [possibleParentInheritance];
					}

					// And if so, then add the parents to our resulting map of parents.
					resultingInheritancesMap.set(row.inheritableScope.id, _.uniqBy(currentInheritancesForScope, 'inheritedScopeSnapshotId'));
				}
			}
		}

		return resultingInheritancesMap;
	}

	/**
	 *
	 * @param childRow
	 * @returns Like, directParentsMapOfChildInheritances, but instead of passing in the inheritances of the child row, you pass in the child row itself.
	 */
	directParentInheritancesMapOfChildRow(childRow: ModifiableInheritanceRow): Map<number, ValidatedInheritance[]> {
		return this.directParentsMapOfChildInheritances(ScopeSnapshotInheritancesComponent.validatedInheritancesOfModifiableRow(childRow));
	}

	/**
	 *
	 * @param childInheritances
	 * @returns Like directParentsMapOfChildInheritances, but this function returns all the ancestors of the childs, including the direct parents, and the parents of the parents, and so on.
	 */
	ancestorsMapOfChildInheritances(childInheritances: ValidatedInheritance[]): Map<number, ValidatedInheritance[]> {
		const resultingInheritancesMap: Map<number, ValidatedInheritance[]> = new Map();

		// Super important base case to prevent infinite recursion. If we have traversed up the tree and we have no more parents, then we simply return an empty map.
		if (childInheritances.length === 0) {
			return resultingInheritancesMap;
		}

		const directParentInheritancesMap = this.directParentsMapOfChildInheritances(childInheritances);
		let resultingDirectParentInheritances: ValidatedInheritance[] = [];

		// Find the direct parents of the children, and add them to the resulting map.
		for (const [currParentScopeId, currDirectParentInheritances] of directParentInheritancesMap) {
			let savedInheritancesForParentScope = resultingInheritancesMap.get(currParentScopeId);

			if (savedInheritancesForParentScope) {
				savedInheritancesForParentScope = savedInheritancesForParentScope.concat(currDirectParentInheritances);
			}
			else {
				savedInheritancesForParentScope = currDirectParentInheritances;
			}
			resultingInheritancesMap.set(currParentScopeId, _.uniqBy(savedInheritancesForParentScope, 'inheritedScopeSnapshotId'));
			resultingDirectParentInheritances = _.uniqBy(resultingDirectParentInheritances.concat(currDirectParentInheritances), 'inheritedScopeSnapshotId');
		}

		// Recursively find the ancestors of the direct parents, and add them to the resulting map.
		this.ancestorsMapOfChildInheritances(resultingDirectParentInheritances).forEach((ancestorInheritances, ancestorScopeId) => {
			let savedInheritancesForAncestorScope = resultingInheritancesMap.get(ancestorScopeId);

			if (savedInheritancesForAncestorScope) {
				savedInheritancesForAncestorScope = savedInheritancesForAncestorScope.concat(ancestorInheritances);
			}
			else {
				savedInheritancesForAncestorScope = ancestorInheritances;
			}

			resultingInheritancesMap.set(ancestorScopeId, _.uniqBy(savedInheritancesForAncestorScope, 'inheritedScopeSnapshotId'));
		});

		return resultingInheritancesMap;
	}

	/**
	 *
	 * @param childRow
	 * @returns Given a child row, this function returns the validated scope snapshots of the child row. If the child row is in a conflicting state, then it returns the conflicting scope snapshots. If the child row is in a directly or indirectly inherited state, then it returns the single scope snapshot.
	 */
	static validatedInheritancesOfModifiableRow(childRow: ModifiableInheritanceRow): ValidatedInheritance[] {
		if (childRow.loaded.loadedStateType === InheritanceRowStateType.CONFLICTING_INHERITANCES) {
			return childRow.loaded.conflictingInheritances;
		}
		else if (childRow.loaded.loadedStateType === InheritanceRowStateType.INDIRECTLY_INHERITED || childRow.loaded.loadedStateType === InheritanceRowStateType.DIRECTLY_INHERITED) {
			return [childRow.loaded.loadedInheritance];
		}
		else {
			return [];
		}
	}

	ancestorInheritancesMapOfChildRow(childRow: ModifiableInheritanceRow): Map<number, ValidatedInheritance[]> {
		return this.ancestorsMapOfChildInheritances(ScopeSnapshotInheritancesComponent.validatedInheritancesOfModifiableRow(childRow));
	}

	/**
	 *
	 * @param childRow
	 * @returns Given a child row, this function returns a map of the direct parent rows of the child row, where the key is the scope id of the direct parent row and the value is the direct parent row itself.
	 */
	directParentRowsMapOfChildRow(childRow: ModifiableInheritanceRow | null): Map<number, ModifiableInheritanceRow> {
		const directParentRowsMap: Map<number, ModifiableInheritanceRow> = new Map();

		if (childRow === null) {
			for (const [scopeId, row] of this.requireModifiableInheritancesSection().inheritanceRowsMap) {
				if (row.loaded.loadedStateType === InheritanceRowStateType.DIRECTLY_INHERITED) {
					directParentRowsMap.set(scopeId, row);
				}
			}
			return directParentRowsMap;
		}
		else {
			const allInheritanceRowsMap = this.requireModifiableInheritancesSection().inheritanceRowsMap;
			const directParentScopeIds = [...this.directParentInheritancesMapOfChildRow(childRow).keys()];

			for (const scopeId of directParentScopeIds) {
				const directParentRow = allInheritanceRowsMap.get(scopeId);
				if (directParentRow !== undefined) {
					directParentRowsMap.set(scopeId, directParentRow);
				}
			}
		}

		return directParentRowsMap;
	}

	/**
	 *
	 * @param childRow
	 * @returns Like directParentRowsMapOfChildRow, but this function returns all the ancestor rows of the child row, including the direct parent rows, and the parent rows of the parent rows, and so on.
	 */
	ancestorRowsMapOfChildRow(childRow:	ModifiableInheritanceRow | null): Map<number, ModifiableInheritanceRow> {
		const ancestorRowsMap: Map<number, ModifiableInheritanceRow> = new Map();

		if (childRow === null) {
			for (const [scopeId, row] of this.requireModifiableInheritancesSection().inheritanceRowsMap) {
				if (row.loaded.loadedStateType === InheritanceRowStateType.INDIRECTLY_INHERITED || row.loaded.loadedStateType === InheritanceRowStateType.DIRECTLY_INHERITED) {
					ancestorRowsMap.set(scopeId, row);
				}
			}
			return ancestorRowsMap;
		}
		else {
			const allInheritanceRowsMap = this.requireModifiableInheritancesSection().inheritanceRowsMap;
			const ancestorScopeIds = [...this.ancestorInheritancesMapOfChildRow(childRow).keys()];

			for (const scopeId of ancestorScopeIds) {
				const ancestorRow = allInheritanceRowsMap.get(scopeId);
				if (ancestorRow !== undefined) {
					ancestorRowsMap.set(scopeId, ancestorRow);
				}
			}
		}

		return ancestorRowsMap;
	}

	/**
	 *
	 * @param childRow
	 * @returns Like ancestorRowsMapOfChildRow, but this function returns only the ancestor rows that are not direct parents of the child row.
	 */
	nonDirectAncestorRowsMapOfChildRow(childRow: ModifiableInheritanceRow | null): Map<number, ModifiableInheritanceRow> {
		const nonDirectAncestorRowsMap: Map<number, ModifiableInheritanceRow> = new Map();

		if (childRow === null) {
			for (const [scopeId, row] of this.requireModifiableInheritancesSection().inheritanceRowsMap) {
				if (row.loaded.loadedStateType === InheritanceRowStateType.INDIRECTLY_INHERITED) {
					nonDirectAncestorRowsMap.set(scopeId, row);
				}
			}
			return nonDirectAncestorRowsMap;
		}
		else {
			const ancestorRowsMap = this.ancestorRowsMapOfChildRow(childRow);
			const directParentRowsMap = this.directParentRowsMapOfChildRow(childRow);

			for (const [scopeId, ancestorRow] of ancestorRowsMap) {
				if (!directParentRowsMap.has(scopeId)) {
					nonDirectAncestorRowsMap.set(scopeId, ancestorRow);
				}
			}
		}
		return nonDirectAncestorRowsMap;
	}

	/**
	 *
	 * @param rows
	 * @returns Given an array of rows, return an array consisting of just the indices of the rows as they would appear in the UI (i.e. sorted by the standard sort function for inheritance rows).
	 */
	rowsToSortedIndices(rows: ModifiableInheritanceRow[]): number[] {
		const allInheritanceRows = this.requireModifiableInheritancesSection().inheritanceRowsMap;
		const indices: number[] = [];
		const sortedInheritanceRows = Array.from(allInheritanceRows.values()).sort((a, b) => ScopeSnapshotInheritancesComponent.inheritanceRowsSortComparator(a, b))

		for (const row of rows) {
			const index = sortedInheritanceRows.findIndex(inheritanceRow => inheritanceRow.inheritableScope.id === row.inheritableScope.id);
			if (index !== -1) {
				indices.push(index);
			}
		}
		return indices;
	}

	/**
	 *
	 * @param rowsMap
	 * @returns Just a wrapper around rowsToSortedIndices that takes a map instead of an array. Pretties up the template.
	 */
	rowsMapToSortedIndices(rowsMap: Map<number, ModifiableInheritanceRow>): number[] {
		return this.rowsToSortedIndices([...rowsMap.values()]);
	}

	private incrementModifiableOrdinalsIfConflictingWithRow(inheritanceRow: ModifiableInheritanceRow) {
		if (
			ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY === this.inheritancesSection?.stateType &&
			!!inheritanceRow.loaded.modifiable?.newlySelectedDirectParentOrdinalInput
		) {
			const avoidConflictingWithOrdinal = inheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput;

			if (avoidConflictingWithOrdinal) {
				const inheritanceRowsArray = Array.from(this.inheritancesSection.inheritanceRowsMap.values());
				// If the selected ordinal in the provided row conflicts with any of the ordinals of the other direct parents, then we need to increment the ordinals of all direct parents that have an ordinal greater than or equal to the conflicting ordinal.
				const otherInheritanceRowsOfDirectParentsWithEqualOrHigherOrdinals =_.filter(
					inheritanceRowsArray,
					otherInheritanceRow => otherInheritanceRow.templateOrdinal !== inheritanceRow.templateOrdinal &&
					!!otherInheritanceRow.loaded.modifiable?.newlySelectedDirectParentOrdinalInput && otherInheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput >= avoidConflictingWithOrdinal
				);

				const hasConflictingOrdinals = this.isConflictingParentOrdinalInModifications(inheritanceRow);

				if (hasConflictingOrdinals) {
					for (const otherInheritanceRow of otherInheritanceRowsOfDirectParentsWithEqualOrHigherOrdinals) {
						if (!!otherInheritanceRow.loaded.modifiable?.newlySelectedDirectParentOrdinalInput) {
							otherInheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput++;
						}
					}
				}
			}
		}
	}

	handleParentScopeSnapshotOrdinalChange(event: Event, modifiedRow: ModifiableInheritanceRow, newOrdinal: number) {
		if (modifiedRow.loaded.loadedStateType === InheritanceRowStateType.DIRECTLY_INHERITED && modifiedRow.loaded.modifiable?.newlySelectedDirectParent !== null) {
			const otherDirectParents: NewlySelectedDirectParentModifications[] = [];
			for (const [scopeId, row] of this.requireModifiableInheritancesSection().inheritanceRowsMap) {
				if (row.inheritableScope.id !== modifiedRow.inheritableScope.id && row.loaded.loadedStateType === InheritanceRowStateType.DIRECTLY_INHERITED && row.loaded.modifiable?.newlySelectedDirectParentOrdinalInput !== null) {
					otherDirectParents.push(row.loaded.modifiable);
				}
			}

			// Make a list of all the other direct parents (excluding the modified row), and sort them by their ordinal input.
			const sortedOtherDirectParents = otherDirectParents.sort((a, b) => a.newlySelectedDirectParentOrdinalInput - b.newlySelectedDirectParentOrdinalInput);

			// Insert the modified row into the sorted list of other direct parents at the new ordinal.
			const allSortedDirectParents = [...sortedOtherDirectParents];
			allSortedDirectParents.splice(newOrdinal - 1, 0, modifiedRow.loaded.modifiable);

			// Now that we have the modified row in the correct position, reassign the ordinal inputs of all the direct parents.
			// Note that index here is 0-based, so we add 1 to it to get the 1-based ordinal input.
			for (const [index, directParentModifications] of allSortedDirectParents.entries()) {
				directParentModifications.newlySelectedDirectParentOrdinalInput = index + 1;
			}

			const emittableModifications = this.emittableModifications();
			if (emittableModifications !== null){
				this.emitNewDirectInheritanceSelections(emittableModifications);
			}
		}
	}

	handleNewDescendantManagerInnerState(newInnerState: DescendantManagerInnerState) {
		if (this.descendantManagerState === undefined) {
			this.descendantManagerState = {
				isOpen: this.isShowingDescendantScopeSnapshots(),
				innerState: newInnerState
			};
		}
		else {
			this.descendantManagerState.innerState = newInnerState;
		}
		this.emitDescendantManagerState();
	}

	emitDescendantManagerState() {
		this.descendantManagerStateUpdate.emit(this.descendantManagerState);
	}

	setNewState(newState: InheritancesSectionStateType) {
		if (!this.inheritancesSection) {
			throw new Error("Cannot set the new state because the inheritances section is not defined.");
		}
		this.inheritancesSection.stateType = newState;
		// this.emitState(this.inheritancesSection.state);
	}

	// emitState(state: InheritancesSectionStateType) {
	// 	this.newStateEvent.emit(state);
	// }

	viewInheritancesOfAncestor(scopeSnapshot: ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment) {
		this.viewOrModifyOrDiscardSsInheritancesEvent.emit({
			ssInheritanceActionsForEachTarget: new Map([[scopeSnapshot.id, {
				actionType: "view",
				clearExistingModificationsIfPresent: false
			}]]),
			overallMergeStrategy: "only-perform-actions-on-provided-targets",
			scrollToTargetScopeSnapshot: scopeSnapshot.id
		});
	}

	emitNewDirectInheritanceSelections(newlySelectedDirectParents: ScopeSnapshotFieldsForScopeSnapshotSelectorFragment[]) {
		const targetScopeSnapshot = this.requireTargetScopeSnapshot();
		this.viewOrModifyOrDiscardSsInheritancesEvent.emit({
			ssInheritanceActionsForEachTarget: new Map([[targetScopeSnapshot.id, {
				actionType: "modify",
				directParentScopeSnapshotIds: newlySelectedDirectParents.map(scopeSnapshot => scopeSnapshot.id),
				mergeStrategy: "replace-entire-parent-list"
			}]]),
			overallMergeStrategy: "only-perform-actions-on-provided-targets",
			scrollToTargetScopeSnapshot: null
		});
	}

	private static validatedInheritancesToModifiableInheritancesSection(validatedInheritances: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number], scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[]): ModifiableInheritancesSection {

		let modifiableInheritancesSection: ModifiableInheritancesSection = {
			stateType: InheritancesSectionStateType.MODIFY,
			incompatibleFlagNodes: validatedInheritances.incompatibleFlagNodes,
			incompatibleFlagValues: validatedInheritances.incompatibleFlagValues,
			inheritanceRowsMap: new Map(),
			descendantScopeDifferences: validatedInheritances.descendantScopeDifferences,
		}

		let i = 0;
		for (const inheritanceRowDetails of validatedInheritances.ancestorScopeDifferences) {
			const isSelfInheritance = inheritanceRowDetails.validatedInheritances.some((inheritance) => inheritance.isSelf);
			if (!isSelfInheritance) {
				const inheritanceRowLoadedSection = ScopeSnapshotInheritancesComponent.newInheritanceRowLoadedSection(inheritanceRowDetails, scopeVariables);
				modifiableInheritancesSection.inheritanceRowsMap.set(inheritanceRowDetails.ancestorScope.id, {
					inheritableScope: inheritanceRowDetails.ancestorScope,
					templateOrdinal: i + 1,
					loaded: inheritanceRowLoadedSection,
				})
				i++;
			}
		}

		return modifiableInheritancesSection;
	}

	private static staticInheritancesToNonModifiableInheritancesSection(staticInheritances: StaticScopeSnapshotInheritance[], scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[]): NonModifiableInheritancesSection {
		return {
			stateType: InheritancesSectionStateType.VIEW,
			inheritanceRows: staticInheritances.map(inheritance => ({
				selectorState: {
					staticity: Staticity.STATIC_SCOPE_WITH_STATIC_VERSION,
					actionsWhitelist: [ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE],
					initialScopeSnapshot: inheritance.inheritedScopeSnapshot,
					scopeVariables: scopeVariables
				}
			}))
		}
	}

	cancelModifications() {
		this.setNewState(InheritancesSectionStateType.VIEW);
	}

	isConflictingParentOrdinalInModifications(inheritanceRow: ModifiableInheritanceRow): boolean {
		if (!this.inheritancesSection) {
			throw new Error("Cannot check for conflicting parent ordinals because the inheritances section is not defined.");
		}

		if (InheritancesSectionStateType.MODIFY !== this.inheritancesSection.stateType) {
			throw new Error("Cannot check for conflicting parent ordinals when the inheritances section is not in a modifiable state.");
		}
		// const inheritanceRow = this.inheritancesSection.inheritanceRowsMap[inheritableScopeId];
		// if (!inheritanceRow) {
		// 	throw new Error("Inheritance row not found for inheritable scope id " + inheritableScopeId);
		// }
		const selectedOrdinal = inheritanceRow.loaded.modifiable?.newlySelectedDirectParentOrdinalInput;
		if (!selectedOrdinal) {
			return false;
		}

		return _.some(Array.from(this.inheritancesSection.inheritanceRowsMap.values()), (otherInheritanceRow) => {
			return otherInheritanceRow.inheritableScope.id !== inheritanceRow.inheritableScope.id && !!otherInheritanceRow.loaded.modifiable?.newlySelectedDirectParentOrdinalInput && otherInheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput === selectedOrdinal;
		});
	}

	private static newInheritanceRowLoadedSection(
		ancestorScopeDifference: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number],
		scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[]
	): ModifiableInheritanceRow["loaded"] {

		// We use this primarily to determine the initial parent ordinal of the inheritance row, which will be used to populate
		// the value of initialParentOrdinal.
		let initialStatePropertiesOfInheritance: InheritanceRowInitialDirectInheritance | InheritanceRowInitialIndirectInheritance | InheritanceRowInitialNonInheritance;

		const savedInheritance = ancestorScopeDifference["savedInheritance"];
		const loadedScope = ScopeSnapshotService.formatInheritablesToScopeForSelector(ancestorScopeDifference.ancestorScope, ancestorScopeDifference.ancestorScopeSnapshots);

		let initialScopeSnapshot: {
			__typename: "ScopeSnapshots",
			scope: ScopeFieldsForScopeSnapshotSelectorFragment;
			id: number;
			scopeId: number;
			scopeVersion: string;
			createdAt: string;
		} | null = null;

		// if (inheritance && inheritance.isDirectInheritance) {
		if (savedInheritance) {
			const inheritedScopeSnapshot = ancestorScopeDifference.ancestorScopeSnapshots.find(inheritableScopeSnapshot => inheritableScopeSnapshot.id === savedInheritance.inheritedScopeSnapshotId);
			if (!inheritedScopeSnapshot) {
				throw new Error("Could not find the inherited scope snapshot for the existing inheritance.");
			}

			initialScopeSnapshot = {
				...inheritedScopeSnapshot,
				__typename: "ScopeSnapshots",
				scope: loadedScope,
			};

			if (savedInheritance.isDirectInheritance) {
				initialStatePropertiesOfInheritance = {
					initialStateType: InheritanceRowStateType.DIRECTLY_INHERITED,
					initialScopeSnapshot: _.omit(initialScopeSnapshot, '__typename'),
					initialParentOrdinal: savedInheritance.ordinalWithinDirectInheritances
				}
			}
			else {
				initialStatePropertiesOfInheritance = {
					initialStateType: InheritanceRowStateType.INDIRECTLY_INHERITED,
					initialScopeSnapshot: _.omit(initialScopeSnapshot, '__typename'),
				}
			}
		}
		else {
			initialStatePropertiesOfInheritance = {
				initialStateType: InheritanceRowStateType.NOT_INHERITED,
			}
		}

		const newInheritances = ancestorScopeDifference["validatedInheritances"];
		const firstNewInheritance = newInheritances?.[0];

		if (!firstNewInheritance) {
			// If we have no inheritance, then we will display the scope as not inherited.
			const loadedStateType = InheritanceRowStateType.NOT_INHERITED;

			return {
				loadedStateType: loadedStateType,
				modifiable: {
					newlySelectedDirectParentOrdinalInput: null,
					newlySelectedDirectParent: null
				},
				...initialStatePropertiesOfInheritance,
				selectorState: {
					staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION,
					actionsWhitelist: [ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE],
					// initialScope: loadedScope,
					...(
						initialScopeSnapshot ? {
							initialScopeSnapshot: initialScopeSnapshot,
							modifications: {
								state: ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
								selectedScopeSnapshotId: null
							}
						} :
						{
							initialScope: loadedScope
						}
					),
					scopeVariables: scopeVariables
				}
			}
		}
		else {
			const duplicateNewInheritance = newInheritances[1];
			const remainingDuplicateNewInheritances = newInheritances.slice(2);

			// If we have a duplicate inheritance, then we will display the duplicate inheritance as a conflicting inheritance.
			if (duplicateNewInheritance) {
				const loadedStateType = InheritanceRowStateType.CONFLICTING_INHERITANCES;

				if (firstNewInheritance.isSelf !== false || duplicateNewInheritance.isSelf !== false) {
					throw new Error("Cannot use a self reference inheritance to generate the modifiable inheritances section.");
				}

				return {
					loadedStateType: loadedStateType,
					...initialStatePropertiesOfInheritance,
					//...initialStateAndParentOrdinal,
					conflictingInheritances: [
						firstNewInheritance, duplicateNewInheritance, ...remainingDuplicateNewInheritances
					],
					selectorState: {
						staticity: Staticity.STATIC_SCOPE_WITH_STATIC_VERSION,
						actionsWhitelist: [],
						initialScope: loadedScope,
						scopeVariables: scopeVariables
					}
				}
			}
			// If we have a single inheritance, then we will display the inheritance as either a direct parent or an inherited parent.
			else {
				const loadedScopeSnapshot = ScopeSnapshotService.formatInheritablesToScopeSnapshotForSelector(firstNewInheritance.inheritedScopeSnapshotId, ancestorScopeDifference.ancestorScope, ancestorScopeDifference.ancestorScopeSnapshots)

				// If the inheritance is a direct parent, then we will display it as a direct parent.
				if (firstNewInheritance.isDirectInheritance) {
					const loadedStateType = InheritanceRowStateType.DIRECTLY_INHERITED;

					return {
						loadedStateType: loadedStateType,
						loadedParentOrdinal: firstNewInheritance.ordinalWithinDirectInheritances,
						loadedInheritance: firstNewInheritance,

						modifiable: {
							newlySelectedDirectParentOrdinalInput: firstNewInheritance.ordinalWithinDirectInheritances,
							newlySelectedDirectParent: loadedScopeSnapshot,
						},
						...initialStatePropertiesOfInheritance,
						selectorState: {
							staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION,
							actionsWhitelist: [ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE],
							...(initialScopeSnapshot ? {initialScopeSnapshot: initialScopeSnapshot} : {initialScope: loadedScope}),
							modifications: {
								state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
								selectedScopeSnapshotId: loadedScopeSnapshot.id
							},
							scopeVariables: scopeVariables
						}
					}
				}
				// If the inheritance is not a direct parent, then we will display it as an inherited parent.
				else {
					const loadedStateType = InheritanceRowStateType.INDIRECTLY_INHERITED;

					return {
						loadedStateType: loadedStateType,
						loadedInheritance: firstNewInheritance,

						...initialStatePropertiesOfInheritance,
						selectorState: {
							staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION,
							actionsWhitelist: [ScopeSnapshotSelectorActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE],
							...(initialScopeSnapshot ? {initialScopeSnapshot: initialScopeSnapshot} : {initialScope: loadedScope}),
							modifications: {
								state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
								selectedScopeSnapshotId: loadedScopeSnapshot.id
							},
							scopeVariables: scopeVariables
						}
					}
				}
			}

		}
	}

	// rowIndex is 0-based
	static inheritanceTriangleSvgPoints(rowIndex: number): string {
		const consts = ScopeSnapshotInheritancesComponent.inheritanceDiagramConstants;
		const middleOfRowY = ScopeSnapshotInheritancesComponent.inheritanceDiagramMiddleOfRowY(rowIndex);

		return `${consts.leftMarginForParentTriangle}                        ${middleOfRowY},
						${consts.leftMarginForParentTriangle + consts.triangleWidth} ${middleOfRowY - 7},
						${consts.leftMarginForParentTriangle + consts.triangleWidth} ${middleOfRowY + 7}`;
	}

	static inheritanceDiagramTopOfRowY(rowIndex: number, isBaseRow = false): number {
		const consts = ScopeSnapshotInheritancesComponent.inheritanceDiagramConstants;
		let top = (consts.headingHeight + consts.rowGap) + (consts.rowGap + consts.rowHeight) * rowIndex;

		if (isBaseRow) {
			top += consts.rowGap + consts.baseRowBorderTopHeight;
		}

		return top;
	}

	static inheritanceDiagramMiddleOfRowY(rowIndex: number, isBaseRow = false): number {
		return this.inheritanceDiagramTopOfRowY(rowIndex, isBaseRow) + ScopeSnapshotInheritancesComponent.inheritanceDiagramConstants.rowHeight / 2;
	}

	static inheritanceDiagramBottomOfRowY(rowIndex: number, isBaseRow = false): number {
		return this.inheritanceDiagramTopOfRowY(rowIndex, isBaseRow) + ScopeSnapshotInheritancesComponent.inheritanceDiagramConstants.rowHeight;
	}

	static isInheritanceRowChanged(inheritanceRow: ModifiableInheritanceRow): boolean {
		const isStateDifferent = inheritanceRow.loaded.initialStateType !== inheritanceRow.loaded.loadedStateType;
		const isParentOrdinalDifferent = inheritanceRow.loaded.initialParentOrdinal !== inheritanceRow.loaded.loadedParentOrdinal;
		const isScopeSnapshotDifferent = inheritanceRow.loaded.loadedStateType === ScopeSnapshotInheritancesComponent.inheritanceRowStateTypes.CONFLICTING_INHERITANCES || inheritanceRow.loaded.initialScopeSnapshot?.id !== inheritanceRow.loaded.loadedInheritance?.inheritedScopeSnapshotId;

		return isStateDifferent || isParentOrdinalDifferent || isScopeSnapshotDifferent;
	}

	newlySelectedDirectParentOrdinalInput(modifiableInheritanceRow: ModifiableInheritanceRow): number | null {
		if (
				(
					modifiableInheritanceRow.loaded.loadedStateType === ScopeSnapshotInheritancesComponent.inheritanceRowStateTypes.DIRECTLY_INHERITED ||
					modifiableInheritanceRow.loaded.loadedStateType === ScopeSnapshotInheritancesComponent.inheritanceRowStateTypes.NOT_INHERITED
				) &&
				modifiableInheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput !== undefined
			) {
			return modifiableInheritanceRow.loaded.modifiable.newlySelectedDirectParentOrdinalInput;
		}

		return null;
	}

	isShowingOrdinalSelectorColumn(): boolean {
		if (this.inheritancesSection?.stateType === InheritancesSectionStateType.MODIFY) {
			for (const [scopeId, inheritanceRow] of this.inheritancesSection.inheritanceRowsMap) {
				if (this.newlySelectedDirectParentOrdinalInput(inheritanceRow) !== null) {
					return true;
				}
			}
		}
		return false;
	}

	viewOrModifyOrDiscardSsInheritances(viewOrModifyOrDiscardSsInheritances: ViewOrModifyOrDiscardSsInheritancesAction) {
		this.viewOrModifyOrDiscardSsInheritancesEvent.emit(viewOrModifyOrDiscardSsInheritances);
	}

	countDescendantScopeSnapshots(inheritancesSection: ModifiableInheritancesSection): number {
		return _.sum(inheritancesSection.descendantScopeDifferences.map(difference => difference.descendantScopeSnapshots.length));
	}

	isShowingDescendantScopeSnapshots(): boolean {
		// console.log("this.descendantManagerState", this.descendantManagerState);
		return this.descendantManagerState?.isOpen === true;
	}

	showDescendantScopeSnapshots() {
		if (this.descendantManagerState !== undefined) {
			this.descendantManagerState.isOpen = true;
		}
		else {
			this.descendantManagerState = {
				isOpen: true, // This will result in the modifiable descendant inheritances component being displayed through an ngIf in the template
				innerState: undefined // This will result in the modifiable descendant inheritances component initializing its state with its own default values. See the ngOnInit() of modifiable-descendant-inheritances to see this behavior.
			};
		}
		this.cd.detectChanges(); // Needed because we're modifying an inner property
	}

	hideDescendantScopeSnapshots() {
		if (this.descendantManagerState?.isOpen === true && this.descendantManagerState?.innerState !== undefined) {
			this.descendantManagerState.isOpen = false;
			this.cd.detectChanges(); // Needed because we're modifying an inner property
			this.descendantManagerStateUpdate.emit({isOpen: false, innerState: this.descendantManagerState.innerState});
		}
	}

	discardAncestorModificationsAndDescendantManagerState() {
		const targetScopeSnapshot = this.requireTargetScopeSnapshot();
		this.viewOrModifyOrDiscardSsInheritancesEvent.emit({
			ssInheritanceActionsForEachTarget: new Map([[targetScopeSnapshot.id, {
				actionType: "discard"
			}]]),
			overallMergeStrategy: "only-perform-actions-on-provided-targets",
			scrollToTargetScopeSnapshot: null
		});
	}

	modifiedAncestorsCount(): number {
		const inheritancesSection = this.inheritancesSection;
		if (inheritancesSection?.stateType === ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY) {
			return [...inheritancesSection.inheritanceRowsMap.values()].filter(inheritanceRow => ScopeSnapshotInheritancesComponent.isInheritanceRowChanged(inheritanceRow)).length;
		}
		return 0;
	}

	modifiedDescendantsCount(): number {
		const inheritancesSection = this.inheritancesSection;
		if (inheritancesSection?.stateType === ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY) {
			const allDescendantScopeSnapshots = _.flatMap(inheritancesSection.descendantScopeDifferences, (difference) => difference.descendantScopeSnapshots);
			return allDescendantScopeSnapshots.filter(descendantScopeSnapshot => ScopeSnapshotModifiableDescendantInheritancesComponent.isInheritanceRowChanged(descendantScopeSnapshot)).length;
		}
		return 0;
	}

	hasErrors(): boolean {
		const inheritancesSection = this.inheritancesSection;
		if (inheritancesSection?.stateType === ScopeSnapshotInheritancesComponent.inheritancesSectionStateTypes.MODIFY) {
			return inheritancesSection.incompatibleFlagNodes.length > 0 || inheritancesSection.incompatibleFlagValues.length > 0;
		}
		return false;
	}
}

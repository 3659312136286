import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/app-common/common.service';
import { CrudStateService, GqlRequestInfo, gqlRequestInfo } from 'src/app/crud-state.service';
import { FlagValueImportOption, ImportJsonIntoScopeSnapshotMutation, ImportJsonIntoScopeSnapshotMutationVariables, ScopeSnapshots } from 'src/generated/graphql';
import { TypedJSON } from 'typesafe-json';

// enum ImportJsonEmittableEvent {
// 	CANCEL = 'CANCEL',
// 	IMPORTED = 'IMPORTED'
// }

type ScopeSnapshot = Pick<ScopeSnapshots, 'id' | 'scopeId' | 'scopeVersion'>;

type ComponentGqlRequestInfos = {
	queries: {
	},
	mutations: {
		importJsonIntoScopeSnapshot: GqlRequestInfo<ImportJsonIntoScopeSnapshotMutation, ImportJsonIntoScopeSnapshotMutationVariables>
	},
	fragments: {
	},
};

@Component({
  selector: 'app-import-json[scope-snapshot]',
  templateUrl: './import-json.component.html',
  styleUrls: ['./import-json.component.scss']
})
export class ImportJsonComponent implements OnDestroy {
	@Input('scope-snapshot') scopeSnapshot?: ScopeSnapshot;
	@Output('imported') importedEvent: EventEmitter<void> = new EventEmitter();
	@Output('cancel') cancelEvent: EventEmitter<void> = new EventEmitter();

	flagValueImportOptions = [
		FlagValueImportOption.OnlyIfResultingInValueChange,
		FlagValueImportOption.OnlyIfNoExistingValueAtAll,
		FlagValueImportOption.OnlyIfNoExistingValueInCurrentScopeSnapshot,
		FlagValueImportOption.OverrideExistingValue,
		FlagValueImportOption.Skip
	];

	versionLabel = CommonService.friendlyVersionLabel;

	showingAdvancedOptions = false;

	constructor(private apollo: Apollo, private commonService: CommonService, private messageService: MessageService) {}

	gqlRequestInfos: ComponentGqlRequestInfos = {
		queries: {},
		mutations: {
			importJsonIntoScopeSnapshot: gqlRequestInfo(gql`
				mutation importJsonIntoScopeSnapshot(
					$importIntoScopeSnapshotId: Int!,
					$importJson: jsonb!,
					$flagValueImportOption: FlagValueImportOption!) {
					importJsonIntoScopeSnapshot(importIntoScopeSnapshotId: $importIntoScopeSnapshotId,
					importJson: $importJson,
					flagValueImportOption: $flagValueImportOption
				) {
						importedFlagNodesCount
						importedFlagValuesCount
					}
				}
			`),
		},
		fragments: {}
	}

	importForm: FormGroup<{
		json: FormControl<string>,
		flagValueImportOption: FormControl<FlagValueImportOption>
	}> = new FormGroup({
		json: new FormControl<string>(
			"{}",
			{
				nonNullable: true,
				validators: [
					Validators.required,
					(formControl: AbstractControl) => {
						if (this.jsonInputToJsonObject(formControl.value) !== undefined) {
							return null;
						}
						else {
							return {invalidJson: true};
						}
					}
				]
			}
		),
		flagValueImportOption: new FormControl<FlagValueImportOption>(
			FlagValueImportOption.OnlyIfResultingInValueChange,
			{nonNullable: true, validators: [Validators.required]}
		)
	});

	flagValueImportOptionLabel(flagValueImportOption: FlagValueImportOption): string {
		switch (flagValueImportOption) {
			case FlagValueImportOption.OnlyIfResultingInValueChange:
				return `Import <span class="emphasize">only</span> those flag values that would alter the resulting raw configuration. This option intelligently skips importing those flag values where an equal inherited one is already in use, thus leaving the inherited scope snapshots in control whenever possible.`;
			case FlagValueImportOption.OverrideExistingValue:
				return `Import <span class="emphasize">all</span> flag values, replacing any existing flag values in the current scope snapshot when present, and overriding any inherited flag values when present.`;
			case FlagValueImportOption.OnlyIfNoExistingValueInCurrentScopeSnapshot:
				return `Import flag values <span class="emphasize">only</span> in cases where there is <span class="emphasize">no</span> existing flag value in the <span class="emphasize">current</span> scope snapshot, leaving all existing flag values in the current scope snapshot alone, while overriding any inherited flag values when present.`;
			case FlagValueImportOption.OnlyIfNoExistingValueAtAll:
				return `Import flag values <span class="emphasize">only</span> in cases where there is <span class="emphasize">no</span> existing flag value <span class="emphasize">at all</span>, neither in an inherited scope snapshot nor in the current scope snapshot.`;
			case FlagValueImportOption.Skip:
				return `Do not import any flag values. Simply create flags as needed.`;
			default:
				return flagValueImportOption;
		};
	}

	jsonInputToJsonObject(jsonInput: string): {[key: string]: any;} | undefined {
		return TypedJSON.parse(jsonInput).object();
	}

	cancelImport() {
		this.cancelEvent.emit();
	}

	requireScopeSnapshot(): ScopeSnapshot {
		if (this.scopeSnapshot === undefined) {
			throw new Error(`Scope snapshot is undefined.`);
		}
		return this.scopeSnapshot;
	}

	importJson() {
		if (this.importForm.valid) {
			this.importForm.value.flagValueImportOption
			const jsonInput = this.importForm.value.json;
			if (jsonInput === undefined) {
				throw new Error(`JSON input is undefined.`);
			}

			const parsedJsonObject = this.jsonInputToJsonObject(jsonInput);
			if (parsedJsonObject === undefined) {
				throw new Error(`JSON input is not an object.`);
			}

			const flagValueImportOption = this.importForm.value.flagValueImportOption;
			if (flagValueImportOption === undefined) {
				throw new Error(`Flag value import option is undefined.`);
			}

			const importJsonIntoScopeSnapshotInfo = this.gqlRequestInfos.mutations.importJsonIntoScopeSnapshot;
			importJsonIntoScopeSnapshotInfo.subscription?.unsubscribe();
			importJsonIntoScopeSnapshotInfo.subscription = this.apollo.mutate({
				mutation: importJsonIntoScopeSnapshotInfo.gql,
				variables: {
					importIntoScopeSnapshotId: this.requireScopeSnapshot().id,
					importJson: parsedJsonObject,
					flagValueImportOption: flagValueImportOption
				}
			}).subscribe({
				next: ({ data }) => {
					let successMessage = '';
					if (data?.importJsonIntoScopeSnapshot) {
						successMessage = `${data.importJsonIntoScopeSnapshot.importedFlagNodesCount} flag node${(data.importJsonIntoScopeSnapshot.importedFlagNodesCount !== 1 ? 's' : '')} and ${data.importJsonIntoScopeSnapshot.importedFlagValuesCount} flag value${(data.importJsonIntoScopeSnapshot.importedFlagValuesCount !== 1 ? 's' : '')} were imported.`
					}
					this.messageService.add({ severity: 'success', summary: 'Imported JSON', detail: successMessage, life: 10000 });
					this.importedEvent.emit();
				},
				error: (error) => this.commonService.mutationErrorHandler(error)
			});
		}
	}

	ngOnDestroy(){
		CrudStateService.unsubscribeFromGqlSubscriptions(this.gqlRequestInfos);
	}

	// isValidJson() {
	// 	try {
	// 		JSON.parse(this.importForm.value.json);

	// setNewState(newState: ImportJsonSectionState) {
	// 	this.importJsonSection.state = newState;
	// 	this.emitState();
	// }

	// emitState() {
	// 	this.newStateEvent.emit(this.importJsonSection.state);
	// }

}

import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ScopeFieldsForScopeSnapshotSelectorFragment, ScopeFieldsForScopeSnapshotSelectorFragmentDoc, ScopeSnapshotFieldsForScopeSnapshotSelectorFragment, ScopeByOptionIdsQuery, ScopeByOptionIdsQueryVariables, ScopeVariableFieldsForScopeSnapshotSelectorFragment, ReplicateScopeSnapshotAsNewVersionMutation, ReplicateScopeSnapshotAsNewVersionMutationVariables, CreateSnapshotInExistingScopeMutation, CreateSnapshotInExistingScopeMutationVariables, CreateSnapshotWithNewScopeMutation, CreateSnapshotWithNewScopeMutationVariables, ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc, UpdateScopeDefinitionMutation, UpdateScopeDefinitionMutationVariables, ScopeByOptionIdsAndScopeUpdateValidatablesQuery, ScopeByOptionIdsAndScopeUpdateValidatablesQueryVariables, ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment, ScopeFieldsForStaticScopeSnapshotSelectorFragment, ReplicateFlagsAndValuesMutation, ReplicateFlagsAndValuesMutationVariables } from 'src/generated/graphql';
import _, { forEach, initial } from 'lodash';
import { CrudStateService, GqlRequestInfo, gqlRequestInfo } from 'src/app/crud-state.service';
import { CommonService } from 'src/app/app-common/common.service';
import { Subject } from 'rxjs';
import { CompletedScopeDefinitionMap, EssentialValidatableScopeContainment, ScopeDefinitionMap, ScopeSnapshotService } from 'src/app/scope-snapshot.service';
import type { XOR } from 'ts-xor'
import { ApolloQueryResult } from '@apollo/client/core';
import { MessageService, TooltipOptions } from 'primeng/api';
import { DeepOmit } from '@apollo/client/utilities';

type CleanScopeSnapshotFieldsForScopeSnapshotSelectorFragment = DeepOmit<ScopeSnapshotFieldsForScopeSnapshotSelectorFragment, '__typename' | 'scope.__typename' | 'scope.comparableScopeDefiners.__typename' | 'scope.comparableScopeDefiners.scopeVariable.__typename' | 'scope.comparableScopeDefiners.scopeOption.__typename'>;

type CleanScopeFieldsForStaticScopeSnapshotSelectorFragment = DeepOmit<ScopeFieldsForStaticScopeSnapshotSelectorFragment, '__typename' | 'comparableScopeDefiners.__typename' | 'comparableScopeDefiners.scopeVariable.__typename' | 'comparableScopeDefiners.scopeOption.__typename'>;

type CleanScopeFieldsForScopeSnapshotSelectorFragment = DeepOmit<ScopeFieldsForScopeSnapshotSelectorFragment, '__typename' | 'comparableScopeDefiners.__typename' | 'comparableScopeDefiners.scopeVariable.__typename' | 'comparableScopeDefiners.scopeOption.__typename' | 'scopeSnapshots.__typename'>;

type CleanScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment = DeepOmit<ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment, '__typename' | 'scope.__typename' | 'scope.comparableScopeDefiners.__typename' | 'scope.comparableScopeDefiners.scopeVariable.__typename' | 'scope.comparableScopeDefiners.scopeOption.__typename'>;

type ScopeSnapshotWithOtherSnapshotsInSameScope = CleanScopeSnapshotFieldsForScopeSnapshotSelectorFragment;
type ScopeWithSnapshots = CleanScopeFieldsForScopeSnapshotSelectorFragment;
type StaticScopeSnapshot = CleanScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment;
type StaticScope = CleanScopeFieldsForStaticScopeSnapshotSelectorFragment;

type PrimitiveScopeSnapshot = {
	id: number,
	scopeId: number,
	scopeVersion: string,
	createdAt: string,
};

type ComponentGqlRequestInfos = {
	queries: {
		scopeByOptionIds: GqlRequestInfo<ScopeByOptionIdsQuery, ScopeByOptionIdsQueryVariables>,
		scopeByOptionIdsAndScopeUpdateValidatables: GqlRequestInfo<ScopeByOptionIdsAndScopeUpdateValidatablesQuery, ScopeByOptionIdsAndScopeUpdateValidatablesQueryVariables>,
	},
	mutations: {
		replicateScopeSnapshotAsNewVersion: GqlRequestInfo<ReplicateScopeSnapshotAsNewVersionMutation, ReplicateScopeSnapshotAsNewVersionMutationVariables>,
		// replicateFlagsAndValues: GqlRequestInfo<ReplicateFlagsAndValuesMutation, ReplicateFlagsAndValuesMutationVariables>,
		replicateFlagsAndValues: GqlRequestInfo<ReplicateFlagsAndValuesMutation, ReplicateFlagsAndValuesMutationVariables>,
		createSnapshotInExistingScope: GqlRequestInfo<CreateSnapshotInExistingScopeMutation, CreateSnapshotInExistingScopeMutationVariables>,
		createSnapshotWithNewScope: GqlRequestInfo<CreateSnapshotWithNewScopeMutation, CreateSnapshotWithNewScopeMutationVariables>,
		updateScopeDefinition: GqlRequestInfo<UpdateScopeDefinitionMutation, UpdateScopeDefinitionMutationVariables>,
	}
};

type OnChangeCheckboxEvent = {
	checked?: boolean,
	originalEvent?: Event
};

export enum Staticity {
	// Static scope snapshot selector. You can't select scope options nor select a scope snapshot.
	STATIC_SCOPE_WITH_STATIC_VERSION,
	// Partially static scope snapshot selector. You can't select scope options but you can select a scope snapshot.
	STATIC_SCOPE_WITH_SELECTABLE_VERSION,
	// Partially static scope snapshot selector. You can't select scope options but you can select a scope snapshot and also enter a new scope version.
	STATIC_SCOPE_WITH_INPUTTABLE_VERSION,
	// Partially static scope snapshot selector. You can select scope options but you cannot select a scope snapshot nor enter a new scope version.
	SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT,
	// Fully selectable scope snapshot selector. You can select scope options and a scope snapshot but you cannot enter a new scope version.
	SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION,
	// Fully selectable and inputtable scope snapshot selector. You can select scope options and a scope snapshot. You can also enter a new scope version.
	SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION,
}

export enum ModificationState {
	PENDING_SELECTION_OF_SCOPE_OPTIONS,
	SELECTED_SCOPE_OPTIONS_AND_SEARCHING_FOR_SCOPE,
	SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED,
	SELECTED_SCOPE_WITH_NO_VERSION_INPUT,
	SELECTED_SCOPE_WITH_INPUTTABLE_VERSION,
	SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
	SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
	SELECTING_FLAGS_AND_VALUES_TO_REPLICATE,
}

// type StaticityWithModifications = NonModifiableStaticity | StaticityWithSnapshotModifications | StaticityWithScopeAndSnapshotModifications;
type StateCommon = {
	scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[],
	actionsWhitelist: ActionType[]
}

// **************************************************
// *                                                *
// *   STATE FOR STATIC SCOPE WITH STATIC VERSION   *
// *                                                *
// **************************************************
// If both the scope section and scope snapshot of the selector are static, then you must provide either a scope or scope snapshot (which includes a scope embedded inside) to initialize it. No modifications are allowed since the selector is completely static.
export type StaticScopeWithStaticSnapshotSelectorState =
	StateCommon
	&
	{
		readonly staticity: Staticity.STATIC_SCOPE_WITH_STATIC_VERSION;
	}
	&
	(
		XOR<
			{
				initialScope: StaticScope,
			}
			,
			{
				initialScopeSnapshot: StaticScopeSnapshot
			}
		>
	);


// ********************************************************************************************************
// *                                                                                                      *
// *   STATES FOR (1) STATIC SCOPE WITH SELECTABLE VERSION AND (2) STATIC SCOPE WITH INPUTTABLE VERSION   *
// *                                                                                                      *
// ********************************************************************************************************
// With a static scope definition that allows you to select a scope snapshot or input a new version, you must provide a scope or scope snapshot (which includes a scope embedded inside) to initialize it. The allowed modifications consist of selecting a scope snapshot or entering a new scope version, as you would expect.
export type StaticScopeWithModifiableVersionSelectorState =
	StateCommon
	&
	(
		XOR<
			{
				readonly staticity: Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION,
				modifications?:
					XOR<
						(
							XOR<
								{
									state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN
								}
								,
								{
									state: ModificationState.SELECTING_FLAGS_AND_VALUES_TO_REPLICATE,
									isSaving: boolean,
								}
							>
						)
						&
						{
							// We only provide the selected scope snapshot id here, since the scope is already embedded inside of the initializer. This helps avoid the possibility of selecting a scope snapshot that doesn't belong to the scope that the selector was initialized with.
							readonly selectedScopeSnapshotId: number
						}
						,
						{
							state: ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
							readonly selectedScopeSnapshotId: null
						}
					>
			}
			,
			{
				readonly staticity: Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION,
				modifications?:

					XOR<
						{
							state: ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION,
							isSaving: boolean,
							// Allow modifying the scope version in this case since the staticity allows it.
							versionInput: string // Consider using a FormControl instead of a string here.
						}
						,
						(
							XOR<
								{
									state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN
								}
								,
								{
									state: ModificationState.SELECTING_FLAGS_AND_VALUES_TO_REPLICATE,
									isSaving: boolean,
								}
							>
						)
						&
						{
							readonly selectedScopeSnapshotId: number
						}
					>
			}
		>
	)
	&
	// Require a scope or scope snapshot (which includes a scope embedded inside) to initialize it.
	(
		XOR<
			{
				initialScope: ScopeSnapshotWithOtherSnapshotsInSameScope["scope"],
			}
			,
			{
				initialScopeSnapshot: ScopeSnapshotWithOtherSnapshotsInSameScope,
			}
		>
	);

// **************************************************
// *                                                *
// *   STATE FOR SELECTABLE SCOPE WITH NO VERSION   *
// *                                                *
// **************************************************

// Helper type to be used for the modifications section when the staticity is either SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT or SELECTED_SCOPE_WITH_SELECTABLE_VERSION.
type ModificationsForSelectableScopeCommon =
	XOR<
		{
			state: ModificationState.PENDING_SELECTION_OF_SCOPE_OPTIONS,
			// Partially or fully selected scope options, where one or more scope variables may or may not have any scope options selected yet. Do not allow loading a scope yet because it's not guaranteed that the scope options are fully selected here.
			readonly scopeDefinitionMap: ScopeDefinitionMap,
			isSearchingForScope: false,
		}
	,
	XOR<
	{
		state: ModificationState.SELECTED_SCOPE_OPTIONS_AND_SEARCHING_FOR_SCOPE,
		// Fully selected scope options, where all scope variables have a set of scope options selected (remember a set can be empty, which means we would be implicitly selecting the 'All' option for that scope variable). At this point, we load a scope along with its snapshots.
		readonly scopeDefinitionMap: CompletedScopeDefinitionMap,
		// If the scope options are fully defined, you must be searching for the scope that's defined by those scope options. If none is found, then we go back to the previous state where isSearchingForScope is false and scopeDefinitionMap can be either partially or fully defined.
		isSearchingForScope: true,
	},
	{
		state: ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED,
		// In this case, we are allowing the user to modify a scope's definition. So, the selected scope options must be fully defined, and we also provide an array that allows us to verify that the new scope definition does not cause any invalid inheritances.
		readonly scopeDefinitionMap: CompletedScopeDefinitionMap,
		validatableScopeContainments?: EssentialValidatableScopeContainment[],
	}
>>;

export type SelectableScopeWithNoVersionSelectorState =
	StateCommon
	&
	(
		{
			readonly staticity: Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT,
			modifications?: XOR<
				ModificationsForSelectableScopeCommon,
				{
					state: ModificationState.SELECTED_SCOPE_WITH_NO_VERSION_INPUT,
					readonly selectedScope: StaticScope,
				}
			>
		}
	)
	&
	// For this staticity where you can fully select a scope but not select a scope snapshot, you can initialize it with: nothing passed in, a set of scope options, or a scope. And, since you won't be selecting a scope snapshot, passing in a scope does not need to include the other snapshots in the same scope (hence, why we use StaticScope here and not ScopeWithSnapshots).
	(
		XOR<
			{}
		,
		XOR<
			{readonly initialComparableScopeDefiners: StaticScope["comparableScopeDefiners"]}
		,
			{initialScope: StaticScope}
		>>
	);

// **********************************************************
// *                                                        *
// *   STATE FOR SELECTABLE SCOPE WITH SELECTABLE VERSION   *
// *                                                        *
// **********************************************************

// Helper type to be used for the modifications section of a state with staticity of Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION where you can select both a scope definition and a scope snapshot.
type ModificationsForSelectableScopeWithSelectableVersion =
	XOR<ModificationsForSelectableScopeCommon
	,
	XOR<
	{
		state: ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
		// Once the scope is found, assign it here and allow selecting a scope snapshot (if allowed by the whitelisted actions).
		readonly selectedScope: ScopeSnapshotWithOtherSnapshotsInSameScope["scope"],
	}
	,
	(
		XOR<
			{
				state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN
			}
			,
			{
				state: ModificationState.SELECTING_FLAGS_AND_VALUES_TO_REPLICATE,
				isSaving: boolean,
			}
		>
	)
	&
	{
		// Once a scope snapshot is selected, assign it here. Remember, the scope and it's other snapshots are embedded inside this selected scope snapshot as selectedScopeSnapshot.scope.
		// So, by not including the selectedScope here, we haven't lost the scope and we haven't lost the other snapshots.
		readonly selectedScopeSnapshot: ScopeSnapshotWithOtherSnapshotsInSameScope,
	}
	>>;

// With a staticity that lets you select both a scope definition and a scope snapshot, you can pretty much initialize it with anything you want: nothing passed in, a set of scope options that partially defines a scope, a scope, or a scope snapshot.
// As for modifications, here you are allowed to select its scope options as well. And once a scope is selected, meaning a scope was found after searching for one using your selected scope options, or you're using the one that the selector was initialized with, you can then select a snapshot from that selected scope.
export type SelectableScopeWithSelectableVersionSelectorState =
	StateCommon
	&
	(
		{
			readonly staticity: Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION,
			modifications?: ModificationsForSelectableScopeWithSelectableVersion
		}
	)
	&
	// As mentioned above for these staticities where you can select both a scope definition and a scope snapshot, you can pretty much initialize it with anything you want - nothing passed in, a set of scope options, a scope, or a scope snapshot.
	(
		XOR<
			{}
		,
		XOR<
			{readonly initialComparableScopeDefiners: ScopeSnapshotWithOtherSnapshotsInSameScope["scope"]["comparableScopeDefiners"]}
		,
		XOR<
			{initialScopeSnapshot: ScopeSnapshotWithOtherSnapshotsInSameScope}
		,
			{initialScope: ScopeSnapshotWithOtherSnapshotsInSameScope["scope"],}
		>>>
	);

// **********************************************************
// *                                                        *
// *   STATE FOR SELECTABLE SCOPE WITH INPUTTABLE VERSION   *
// *                                                        *
// **********************************************************

// Helper type to be used for the modifications section of a state with staticity of Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION where you can select a scope definition and input a scope version. Or create a new scope along with your inputted scope version.
// This is very similar to the above ModificationsForSelectableScopeWithSelectableVersion type, and thus, it builds upon it such that the only additions here are that you can input a new scope version along with your selected scope or scope options that fully define a new scope.
type ModificationsForSelectableScopeWithInputtableVersion =
	XOR<
		// Reuse the same modifications that are used when the staticity is SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION, except for the modifications where the state is SELECTED_SCOPE_WITH_INPUTTABLE_VERSION or SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED. This is because we are going to redo those modification states down below, since they are a little different for this staticity (SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION).
		Exclude<Exclude<ModificationsForSelectableScopeWithSelectableVersion, {state: ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION}>, {state: ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED}>
	,
	XOR<
		// Here, we also allow the user to input a new scope version for the selected scope.
		{
			state: ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION,
			isSaving: boolean,
			readonly selectedScope: ScopeSnapshotWithOtherSnapshotsInSameScope["scope"],
			versionInput: string // Consider using a FormControl instead of a string here.
		}
	,
		// And, we also allow the user to input a new scope version for a brand new scope.
		{
			state: ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED,
			isSaving: boolean,
			// Fully defined scope options, where all scope variables have a set of scope options selected. Allow the user to input a new scope version for this brand new scope defined by these scope options.
			readonly scopeDefinitionMap: CompletedScopeDefinitionMap,
			validatableScopeContainments?: EssentialValidatableScopeContainment[],
			versionInput: string // Consider using a FormControl instead of a string here.
		}
	>>;

// With a staticity where you are allowed to select both a scope definition and input a new version, it is very similar to the above, where you can select both a scope definition and a scope snapshot. The only difference here is that you can also input a new scope version.
export type SelectableScopeWithInputtableVersionSelectorState =
	StateCommon
	&
	(
		{
			readonly staticity: Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION,
			modifications?: ModificationsForSelectableScopeWithInputtableVersion
		}
		&
		(
			XOR<
				{}
			,
			XOR<
				{initialComparableScopeDefiners: ScopeSnapshotWithOtherSnapshotsInSameScope["scope"]["comparableScopeDefiners"],}
			,
			XOR<
				{initialScopeSnapshot: ScopeSnapshotWithOtherSnapshotsInSameScope,}
			,
				{initialScope: ScopeSnapshotWithOtherSnapshotsInSameScope["scope"],}
			>>>
		)
	);

type SelectableScopeWithModifiableVersionSelectorState = XOR<SelectableScopeWithSelectableVersionSelectorState, SelectableScopeWithInputtableVersionSelectorState>;

type StateWithModifiableVersion = XOR<StaticScopeWithModifiableVersionSelectorState, SelectableScopeWithModifiableVersionSelectorState>;

export type ModifiableState = XOR<StaticScopeWithModifiableVersionSelectorState, XOR<SelectableScopeWithNoVersionSelectorState, SelectableScopeWithModifiableVersionSelectorState>>;
export type State = XOR<StaticScopeWithStaticSnapshotSelectorState, ModifiableState>;

export enum ActionType {
	GO_TO_SNAPSHOT_IN_SAME_SCOPE,
	GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE,
	REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION,
	ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
	EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
	CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
	CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE,
	CREATE_BLANK_SNAPSHOT_IN_DIFFERENT_SCOPE,
	CREATE_BLANK_SNAPSHOT_WITH_NEW_SCOPE,
	UPDATE_SCOPE_DEFINITION,
	VIEW_INHERITANCES_OF_NEWLY_SELECTED_SCOPE_SNAPSHOT,
	RESET_TO_INITIAL_STATE
};

type ActionBaseCommon = {
	defaultButtonLabel: string,
	type: ActionType,
	isPrimary: boolean, // Indicates if this is the primary action which should be displayed as blue (or whatever the primary color is), rather than gray.
}

export type ReplicateScopeSnapshotAsNewVersionActionBase = Extract<ActionBase, {type: ActionType.REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION}>;

export type CherryPickReplicateActionBase = Extract<ActionBase, {type: ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}>;

export type ActionBase =
	ActionBaseCommon &
	(
		// XOR<
		{
			type: ActionType.REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION,
			isPrimary: boolean,
			defaultButtonLabel: string,
			replicateScopeSnapshot: ScopeSnapshotWithOtherSnapshotsInSameScope,
			newVersion: string,
			inputs?: {
				newDirectlyInheritedScopeSnapshotIds: number[]
			}
		}
		|
		{
			type: ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION
			isPrimary: boolean,
			defaultButtonLabel: string,
			replicateScopeSnapshot: StaticScopeSnapshot,
			destinationScopeSnapshot: StaticScopeSnapshot
		}
		|
		{
			type: ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION
			isPrimary: boolean,
			defaultButtonLabel: string,
		}
		|
		{
			type: ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
			isPrimary: boolean,
			defaultButtonLabel: string,
			replicateScopeSnapshot: StaticScopeSnapshot,
			destinationScopeSnapshot: StaticScopeSnapshot
			inputs?: {
				flagsOfScopeFlagNodeIds: number[],
				valuesOfScopeFlagNodeIds: number[]
			}
		}
		|
		{
			type: ActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE,
			destinationScopeSnapshot: StaticScopeSnapshot
		}
		|
		{
			type: ActionType.GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE,
			destinationScopeSnapshot: StaticScopeSnapshot
		}
		|
		{
			type: ActionType.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE,
			scope: StaticScope,
			newVersion: string
		}
		|
		{
			type: ActionType.CREATE_BLANK_SNAPSHOT_IN_DIFFERENT_SCOPE,
			destinationScope: StaticScope,
			newVersion: string
		}
		|
		{
			type: ActionType.CREATE_BLANK_SNAPSHOT_WITH_NEW_SCOPE,
			scopeOptionIdsByVariable: CompletedScopeDefinitionMap
			newVersion: string
		}
		|
		{
			type: ActionType.UPDATE_SCOPE_DEFINITION,
			scope: StaticScope,
			replaceWithScopeOptions: ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number][],
		}
		|
		{
			type: ActionType.VIEW_INHERITANCES_OF_NEWLY_SELECTED_SCOPE_SNAPSHOT,
			selectedScopeSnapshot: StaticScopeSnapshot
		}
		|
		{
			type: ActionType.RESET_TO_INITIAL_STATE,
		}
	);


export type Action = Required<ActionBase>; // Specifically, make the inputs property required.

// export type Actions = {
// 	actionSet: ActionSet,
// 	labels: ButtonLabelFns
// };

export enum SelectorStateChangerOptionType {
	RESET_TO_INITIAL_STATE,
	// SELECT_VERSION
}

export type SelectorStateChangerOption =
{
	type: SelectorStateChangerOptionType.RESET_TO_INITIAL_STATE,
}

export enum ErrorPreventingActionCode {
	CONFLICT_WITH_EXISTING_SCOPE,
	INCOMPATIBLE_INHERITANCES
}

export type ErrorPreventingAction = {
	code: ErrorPreventingActionCode.INCOMPATIBLE_INHERITANCES,
	summary: string,
	allIncompatibleInheritances: EssentialValidatableScopeContainment[],
	incompatibleChildSnapshotInheritances: EssentialValidatableScopeContainment[],
	incompatibleChildScopeInheritances: EssentialValidatableScopeContainment[],
	incompatibleParentSnapshotInheritances: EssentialValidatableScopeContainment[],
	incompatibleParentScopeInheritances: EssentialValidatableScopeContainment[],
	isExpandable: true,
	// detail?: string,
} | {
	code: ErrorPreventingActionCode.CONFLICT_WITH_EXISTING_SCOPE,
	summary: string,
	isExpandable?: false,
}

export type SsSelectorTooltipDetails = {
	scopeVariableDropdowns: string | undefined | {
		id: number,
		message: string | undefined
	}[],
	snapshotDropdown: string | undefined
}
@Component({
	selector: 'app-scope-snapshot-selector[state]',
	templateUrl: './scope-snapshot-selector.component.html',
	styleUrls: ['./scope-snapshot-selector.component.scss']
})
export class ScopeSnapshotSelectorComponent implements OnInit, OnDestroy {
	@Input('state') state?: State;
	@Input('actioner') actioner?: Subject<Action>;
	@Input('styles-for-spanning-across-parent-grid') stylesForSpanningAcrossParentGrid?: {
		'first-dropdown'?: {
			'grid-column-start'?: string,
			'grid-row-start'?: string,
		},
	};
	@Input('z-index-for-dropdowns') ZIndexForDropdowns?: number;
	@Input('show-diffs') showDiffs: boolean = false;
	@Input('draw-snapshot-to-scope-connectors') drawSnapshotToScopeConnectors: false | {
		areMoreSnapshotsAbove: boolean,
		areMoreSnapshotsBelow: boolean,
		distanceFromSnapshotToMidlinePx: number,
	} = false;
	@Input('disable-scope-search') disableScopeSearch: boolean = false;
	@Input('tooltip') tooltip?: SsSelectorTooltipDetails;
	@Input('scope-options-filter') scopeOptionsFilter?: CompletedScopeDefinitionMap;
	strokeWidthForScopeToSnapshotConnector = 1;

	@Output('state-update') stateUpdate: EventEmitter<State> = new EventEmitter();

	typedState = (state: State) => state;
	typedScopeVariable = (scopeVariable: ScopeVariableFieldsForScopeSnapshotSelectorFragment) => scopeVariable;

	Staticity = Staticity;
	self = ScopeSnapshotSelectorComponent;
	hideSnapshotsDropdown = false;

	gqlRequestInfos: ComponentGqlRequestInfos	= {
		queries: {
			scopeByOptionIds: {
				subscription: null,
			 	gql: gql`
					${ScopeFieldsForScopeSnapshotSelectorFragmentDoc}
					query scopeByOptionIds($scopeOptionIds: jsonb!) {
						comparableScopeDefinitions(where: {scopeIdentifyingDefinitionJsonb: {_containedIn: $scopeOptionIds, _contains: $scopeOptionIds}}) {
							scopeId
							scope {
								...scopeFieldsForScopeSnapshotSelector
							}
						}
					}
				`
			},
			scopeByOptionIdsAndScopeUpdateValidatables: {
				subscription: null,
			 	gql: gql`
					${ScopeFieldsForScopeSnapshotSelectorFragmentDoc}
					query scopeByOptionIdsAndScopeUpdateValidatables($scopeOptionIds: jsonb!) {
						comparableScopeDefinitions(where: {scopeIdentifyingDefinitionJsonb: {_containedIn: $scopeOptionIds, _contains: $scopeOptionIds}}) {
							scopeId
							scope {
								...scopeFieldsForScopeSnapshotSelector
							}
						}
						validatableScopeContainments {
							id

							scopeId
							containableScopeDefinition
							scopeSnapshotId

							directlyInheritedScopeId
							containableDirectlyInheritedScopeDefinition
							directlyInheritedScopeSnapshotId

							ordinal

							scopeSnapshot {
								scopeVersion
								createdAt
							}
							directlyInheritedScopeSnapshot {
								scopeVersion
								createdAt
							}
						}
					}
				`
			}
		},
		mutations: {
			replicateScopeSnapshotAsNewVersion: gqlRequestInfo(gql`
				${ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc}
				mutation replicateScopeSnapshotAsNewVersion(
					$replicateScopeSnapshotId: Int!,
					$newDirectlyInheritedScopeSnapshotIds: [Int!]!,
					$newScopeVersion: String!
				) {

					replicateScopeSnapshot(
						replicateScopeSnapshotId: $replicateScopeSnapshotId,
						newDirectlyInheritedScopeSnapshotIds: $newDirectlyInheritedScopeSnapshotIds,
						newScopeVersion: $newScopeVersion
					) {
						id
						scopeId
						scopeVersion
						createdAt
						scopeSnapshot {
							...scopeSnapshotFieldsForScopeSnapshotSelector
						}
					}
				}
			`),
			replicateFlagsAndValues: gqlRequestInfo(gql`
				${ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc}
				mutation replicateFlagsAndValues(
					$replicateFromScopeSnapshotId: Int!,
					$replicateFlagsOfScopeFlagNodeIds: [Int!]!,
					$replicateValuesOfScopeFlagNodeIds: [Int!]!,
					$destinationScopeSnapshotId: Int!
				) {

					replicateFlagsAndValues(
						replicateFromScopeSnapshotId: $replicateFromScopeSnapshotId,
						replicateFlagsOfScopeFlagNodeIds: $replicateFlagsOfScopeFlagNodeIds,
						replicateValuesOfScopeFlagNodeIds: $replicateValuesOfScopeFlagNodeIds,
						destinationScopeSnapshotId: $destinationScopeSnapshotId
					) {
						replicatedFlagsCount
						replicatedValuesCount
					}
				}
			`),

			createSnapshotWithNewScope: gqlRequestInfo(gql`
				${ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc}
				mutation createSnapshotWithNewScope($scopeDefiners: [ScopeDefinersInsertInput!]!, $scopeVersion: String!) {
					insertScopeSnapshotsOne(object: {
						scopeVersion: $scopeVersion,
						scope: {data: {scopeDefiners: {data: $scopeDefiners}}}
					}) {
						...scopeSnapshotFieldsForScopeSnapshotSelector
					}
				}
			`),
			createSnapshotInExistingScope: gqlRequestInfo(gql`
				${ScopeSnapshotFieldsForScopeSnapshotSelectorFragmentDoc}
				mutation createSnapshotInExistingScope($scopeId: Int!, $scopeVersion: String!) {
					insertScopeSnapshotsOne(object: {
						scopeId: $scopeId,
						scopeVersion: $scopeVersion
					}) {
						...scopeSnapshotFieldsForScopeSnapshotSelector
					}
				}
			`),
			updateScopeDefinition: gqlRequestInfo(gql`
				${ScopeFieldsForScopeSnapshotSelectorFragmentDoc}
				mutation updateScopeDefinition($scopeId: Int! $replaceWithScopeDefiners: [ScopeDefinersInsertInput!]!) {
  				deleteScopeDefiners(where: {scopeId: {_eq: $scopeId}}) {
						returning {
							id
							scope {
								...scopeFieldsForScopeSnapshotSelector
							}
						}
					}

					insertScopeDefiners(objects: $replaceWithScopeDefiners) {
						returning {
							id
							scope {
								...scopeFieldsForScopeSnapshotSelector
							}
						}
					}
				}
			`)
		}
	}

	versionLabel = CommonService.friendlyVersionLabel;
	ScopeSnapshotService = ScopeSnapshotService;

	scopeWithSnapshotsCache: Map<number, ScopeSnapshotWithOtherSnapshotsInSameScope["scope"]> = new Map();

	constructor(private apollo: Apollo, private commonService: CommonService, private messageService: MessageService, private ssService: ScopeSnapshotService, private cd: ChangeDetectorRef) {}

	private requireState(): State {
		if (!this.state){
			throw new Error("state is undefined");
		}
		return this.state;
	}

	private requireStateWithSelectableScope(): SelectableScopeWithNoVersionSelectorState | SelectableScopeWithSelectableVersionSelectorState | SelectableScopeWithModifiableVersionSelectorState {
		const state = this.requireState();
		if (state.staticity !== Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT && state.staticity !== Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION && state.staticity !== Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) {
			throw new Error("state does not have a selectable scope");
		}
		return state;
	}

	private requireStateWithModifiableVersion(): StaticScopeWithModifiableVersionSelectorState | SelectableScopeWithModifiableVersionSelectorState {
		const state = this.requireState();
		if (state.staticity !== Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION && state.staticity !== Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION && state.staticity !== Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION && state.staticity !== Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) {
			throw new Error("state does not have a selectable version");
		}
		return state;
	}

	private requireScopeVariables(): ScopeVariableFieldsForScopeSnapshotSelectorFragment[] {
		const state = this.requireState();
		return state.scopeVariables;
	}

	ngOnDestroy(): void {
		CrudStateService.unsubscribeFromGqlSubscriptions(this.gqlRequestInfos);
		this.actioner?.unsubscribe();
	}

	ngOnInit() {
		if (this.actioner){
			this.actioner.subscribe(action => {
				switch (action.type) {
					case ActionType.RESET_TO_INITIAL_STATE:
						this.resetState();
						break;
					case ActionType.REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION:
						this.replicateScopeSnapshotAsNewVersion(action);
						break;
					case ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION:
						this.enterSelectFlagsAndValuesToReplicateMode();
						break;
					case ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION:
						this.replicateFlagsAndValuesIntoSelectedVersion(action);
						break;
					case ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION:
						this.exitSelectFlagsAndValuesToReplicateMode();
						break;
					case ActionType.CREATE_BLANK_SNAPSHOT_IN_DIFFERENT_SCOPE:
					case ActionType.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE:
						this.createSnapshotInExistingScope(action);
						break;
					case ActionType.CREATE_BLANK_SNAPSHOT_WITH_NEW_SCOPE:
						this.createSnapshotWithNewScope(action);
						break;
					case ActionType.UPDATE_SCOPE_DEFINITION:
						this.updateScopeDefinition(action);
						break;
				}
			});
		}
		// The scope snapshot inheritances component relies on the initial state being emitted on first load.
		this.emitStateUpdates();
	}

	resetState() {
		const state = this.requireState();
		if ("modifications" in state){
			delete state.modifications;
		}
		this.emitStateUpdates();
	}

	ngStyleForScopeOptionsDropdown(scopeVariableIndex: number): { [key: string]: string | undefined } {
		const ngStyle: { [key: string]: string | undefined} = {};

		if (this.ZIndexForDropdowns !== undefined) {
			ngStyle['z-index'] = this.ZIndexForDropdowns.toString();
		}
		if (this.stylesForSpanningAcrossParentGrid !== undefined) {
			const firstDropdownStyles = this.stylesForSpanningAcrossParentGrid['first-dropdown'];
			if (scopeVariableIndex === 0 && firstDropdownStyles !== undefined) {
				ngStyle['grid-column-start'] = firstDropdownStyles['grid-column-start'];
				ngStyle['grid-row-start'] = firstDropdownStyles['grid-row-start'];
			}
		}
		return ngStyle;
	}

	scopeVariableById(id: ScopeVariableFieldsForScopeSnapshotSelectorFragment["id"]): ScopeVariableFieldsForScopeSnapshotSelectorFragment {
		const scopeVariables = this.requireScopeVariables();
		const scopeVariable = scopeVariables.find(scopeVariable => scopeVariable.id === id);
		if (scopeVariable === undefined){
			throw new Error("scopeVariable is undefined");
		}
		return scopeVariable;
	}

	scopeOptionById(id: ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number]["id"]): ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number] {
		for (const scopeVariable of this.requireScopeVariables()) {
			const scopeOption = scopeVariable.scopeOptions.find(scopeOption => scopeOption.id === id);
			if (scopeOption !== undefined){
				return scopeOption;
			}
		}
		throw new Error(`Scope option ${id} not found`);
	}

	scopeOptionsByIds(ids: Set<ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number]["id"]>): ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number][] {
		const scopeOptions: ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number][] = [];
		for (const scopeVariable of this.requireScopeVariables()) {
			for (const scopeOption of scopeVariable.scopeOptions) {
				if (ids.has(scopeOption.id)) {
					scopeOptions.push(scopeOption);
				}
			}
		}
		return scopeOptions;
	}

	scopeOptionValuesByIds(ids: Set<ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number]["id"]>): string[] {
		return this.scopeOptionsByIds(ids).map(scopeOption => scopeOption.value);
	}

	private emitStateUpdates() {
		this.stateUpdate.emit(this.state);
		this.cd.detectChanges();
	};

	private handleResponseDataForSelectedScopeDefinition(newScopes: ScopeFieldsForScopeSnapshotSelectorFragment[], currentState: SelectableScopeWithNoVersionSelectorState | SelectableScopeWithSelectableVersionSelectorState | SelectableScopeWithModifiableVersionSelectorState, originalValidatableScopeContainments?: EssentialValidatableScopeContainment[]): void {
		// const newScopes = comparableScopeDefinitions.map(comparableScopeDefinition => comparableScopeDefinition.scope);

		// Note that newScopes may be empty if there are no scopes that match the selected scope definition.
		ScopeSnapshotSelectorComponent.updateStateWithNewScopes(currentState, newScopes);

		// If after trying to update the state with the new scopes, we still don't have a selected scope, then depending on the staticity one of two things should happen:
		// 1. If the staticity is SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION, then we should enable the version input to allow the user to create a new scope with the selected scope options, along with a snapshot.
		// 2. If the staticity is SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION or SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT, then we should simply set isSearchingForScope to false, which will let the user continue selecting scope options and searching for a scope.
		if (currentState.modifications?.state === ModificationState.SELECTED_SCOPE_OPTIONS_AND_SEARCHING_FOR_SCOPE) {

			if (currentState.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) {
				currentState.modifications = {
					state: ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED,
					isSaving: false,
					scopeDefinitionMap: currentState.modifications.scopeDefinitionMap,
					validatableScopeContainments: originalValidatableScopeContainments,
					versionInput: ""
				}
			}
			else {
				currentState.modifications = {
					state: ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED,
					scopeDefinitionMap: currentState.modifications.scopeDefinitionMap,
					validatableScopeContainments: originalValidatableScopeContainments,
				}
			}
		}
		this.emitStateUpdates();
	}

	private handleScopeDefinitionStateChanges(): void {
		// If the scope definition has changed, unsubscribe from the previous subscription because it is no longer relevant to our new scope definition.
		this.gqlRequestInfos.queries.scopeByOptionIds.subscription?.unsubscribe();
		this.gqlRequestInfos.queries.scopeByOptionIdsAndScopeUpdateValidatables.subscription?.unsubscribe();

		const currentState = this.requireStateWithSelectableScope();

		const completedScopeDefinitionMap = ScopeSnapshotSelectorComponent.completedScopeDefinitionMap(currentState);
		if (completedScopeDefinitionMap !== undefined) {
			if (!this.disableScopeSearch){
				// At this point, we know that the scope is fully defined, so we can search for scope snapshots for the selected scope.
				currentState.modifications = {
					state: ModificationState.SELECTED_SCOPE_OPTIONS_AND_SEARCHING_FOR_SCOPE,
					scopeDefinitionMap: completedScopeDefinitionMap,
					isSearchingForScope: true
				}
				this.emitStateUpdates();

				const fullySelectedScopeOptionIds = ScopeSnapshotService.completedScopeDefinitionMapToOptionIds(completedScopeDefinitionMap);

				const gqlVariables = {
					scopeOptionIds: [...fullySelectedScopeOptionIds]
				};

				const errorHandler = (error: any) => {
					this.commonService.queryErrorHandler(error);
					const currentState = this.requireStateWithSelectableScope();
					if (currentState.modifications?.scopeDefinitionMap){
						currentState.modifications = {
							state: ModificationState.PENDING_SELECTION_OF_SCOPE_OPTIONS,
							scopeDefinitionMap: currentState.modifications.scopeDefinitionMap,
							isSearchingForScope: false
						}
					}
					this.emitStateUpdates();
				};

				if (currentState.actionsWhitelist.includes(ActionType.UPDATE_SCOPE_DEFINITION)) {
					this.gqlRequestInfos.queries.scopeByOptionIdsAndScopeUpdateValidatables.subscription = this.apollo.watchQuery({
						query: this.gqlRequestInfos.queries.scopeByOptionIdsAndScopeUpdateValidatables.gql,
						variables: gqlVariables,
					}).valueChanges.subscribe({
						next: (data) => {
							this.handleResponseDataForSelectedScopeDefinition(data.data.comparableScopeDefinitions.map((csd) => csd.scope), currentState, data.data.validatableScopeContainments);
						},
						error: errorHandler,
					});
				}
				else {
					this.gqlRequestInfos.queries.scopeByOptionIds.subscription = this.apollo.watchQuery({
						query: this.gqlRequestInfos.queries.scopeByOptionIds.gql,
						variables: gqlVariables,
					}).valueChanges.subscribe({
						next: (data) => {
							this.handleResponseDataForSelectedScopeDefinition(data.data.comparableScopeDefinitions.map((csd) => csd.scope), currentState);
						},
						error: errorHandler,
					});
				}
			}
			else {
				currentState.modifications = {
					state: ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED,
					scopeDefinitionMap: completedScopeDefinitionMap,
				}
				this.emitStateUpdates();
			}
		}
		else {
			// At this point, we know that the scope is not fully defined yet, so just emit the state update events since this function was called to handle a state change.
			this.emitStateUpdates();
		}
	}

	// private newStateScopeSnapshotByVersion(): NonNullable<ScopeSnapshotWithOtherSnapshotsInSameScope["scope"]>["scopeSnapshots"][number] | null {
	// 	const version = this.newState.version;
	// 	if (version !== undefined) {
	// 		const scopeSnapshot = this.newState.loadedScopeWithSnapshots?.scopeSnapshots?.find(scopeSnapshot => scopeSnapshot.scopeVersion === version);
	// 		if (scopeSnapshot !== undefined) {
	// 			return scopeSnapshot;
	// 		}
	// 	}
	// 	return null;
	// }

	clickAllScopeOption(event: OnChangeCheckboxEvent, scopeVariableId: ScopeVariableFieldsForScopeSnapshotSelectorFragment["id"]): void {
		const state = this.requireStateWithSelectableScope();

		const newModifications: {
			state: ModificationState.PENDING_SELECTION_OF_SCOPE_OPTIONS,
			isSearchingForScope: false,
			scopeDefinitionMap: ScopeDefinitionMap
		} = {
			state: ModificationState.PENDING_SELECTION_OF_SCOPE_OPTIONS,
			scopeDefinitionMap: state.modifications?.scopeDefinitionMap === undefined ? ScopeSnapshotSelectorComponent.scopeDefinitionMap(state) : state.modifications.scopeDefinitionMap,
			isSearchingForScope: false
		}

		const isAllScopeOptionSelected = event.checked;

		if (isAllScopeOptionSelected) {
			if (newModifications.scopeDefinitionMap.get(scopeVariableId) === undefined) {
				newModifications.scopeDefinitionMap.set(scopeVariableId, new Set());
			}
			else {
				newModifications.scopeDefinitionMap.get(scopeVariableId)?.clear();
			}
		}
		else {
			newModifications.scopeDefinitionMap.set(scopeVariableId, undefined);
		}

		state.modifications = newModifications;

		this.handleScopeDefinitionStateChanges();
	}

	clickScopeOption(event: OnChangeCheckboxEvent, scopeOptionId: ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number]["id"]): void {
		const state = this.requireStateWithSelectableScope();

		const newModifications: {
			state: ModificationState.PENDING_SELECTION_OF_SCOPE_OPTIONS,
			isSearchingForScope: false,
			scopeDefinitionMap: ScopeDefinitionMap
		} = {
			state: ModificationState.PENDING_SELECTION_OF_SCOPE_OPTIONS,
			isSearchingForScope: false,
			scopeDefinitionMap: state.modifications?.scopeDefinitionMap === undefined ? ScopeSnapshotSelectorComponent.scopeDefinitionMap(state) : state.modifications.scopeDefinitionMap
		}

		const isCheckboxChecked = event.checked;

		const scopeOption = this.scopeOptionById(scopeOptionId);

		if (isCheckboxChecked) {
			const scopeOptionIds = newModifications.scopeDefinitionMap.get(scopeOption.scopeVariableId);
			if (scopeOptionIds === undefined) {
				newModifications.scopeDefinitionMap.set(scopeOption.scopeVariableId, new Set([scopeOptionId]));
			}
			else {
				scopeOptionIds.add(scopeOptionId);
			}
		}
		else {
			const scopeOptionIds = newModifications.scopeDefinitionMap.get(scopeOption.scopeVariableId);
			if (scopeOptionIds !== undefined) {
				scopeOptionIds.delete(scopeOptionId);
				if (scopeOptionIds.size === 0) {
					// If there are no more scope options selected for this scope variable, then set the scope variable's scope option ids to undefined to indicate that no scope options are selected for this scope variable, not even the 'All' option (which would be the case if we left it as an empty set).
					newModifications.scopeDefinitionMap.set(scopeOption.scopeVariableId, undefined);
				}
			}
		}

		state.modifications = newModifications;

		this.handleScopeDefinitionStateChanges();
	}

	handleClickDeselectScopeSnapshotInDropdown(): void {
		const state = this.requireStateWithModifiableVersion();

		const selectedScope = ScopeSnapshotSelectorComponent.selectedScopeWithSnapshots(state);

		if (!selectedScope) {
			// This should never really even be possible in the UI, but we'll throw an error just in case.
			throw new Error("There is no selected scope to default to when trying to deselect the selected scope snapshot");
		}
		else {
			state.modifications = {
				state: ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
				selectedScope: selectedScope,
			}
			this.emitStateUpdates();
		}
	}

	handleClickScopeSnapshotInDropdown(clickedScopeSnapshot: ScopeFieldsForScopeSnapshotSelectorFragment["scopeSnapshots"][number]): void {
		const state = this.requireStateWithModifiableVersion();
		const selectedScope = ScopeSnapshotSelectorComponent.selectedScopeWithSnapshots(state);
		if (!selectedScope || selectedScope.id !== clickedScopeSnapshot.scopeId) {
			// This should never really even be possible in the UI, but we'll throw an error just in case.
			throw new Error("Either there is no selected scope, or the scope snapshot you attempted to select does not match the currently selected scope");
		}

		const currentlySelectedScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(state);

		if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) {
			if (currentlySelectedScopeSnapshot && currentlySelectedScopeSnapshot.id === clickedScopeSnapshot.id) {
				state.modifications = {
					state: ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
					selectedScope: selectedScope,
				}
			}
			else {
				state.modifications = {
					state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
					selectedScopeSnapshot: {
						...clickedScopeSnapshot, // TODO Test to ensure that this newly clicked scope snapshot gets updated with a new scopeVersion when the user updates the scope's version
						scope: selectedScope,
					}
				}

				// Close the snapshots dropdown after clicking on a scope snapshot. This makes it easier for the user to see the action buttons.
				this.hideSnapshotsDropdown = true;
				this.cd.detectChanges();
				setTimeout(() => {
					this.hideSnapshotsDropdown = false;
					this.cd.detectChanges();
				}, 0);
			}


			this.emitStateUpdates();
		}
		else if (state.staticity === Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) {
			if (currentlySelectedScopeSnapshot && currentlySelectedScopeSnapshot.id === clickedScopeSnapshot.id) {
				state.modifications = undefined;
			}
			else {
				state.modifications = {
					state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
					selectedScopeSnapshotId: clickedScopeSnapshot.id,
				}

				// Close the snapshots dropdown after clicking on a scope snapshot. This makes it easier for the user to see the action buttons.
				this.hideSnapshotsDropdown = true;
				this.cd.detectChanges();
				setTimeout(() => {
					this.hideSnapshotsDropdown = false;
					this.cd.detectChanges();
				}, 0);
			}

			this.emitStateUpdates();
		}
	}

	enterScopeVersionInputMode(): void {
		const state = this.requireStateWithModifiableVersion();
		if (
			state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION ||
			state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION
		) {
			const selectedScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(state);
			const scopeVersionInput = selectedScopeSnapshot?.scopeVersion ?? "";

			if (state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) {
				state.modifications = {
					state: ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION,
					isSaving: false,
					versionInput: scopeVersionInput,
				};
			}
			else {
				const selectedScope = ScopeSnapshotSelectorComponent.selectedScopeWithSnapshots(state);
				if (!selectedScope) {
					throw new Error("Cannot enter scope version input mode because there is no selected scope");
				}
				state.modifications = {
					state: ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION,
					isSaving: false,
					versionInput: scopeVersionInput,
					selectedScope: selectedScope
				};
			}

			this.emitStateUpdates();
		}
		else {
			throw new Error("Cannot enter scope version input mode because the staticity does not allow for it");
		}
	}

	leaveScopeVersionInputMode(): void {
		const state = this.requireStateWithModifiableVersion();
		if (
			(
				state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION
			) &&
			state.modifications?.versionInput !== undefined
		) {
			// Get the selected scope snapshot according to the version input field, if any.
			const selectedScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshotWithOtherSnapshotsInSameScope(state);
			const selectedScope = ScopeSnapshotSelectorComponent.selectedScopeWithSnapshots(state);

			// If the scope is static but you can input a version, exit the version input mode properly.
			if (state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) {
				// If the inputted version results in selecting an actual scope snapshot, then exit the version input mode but have the scope snapshot selected in the dropdown.
				if (selectedScopeSnapshot) {
					state.modifications = {
						state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
						selectedScopeSnapshotId: selectedScopeSnapshot.id,
					}
				}
				// Otherwise, make the dropdown deselected. It should say "Select a scope snapshot" in the UI.
				else {
					state.modifications = undefined;
				}
			}
			// If the scope is selectable and you can input a version, exit the version input mode properly.
			else {
				// If the inputted version results in selecting an actual scope snapshot, then select it.
				if (selectedScopeSnapshot) {
					state.modifications = {
						state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
						selectedScopeSnapshot: selectedScopeSnapshot,
					}
				}
				// Otherwise, go back to simply having a selected scope. It should say "Select a scope snapshot" in the UI.
				else if (selectedScope){
					state.modifications = {
						state: ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
						selectedScope: selectedScope
					}
				}
				else {
					// We could probably avoid having to throw this runtime error by using narrowing, but probably not worth the effort right now. This should never really happen unless there is a bug in the function selectedScope.
					throw new Error("Cannot leave scope version input mode because there is no selected scope");
				}
			}
			this.emitStateUpdates();
		}
		else {
			throw new Error("Cannot leave scope version input mode because the staticity is not consistent with an inputtable version");
		}
	}

	static isSaving(state: State): boolean {
		return ScopeSnapshotSelectorComponent.isSavingScopeSnapshot(state) || ScopeSnapshotSelectorComponent.isReplicatingFlagsAndValues(state);
	}

	static isSavingScopeSnapshot(state: State): boolean {
		if (
			state.modifications &&
			(
				(
					state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION &&
					(
						state.modifications.state === ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION ||
						state.modifications.state === ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED
					)
				) ||
				(
					state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION &&
					state.modifications.state === ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION
				)
			)
		) {
			return state.modifications.isSaving;
		}
		return false;
	}

	static isReplicatingFlagsAndValues(state: State): boolean {
		if (
			state.modifications &&
			(
				(
					state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION ||
					state.staticity === Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION ||
					state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION ||
					state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION
				) &&
				state.modifications.state === ModificationState.SELECTING_FLAGS_AND_VALUES_TO_REPLICATE
			)
		) {
			return state.modifications.isSaving;
		}
		return false;
	}

	private setIsSavingIfApplicable(isSaving: boolean): void {
		const state = this.requireState();
		if (
			// Set isSaving when you are in the process of creating a new scope snapshot.
			(
				(state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION || state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) &&
				state.modifications &&
				(
					state.modifications.state === ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION ||
					state.modifications.state === ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED
				)
			)
				||
			// OR, set isSaving when you are in the process of replicating flags and values.
			(
				(state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION || state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) &&
				state.modifications &&
				state.modifications.state === ModificationState.SELECTING_FLAGS_AND_VALUES_TO_REPLICATE
			)
		) {
			state.modifications.isSaving = isSaving;
		}
	}

	enterSelectFlagsAndValuesToReplicateMode(): void {
		const state = this.requireStateWithModifiableVersion();
		const initialScopeSnapshot = ScopeSnapshotSelectorComponent.initialScopeSnapshot(state);
		const selectedScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshotWithOtherSnapshotsInSameScope(state);

		if (initialScopeSnapshot && selectedScopeSnapshot && initialScopeSnapshot.scope.id === selectedScopeSnapshot.scope.id && initialScopeSnapshot.id !== selectedScopeSnapshot.id) {
			if (state.staticity === Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) {
				state.modifications = {
					state: ModificationState.SELECTING_FLAGS_AND_VALUES_TO_REPLICATE,
					isSaving: false,
					selectedScopeSnapshotId: selectedScopeSnapshot.id,
				}
			}
			else if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) {
				state.modifications = {
					state: ModificationState.SELECTING_FLAGS_AND_VALUES_TO_REPLICATE,
					isSaving: false,
					selectedScopeSnapshot: selectedScopeSnapshot,
				}
			}
			this.emitStateUpdates();
		}
	}

	replicateFlagsAndValuesIntoSelectedVersion(action: Extract<Action, {type: ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}>) {
		const replicateFlagsAndValuesInfo = this.gqlRequestInfos.mutations.replicateFlagsAndValues;

		replicateFlagsAndValuesInfo.subscription?.unsubscribe();

		this.setIsSavingIfApplicable(true);
		replicateFlagsAndValuesInfo.subscription = this.apollo.mutate({
			mutation: replicateFlagsAndValuesInfo.gql,
			variables: {
				replicateFromScopeSnapshotId: action.replicateScopeSnapshot.id,
				replicateFlagsOfScopeFlagNodeIds: action.inputs.flagsOfScopeFlagNodeIds,
				replicateValuesOfScopeFlagNodeIds: action.inputs.valuesOfScopeFlagNodeIds,
				destinationScopeSnapshotId: action.destinationScopeSnapshot.id,
			}
		}).subscribe({
			next: ({ data }) => {
				this.setIsSavingIfApplicable(false);

				const replicatedFlagsCount = data?.replicateFlagsAndValues?.replicatedFlagsCount;
				const replicatedValuesCount = data?.replicateFlagsAndValues?.replicatedValuesCount;

				if (replicatedFlagsCount === undefined || replicatedValuesCount === undefined) {
					this.commonService.mutationErrorHandler(new Error("replicatedFlagsCount or replicatedValuesCount is undefined"));
				}
				else {
					const message = `Replicated ${replicatedFlagsCount} flag${replicatedFlagsCount === 1 ? '' : 's'} and ${replicatedValuesCount} value${replicatedValuesCount === 1 ? '' : 's'} from ${this.versionLabel(action.replicateScopeSnapshot.scopeVersion)} into ${this.versionLabel(action.destinationScopeSnapshot.scopeVersion)}.`;

					this.messageService.add({ severity: 'success', summary: 'Flags/Values Replicated', detail: message, life: 10000 });

					this.exitSelectFlagsAndValuesToReplicateMode();
				}
			},
			error: (error) => {
				this.setIsSavingIfApplicable(false);
				this.commonService.mutationErrorHandler(error);
			}
		});
	}

	exitSelectFlagsAndValuesToReplicateMode(): void {
		const state = this.requireStateWithModifiableVersion();
		const selectedScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshotWithOtherSnapshotsInSameScope(state);

		if (selectedScopeSnapshot) {
			if (state.staticity === Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) {
				state.modifications = {
					state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
					selectedScopeSnapshotId: selectedScopeSnapshot.id,
				}
			}
			else if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) {
				state.modifications = {
					state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
					selectedScopeSnapshot: selectedScopeSnapshot,
				}
			}
			this.emitStateUpdates();
		}
	}

	replicateScopeSnapshotAsNewVersion(action: Required<ReplicateScopeSnapshotAsNewVersionActionBase>) {
		const replicateScopeSnapshotInfo = this.gqlRequestInfos.mutations.replicateScopeSnapshotAsNewVersion;

		replicateScopeSnapshotInfo.subscription?.unsubscribe();

		this.setIsSavingIfApplicable(true);
		replicateScopeSnapshotInfo.subscription = this.apollo.mutate({
			mutation: replicateScopeSnapshotInfo.gql,
			variables: {
				replicateScopeSnapshotId: action.replicateScopeSnapshot.id,
				newDirectlyInheritedScopeSnapshotIds: action.inputs.newDirectlyInheritedScopeSnapshotIds,
				newScopeVersion: action.newVersion
			}
		}).subscribe({
			next: ({ data }) => {
				this.setIsSavingIfApplicable(false);

				this.messageService.add({ severity: 'success', summary: 'Scope Snapshot Replicated', detail: `Scope snapshot ${this.versionLabel(action.replicateScopeSnapshot.scopeVersion)} was replicated${data?.replicateScopeSnapshot ? ' as ' + this.versionLabel(data.replicateScopeSnapshot.scopeVersion) : ''}.`, life: 10000 });
				this.emitStateUpdates();
			},
			error: (error) => {
				this.setIsSavingIfApplicable(false);
				this.commonService.mutationErrorHandler(error);
			}
		});
	}

	createSnapshotInExistingScope(action: Extract<Action, {type: ActionType.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE | ActionType.CREATE_BLANK_SNAPSHOT_IN_DIFFERENT_SCOPE}>) {
		const actionScope = action.type === ActionType.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE ? action.scope : action.destinationScope;
		const createSnapshotInExistingScopeInfo = this.gqlRequestInfos.mutations.createSnapshotInExistingScope;

		createSnapshotInExistingScopeInfo.subscription?.unsubscribe();

		this.setIsSavingIfApplicable(true);
		createSnapshotInExistingScopeInfo.subscription = this.apollo.mutate({
			mutation: createSnapshotInExistingScopeInfo.gql,
			variables: {
				scopeId: actionScope.id,
				scopeVersion: action.newVersion
			}
		}).subscribe({
			next: ({ data }) => {
				this.setIsSavingIfApplicable(false);

				// let messageDetails = undefined;
				// if (data?.insertScopeSnapshotsOne?.scopeVersion) {
				// 	messageDetails = `Scope snapshot ${this.versionLabel(data?.insertScopeSnapshotsOne?.scopeVersion)} was created in new scope.`;
				// }
				let messageSummary = 'Scope snapshot created';
				let messageDetails = undefined;
				if (data?.insertScopeSnapshotsOne?.scopeVersion) {
					messageDetails = `Created blank ${this.versionLabel(data.insertScopeSnapshotsOne.scopeVersion)}.`
					//  action.type === ActionType.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE ? `Created blank ${this.versionLabel(data.insertScopeSnapshotsOne.scopeVersion)}.` : `Created ${this.versionLabel(data.insertScopeSnapshotsOne.scopeVersion)}.`;
				}

				this.messageService.add({ severity: 'success', summary: messageSummary, detail: messageDetails, life: 10000 });
				if (data?.insertScopeSnapshotsOne) {
					ScopeSnapshotSelectorComponent.updateStateWithNewScopeSnapshots(this.requireState(), [data?.insertScopeSnapshotsOne]);
				}
				this.emitStateUpdates();
			},
			error: (error) => {
				this.setIsSavingIfApplicable(false);
				this.commonService.mutationErrorHandler(error);
			}
		});
	}

	createSnapshotWithNewScope(action: Extract<Action, {type: ActionType.CREATE_BLANK_SNAPSHOT_WITH_NEW_SCOPE}>) {
		const createSnapshotWithNewScopeInfo = this.gqlRequestInfos.mutations.createSnapshotWithNewScope;

		createSnapshotWithNewScopeInfo.subscription?.unsubscribe();

		this.setIsSavingIfApplicable(true);
		createSnapshotWithNewScopeInfo.subscription = this.apollo.mutate({
			mutation: createSnapshotWithNewScopeInfo.gql,
			variables: {
				scopeDefiners: [...ScopeSnapshotService.completedScopeDefinitionMapToOptionIds(action.scopeOptionIdsByVariable)].map(scopeOptionId => ({scopeOptionId})),
				scopeVersion: action.newVersion
			}
		}).subscribe({
			next: ({ data }) => {
				this.setIsSavingIfApplicable(false);

				let messageSummary = 'Scope and snapshot created';
				let messageDetails = undefined;
				if (data?.insertScopeSnapshotsOne) {
					messageDetails = `${this.versionLabel(data.insertScopeSnapshotsOne.scopeVersion)} created in new scope.`
				}
				this.messageService.add({ severity: 'success', summary: messageSummary, detail: messageDetails, life: 10000 });
				if (data?.insertScopeSnapshotsOne) {
					ScopeSnapshotSelectorComponent.updateStateWithNewScopeSnapshots(this.requireState(), [data?.insertScopeSnapshotsOne]);
				}
				this.emitStateUpdates();
			},
			error: (error) => {
				this.setIsSavingIfApplicable(false);
				this.commonService.mutationErrorHandler(error);
			}
		});
	}

	updateScopeDefinition(action: Extract<Action, {type: ActionType.UPDATE_SCOPE_DEFINITION}>) {
		const state = this.requireStateWithSelectableScope();
		const revertToModificationsOnFailure = state.modifications;

		const completedScopeDefinitionMap = ScopeSnapshotSelectorComponent.completedScopeDefinitionMap(state);
		if (completedScopeDefinitionMap === undefined) {
			throw new Error("Cannot update scope definition because the scope is not fully defined");
		}
		state.modifications = {
			state: ModificationState.SELECTED_SCOPE_OPTIONS_AND_SEARCHING_FOR_SCOPE,
			scopeDefinitionMap: completedScopeDefinitionMap,
			isSearchingForScope: true
		};
		this.emitStateUpdates();

		const updateScopeDefinitionInfo = this.gqlRequestInfos.mutations.updateScopeDefinition;
		updateScopeDefinitionInfo.subscription?.unsubscribe();
		updateScopeDefinitionInfo.subscription = this.apollo.mutate({
			mutation: updateScopeDefinitionInfo.gql,
			variables: {
				scopeId: action.scope.id,
				replaceWithScopeDefiners: action.replaceWithScopeOptions.map(scopeOption => ({scopeId: action.scope.id, scopeOptionId: scopeOption.id})),
			}
		}).subscribe({
			next: ({ data }) => {
				let messageSummary = 'Updated Scope Definition';
				this.messageService.add({ severity: 'success', summary: messageSummary, detail: undefined, life: 10000 });
				let updatedScope: ScopeFieldsForScopeSnapshotSelectorFragment | undefined = undefined;

				// Get the latest updated scope from the mutation result, if any. Inserts happen after deletes, so if there are any inserts, then the last insert will contain the latest updated scope. Otherwise, if there are any deletes, then the last delete will contain the latest updated scope. The next ~4 lines are important to get right. If you decide to change them, make sure to test modifying a scope definition on the scopes and snapshots page to see if the scope definition still gets updated properly in the UI.
				updatedScope = data?.deleteScopeDefiners?.returning?.slice(-1)[0]?.scope;
				if (data?.insertScopeDefiners?.returning !== undefined) {
					updatedScope = data?.insertScopeDefiners?.returning?.slice(-1)[0]?.scope;
				}

				if (updatedScope) {
					ScopeSnapshotSelectorComponent.updateStateWithNewScopes(this.requireState(), [updatedScope]);
				}
				else if (!updatedScope) {
					state.modifications = revertToModificationsOnFailure;
					this.messageService.add({ severity: 'error', summary: 'Could not find the updated scope after updating the scope definition. Consider refreshing the page for fresh data.', detail: undefined, life: 10000 });
				}
				this.emitStateUpdates();
			},
			error: (error) => {
				this.commonService.mutationErrorHandler(error);
				state.modifications = revertToModificationsOnFailure;
				this.emitStateUpdates();
			}
		});
	}

	tooltipForScopeVariableDropdown(scopeVariableId: number): string | undefined {
		if (this.tooltip === undefined) {
			return undefined;
		}

		if (typeof this.tooltip.scopeVariableDropdowns === 'string' || this.tooltip.scopeVariableDropdowns === undefined) {
			return this.tooltip.scopeVariableDropdowns;
		}

		const tooltipForSvDropdown = this.tooltip.scopeVariableDropdowns.find(sv => sv.id === scopeVariableId);

		if (tooltipForSvDropdown !== undefined) {
			return tooltipForSvDropdown.message;
		}
		return undefined;
	}

	tooltipForSnapshotDropdown(): string | undefined {
		return this.tooltip?.snapshotDropdown;
	}

	tooltipOptionsForScopeVariableDropdown(): TooltipOptions {
		return {
			tooltipPosition: 'top',
			positionTop: -10,
		}
	}

	tooltipOptionsForSnapshotDropdown(): TooltipOptions {
		return {
			tooltipPosition: 'top',
			positionTop: -10,
		}
	}

	isScopeOptionPresentInDropdown(scopeVariable: State['scopeVariables'][number], scopeOption: State['scopeVariables'][number]['scopeOptions'][number] | null) {
		if (this.scopeOptionsFilter === undefined) {
			return true;
		}

		const state = this.requireState();

		if (scopeOption === null && ScopeSnapshotSelectorComponent.isAllScopeOptionSelectedForScopeVariable(state, scopeVariable.id)) {
			return true;
		}

		if (scopeOption !== null && ScopeSnapshotSelectorComponent.isScopeOptionSelected(state, scopeOption.id)) {
			return true;
		}

		const scopeOptionsFilterForSv = this.scopeOptionsFilter.get(scopeVariable.id);

		if (scopeOptionsFilterForSv === undefined) {
			throw new Error("Could not find the scope options filter for scope variable: " + scopeVariable.name);
		}

		// All option available within filter
		if (scopeOptionsFilterForSv.size === 0) {
			return true;
		}

		return scopeOption !== null && scopeOptionsFilterForSv.has(scopeOption.id);
	}

	/**
	 * ---------------------------------------------------------------------
	 * START OF STATIC FUNCTIONS - Put all static functions below this line.
	 * ---------------------------------------------------------------------
	 * */

	static whitelistedActionsForSnapshotsOfSelectedScope(state: State): ActionType[] {
		const whitelist = state.actionsWhitelist;
		const actions = ScopeSnapshotSelectorComponent.allActionsForSnapshotsOfSelectedScope(state);

		if (whitelist !== undefined) {
			return actions.filter(action => whitelist.includes(action));
		}

		return actions;
	}

	private static allActionsForSnapshotsOfSelectedScope(state: State): ActionType[] {
		const actions: ActionType[] = [];
		const selectedScope = ScopeSnapshotSelectorComponent.selectedScope(state);
		if (!selectedScope) {
			return actions;
		}

		const initialScope = ScopeSnapshotSelectorComponent.initialScope(state);

		if (initialScope && selectedScope.id === initialScope.id) {
			actions.push(ActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE);
		}
		if (!initialScope || selectedScope.id !== initialScope.id) {
			actions.push(ActionType.GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE);
		}

		return actions;
	}

	// static whitelistedActionBases(state: State): {[key in ActionType]?: Extract<ActionBase, {type: key}>} {
	// 	const whitelistedActionBases = _.clone(ScopeSnapshotSelectorComponent.whitelistedActionBases(state));
	// 	const whitelist = state.actionsWhitelist;

	// 	for (const actionType in (whitelistedActionBases)) {
	// 		if (!whitelist.includes(actionType)) {
	// 			delete whitelistedActionBases[actionType as ActionType];
	// 		}
	// 	}

	// 	return whitelistedActionBases;
	// }

	static whitelistedActionBases(state: State): {[key in ActionType]: Extract<ActionBase, {type: key}> | null} {
		let actionMap: {[key in ActionType]: Extract<ActionBase, {type: key}> | null} = {
			[ActionType.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE]: null,
			[ActionType.CREATE_BLANK_SNAPSHOT_IN_DIFFERENT_SCOPE]: null,
			[ActionType.CREATE_BLANK_SNAPSHOT_WITH_NEW_SCOPE]: null,
			[ActionType.REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION]: null,
			[ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: null,
			[ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: null,
			[ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: null,
			[ActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE]: null,
			[ActionType.GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE]: null,
			[ActionType.VIEW_INHERITANCES_OF_NEWLY_SELECTED_SCOPE_SNAPSHOT]: null,
			[ActionType.UPDATE_SCOPE_DEFINITION]: null,
			[ActionType.RESET_TO_INITIAL_STATE]: null,
		};

		if (state.staticity === Staticity.STATIC_SCOPE_WITH_STATIC_VERSION) {
			const initialScopeSnapshot = ScopeSnapshotSelectorComponent.initialScopeSnapshot(state);
			if (initialScopeSnapshot) {
				actionMap[ActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE] = {
					type: ActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE,
					defaultButtonLabel: `Go to ${CommonService.friendlyVersionLabel(initialScopeSnapshot.scopeVersion)}`,
					destinationScopeSnapshot: initialScopeSnapshot,
					isPrimary: true,
				};
			}
		}
		else {
			const actionForReplication = ScopeSnapshotSelectorComponent.actionForReplicateScopeSnapshotAsNewVersion(state);
			if (actionForReplication) {
				actionMap[ActionType.REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION] = actionForReplication;
			}

			let isPrimaryActionTaken = !!actionForReplication;

			const initialState = ScopeSnapshotSelectorComponent.initialStateFromState(state);
			const initialScope = ScopeSnapshotSelectorComponent.selectedScope(initialState);
			const initialScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(initialState);
			const selectedScopeSnapshot = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(state);
			const selectedScope = ScopeSnapshotSelectorComponent.selectedScope(state);

			// If we've selected a scope snapshot and it's not the same as the initial scope snapshot, then proceed to determine if the selected scope snapshot is in the same scope as the initial scope, if any, and create the action bases accordingly, whether it's to go to the snapshot in the same scope or in a different scope.
			// Also create the action base to view the inheritances of the selected scope snapshot.
			// Also create the action base to deploy the selected scope snapshot.
			if (selectedScopeSnapshot && !(initialScopeSnapshot && initialScopeSnapshot.id === selectedScopeSnapshot.id)) {
				if (initialScope && selectedScopeSnapshot.scope.id === initialScope.id) {
					actionMap[ActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE] = {
						type: ActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE,
						defaultButtonLabel: `Go to ${CommonService.friendlyVersionLabel(selectedScopeSnapshot.scopeVersion)}`,
						destinationScopeSnapshot: selectedScopeSnapshot,
						isPrimary: !isPrimaryActionTaken,
					};
				}
				else {
					actionMap[ActionType.GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE] = {
						type: ActionType.GO_TO_SNAPSHOT_IN_DIFFERENT_SCOPE,
						defaultButtonLabel: `Go to ${CommonService.friendlyVersionLabel(selectedScopeSnapshot.scopeVersion)} of Selected Scope`,
						destinationScopeSnapshot: selectedScopeSnapshot,
						isPrimary: !isPrimaryActionTaken,
					};
				}
			}

			// If we have a selected scope snapshot, allow the user to view the inheritances of the selected scope snapshot.
			if (selectedScopeSnapshot) {
				actionMap[ActionType.VIEW_INHERITANCES_OF_NEWLY_SELECTED_SCOPE_SNAPSHOT] = {
					type: ActionType.VIEW_INHERITANCES_OF_NEWLY_SELECTED_SCOPE_SNAPSHOT,
					defaultButtonLabel: `View Inheritances of ${CommonService.friendlyVersionLabel(selectedScopeSnapshot.scopeVersion)}`,
					selectedScopeSnapshot: selectedScopeSnapshot,
					isPrimary: false,
				}
			}

			// If you can input a version, then allow creating a blank scope snapshot.
			if (
				(state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION || state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION)
				&& !selectedScopeSnapshot && !!state.modifications?.versionInput
			) {

				// If the initial scope and selected scope are the same, then display a button like "Create blank v2" and allow such action.
				if (initialScope && selectedScope && initialScope.id === selectedScope.id) {
					actionMap[ActionType.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE] = {
						type: ActionType.CREATE_BLANK_SNAPSHOT_IN_SAME_SCOPE,
						defaultButtonLabel: `Create Blank ${CommonService.friendlyVersionLabel(state.modifications.versionInput)}`,
						scope: initialScope,
						newVersion: state.modifications.versionInput,
						isPrimary: !isPrimaryActionTaken,
					};
				}

				// If the initial scope and selected scope are different, then display a button like "Create v2 in selected scope" and allow such action.
				else if (selectedScope && !(initialScope && initialScope.id === selectedScope.id)) {
					actionMap[ActionType.CREATE_BLANK_SNAPSHOT_IN_DIFFERENT_SCOPE] = {
						type: ActionType.CREATE_BLANK_SNAPSHOT_IN_DIFFERENT_SCOPE,
						defaultButtonLabel: `Create ${CommonService.friendlyVersionLabel(state.modifications.versionInput)} in Selected Scope`,
						destinationScope: selectedScope,
						newVersion: state.modifications.versionInput,
						isPrimary: !isPrimaryActionTaken,
					};
				}

				// If the selected scope options do not even result in an existing scope, then display a button like "Create v1 in New Scope" and allow such action.
				const completedScopeDefinitionMap = ScopeSnapshotSelectorComponent.completedScopeDefinitionMap(state);
				if (!selectedScope && completedScopeDefinitionMap){
					actionMap[ActionType.CREATE_BLANK_SNAPSHOT_WITH_NEW_SCOPE] = {
						type: ActionType.CREATE_BLANK_SNAPSHOT_WITH_NEW_SCOPE,
						defaultButtonLabel: `Create ${CommonService.friendlyVersionLabel(state.modifications.versionInput)} in New Scope`,
						scopeOptionIdsByVariable: completedScopeDefinitionMap,
						newVersion: state.modifications.versionInput,
						isPrimary: !isPrimaryActionTaken,
					};
				}
			}

			if (ScopeSnapshotSelectorComponent.isUIDirty(state)) {
				actionMap[ActionType.RESET_TO_INITIAL_STATE] = {
					type: ActionType.RESET_TO_INITIAL_STATE,
					defaultButtonLabel: "Cancel",
					isPrimary: false,
				};
			}

			// If the selected scope options do not result in an existing scope, then allow the user to update the scope definition of the initial scope to that defined by the selected scope options.
			if (
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION
			) {
				if (initialScope && state.modifications?.state === ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED && state.modifications.validatableScopeContainments !== undefined) {


					if (ScopeSnapshotSelectorComponent.isInitialScopeUpdatableToNewScopeDefinition(state)) {

						// Generate the new list of scope options to replace the current list of scope options with.
						const replaceWithScopeOptions: ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number][] = [];
						for (const scopeVariable of state.scopeVariables) {
							const selectedScopeOptionIdsForVariable = state.modifications.scopeDefinitionMap.get(scopeVariable.id);

							// This should never really happen unless for some reason there's more scope variables present in the state.scopeVariables that are not present in the selected scope options map. We throw an error just to make the compiler happy.
							if (selectedScopeOptionIdsForVariable === undefined) {
								throw new Error("Could not find the selected scope option ids for scope variable id " + scopeVariable.id);
							}

							for (const scopeOption of scopeVariable.scopeOptions) {
								if (selectedScopeOptionIdsForVariable.has(scopeOption.id)) {
									replaceWithScopeOptions.push(scopeOption);
								}
							}
						}

						actionMap[ActionType.UPDATE_SCOPE_DEFINITION] = {
							type: ActionType.UPDATE_SCOPE_DEFINITION,
							scope: initialScope,
							replaceWithScopeOptions: replaceWithScopeOptions,
							defaultButtonLabel: "Update Scope Definition",
							isPrimary: !isPrimaryActionTaken,
						};
					}
				}
			}

			const actionsForCherryPickReplication = ScopeSnapshotSelectorComponent.actionsForCherryPickReplication(state);

			if (actionsForCherryPickReplication[ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]) {
				actionMap[ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION] = actionsForCherryPickReplication[ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION];
			}
			if (actionsForCherryPickReplication[ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]) {
				actionMap[ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION] = actionsForCherryPickReplication[ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION];
			}
			if (actionsForCherryPickReplication[ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]) {
				actionMap[ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION] = actionsForCherryPickReplication[ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION];

				// If we are in the cherry pick replication mode, then remove the Cancel action as well as the "Go to v1" action, since we would want the user to first exit the cherry pick replication mode before being able to perform any other actions.
				actionMap[ActionType.RESET_TO_INITIAL_STATE] = null;
				actionMap[ActionType.GO_TO_SNAPSHOT_IN_SAME_SCOPE] = null;

			}
		}

		const actionBases = _.compact(Object.values(actionMap));
		for (const actionBase of actionBases) {
			if (!state.actionsWhitelist.includes(actionBase.type)) {
				actionMap[actionBase.type] = null;
			}
		}

		return actionMap;
	}

	static versionInputtableModificationsOrReturnUndefined(state: State): {versionInput: string} | undefined {
		if ((state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) && state.modifications?.versionInput !== undefined) {
			return state.modifications;
		}
		return undefined;
	}

	static hasScopeChanged(state: State): boolean {
		if (state.staticity === Staticity.STATIC_SCOPE_WITH_STATIC_VERSION) {
			return false;
		}
		else {
			const initialScope = ScopeSnapshotSelectorComponent.initialScope(state);
			const newScope = ScopeSnapshotSelectorComponent.selectedScope(state);
			return initialScope?.id !== newScope?.id;
		}
	}

	/**
	 *
	 * @param state
	 * @returns
	 * Check to make sure that the new scope definition that was selected:
	 * 1. Does not conflict with any existing scope snapshots, and
	 * 2. Is compatible with the existing scope snapshots - that is, each existing child scope snapshot has a scope definition that is narrower than the new scope definition, and each existing parent scope snapshot has a scope definition that is broader than the new scope definition.
	 */
	static isInitialScopeUpdatableToNewScopeDefinition(state: State): boolean {

		if (
			state.initialScope &&
			(state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) &&
			state.modifications?.state === ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED &&
			state.modifications.validatableScopeContainments !== undefined
		) {

			const completedScopeDefinitionMap = state.modifications.scopeDefinitionMap;
			const validatableScopeContainmentsForModifiedDefinition = ScopeSnapshotService.validatableScopeContainmentsWithModifiedScope(state.modifications.validatableScopeContainments, state.initialScope.id, completedScopeDefinitionMap, state.scopeVariables);

			return ScopeSnapshotService.areDirectInheritancesCompatible(validatableScopeContainmentsForModifiedDefinition);
		}

		return false;
	}

	// @memoize()
	// This method is only meant for display purposes. Use isInitialScopeUpdatableToNewScopeDefinition(), or areDirectInheritancesCompatible(), or incompatibleDirectInheritances() for logic instead.
	static presentableErrorsPreventingScopeDefinitionUpdate(state: State): ErrorPreventingAction[] {
		const errors: ErrorPreventingAction[] = [];
		if (
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION
			) {

			if (state.initialScope && state.modifications?.state === ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED && state.modifications.validatableScopeContainments !== undefined) {
				const validatableScopeContainmentsForModifiedDefinition = ScopeSnapshotService.validatableScopeContainmentsWithModifiedScope(state.modifications.validatableScopeContainments, state.initialScope.id, state.modifications.scopeDefinitionMap, state.scopeVariables);
				const incompatibleInheritances = ScopeSnapshotService.incompatibleDirectInheritances(validatableScopeContainmentsForModifiedDefinition);

				if (incompatibleInheritances.length > 0) {
					const incompatibleChildSnapshotInheritances = incompatibleInheritances.filter(inheritance => inheritance.directlyInheritedScopeId === state.initialScope.id); // The child scope id is inheritance.scopeId and the child scope snapshot id is inheritance.scopeSnapshotId
					const incompatibleParentSnapshotInheritances = incompatibleInheritances.filter(inheritance => inheritance.scopeId === state.initialScope.id); // The parent scope id is inheritance.directlyInheritedScopeId and the parent scope snapshot id is inheritance.directlyInheritedScopeSnapshotId

					const uniqueIncompatibleChildScopeSnapshots = _.uniqBy(incompatibleChildSnapshotInheritances, inheritance => inheritance.scopeSnapshotId);
					const uniqueIncompatibleChildScopes = _.uniqBy(incompatibleChildSnapshotInheritances, inheritance => inheritance.scopeId);

					const uniqueIncompatibleParentScopeSnapshots = _.uniqBy(incompatibleParentSnapshotInheritances, inheritance => inheritance.directlyInheritedScopeSnapshotId);
					const uniqueIncompatibleParentScopes = _.uniqBy(incompatibleParentSnapshotInheritances, inheritance => inheritance.directlyInheritedScopeId);

					const incompatibilityStrings = [];
					if (uniqueIncompatibleParentScopeSnapshots.length > 0) {
						incompatibilityStrings.push(`${uniqueIncompatibleParentScopeSnapshots.length} parent snapshot${uniqueIncompatibleParentScopeSnapshots.length > 1 ? "s" : ""} (${uniqueIncompatibleParentScopes.length > 1 ? `across ${uniqueIncompatibleParentScopes.length} scopes` : `in ${uniqueIncompatibleParentScopes.length} scope`})`);
					}
					if (uniqueIncompatibleChildScopeSnapshots.length > 0) {
						incompatibilityStrings.push(`${uniqueIncompatibleChildScopeSnapshots.length} child snapshot${uniqueIncompatibleChildScopeSnapshots.length > 1 ? "s" : ""} (${uniqueIncompatibleChildScopes.length > 1 ? `across ${uniqueIncompatibleChildScopes.length} scopes` : `in ${uniqueIncompatibleChildScopes.length} scope`})`);
					}

					errors.push({
						code: ErrorPreventingActionCode.INCOMPATIBLE_INHERITANCES,
						summary: `Incompatible with ${incompatibilityStrings.join(" and ")}`,
						allIncompatibleInheritances: incompatibleInheritances,
						incompatibleChildSnapshotInheritances: uniqueIncompatibleChildScopeSnapshots,
						incompatibleChildScopeInheritances: uniqueIncompatibleChildScopes,
						incompatibleParentSnapshotInheritances: uniqueIncompatibleParentScopeSnapshots,
						incompatibleParentScopeInheritances: uniqueIncompatibleParentScopes,
						isExpandable: true
					});
				}
			}
			else if (
				state.modifications?.state === ModificationState.SELECTED_SCOPE_WITH_NO_VERSION_INPUT ||
				state.modifications?.state === ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION ||
				state.modifications?.state === ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION ||
				state.modifications?.state === ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN
			) {
				const initialScope = ScopeSnapshotSelectorComponent.initialScope(state);
				const selectedScope = ScopeSnapshotSelectorComponent.selectedScope(state);
				if (initialScope && selectedScope && initialScope.id !== selectedScope.id) {
					errors.push({
						code: ErrorPreventingActionCode.CONFLICT_WITH_EXISTING_SCOPE,
						summary: "Conflicts with existing scope"
					});
				}
			}

		}

		return errors;
	}

	private static actionForReplicateScopeSnapshotAsNewVersion(state: State): Extract<ActionBase, {type: ActionType.REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION}> | undefined {
		if (
			state.modifications && state.initialScopeSnapshot &&
			(
				(state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) ||
				(state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION && (state.initialScopeSnapshot.scope.id === state.modifications.selectedScope?.id))
			) &&
			state.modifications.versionInput && state.modifications.versionInput !== "" && !_.some(state.initialScopeSnapshot.scope.scopeSnapshots, scopeSnapshot => scopeSnapshot.scopeVersion === state.modifications?.versionInput)
		) {
			return {
				type: ActionType.REPLICATE_SCOPE_SNAPSHOT_AS_NEW_VERSION,
				defaultButtonLabel: `Replicate ${CommonService.friendlyVersionLabel(state.initialScopeSnapshot.scopeVersion)} as ${CommonService.friendlyVersionLabel(state.modifications.versionInput)}`,
				replicateScopeSnapshot: state.initialScopeSnapshot,
				newVersion: state.modifications.versionInput,
				isPrimary: true, // Indicates if this is the primary action which should be displayed as blue (or whatever the primary color is), rather than gray.
			};
		}
		return undefined;
	}

	private static actionsForCherryPickReplication(state: State): {
		[ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: Extract<ActionBase, {type: ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}> | null,
		[ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: Extract<ActionBase, {type: ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}> | null,
		[ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: Extract<ActionBase, {type: ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}> | null,
		}
	{
		const actions: {
			[ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: Extract<ActionBase, {type: ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}> | null,
			[ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: Extract<ActionBase, {type: ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}> | null,
			[ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: Extract<ActionBase, {type: ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION}> | null,
			} = {
			[ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: null,
			[ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: null,
			[ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION]: null,
		};

		const initialScopeSnapshot = this.initialScopeSnapshot(state);
		const selectedScopeSnapshot = this.selectedScopeSnapshot(state);
		if (
			initialScopeSnapshot && selectedScopeSnapshot && initialScopeSnapshot.scope.id === selectedScopeSnapshot.scope.id && initialScopeSnapshot.id !== selectedScopeSnapshot.id
		) {

			const enterModeForCherryPickReplicateAction = {
				type: ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
				defaultButtonLabel: `Cherry-pick Flags/Values into ${CommonService.friendlyVersionLabel(selectedScopeSnapshot.scopeVersion)}`,
				replicateScopeSnapshot: initialScopeSnapshot,
				destinationScopeSnapshot: selectedScopeSnapshot,
				isPrimary: false, // Indicates if this is the primary action which should be displayed as blue (or whatever the primary color is), rather than gray.
			} as const;

			const exitModeForCherryPickReplicateAction = {
				type: ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
				defaultButtonLabel: `Cancel Cherry-picking`,
				isPrimary: false,
			} as const;

			const cherryPickReplicateAction = {
				type: ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION,
				defaultButtonLabel: `Replicate Flags and Values into ${CommonService.friendlyVersionLabel(selectedScopeSnapshot.scopeVersion)}`,
				replicateScopeSnapshot: initialScopeSnapshot,
				destinationScopeSnapshot: selectedScopeSnapshot,
				isPrimary: true,
			} as const;

			if (state.staticity !== Staticity.STATIC_SCOPE_WITH_STATIC_VERSION) {
				if (state.modifications?.state === ModificationState.SELECTING_FLAGS_AND_VALUES_TO_REPLICATE) {
					actions[ActionType.CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION] = cherryPickReplicateAction;
					actions[ActionType.EXIT_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION] = exitModeForCherryPickReplicateAction;
				}
				else {
					actions[ActionType.ENTER_MODE_FOR_CHERRY_PICK_REPLICATE_INTO_SELECTED_VERSION] = enterModeForCherryPickReplicateAction;
				}
			}
		}
		return actions;
	}

	static isSelectingFlagsAndValuesToReplicate(state: State): boolean {
		return state.modifications?.state === ModificationState.SELECTING_FLAGS_AND_VALUES_TO_REPLICATE;
	}

	static isSelectorDisabled(state: State): boolean {
		const isSaving = ScopeSnapshotSelectorComponent.isSaving(state);
		const isSelectingFlagsAndValuesToReplicate = ScopeSnapshotSelectorComponent.isSelectingFlagsAndValuesToReplicate(state);
		return isSaving || isSelectingFlagsAndValuesToReplicate || ((state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION) && state.modifications?.isSearchingForScope === true);
	}

	static isActionsDisabled(state: State): boolean {
		const isSaving = ScopeSnapshotSelectorComponent.isSaving(state);
		return isSaving || ((state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION) && state.modifications?.isSearchingForScope === true);
	}

	/**
	 * Indicates whether the resulting selections of the scope snapshot selector are dirty. This means that the user has made modifications that result in a different set of scope options being selected, a different scope snapshot being selected, or a different scope snapshot selected.
	 * @param state
	 * @returns
	 */
	static isSelectionsDirty(state: ModifiableState): boolean {
		const initialState = ScopeSnapshotSelectorComponent.initialStateFromState(state);

		if (ScopeSnapshotSelectorComponent.selectedScopeSnapshot(state)?.id !== ScopeSnapshotSelectorComponent.selectedScopeSnapshot(initialState)?.id) {
			return true;
		}

		if (ScopeSnapshotSelectorComponent.selectedScope(state)?.id !== ScopeSnapshotSelectorComponent.selectedScope(initialState)?.id) {
			return true;
		}

		if (ScopeSnapshotSelectorComponent.isScopeDefinitionDirty(state)) {
			return true;
		}

		return false;
	}

	static isScopeDefinitionDirty(state: State): boolean {
		const initialState = ScopeSnapshotSelectorComponent.initialStateFromState(state);

		const selectedOptionIds = ScopeSnapshotSelectorComponent.scopeDefinitionMap(state);
		const initialOptionIds = ScopeSnapshotSelectorComponent.scopeDefinitionMap(initialState);

		return !ScopeSnapshotService.areScopeDefinitionMapsEqual(selectedOptionIds, initialOptionIds);
	}

	/**
	 * Indicates whether the resulting UI of the scope snapshot selector is dirty. This means if it is loading, if there is a version input, if the selected scope snapshot is different, or if the selected scope is different. All of these indicate that there should be a cancel button visible to the user.
	 */
	static isUIDirty(state: State): boolean {
		if (state.staticity === Staticity.STATIC_SCOPE_WITH_STATIC_VERSION) {
			return false;
		}
		if (this.isSelectionsDirty(state)) {
			return true;
		}
		// const initialState = ScopeSnapshotSelectorComponent.initialStateFromState(state);

		const isSearchingForScope = (
			state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT ||
			state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION ||
			state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION
		) &&
		state.modifications?.state === ModificationState.SELECTED_SCOPE_OPTIONS_AND_SEARCHING_FOR_SCOPE;

		if (isSearchingForScope) {
			return true;
		}

		const hasVersionInput = (
			state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION ||
			state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION
		) &&
		state.modifications && "versionInput" in state.modifications;

		if (hasVersionInput) {
			return true;
		}

		return false;
	}

	/**
	 * @returns If modifications were made to the selector, then we return the selected scope based on the modifications, or undefined if the modifications indicate that no scope was selected (which could mean that the user either deselected the initial scope, or there was no initial scope and the user didn't select one at all).
	 *
	 * If there were no modifications on the other hand, then we return the initial scope if present, or undefined if there was no initial scope.
	 *
	 * From a visual standpoint, this function will give you the scope that represents the one you currently see in the selector.
	 */
	static selectedScope(state: State): StaticScope | undefined {
		if (state.staticity === Staticity.STATIC_SCOPE_WITH_STATIC_VERSION) {
			if (state.initialScope) {
				return state.initialScope;
			}
			else {
				return state.initialScopeSnapshot.scope;
			}
		}
		else if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT) {
			if (state.modifications?.state === ModificationState.SELECTED_SCOPE_WITH_NO_VERSION_INPUT) {
				return state.modifications.selectedScope;
			}
			else if (state.modifications?.state !== undefined) {
				return undefined;
			}
			else if (state.initialScope) {
				return state.initialScope;
			}
		}
		else {
			return ScopeSnapshotSelectorComponent.selectedScopeWithSnapshots(state);
		}
	}

	/**
	 *
	 * @returns Similar to selectedScope(state), this function returns a scope object but with the addition of the scope's snapshots. We only do this if the scope snapshot selector is in a modifiable state, meaning, the snapshots are actually present in the state for us the fetch (and consequently, the snapshots are actually present in the UI).
	 */
	static selectedScopeWithSnapshots(state: StateWithModifiableVersion): ScopeWithSnapshots | undefined {
		if (state.staticity === Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) {

			if (state.initialScope) {
				return state.initialScope;
			}
			else {
				return state.initialScopeSnapshot.scope;
			}
		}
		else if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) {
			if (state.modifications) {
				if (state.modifications.selectedScope) {
					return state.modifications.selectedScope;
				}
				else if (state.modifications.selectedScopeSnapshot) {
					state.modifications.selectedScopeSnapshot.scope;
					return state.modifications.selectedScopeSnapshot.scope;
				}
			}
			else {
				if (state.initialScope) {
					return state.initialScope;
				}
				else if (state.initialScopeSnapshot) {
					return state.initialScopeSnapshot.scope;
				}
			}
		}
	}

	static scopeSnapshotsInDropdownCount(state: State): number {
		if (state.staticity === Staticity.STATIC_SCOPE_WITH_STATIC_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT) {
			return 0;
		}
		else {
			const selectedScope = ScopeSnapshotSelectorComponent.selectedScopeWithSnapshots(state);
			if (selectedScope) {
				return selectedScope.scopeSnapshots.length;
			}
			else {
				return 0;
			}
		}
	}

	/**
	 *
	 * @returns If modifications were made to the selector, then we return the selected scope snapshot based on the modifications, or undefined if the modifications indicate that no scope snapshot was selected (which could mean that the user either deselected the initial scope snapshot, or there was no initial scope snapshot and the user didn't select one at all).
	 *
	 * If there were no modifications on the other hand, then we return the initial scope snapshot if present, or undefined if there was no initial scope snapshot.
	 *
	 * From a visual standpoint, this function will give you the scope snapshot that represents the one you currently see in the selector.
	 */
	static selectedScopeSnapshot(state: State): StaticScopeSnapshot | undefined {
		if (state.staticity === Staticity.STATIC_SCOPE_WITH_STATIC_VERSION) {
			if (state.initialScopeSnapshot) {
				return state.initialScopeSnapshot;
			}
		}
		else if (state.staticity !== Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT) {
			return ScopeSnapshotSelectorComponent.selectedScopeSnapshotWithOtherSnapshotsInSameScope(state);
		}
		return undefined;
	}

	/**
	 *
	 * @returns Similar to selectedScopeWithSnapshots() except we return an actual scope snapshot rather than a scope. And, as long as this function doesn't return undefined, it is guaranteed that other snapshots in the same scope are provided in the scope.scopeSnapshots array. In other words, if you do `scopeSnapshot = selectedScopeSnapshotWithOtherSnapshotsInSameScope(state)`, and it doesn't return undefined, then it is guaranteed that scopeSnapshot.scope.scopeSnapshots provides you with a full list of snapshots.
	 * This is useful when the scope snapshot selector is fully modifiable (meaning you can select/create a scope as well as use the snapshot dropdown/input). This function provides the necessary data needed to populate such a fully modifiable scope snapshot selector.
	 */
	static selectedScopeSnapshotWithOtherSnapshotsInSameScope(state: StateWithModifiableVersion): ScopeSnapshotWithOtherSnapshotsInSameScope | undefined {
		if (state.staticity === Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION) {
			if (state.modifications) {
				const initialScope = state.initialScope ? state.initialScope : state.initialScopeSnapshot.scope;

				let foundScopeSnapshot: ScopeSnapshotWithOtherSnapshotsInSameScope["scope"]["scopeSnapshots"][number] | undefined;

				if (state.modifications.selectedScopeSnapshotId) {
					const selectedScopeSnapshotId = state.modifications.selectedScopeSnapshotId;
					foundScopeSnapshot = initialScope.scopeSnapshots.find(scopeSnapshot => scopeSnapshot.id === selectedScopeSnapshotId);
				}
				else if (state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION && state.modifications.versionInput) {
					const selectedScopeVersion = state.modifications.versionInput;
					foundScopeSnapshot = initialScope.scopeSnapshots.find(scopeSnapshot => scopeSnapshot.scopeVersion === selectedScopeVersion);
				}

				if (foundScopeSnapshot) {
					return {
						...foundScopeSnapshot, // TODO Test to ensure that this scope snapshot gets updated with a new scopeVersion when the user updates the scope's version
						scope: initialScope
					}
				}

				// Make sure we return undefined here to indicate that the user has modified the selector such that there is no longer a selected scope snapshot, even if there was one on initialization.
				return undefined;
			}
			else {
				// If there are no modifications, return the initial scope snapshot if present.
				if (state.initialScopeSnapshot) {
					return state.initialScopeSnapshot;
				}
			}
		}
		else if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) {
			if (state.modifications) {
				// If there are modifications and we have a staticity where the user can select a scope snapshot from the dropdown, then see if the user selected a scope snapshot from the dropdown.
				if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION) {
					if (state.modifications.selectedScopeSnapshot) {
						return state.modifications.selectedScopeSnapshot;
					}
					// At this point, we know that no scope snapshot was selected. Make sure we return undefined here to indicate that the user has modified the selector such that there is no longer a selected scope snapshot, even if there was one on initialization.
					return undefined;
				}

				// The staticity in this else case is Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION. So, see if we can find a scope snapshot based on either the inputted version or a selected scope snapshot from the dropdown.
				else {
					if (state.modifications.versionInput) {
						const selectedScopeVersion = state.modifications.versionInput;
						let selectedScope: ScopeSnapshotWithOtherSnapshotsInSameScope["scope"] | undefined;
						selectedScope = state.modifications.selectedScope;

						if (selectedScope) {
							const foundScopeSnapshot = selectedScope.scopeSnapshots.find(scopeSnapshot => scopeSnapshot.scopeVersion === selectedScopeVersion);
							if (foundScopeSnapshot) {
								return {
									...foundScopeSnapshot, // TODO Test to ensure that this scope snapshot gets updated with a new scopeVersion when the user updates the scope's version
									scope: selectedScope
								}
							}
						}
					}
					else if (state.modifications.selectedScopeSnapshot) {
						return state.modifications.selectedScopeSnapshot;
					}

					// At this point, we know that no scope snapshot was found based on the inputted version and no scope snapshot was selected either. Make sure we return undefined here to indicate that the user has modified the selector such that there is no longer a selected scope snapshot, even if there was one on initialization.
					return undefined;
				}
			}
			// If there are no modifications, return the initial scope snapshot if present.
			else if (state.initialScopeSnapshot) {
				return state.initialScopeSnapshot;
			}
		}
		return undefined;
	}

	// Generate a map of scope variable ids to a set of selected scope option ids. If the set is undefined for a scope variable id, it means that the user has not selected any scope options for that scope variable yet.
	// The map looks like this:
	// {
	// 		3: Set(2) {10, 11},
	// 		5: Set(0) {},
	// 		8: undefined
	// }
	// In this example, we have:
	//  - Scope variable id 3 with two scope options selected.
	//  - Scope variable id 5 with zero scope options selected, which means that it will default to the 'All' label in the UI which represents that all options will be targeted for that scope variable.
	//  - Scope variable id 8 with an undefined set of scope options, which means that it will show up as empty in the UI, and will require selecting at least one scope option in order to proceed with selecting or creating a new scope and/or snapshot.
	static scopeDefinitionMap(state: State): ScopeDefinitionMap {
		const selectedScope = ScopeSnapshotSelectorComponent.selectedScope(state);
		if (selectedScope) {
			return ScopeSnapshotService.scopeDefinitionMapFromComparableScopeDefiners(selectedScope.comparableScopeDefiners, state.scopeVariables);
		}
		else if ((state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) && state.modifications?.scopeDefinitionMap) {
			return state.modifications.scopeDefinitionMap;
		}
		else if (state.initialComparableScopeDefiners) {
			return ScopeSnapshotService.scopeDefinitionMapFromComparableScopeDefiners(state.initialComparableScopeDefiners, state.scopeVariables);
		}
		else {
			return ScopeSnapshotService.scopeDefinitionMapFromComparableScopeDefiners([], state.scopeVariables);
		}
	}

	// If the scope snapshot selector has an option selected for each scope variable, then return the map of scope variable ids to a set of selected scope option ids. Otherwise, return undefined. Note, this will return undefined if there is even one scope variable that does not have a scope option selected (keep in mind that selecting the 'All' option for a scope variable is considered selecting a scope option).
	static completedScopeDefinitionMap(state: State): CompletedScopeDefinitionMap | undefined {
		// const scopeVariables = this.requireScopeVariables();
		const scopeDefinitionMap = ScopeSnapshotSelectorComponent.scopeDefinitionMap(state);
		const completedScopeDefinitionMap: CompletedScopeDefinitionMap = new Map();

		for (const scopeVariable of state.scopeVariables) {
			const selectedScopeOptionIdsForScopeVariable = scopeDefinitionMap.get(scopeVariable.id);
			if (selectedScopeOptionIdsForScopeVariable === undefined) {
				return undefined;
			}
			else {
				completedScopeDefinitionMap.set(scopeVariable.id, selectedScopeOptionIdsForScopeVariable);
			}
		}

		return completedScopeDefinitionMap;
	}

	static isChildScopeDefinitionContainedInParentScopeDefinition(childScopeDefinitionMap: CompletedScopeDefinitionMap, parentScopeDefinitionMap: CompletedScopeDefinitionMap): boolean {
		for (const [scopeVariableId, childScopeOptionIds] of childScopeDefinitionMap.entries()) {
			const parentScopeOptionIds = parentScopeDefinitionMap.get(scopeVariableId);
			if (parentScopeOptionIds === undefined) {
				throw new Error(`Parent scope definition map is missing scope variable id: ${scopeVariableId}`);
			}
			// If no scope options are explicitly selected for this scope variable in the parent scope definition map, it means that the 'All' option is implicitly selected, by our design of CompletedScopeDefinitionMap.
			// And so, simply continue to the next scope variable, since we know that the 'All' option definitely contains any possible scope options that the child scope definition map may have for this scope variable.
			if (parentScopeOptionIds.size === 0) {
				continue; // Move on to the next scope variable.
			}

			// At this point, we know that the parent scope definition map has scope options selected for this scope variable, more than just the 'All' option.
			// So, if the child scope definition map has no scope options selected for this scope variable, return false to indicate that the child scope definition map is not contained in the parent scope definition map.
			if (childScopeOptionIds.size === 0) {
				return false;
			}

			for (const scopeOptionId of childScopeOptionIds) {
				if (!parentScopeOptionIds.has(scopeOptionId)) {
					return false;
				}
			}
		}
		// If we reach this point, it means that we have verified that the child scope definition map is contained in the parent scope definition map for all scope variables.
		return true;
	}

	static isScopeDefinitionSelectable(state: State): boolean {
		const staticity = state.staticity;
		return 	staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT ||
						staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION ||
						staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION;
	}

	static isScopeVersionSelectableOrCreatable(state: State): boolean {
		const staticity = state.staticity;
		return	staticity === Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION ||
						staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION ||
						staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION ||
						staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION;
	}

	static isScopeVersionCreatable(state: State): boolean {
		const staticity = state.staticity;
		return	staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION ||
						staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION;
	}

	static isScopeOptionSelected(state: State, scopeOptionId: ScopeVariableFieldsForScopeSnapshotSelectorFragment["scopeOptions"][number]["id"]): boolean {
		const scopeDefinitionMap = ScopeSnapshotSelectorComponent.scopeDefinitionMap(state);
		for (const scopeOptionIds of scopeDefinitionMap.values()) {
			if (scopeOptionIds !== undefined && scopeOptionIds.has(scopeOptionId)){
				return true;
			}
		}
		return false;
	}

	static isAllScopeOptionSelectedForScopeVariable(state: State, scopeVariableId: ScopeVariableFieldsForScopeSnapshotSelectorFragment["id"]): boolean {
		const scopeDefinitionMap = ScopeSnapshotSelectorComponent.scopeDefinitionMap(state);
		const scopeOptionIds = scopeDefinitionMap.get(scopeVariableId);
		return scopeOptionIds !== undefined && scopeOptionIds.size === 0;
	}

	static initialScope(state: State): StaticScope | undefined {
		const initialState = ScopeSnapshotSelectorComponent.initialStateFromState(state);
		return ScopeSnapshotSelectorComponent.selectedScope(initialState);
	}

	static initialScopeSnapshot(state: State): StaticScopeSnapshot | undefined {
		const initialState = ScopeSnapshotSelectorComponent.initialStateFromState(state);
		return ScopeSnapshotSelectorComponent.selectedScopeSnapshot(initialState);
	}

	// To get the initial state from a given state, simply remove the modifications object from the state.
	static initialStateFromState<GivenState extends State>(state: GivenState): GivenState {
		if ("modifications" in state) {
			return {
				...state,
				modifications: undefined
			}
		}
		return {...state};
	}

	static isScopeVariableSelectionsDirty(state: State, scopeVariableId: ScopeVariableFieldsForScopeSnapshotSelectorFragment["id"]): boolean {
		const initialState = this.initialStateFromState(state);

		const initialScopeOptionIds = ScopeSnapshotSelectorComponent.scopeDefinitionMap(initialState).get(scopeVariableId);
		const newScopeOptionIds = ScopeSnapshotSelectorComponent.scopeDefinitionMap(state).get(scopeVariableId);

		return !_.isEqual(initialScopeOptionIds, newScopeOptionIds);
	}

	static isSelectedScopeSnapshotIdDirty(state: State): boolean {
		const initialScopeSnapshotId = ScopeSnapshotSelectorComponent.initialScopeSnapshot(state)?.id;
		const newScopeSnapshotId = ScopeSnapshotSelectorComponent.selectedScopeSnapshot(state)?.id;

		return !_.isEqual(initialScopeSnapshotId, newScopeSnapshotId);
	}

	static updateStateWithNewScopeVariables(state: State, newScopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[]): State {
		state.scopeVariables = newScopeVariables;

		return state;
	}

	static updateStateWithNewValidatableScopeContainments(state: State, newValidatableScopeContainments: EssentialValidatableScopeContainment[]): State {
		if ((state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION) && state.modifications?.state === ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED) {
			state.modifications.validatableScopeContainments = newValidatableScopeContainments;
		}

		return state;
	}

	static updateStateWithNewScopes<GivenState extends State>(state: GivenState, newScopes: ScopeFieldsForScopeSnapshotSelectorFragment[]): GivenState {
		if (state.initialScope) {
			const newInitialScope = newScopes.find(scope => scope.id === state.initialScope.id);
			if (newInitialScope) {
				state.initialScope = newInitialScope;
			}
		}

		if (state.initialScopeSnapshot) {
			const newInitialScope = newScopes.find(scope => scope.id === state.initialScopeSnapshot.scope.id);
			if (newInitialScope) {
				state.initialScopeSnapshot = {
					...state.initialScopeSnapshot,
					scope: newInitialScope
				}
			}
		}

		if (
			(
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION
			) &&
			(
				state.modifications?.state === ModificationState.SELECTED_SCOPE_WITH_NO_VERSION_INPUT ||
				state.modifications?.state === ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION ||
				state.modifications?.state === ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION
			)
		) {
			const selectedScope = state.modifications.selectedScope;
			const newSelectedScope = newScopes.find(scope => scope.id === selectedScope.id);
			if (newSelectedScope) {
				state.modifications = {
					...state.modifications,
					selectedScope: newSelectedScope
				}
			}
		}

		else if (
			(state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) &&
			state.modifications?.state === ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN
		) {
			const selectedScopeSnapshot = state.modifications.selectedScopeSnapshot;
			const newSelectedScope = newScopes.find(scope => scope.id === selectedScopeSnapshot.scope.id);
			if (newSelectedScope) {
				state.modifications = {
					...state.modifications,
					selectedScopeSnapshot: {
						...selectedScopeSnapshot,
						scope: newSelectedScope
					}
				}
			}
		}

		// If all scope variables have scope options selected, then see if the selected scope options match any of the scopes. If so, then select that scope. Otherwise, enable the version input to allow the user to create a new scope with the selected scope options, along with a snapshot.
		else if (
			(
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION
			)
			&&
			(
				state.modifications?.state === ModificationState.SELECTED_SCOPE_OPTIONS_AND_SEARCHING_FOR_SCOPE ||
				state.modifications?.state === ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED
			) // This predicate ensures that all scope variables have scope options selected.
		) {
			// This is guaranteed to have all scope variables with scope options selected due to the above predicates.
			const scopeDefinitionMap = state.modifications.scopeDefinitionMap;
			const foundScopeWithMatchingScopeOptionIds = newScopes.find(
				scope => scope.comparableScopeDefiners.every(
					comparableScopeDefiner => {
						const selectedScopeOptionIds = scopeDefinitionMap.get(comparableScopeDefiner.scopeVariable.id);

						// Determine if the selected scope options match any of the provided scopes in newScopes, by comparing the selected scope options with the scope options of the each provided scope.
						return 	selectedScopeOptionIds !== undefined
										&&
										(
											(comparableScopeDefiner.scopeOption === null && comparableScopeDefiner.isScopeOptionIdExplicitlySelected && selectedScopeOptionIds.size === 0) ||																	// Ensure that the 'All' option is selected in both.
											(comparableScopeDefiner.scopeOption === null && !comparableScopeDefiner.isScopeOptionIdExplicitlySelected && selectedScopeOptionIds.size > 0) ||																	// Ensure that the 'All' option is not selected in both.
											(comparableScopeDefiner.scopeOption !== null && comparableScopeDefiner.isScopeOptionIdExplicitlySelected && selectedScopeOptionIds.has(comparableScopeDefiner.scopeOption.id)) || // Ensure that the selected scope option is selected in both.
											(comparableScopeDefiner.scopeOption !== null && !comparableScopeDefiner.isScopeOptionIdExplicitlySelected && !selectedScopeOptionIds.has(comparableScopeDefiner.scopeOption.id))  // Ensure that the selected scope option is not selected in both.
										);
					}
				)
			);

			// If we found the scope that matches the selected scope options, then select it.
			if (foundScopeWithMatchingScopeOptionIds) {
				let firstScopeSnapshot = foundScopeWithMatchingScopeOptionIds.scopeSnapshots[0];

				// If there is a snapshot in the scope, then help the user by auto-selecting it to speed up the process of selecting a snapshot. Only do this if the staticity allows you to select a snapshot.
				if (firstScopeSnapshot && (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION)) {
					// If we previously had a version input field, then keep it, but update the selector's state to indicate that we now have a selected scope.
					if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION && state.modifications.state === ModificationState.SELECTED_SCOPE_OPTIONS_BUT_NO_MATCHING_SCOPE_SELECTED && state.modifications.versionInput !== undefined) {
						state.modifications = {
							state: ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION,
							isSaving: false,
							selectedScope: foundScopeWithMatchingScopeOptionIds,
							versionInput: state.modifications.versionInput
						}
					}
					// If we did not have a version input field, then simply update the selector's state to indicate that we now have a selected scope, and possibly even a selected scope snapshot (if there is only one snapshot for this scope).
					else {
						// If there's only one snapshot, then select it.
						if (foundScopeWithMatchingScopeOptionIds.scopeSnapshots.length === 1) {
							state.modifications = {
								state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
								selectedScopeSnapshot: {
									...firstScopeSnapshot,
									scope: foundScopeWithMatchingScopeOptionIds
								}
							}
						}
						// Otherwise, if there is more than one snapshot for this scope, then only select the scope, but don't select a snapshot. The user will see "Select a snapshot" in the dropdown label.
						else {
							state.modifications = {
								state: ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
								selectedScope: foundScopeWithMatchingScopeOptionIds
							}
						}
					}
				}
				// If there are no snapshots, or if the staticity does not allow for selecting a snapshot, then simply select the scope below.
				else {
					// If the staticity allows it, then enable the version input to allow the user to create a new scope with the selected scope options, along with a snapshot. The user will see "Enter a new version" in the version input placeholder.
					if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) {
						state.modifications = {
							state: ModificationState.SELECTED_SCOPE_WITH_INPUTTABLE_VERSION,
							isSaving: false,
							selectedScope: foundScopeWithMatchingScopeOptionIds,
							versionInput: ""
						}
					}
					// Otherwise, just select the scope, but don't select a snapshot. If the staticity is SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION, then the user will see "No snapshots available" in the dropdown label.
					else if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION) {
						state.modifications = {
							state: ModificationState.SELECTED_SCOPE_WITH_SELECTABLE_VERSION,
							selectedScope: foundScopeWithMatchingScopeOptionIds
						}
					}
					// But if the staticity is SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT, then the user won't see the snapshots selector at all.
					else if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT) {
						state.modifications = {
							state: ModificationState.SELECTED_SCOPE_WITH_NO_VERSION_INPUT,
							selectedScope: foundScopeWithMatchingScopeOptionIds
						}
					}
				}
			}
		}

		return state;
	}

	static updateStateWithNewScopeSnapshots(state: State, newScopeSnapshots: ScopeSnapshotWithOtherSnapshotsInSameScope[]): State {
		if (state.initialScopeSnapshot) {
			const newInitialScopeSnapshot = newScopeSnapshots.find(scopeSnapshot => scopeSnapshot.id === state.initialScopeSnapshot.id);
			if (newInitialScopeSnapshot) {
				state.initialScopeSnapshot = newInitialScopeSnapshot;
			}
		}

		if (
			(
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION ||
				state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION ||
				state.staticity === Staticity.STATIC_SCOPE_WITH_SELECTABLE_VERSION ||
				state.staticity === Staticity.STATIC_SCOPE_WITH_INPUTTABLE_VERSION
			) &&
			state.modifications?.state === ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN
		) {
			if (state.staticity === Staticity.SELECTABLE_SCOPE_WITH_SELECTABLE_VERSION || state.staticity === Staticity.SELECTABLE_SCOPE_WITH_INPUTTABLE_VERSION) {
				const selectedScopeSnapshot = state.modifications.selectedScopeSnapshot;
				const newSelectedScopeSnapshot = newScopeSnapshots.find(scopeSnapshot => scopeSnapshot.id === selectedScopeSnapshot.id);
				if (newSelectedScopeSnapshot) {
					state.modifications = {
						state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
						selectedScopeSnapshot: newSelectedScopeSnapshot
					}
				}
			}
			else {
				const selectedScopeSnapshotId = state.modifications.selectedScopeSnapshotId;
				const newSelectedScopeSnapshot = newScopeSnapshots.find(scopeSnapshot => scopeSnapshot.id === selectedScopeSnapshotId);
				if (newSelectedScopeSnapshot) {
					state.modifications = {
						state: ModificationState.SELECTED_SCOPE_SNAPSHOT_VIA_DROPDOWN,
						selectedScopeSnapshotId: newSelectedScopeSnapshot.id
					}
				}
			}
		}

		const newScopes = newScopeSnapshots.map(scopeSnapshot => scopeSnapshot.scope);

		ScopeSnapshotSelectorComponent.updateStateWithNewScopes(state, newScopes);

		return state;
	}

	static extractStaticScopeSnapshotFromValidatableInheritance(validatableInheritance: EssentialValidatableScopeContainment, scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[], extractParentSnapshot: boolean): StaticScopeSnapshot {

		const containableScopeDefinition: EssentialValidatableScopeContainment["containableScopeDefinition"] = _.clone(extractParentSnapshot ? validatableInheritance.containableDirectlyInheritedScopeDefinition : validatableInheritance.containableScopeDefinition);

		const scopeVariableIdsWhereAllIsSelected: number[] = [];

		for (const scopeVariableIdToOptionIdString of containableScopeDefinition.filter(scopeVariableIdWithScopeOptionId => scopeVariableIdWithScopeOptionId.split('-')[1] === '*')) {
			const scopeVariableId = scopeVariableIdToOptionIdString.split('-')[0];
			if (scopeVariableId !== undefined) {
				scopeVariableIdsWhereAllIsSelected.push(parseInt(scopeVariableId));
			}
		}

		const comparableScopeDefiners: StaticScopeSnapshot["scope"]["comparableScopeDefiners"] = [];

		for	(const scopeVariable of scopeVariables) {
			if (scopeVariableIdsWhereAllIsSelected.includes(scopeVariable.id)) {
				for (const scopeOption of scopeVariable.scopeOptions) {
					comparableScopeDefiners.push({
						scopeVariable,
						scopeOption,
						isScopeOptionIdExplicitlySelected: false
					});
				}
				comparableScopeDefiners.push({
					scopeVariable,
					scopeOption: null,
					isScopeOptionIdExplicitlySelected: true
				});
			}
			else {
				for (const scopeOption of scopeVariable.scopeOptions) {
					comparableScopeDefiners.push({
						scopeVariable,
						scopeOption,
						isScopeOptionIdExplicitlySelected: containableScopeDefinition.includes(`${scopeVariable.id}-${scopeOption.id}`)
					});
				}
			}
		}

		let staticScopeSnapshot: StaticScopeSnapshot;
		if (extractParentSnapshot) {
			staticScopeSnapshot = {
				id: validatableInheritance.directlyInheritedScopeSnapshotId,
				scopeId: validatableInheritance.directlyInheritedScopeId,
				scopeVersion: validatableInheritance.directlyInheritedScopeSnapshot.scopeVersion,
				createdAt: validatableInheritance.directlyInheritedScopeSnapshot.createdAt,
				scope: {
					id: validatableInheritance.directlyInheritedScopeId,
					comparableScopeDefiners: comparableScopeDefiners
				}
			};
		}
		else {
			staticScopeSnapshot = {
				id: validatableInheritance.scopeSnapshotId,
				scopeId: validatableInheritance.scopeId,
				scopeVersion: validatableInheritance.scopeSnapshot.scopeVersion,
				createdAt: validatableInheritance.scopeSnapshot.createdAt,
				scope: {
					id: validatableInheritance.scopeId,
					comparableScopeDefiners: comparableScopeDefiners
				}
			};
		}

		return staticScopeSnapshot;
	}

	isSnapshotsSelectorPresent(): boolean {
		const state = this.requireState();
		const isVersionInputHidden = ScopeSnapshotSelectorComponent.versionInputtableModificationsOrReturnUndefined(state) === undefined;
		const selectedScope = ScopeSnapshotSelectorComponent.selectedScope(state);

		return isVersionInputHidden
			&&
			selectedScope !== undefined
			&&
			state.staticity !== Staticity.SELECTABLE_SCOPE_WITH_NO_VERSION_INPUT
	}

	static primitiveToStaticScopeSnapshot(primitive: PrimitiveScopeSnapshot, scope: StaticScope): StaticScopeSnapshot {
		return {
			id: primitive.id,
			scopeId: primitive.scopeId,
			scopeVersion: primitive.scopeVersion,
			createdAt: primitive.createdAt,
			scope: scope
		}
	}

	static primitiveToSelectableScopeSnapshot(primitive: PrimitiveScopeSnapshot, scope: ScopeWithSnapshots): ScopeSnapshotWithOtherSnapshotsInSameScope {
		return {
			id: primitive.id,
			scopeId: primitive.scopeId,
			scopeVersion: primitive.scopeVersion,
			createdAt: primitive.createdAt,
			scope: scope
		}
	}
}
